import React, { useMemo } from "react";
import { Button, Container, LinearProgress } from "@mui/material";
import { useAppSelector, useAppDispatch } from "redux/reducers/hooks";
import { useTranslation } from "react-i18next";
import {
  incrementSlideCounter,
  updateWaitingList,
} from "redux/slices/appointmentSlice";

const WitingListConfirm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loading } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  const renderContent = useMemo(() => {
    if (loading) {
      return <LinearProgress data-testid="loader-testid" />;
    }

    return (
      <div className="options-contianer">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            dispatch(incrementSlideCounter());
            dispatch(
              //@ts-ignore
              updateWaitingList({
                value: true,
                display: t("createEventForm.yes"),
              })
            );
          }}
          className="ff-primary bg-blue-light app-btn yes-btn mr-2"
        >
          {t("createEventForm.yes")}
        </Button>
        <Button
          className="ff-primary text-dark app-btn yes-btn"
          onClick={() => {
            dispatch(incrementSlideCounter());
            dispatch(
              //@ts-ignore
              updateWaitingList({
                value: false,
                display: t("createEventForm.no"),
              })
            );
          }}
          variant="text"
        >
          {t("createEventForm.skip")}
        </Button>
      </div>
    );
  }, [dispatch, loading, t]);

  return (
    <>
      <Container maxWidth="xl">
        <h2 className="text-purple-midi option-lg option-header ff-priamry">
          {t("createEventForm.q_1_1")} <br /> {t("createEventForm.q_1_2")}
        </h2>

        <h4 className=" mb-5 pt-2 ff-priamry">
          {t("createEventForm.q_notify")}
        </h4>
        {renderContent}
      </Container>
    </>
  );
};

export default WitingListConfirm;
