import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Base from './base.js';
import Modals from '../component/Modal/modal';

function AppointmentSettings(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <Base title={'APPOINTMENT SETTINGS'} showHeader={true}>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold mb-1">NEW CLIENT</span>
          <span className="ml-4">Intake form: Form 1</span>
          <span className="ml-4">Payment Link: Send immediately</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold mb-1">REGULAR CLIENT</span>
          <span className="ml-4">Intake form: Form 1</span>
          <span className="ml-4">Payment Link: Send immediately</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold mb-1">AAPOINTMENT TYPE 3</span>
          <span className="ml-4">Intake form: Form 1</span>
          <span className="ml-4">Payment Link: Send immediately</span>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-end button-below">
          <Button variant="primary" size="md" className="mr-5 btn-pg-low" onClick={() => setShowModal(true)}>
            ADD NEW
          </Button>
        </Col>
      </Row>
      <Modals showModal={showModal} modalHeader={'ADD NEW APPOINTMENT'} setShowModal={setShowModal}>
        <Row className="mt-5 justify-content-center">
          <Col xs={12} sm={12} md={10}>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={4} className="text-right">
                Appointment type
              </Form.Label>
              <Col sm={12} md={6}>
                <Form.Control autoComplete="off" type="text" name="type" placeholder="Text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4">
              <Form.Label column sm={12} md={4} className="text-right">
                Intake form
              </Form.Label>
              <Col sm={12} md={6}>
                <Form.Control autoComplete="off" as="select" name="intakeForm">
                  <option value="">form 1</option>
                  <option value="">from 2</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Send payment link immediately when creating appointments"
              />
            </Form.Group>
            <p> Percent to be paid for down payment:</p>
            <div className="down-payment">
              <span>Down payment amount (%)</span>
              <Form.Control
                autoComplete="off"
                aria-label="Default"
                value="50"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>
            <div className="down-payment mt-1">
              <span>Down payment should be paid</span>
              <Form.Control
                autoComplete="off"
                aria-label="Default"
                value="96"
                aria-describedby="inputGroup-sizing-default"
              />
              <span>
                hours before appointment time. (This will only be used if immediate sending is not
                enabled.)
              </span>
            </div>
            <div className="down-payment mt-1">
              <span>Remaining payment should be paid</span>
              <Form.Control
                autoComplete="off"
                aria-label="Default"
                value="48"
                aria-describedby="inputGroup-sizing-default"
              />
              <span>hours before appointment time.</span>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-end mb-2 mt-5">
          <Col
            xs={12}
            sm={12}
            md={12}
            className="d-flex flex-wrap justify-content-center justify-content-md-end">
            <Button variant="primary" size="md" className="mr-2 mb-1">
              CANCEL
            </Button>
            <Button variant="primary" size="md" className="mr-2 mb-1">
              SAVE
            </Button>
            <Button variant="primary" size="md" className="mr-2 mb-1">
              DELETE
            </Button>
            <Button variant="primary" size="md" className="mb-1">
              ADD ANOTHER
            </Button>
          </Col>
        </Row>
      </Modals>
    </Base>
  );
}
export default AppointmentSettings;
