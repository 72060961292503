import { Row, Col, Form, Container, Button } from 'react-bootstrap';
import Base from './base.js';
import { Formik } from 'formik';

function UserManagement(props) {
  const initialValues = {
    userName: '',
    email: '',
    password: '',
    userType: '',
  };

  return (
    <Base title={'MANAGE USER'} showHeader={true}>
      <Row className="mt-4 mb-3">
        <Col xs={12} md={8} lg={7} xl={6}>
          <Formik initialValues={initialValues}>
            {({ values, handleChange }) => (
              <Form autoComplete="off" className="mb-3">
                <Container>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={12} md={4} >
                      User Name
                    </Form.Label>
                    <Col sm={12} md={8} >
                      <Form.Control
                        placeholder="First and Last Name"
                        autoComplete="off"
                        type="text"
                        name="userName"
                        value={values.userName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={3} md={4}>
                      Email
                    </Form.Label>
                    <Col sm={12} md={8} >
                      <Form.Control
                        placeholder="email@example.com"
                        autoComplete="off"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={3} md={4} >
                      Password
                    </Form.Label>
                    <Col sm={12} md={8} >
                      <Form.Control
                        autoComplete="off"
                        type="password"
                        name="password"
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={3} md={4}>
                      User Type
                    </Form.Label>
                    <Col sm={12} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="userType"
                        onChange={handleChange}>
                        <option value="">Type</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className='justify-content-center mb-3'>
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-center button-below">
          <Button variant="primary" size="md" className='btn-pg-low'>
            CANCEL
          </Button>
          <Button variant="primary" size="md" className="ml-2 btn-pg-low">
            SAVE
          </Button>
        </Col>
      </Row>
    </Base>
  );
}
export default UserManagement;
