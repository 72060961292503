import { Row, Col, Form, Card, Button, Table } from "react-bootstrap";
import Base from "./base.js";
import { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { gql, useLazyQuery } from "@apollo/client";
import { urlActions, getValidDateStringForGraphQL } from "../Common/helpers";
import Preloader from "../Common/Preloder/Preloader";
import { accountsFilterVar } from "../cache/cache.js";
import { useTranslation } from "react-i18next";
import { deepCopy } from "../Common/helpers";

export default function PractitionerReport(props) {
  const patient_record = props.location.state
    ? props.location.state.patient_record
    : null;
  const formikRef = useRef();
  const [activePage, setActivePage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [start, setStart] = useState("");
  const [endDate, setEndDate] = useState("");
  const [end, setEnd] = useState("");
  const [doctorTransactions, setDoctorTransactions] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }
    getDoctorTransactions();
    setStart("");
    setEnd("");
  }, []);

  const DOCTOR_TRANSACTIONS = gql`
    query {
      doctorTransactions(active: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            totalAppointments
            confirmedAppointments
            checkedinAppointments
            cancelledAppointments
            noshowAppointments
            appointmentIncome
            otherIncome
            active
            totalOnline
            totalClinic
            totalOther
          }
        }
      }
    }
  `;

  const [
    getDoctorTransactions,
    { data: rawDoctorTransactions = null, loading },
  ] = useLazyQuery(DOCTOR_TRANSACTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getUrl();
    },
  });

  const getUrl = () => {
    window.history.pushState(
      "",
      "",
      "/practitioner/report/?start=" + start + "&end=" + end
    );
  };

  useEffect(() => {
    setDoctorTransactions(deepCopy(rawDoctorTransactions));
  }, [rawDoctorTransactions]);

  var doctor_Transactions = doctorTransactions?.doctorTransactions?.edges?.sort(
    (a, b) => a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  const onSubmit = (values) => {
    // since we have date variable in the filter query we need to pass a valid date
    //  hence creating dummy dates that will cover all dates incase date filter is not seleted
    // let defaultStart= new Date("2002/02/02");
    // let defaultEnd= new Date("2052/02/02");
    let startDateStr = "";
    let endDateStr = "";

    if (startDate) {
      let startDateObj = new Date(startDate);
      startDateStr = new Date(
        startDateObj.getTime() - startDateObj.getTimezoneOffset() * 60000
      );
    }
    if (endDate) {
      let endDateObj = new Date(endDate);
      endDateStr = new Date(
        endDateObj.getTime() - endDateObj.getTimezoneOffset() * 60000
      );
    }

    setStart(getValidDateStringForGraphQL(startDateStr));
    setEnd(getValidDateStringForGraphQL(endDateStr));

    let variables = {};
    if (startDateStr) {
      variables["start"] = getValidDateStringForGraphQL(startDateStr);
    }
    if (endDateStr) {
      variables["end"] = getValidDateStringForGraphQL(endDateStr);
    }
    if (startDateStr && endDateStr) {
      variables["__uriExtra"] =
        "?start=" +
        getValidDateStringForGraphQL(startDateStr) +
        "&end=" +
        getValidDateStringForGraphQL(endDateStr);
    }

    getDoctorTransactions({
      variables: variables,
    });
  };

  const initialValues = {
    startDate: "",
    endDate: "",
  };

  const renderList = (doctorTransactions) => {
    let doctor_transaction_list =
      doctorTransactions && doctorTransactions?.doctorTransactions?.edges
        ? doctorTransactions?.doctorTransactions?.edges
        : [];
    if (doctor_transaction_list.length > 0) {
      return doctor_transaction_list.map((itemNode, index) => {
        let item = itemNode.node;
        // let date = new Date(item.actualDate);
        return (
          <tr key={index} className="patient_table_row">
            <td>
              {item?.firstName} {item?.lastName}
            </td>
            <td>{item?.totalAppointments}</td>
            <td>{item?.totalOnline}</td>
            <td>{item?.totalClinic}</td>
            <td>{item?.totalOther}</td>
            <td>{item?.confirmedAppointments}</td>
            <td>{item?.checkedinAppointments}</td>
            <td>{item?.cancelledAppointments}</td>
            <td>{item?.noshowAppointments}</td>
            <td>{parseFloat(item?.appointmentIncome).toFixed(2)}</td>
            <td>{parseFloat(item?.otherIncome).toFixed(2)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>{t("No data available")}</td>
        </tr>
      );
    }
  };

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
      getDoctorTransactions();
      setStartDate("");
      setEndDate("");
      setStart("");
      setEnd("");
      getUrl();
    }
    accountsFilterVar(null);
  };

  const getTotal = (doctorTransactions) => {
    let doctor_transaction_list =
      doctorTransactions && doctorTransactions?.doctorTransactions?.edges
        ? doctorTransactions?.doctorTransactions?.edges
        : [];
    if (doctor_transaction_list.length > 0) {
      let appointmentTotal = 0.0;
      let otherTotal = 0.0;
      doctor_transaction_list.forEach((element) => {
        appointmentTotal += parseFloat(element?.node?.appointmentIncome);
        otherTotal += parseFloat(element?.node?.otherIncome);
      });

      return (
        <>
          <td className="text-uppercase font-bold">
            {parseFloat(appointmentTotal).toFixed(2)} KWD
          </td>
          <td className="text-uppercase font-bold">
            {parseFloat(otherTotal).toFixed(2)} KWD
          </td>
        </>
      );
    }
  };

  return (
    <Base title={"Practitioner Report"} showHeader={true}>
      <Row className="payment-section">
        <Col md={12} lg={12} className="mr-auto">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="justify-content-start">
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        From
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        To
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={12} lg={6} xl={4} sm={12}>
                    <div
                      className={`d-flex justify-content-end submitPayment ${
                        patient_record ? "pt-3" : ""
                      }`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={resetFilter}
                      >
                        {" "}
                        Reset{" "}
                      </Button>
                      <Button type="submit" variant="primary">
                        {" "}
                        Submit{" "}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={12} className="mb-4">
          {loading ? (
            <Preloader />
          ) : (
            <Card className="paymentListCard">
              <Card.Body className="p-0">
                <Table responsive className=" table-borderless">
                  <thead>
                    <tr className="patient_table_row tableHeader">
                      <th scope="col " className="text-uppercase">
                        Practitioner
                      </th>
                      <th scope="col " className="text-uppercase">
                        Appointment Count
                      </th>
                      <th scope="col " className="text-uppercase">
                        Online{" "}
                      </th>
                      <th scope="col " className="text-uppercase">
                        Clinic{" "}
                      </th>
                      <th scope="col " className="text-uppercase">
                        Other{" "}
                      </th>
                      <th scope="col " className="text-uppercase">
                        Confirmed
                      </th>
                      <th scope="col " className="text-uppercase">
                        Checked In
                      </th>
                      <th scope="col " className="text-uppercase">
                        Cancelled
                      </th>
                      <th scope="col " className="text-uppercase">
                        No Show
                      </th>
                      <th scope="col " className="text-uppercase">
                        Appointment Income
                      </th>
                      <th scope="col " className="text-uppercase">
                        Service Income
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctorTransactions ? renderList(doctorTransactions) : null}
                    {doctorTransactions ? (
                      <tr>
                        <td colSpan="5"></td>
                        <td className="text-uppercase font-bold">
                          Total Amount
                        </td>
                        {getTotal(doctorTransactions)}
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Base>
  );
}
