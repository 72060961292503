//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { client } from "apolloSettings";

import {
  CREATE_UPDATE_CONSULATION_FORM,
  CREATE_UPDATE_THERAPY_FORM,
  GET_PATIENT,
  GET_PATIENT_PROFILE,
  UPDATE_PATIENT_PROFILE,
} from "redux/actions/graphqlQueries/patient";

export const initialState = {
  loading: false,
  patientData: null,
  error: false,
  patientProfile: null,
};

export const getPatient = createAsyncThunk(
  "patient/getPatient",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.query({
        query: GET_PATIENT,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.getPatient;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createUpdatePatientConsultation = createAsyncThunk(
  "patient/createUpdatePatientConsultation",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_UPDATE_CONSULATION_FORM,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.createUpdatePatientConsultation;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createUpdatePatientTherapy = createAsyncThunk(
  "patient/createUpdatePatientTherapy",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_UPDATE_THERAPY_FORM,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.createUpdatePatientTherapy;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPatientProfile = createAsyncThunk(
  "patient/getPatientProfile",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.query({
        query: GET_PATIENT_PROFILE,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.getPatient;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePatientProfile = createAsyncThunk(
  "patient/updatePatientProfile",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_PATIENT_PROFILE,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.patientProfileUpdate.obj;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const patientSlice = createSlice({
  name: "appointmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatient.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.patientData = action.payload;
      })
      .addCase(getPatient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createUpdatePatientConsultation.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createUpdatePatientConsultation.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.patientData = action.payload.obj;
        }
      )
      .addCase(
        createUpdatePatientConsultation.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(createUpdatePatientTherapy.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createUpdatePatientTherapy.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.patientData = action.payload.obj;
        }
      )
      .addCase(
        createUpdatePatientTherapy.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(getPatientProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPatientProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.patientProfile = action.payload;
        }
      )
      .addCase(
        getPatientProfile.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updatePatientProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updatePatientProfile.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.patientProfile = action.payload;
        }
      )
      .addCase(
        updatePatientProfile.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

// export const {} = appointmentSlice.actions;

export default patientSlice.reducer;
