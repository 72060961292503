import Base from '../base.js';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SearchForPatient from './SearchForPatientHoc';

function SearchForPatientPage(props) {
  return (
    <Base title={'Search for Patient'} showHeader={true}>
      <SearchForPatient />
    </Base>
  );
}
export default withRouter(SearchForPatientPage);
