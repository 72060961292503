import React, { useMemo } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import Base from "Views/base";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getDirection } from "../appointment/PatientAppointmentForm";
import { useTranslation } from "react-i18next";

import TherapyForm from "components/forms/TherapyForm";

const PatientTherapyForm = () => {
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);

  const theme = useMemo(
    () =>
      createTheme({
        direction: direction, // Use dynamic direction
        palette: {
          mode: "light", // Assuming light mode, but you can make this dynamic as well
        },
      }),
    [direction]
  );

  const cacheRtl = useMemo(
    () =>
      createCache({
        key: direction === "rtl" ? "muirtl" : "mui",
        stylisPlugins: direction === "rtl" ? [rtlPlugin] : [],
      }),
    [direction]
  );

  return (
    <Base
      isDashboard={true}
      showHeader={false}
      isPatientPortal={true}
      containerClass="mt-0"
      pageHeaderSx={{ height: "auto" }}
    >
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="bg-main mt-4" dir={direction}>
            {/* title */}
           

            <div className="d-flex justify-content-between align-items-center py-4">
              <h3 className="ff-priamry text-purple-dark py-4">
              {t("therapyForm.title")}
              </h3>
              <span className="text-purple-dark">2/2</span>
            </div>
            <TherapyForm />
          </div>
        </ThemeProvider>
      </CacheProvider>
    </Base>
  );
};

export default PatientTherapyForm;
