import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  capitalizeFirstLetter,
  isUserStaff,
  getValidDateStringForGraphQL,
  displayMiddleName,
  getAge,
  concatAllErrors,
} from "../../Common/helpers.js";
import FileUpload from "../../component/upload";
import DatePicker from "react-datepicker";
import CountrySelect from "../countrySelect";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

const CreateNewPatient = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const {
    createUpdatePatient,
    UpdatePatient,
    history,
    is_edit,
    formikRef,
    countriesData,
    governoratesData,
    getAreasForGov,
    areasData,
  } = props;
  const [editPatient, setEditPatient] = useState(null);
  const identifier = props.match.params.identifier;
  const [currentImage, setCurrentImage] = useState(null);
  // const has_appointment_add_permission = getPermissionForAction("appointment", "add");
  const isStaff = isUserStaff();
  const [startDate, setStartDate] = useState("");
  const [focus, setFocus] = useState(null);
  const { t } = useTranslation();
  const REQUEST_PATIENT = gql`
    query {
      patients(identifier:"${identifier}"){
        edges {
          node {
            firstName
            middleName
            lastName
            dob
            id
            idFile
            email
            phone
            gender
            civilId
            doctor{
              id
            }
            firstVisit
            address
            referredBy
            discount
            impressionDiagnosis
            medication
            identifier
            governorate{
              name
              id
            }
            area{
              name
              id
            }
            country{
              name
              code
            }
            
          }
        }
      }
    }
  `;

  const { data } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PATIENT error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (is_edit && data && data.patients && data.patients.edges[0]) {
      let edit_patient = data.patients.edges[0].node;
      setEditPatient(edit_patient);
      if (edit_patient.governorate && edit_patient.governorate.name) {
        let val = {
          governorate: edit_patient.governorate.id,
        };
        getAreasForGov({ variables: val });
      }
      if (edit_patient.dob) {
        setStartDate(new Date(edit_patient.dob));
      }
      if (edit_patient.idFile) {
        setCurrentImage(edit_patient.idFile);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_edit, data]);

  const onSubmit = (values) => {
    let val = {
      firstName: capitalizeFirstLetter(values.firstName),
      middleName: capitalizeFirstLetter(values.middleName),
      lastName: capitalizeFirstLetter(values.lastName),
      phone: values.phone_number,
      dob: getValidDateStringForGraphQL(startDate),
      email: values.email,
      gender: values.gender,
      civil_id: values.civil_id,
      doctor: values.doctor,
      address: values.address,
      country: values.country,
      governorate: values.governorate,
      area: values.area,
      referred_by: values.referred_by,
      diagnosis: values.diagnosis,
      medication: values.medication,
      file_number: values.file_number,
      profile_complete: false,
      identifier: values.file_number,
      discount: values.discount_value,
    };
    let age = getAge(startDate);
    if (age <= 13) {
      alert("Age must be greater than 13");
      return false;
    }
    let variables = val;
    if (is_edit && editPatient) {
      variables["id"] = editPatient.id;
      UpdatePatient({ variables: variables });
    } else {
      createUpdatePatient({
        variables: variables,
      });
    }
  };

  var initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    phone_number: "",
    gender: "",
    civil_id: "",
    doctor: "",
    address: "",
    governorate: "",
    referred_by: "",
    diagnosis: "",
    medication: "",
    file_number: "",
    discount_value: "0.00",
    // first_visit: false,
    file_show: false,
    show_discount: false,
  };

  if (editPatient) {
    initialValues["firstName"] = editPatient.firstName;
    initialValues["middleName"] = displayMiddleName(editPatient.middleName);
    initialValues["lastName"] = editPatient.lastName;
    initialValues["dob"] = new Date(editPatient.dob);
    initialValues["phone_number"] = editPatient.phone;
    initialValues["email"] = editPatient.email;
    initialValues["gender"] = String(editPatient.gender).toLowerCase();
    initialValues["civil_id"] = editPatient.civilId;
    initialValues["doctor"] = editPatient.doctor ? editPatient.doctor.id : "";
    initialValues["address"] = editPatient.address;
    initialValues["referred_by"] = editPatient.referredBy;
    initialValues["diagnosis"] = editPatient.impressionDiagnosis;
    initialValues["medication"] = editPatient.medication;
    initialValues["file_number"] = editPatient.identifier;
    initialValues["country"] = editPatient.country
      ? editPatient.country.code
      : "";
    initialValues["governorate"] = editPatient.governorate
      ? editPatient.governorate.id
      : "";
    initialValues["area"] = editPatient.area ? editPatient.area.id : "";
  }

  const onChangeCountry = (e) => {
    formikRef.current.setFieldValue("country", e.target.value);
  };
  const onChangeGovernorate = (e) => {
    formikRef.current.setFieldValue("governorate", e.target.value);
    let gov = governoratesData.governorates.edges.find(
      (i) => i.node.id === e.target.value
    );
    if (gov) {
      let gov_id = gov.node.id;
      let val = {
        governorate: gov_id,
      };
      getAreasForGov({ variables: val });
    }
  };
  const onChangeArea = (e) => {
    formikRef.current.setFieldValue("area", e.target.value);
  };

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    value = value.replace(/[^a-z '']/gi, "");
    formikRef.current.setFieldValue(fieldName, value);
  };

  const updateFocus = (data) => {
    if (data) {
      setFocus(data);
    }
  };

  const handleSignupPhoneChange = (value) => {
    let num = "+" + value;
    formikRef.current.setFieldValue("phone_number", num);
    // setPhoneNumber(num);
  };

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={11} className="mb-4">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          handleBlur,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row className="text-center">
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pl-0 pr-0">
                  <Form.Group as={Row} className="text-right">
                    <Form.Label
                      xs={12}
                      column
                      sm={4}
                      md={5}
                      className="required"
                    >
                      Patient First Name
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="nope"
                        type="text"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={onChangeEnglishInput}
                        value={values.firstName || ""}
                        id="fn-test"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="required"
                    >
                      Patient Middle Name
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="nope"
                        type="text"
                        name="middleName"
                        onBlur={handleBlur}
                        onChange={onChangeEnglishInput}
                        value={values.middleName || ""}
                        id="mn-test"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="required"
                    >
                      Patient Last Name
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="nope"
                        type="text"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={onChangeEnglishInput}
                        value={values.lastName || ""}
                        id="ln-test"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="text-right required"
                    >
                      Email
                    </Form.Label>
                    <Col sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="nope"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email || ""}
                        id="email-test"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="required"
                    >
                      Date of Birth
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      {/* <Form.Control
                        type="text"
                        autoComplete="bday"
                        required
                        onBlur={handleBlur}
                        name="age"
                        onChange={handleChange}
                        value={values.age || ''}
                      /> */}
                      <DatePicker
                        name="dob"
                        autoComplete="off"
                        selected={startDate}
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                        dateFormat="dd/MMM/yyyy"
                        maxDate={new Date()}
                        id="dob-test"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.age}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="required"
                    >
                      Gender
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        onBlur={handleBlur}
                        as="select"
                        name="gender"
                        value={values.gender}
                        required
                        onChange={handleChange}
                        id="gender-test"
                      >
                        <option value="">Select Gender</option>
                        <option value={"female"}>Female</option>
                        <option value={"male"}>Male</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="">
                    <Form.Label
                      column
                      xs={12}
                      sm={4}
                      md={5}
                      className="required text-right"
                    >
                      Phone Number
                    </Form.Label>
                    <Col xs={12} sm={8} md={7} className="text-left">
                      <CountrySelect
                        required
                        value={values.phone_number || ""}
                        onChange={handleSignupPhoneChange}
                        type="text"
                        name="phone_number"
                        onFocus={() => updateFocus("phone_number")}
                        onBlur={() => updateFocus("blur")}
                        placeholder={
                          focus === "phone_number" ? "" : t("Account.phone")
                        }
                        id="phone-test"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Payment Contact
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="payment_contact"
                        autoComplete="payment_contact"
                        onChange={handleChange}
                        value={values.payment_contact || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group> */}
                  {isStaff ? (
                    <Form.Group as={Row} className="text-left">
                      <Col md={5}></Col>
                      <Col md={7}>
                        <div className="create-new-patient-checkboxes">
                          <Form.Check
                            className="mt-2"
                            type="checkbox"
                            value={values.file_show}
                            name="file_show"
                            onChange={handleChange}
                            id="manual-num-test"
                          />
                          <Form.Label>
                            Administrator manual file number override
                          </Form.Label>
                        </div>
                      </Col>
                    </Form.Group>
                  ) : null}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pl-0 pr-0">
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Practitioner
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        onBlur={handleBlur}
                        as="select"
                        name="doctor"
                        value={values.doctor}
                        required
                        onChange={handleChange}>
                        <option value="">Select Practitioner</option>
                        {doctors.map((doctor) => {
                          return (
                            <option value={doctor.node.id} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group> */}
                  {countriesData ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label
                        column
                        xs={12}
                        sm={4}
                        md={5}
                        className="required"
                      >
                        Country
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="country"
                          value={values.country}
                          required
                          onChange={onChangeCountry}
                          id="country-test"
                        >
                          <option value="">Select Country</option>
                          {Object.entries(countriesData.countries).map(
                            ([key, value], index) => {
                              if (key !== "IL") {
                                return (
                                  <option value={key} key={index}>
                                    {value}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}
                  {governoratesData &&
                  governoratesData.governorates &&
                  governoratesData.governorates.edges &&
                  values.country === "KW" ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label
                        column
                        xs={12}
                        sm={4}
                        md={5}
                        className="required"
                      >
                        Governorate
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="governorate"
                          value={values.governorate}
                          required
                          onChange={onChangeGovernorate}
                          id="govern-test"
                        >
                          <option value="">Select Governorate</option>
                          {governoratesData.governorates.edges.map(
                            (item, index) => {
                              return (
                                <option value={item.node.id} key={index}>
                                  {item.node.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}
                  {areasData &&
                  areasData.areas &&
                  areasData.areas.edges &&
                  values.country === "KW" ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label
                        column
                        xs={12}
                        sm={4}
                        md={5}
                        className="required"
                      >
                        Area
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="area"
                          value={values.area}
                          required
                          onChange={onChangeArea}
                          id="area-test"
                        >
                          <option value="">Select Area</option>
                          {areasData.areas.edges.map((item, index) => {
                            return (
                              <option value={item.node.id} key={index}>
                                {item.node.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Practitioner
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        onBlur={handleBlur}
                        as="select"
                        name="doctor"
                        value={values.doctor}
                        required
                        onChange={handleChange}>
                        <option value="">Select Practitioner</option>
                        {doctors.map((doctor) => {
                          return (
                            <option value={doctor.node.id} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group> */}

                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Address
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="address"
                        autoComplete="address"
                        onChange={handleChange}
                        value={values.address || ""}
                        id="addr-test"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      ID Number
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="civil_id"
                        autoComplete="civil_id"
                        onChange={handleChange}
                        value={values.civil_id || ""}
                        id="id-test"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.civil_id}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {is_edit &&
                  data &&
                  data.patients &&
                  data.patients.edges[0] ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label
                        column
                        xs={12}
                        sm={4}
                        md={5}
                        className="id-label"
                      >
                        <span className="required">ID</span>
                        <small>
                          Please click on the image to select your file
                        </small>
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <FileUpload
                          name="file"
                          imageForPatientEditCreate={true}
                          currentImage={currentImage}
                          setCurrentImage={setCurrentImage}
                          idFile={data.patients.edges[0].node.idFile}
                          patientId={data.patients.edges[0].node.id}
                        />
                      </Col>
                    </Form.Group>
                  ) : null}

                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Referred By
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="referred_by"
                        autoComplete="referred_by"
                        onChange={handleChange}
                        value={values.referred_by || ""}
                        id="refer-test"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.referred_by}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {!is_edit ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5}>
                        ID
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <div className="text-left upload-files-notification">
                          Uploading patient files will be available once the
                          patient has been created.
                        </div>
                      </Col>
                    </Form.Group>
                  ) : null}

                  {values.file_show ? (
                    <>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label column xs={12} sm={4} md={5}>
                          File Number
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="file_number"
                            autoComplete="file_number"
                            onChange={handleChange}
                            value={values.file_number ? values.file_number : ""}
                            id="file-number-test"
                          />
                        </Col>
                      </Form.Group>
                    </>
                  ) : null}
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Impression / Diagnosis
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="diagnosis"
                        autoComplete="diagnosis"
                        onChange={handleChange}
                        value={values.diagnosis || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.diagnosis}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Medication
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="medication"
                        autoComplete="medication"
                        onChange={handleChange}
                        value={values.medication ? values.medication : ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.medication}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group> */}
                  {/* <Form.Group  as={Row} className="text-right">
                    <Form.Label  column xs={12} sm={4} md={5}>Payment Contact Details</Form.Label>
                    <Col xs={12} sm={8} md={7}>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={2}
                      name="payment_contact_details"
                      value={values.payment_contact_details}
                      onChange={handleChange}
                      />
                      </Col>
                  </Form.Group> */}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pl-0 pr-0">
                  <Form.Group as={Row} className="text-left">
                    <Col md={5}></Col>
                    <Col md={7}>
                      <div className="create-new-patient-checkboxes">
                        <Form.Check
                          className="mt-2"
                          type="checkbox"
                          value={values.show_discount}
                          name="show_discount"
                          onChange={handleChange}
                          id="change-discount-test"
                        />
                        <Form.Label>Change Discount</Form.Label>
                      </div>
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} className="text-right">
                      <Col md={5}>
                      </Col>
                      <Col md={7}>
                        <div className='create-new-patient-checkboxes'>
                          <Form.Check
                            className="mt-2"
                            type="checkbox"
                            value={values.file_show}
                            name="file_show"
                            onChange={handleChange}
                          />
                          <Form.Label>
                            Set File Number
                      </Form.Label>

                        </div>
                      </Col>

                    </Form.Group>
                  </Container> */}
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      First visit in Clinic
                    </Form.Label>
                    <Col sm={8} md={7} className="text-left pst-absolute" >
                      <Form.Check
                        className="mt-2"
                        type="checkbox"
                        value={values.first_visit}
                        name="first_visit"
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group> */}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pl-0 pr-0">
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Discount %
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        disabled={!values.show_discount}
                        onBlur={handleBlur}
                        name="discount_value"
                        autoComplete="discount_value"
                        onChange={handleChange}
                        value={values.discount_value}
                        id="discount-test"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className={"button_block mb-2"}>
                <Col
                  className="justify-content-center d-flex mt-2"
                  xs={{ order: "last" }}
                >
                  <Button
                    variant="primary"
                    size="md"
                    active
                    onClick={() => history.goBack()}
                    id="cancel-test"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="justify-content-center d-flex mt-2">
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    active
                    name={!is_edit ? "add" : "edit"}
                    id="submit-test"
                  >
                    Save
                  </Button>
                </Col>
                {/* {!is_edit && has_appointment_add_permission ? (
                  <Col className="justify-content-center d-flex mt-2" xs={{ order: 'first' }}>
                    <Button type="submit" variant="primary" size="md" active name="appointment">
                      Save and Create Appointment
                    </Button>
                  </Col>
                ) : null} */}
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </Col>
  );
};
export default withRouter(CreateNewPatient);
