import React from 'react'

function Badge(props) {
  return (
    <div className='custom-badge'>
      {props.badge}
    </div>
  )
}

export default Badge
