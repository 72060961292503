import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Base from './base.js';
import Modals from '../component/Modal/modal';

function ManageWaitingList(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <Base title={'MANAGE WAITING LIST'} showHeader={true}>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textPrimaryBold">EMERGENCY</span>
          <span className="ml-4">PRIORITY LEVEL 1</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textPrimaryBold">URGENT</span>
          <span className="ml-4">PRIORITY LEVEL 2</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textPrimaryBold">NORMAL</span>
          <span className="ml-4">PRIORITY LEVEL 3</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textPrimaryBold">NEW PATIENT</span>
          <span className="ml-4">PRIORITY LEVEL 4</span>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-end button-below ">
          <Button variant="primary" size="md" className="mr-5 btn-pg-low" onClick={() => setShowModal(true)}>
            ADD NEW
          </Button>
        </Col>
      </Row>
      <Modals
        showModal={showModal}
        modalHeader={'ADD NEW WAITING LIST'}
        setShowModal={setShowModal}>
        <Row className="mb-5 mt-5">
          <Col xs={12} sm={12} md={12}>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={5} className="text-right">
                Waiting List Name
              </Form.Label>
              <Col sm={12} md={7}>
                <Form.Control autoComplete="off" type="text" name="listName" placeholder="Text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={5} className="text-right">
                Priority
              </Form.Label>
              <Col sm={12} md={5}>
                <Form.Control autoComplete="off" as="select" name="priority">
                  <option value="">#</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-2 mt-5">
          <Col
            xs={12}
            sm={12}
            md={12}
            className="d-flex flex-wrap justify-content-center justify-content-sm-end">
            <Button variant="primary" size="md" className="mb-1">
              CANCEL
            </Button>
            <Button variant="primary" size="md" className="ml-2 mr-2 mb-1">
              SAVE
            </Button>
            <Button className="mb-1" variant="primary" size="md">
              ADD ANOTHER
            </Button>
          </Col>
        </Row>
      </Modals>
    </Base>
  );
}
export default ManageWaitingList;
