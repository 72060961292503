import { Row, Col } from 'react-bootstrap';
import Base from './base.js';
import { Link } from 'react-router-dom';


export default function HRPage(props) {
  return (
    <Base title={'Human Resources'} showHeader={true}>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <Link to="/manage/leave/requests">
            <span className="textPrimaryBold">
              MANAGE LEAVE REQUESTS
          </span>
          </Link>
        </Col>
      </Row>
    </Base>
  );
}
