import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Table, Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  success_options,
  PER_PAGE_COUNT,
  urlActions,
  getErrorMessage,
  getPermissionForAction,
  concatAllErrors,
  getDateKuwaitFormatted,
} from "../Common/helpers";
import { Formik } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers";

function ManageLeaveRequests(props) {
  const { history } = props;
  const formikRef = useRef();
  const [startDate, setStartDate] = useState("");
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [activePage, setActivePage] = useState(1);
  const [leavesList, setLeavesList] = useState([]);
  const [userList, setUserList] = useState(null);
  let created = urlActions(window.location.href, "get", "created");
  let user = urlActions(window.location.href, "get", "user");
  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }
  }, []);

  const REQUEST_USERS = gql`
    query {
      users(isActive: true) {
        edges {
          node {
            id
            firstName
            lastName
            approvedSomeLeaveRequests {
              edges {
                node {
                  approvedOne
                  approvedTwo
                }
              }
            }
            approvedSomeLeaveRequestsTwo {
              edges {
                node {
                  approvedOne
                  approvedTwo
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_LEAVES = gql`  
    query ($user:ID, $created:DateTime) {
      leaveRequestsForApproval(
        user:$user,
        created_Date:$created,
        first:${PER_PAGE_COUNT}, offset:${PER_PAGE_COUNT * (activePage - 1)},
      ){
        totalCount
        edges {
          node {
              id
              startDate 
              endDate
              totalDays
              status
              leaveType
              displayLeaveType
              created
              startTime
              endTime
              description
              approvedOneDate
              approvedTwoDate
              approvedOne
              displayStatus
              approvedTwo
              user {
                firstName
                lastName
                phone
                id
                hr{
                  position{
                    name
                  }
                  department{
                    name
                  }
                  numberAnnualLeaves
                }
                
              }
          }
        }
      }
    }
  `;

  const { data: rawUserList } = useQuery(REQUEST_USERS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_USERS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setUserList(deepCopy(rawUserList));
  }, [rawUserList]);

  const [getLeavesFilter, { data: leavesObjFilter = null }] =
    useLazyQuery(REQUEST_LEAVES);
  var users = userList && userList.users ? userList.users.edges : [];
  users = users.sort((a, b) =>
    a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  const APPROVE_LEAVE_REQUEST = gql`
    mutation approvalLeaveRequest($status: String!, $id: ID!) {
      approvalLeaveRequest(input: { id: $id, status: $status }) {
        obj {
          created
          id
          startDate
          endDate
          leaveType
          displayLeaveType
          status
          approvedOneDate
          startTime
          endTime
          approvedOne
          displayStatus
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const APPROVE_LEAVE_REQUEST_TWO = gql`
    mutation approvalLeaveRequestTwo($status: String!, $id: ID!) {
      approvalLeaveRequestTwo(input: { id: $id, status: $status }) {
        obj {
          created
          id
          startDate
          endDate
          leaveType
          displayLeaveType
          startTime
          endTime
          status
          approvedTwo
          approvedTwoDate
          displayStatus
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const [approvalLeaveRequest] = useMutation(APPROVE_LEAVE_REQUEST, {
    onCompleted: ({ approvalLeaveRequest }) => {
      if (
        approvalLeaveRequest?.errors &&
        approvalLeaveRequest?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          approvalLeaveRequest?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = approvalLeaveRequest.obj.displayStatus;
        openSnackbarSuccess("Leave Request " + status, [SNACK_DURATION]);
      }
    },
    refetchQueries: [{ query: REQUEST_LEAVES, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("APPROVE_LEAVE_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [approvalLeaveRequestTwo] = useMutation(APPROVE_LEAVE_REQUEST_TWO, {
    onCompleted: ({ approvalLeaveRequestTwo }) => {
      if (
        approvalLeaveRequestTwo?.errors &&
        approvalLeaveRequestTwo?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          approvalLeaveRequestTwo?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = approvalLeaveRequestTwo.obj.displayStatus;
        openSnackbarSuccess("Leave Request " + status, [SNACK_DURATION]);
      }
    },
    refetchQueries: [{ query: REQUEST_LEAVES, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("APPROVE_LEAVE_REQUEST_TWO error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const approveRequest = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      status: "approved",
    };
    approvalLeaveRequest({ variables: val });
  };

  const approveRequestTwo = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      status: "approved_two",
    };
    approvalLeaveRequestTwo({ variables: val });
  };

  const denyRequest = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      status: "unapproved",
    };
    approvalLeaveRequest({ variables: val });
  };

  const denyRequestTwo = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      status: "unapproved",
    };
    approvalLeaveRequestTwo({ variables: val });
  };

  useEffect(() => {
    let val = {};
    if (created) {
      val["created"] = created;
      setStartDate(new Date(created));
    }

    if (user) {
      val["user"] = user;
    }
    if (Object.keys(val).length > 0) {
      getLeavesFilter({ variables: val });
    } else {
      getLeavesFilter();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    user: user ? user : "",
    startDate: created ? created : "",
  };

  const onSubmit = (values) => {
    let createdDate = startDate;

    let val = {};
    if (values.user) {
      val["user"] = values.user;
    }
    if (createdDate) {
      createdDate.setHours(3);
      val["created"] = createdDate;
    }
    let newUrlParams = "";

    if (val.user) {
      newUrlParams = urlActions(window.location.href, "set", "user", val.user);
    }

    if (newUrlParams && val.created) {
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "created",
        new Date(val.created).toISOString()
      );
    } else if (val.created) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "created",
        new Date(val.created).toISOString()
      );
    }
    window.history.pushState("", "", "?" + newUrlParams);

    getLeavesFilter({ variables: val });
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    let created = urlActions(window.location.href, "get", "created");
    let user = urlActions(window.location.href, "get", "user");
    let otherParams = "";
    if (user) {
      otherParams += "&user=" + user;
    }
    if (created) {
      otherParams += "&created=" + created;
    }
    if (otherParams) {
      history.push("/manage/leave/requests?page=" + number + otherParams);
    } else {
      history.push("/manage/leave/requests?page=" + number);
    }
  };

  const renderPages = () => {
    let total_pages =
      leavesObjFilter &&
      leavesObjFilter.leaveRequestsForApproval &&
      leavesObjFilter.leaveRequestsForApproval.totalCount
        ? Math.ceil(
            leavesObjFilter.leaveRequestsForApproval.totalCount / PER_PAGE_COUNT
          )
        : 1;
    let items = [];
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
      setStartDate("");
    }
    let newUrlParams = urlActions(window.location.href, "delete", "user");
    newUrlParams = urlActions(
      window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
      "delete",
      "created"
    );
    window.history.pushState("", "", "?" + newUrlParams);
    handlePageChange(1);
    getLeavesFilter();
  };

  const getStatus = (displayStatus) => {
    if (displayStatus === "Unapproved") {
      return "Denied";
    } else {
      return displayStatus;
    }
  };
  useEffect(() => {
    let leavesArr = leavesObjFilter?.leaveRequestsForApproval?.edges;
    if (leavesArr?.length > 0) {
      if (getPermissionForAction("leave_request_tier_two", "approve")) {
        leavesArr = leavesArr?.filter((i) => i.node.approvedOne === true);
        setLeavesList(leavesArr);
      } else {
        // leavesArr = leavesArr.filter(i => i.node.approvedOne !== true);
        setLeavesList(leavesArr);
      }
    }
  }, [leavesObjFilter]);

  const getButtons = (leave_obj) => {
    return (
      <>
        <td className="icon">
          <Button variant="link" className="py-0" id="leave-approve-test">
            {" "}
            <FontAwesomeIcon
              icon={faCheckCircle}
              onClick={() => {
                leave_obj.approvedOne === true
                  ? approveRequestTwo(leave_obj)
                  : approveRequest(leave_obj);
              }}
            />{" "}
          </Button>
          <Button variant="link" className="py-0" id="leave-deny-test">
            {" "}
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => {
                leave_obj.approvedOne === true
                  ? denyRequestTwo(leave_obj)
                  : denyRequest(leave_obj);
              }}
            />{" "}
          </Button>
        </td>
      </>
    );
  };

  return (
    <Base title={"MANAGE LEAVE REQUESTS"} showHeader={true}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({ handleSubmit, handleChange, handleBlur, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-2 justify-content-end">
              <Col xs={12} sm={6} md={6} lg={4} className="justify-content-end">
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={3}
                    md={3}
                    lg={4}
                    className="text-right pr-0"
                  >
                    Staff
                  </Form.Label>
                  <Col sm={12} md={9} lg={8}>
                    <Form.Control
                      autoComplete="off"
                      onBlur={handleBlur}
                      as="select"
                      name="user"
                      value={values.user}
                      onChange={handleChange}
                    >
                      <option value="">Select Staff</option>
                      {users.map((user) => {
                        return (
                          <option value={user.node.id} key={user.node.id}>
                            {user.node.firstName} {user.node.lastName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="justify-content-end">
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={3}
                    md={3}
                    lg={3}
                    className="text-right pr-0 "
                  >
                    Date
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <DatePicker
                      autoComplete="off"
                      name="startDate"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      dateFormat="dd/MMM/yyyy"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={3}
                className="manage-leave-filter-block"
              >
                <Button type="submit"> Submit </Button>
                <Button className="ml-3" onClick={resetFilter}>
                  {" "}
                  Reset{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Row>
        <Table responsive className=" table-borderless">
          <thead>
            <tr className="patient_table_row tableHeader">
              <th scope="col " className="text-uppercase">
                Name
              </th>
              <th scope="col " className="text-uppercase">
                DATE OF REQUEST
              </th>
              <th scope="col " className="text-uppercase">
                LEAVE TYPE
              </th>
              <th scope="col " className="text-uppercase">
                START DATE
              </th>
              <th scope="col " className="text-uppercase">
                END DATE
              </th>
              <th scope="col " className="text-uppercase text-center">
                STATUS
              </th>
            </tr>
          </thead>
          <tbody>
            {leavesObjFilter?.leaveRequestsForApproval?.edges?.length > 0 ? (
              leavesObjFilter?.leaveRequestsForApproval?.edges.map(
                (leave, index) => {
                  let leaveObj = leave?.node;
                  return (
                    <tr key={index} className="patient_table_row">
                      <td>
                        {" "}
                        <Link to={"/leave/request/" + leaveObj?.id}>
                          {" "}
                          {leaveObj?.user?.firstName} {leaveObj?.user?.lastName}
                          {/* <b className='mx-3'>{leaveObj.status} </b> */}
                        </Link>{" "}
                      </td>
                      <td>{getDateKuwaitFormatted(leaveObj.created)}</td>
                      <td>
                        {leaveObj.leaveType === "LEAVE"
                          ? "Annual Leave"
                          : leaveObj.displayLeaveType}
                      </td>
                      <td>{getDateKuwaitFormatted(leaveObj.startDate)}</td>
                      <td>{getDateKuwaitFormatted(leaveObj.endDate)}</td>
                      {getPermissionForAction(
                        "leave_request_tier_two",
                        "approve"
                      ) ? (
                        leaveObj.status === "APPROVED" ? (
                          getButtons(leaveObj)
                        ) : (
                          <td className="text-center py-4">
                            {" "}
                            <span>{getStatus(leaveObj.displayStatus)}</span>
                          </td>
                        )
                      ) : leaveObj.approvedOne !== true &&
                        leaveObj.status === "PENDING" ? (
                        getButtons(leaveObj)
                      ) : (
                        <td className="text-center py-4">
                          {" "}
                          <span>{getStatus(leaveObj.displayStatus)}</span>
                        </td>
                      )}
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No Leaves Requested</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      {/* {leavesList && leavesList.length > PER_PAGE_COUNT ? <div className="d-flex justify-content-center pt-5"> */}
      {leavesObjFilter &&
      leavesObjFilter.leaveRequestsForApproval &&
      leavesObjFilter.leaveRequestsForApproval.totalCount > PER_PAGE_COUNT ? (
        <div className="d-flex justify-content-center pt-5">
          <Pagination>{renderPages()}</Pagination>
        </div>
      ) : null}
    </Base>
  );
}
export default ManageLeaveRequests;
