import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";
import moment from "moment";

export const getForamttedTime = (date) => {
  if (date) {
    let typeDate = typeof date;
    if (typeDate === "string") {
      date = new Date(date);
    }

    return moment(date).format("h:mm A");
  }
};

export const getDate = (date) => {
  let today = new Date(date);
  return moment(today).format("D/MMM/YYYY");
};

export const dateWithDay = (date) => {
  if (!date) {
    date = new Date();
  }

  return moment(date).format("dddd D/MMM/YYYY");
};

export const getDay = (index) => {
  let days = [
    i18n.language === "en" ? "Sunday" : "الأحد",
    i18n.language === "en" ? "Monday" : "الاثنين",
    i18n.language === "en" ? "Tuesday" : "الثلاثاء",
    i18n.language === "en" ? "Wednesday" : "الأربعاء",
    i18n.language === "en" ? "Thursday" : "الخميس",
    i18n.language === "en" ? "Friday" : "الجمعة",
    i18n.language === "en" ? "Saturday" : "السبت",
  ];
  return days[index];
};

export const sortByDate = (appointments) => {
  return appointments
    .slice()
    .sort((a, b) => new Date(a.start) - new Date(b.start));
};

export const sortByStartDate = (date) => {
  return date.sort((a, b) => new Date(a.start) - new Date(b.start));
};

const CopyToClipBoardButton = (props) => {
  // This helper function expect textValue props
  // This copy the text to clipboard

  const copyIcon = <FontAwesomeIcon icon={faCopy} />;
  const checkIcon = (
    <FontAwesomeIcon icon={faCheckCircle} className="helpers-check-icon" />
  );
  const failIcon = (
    <FontAwesomeIcon icon={faTimesCircle} className="helpers-fail-icon" />
  );
  const [copied, setCopied] = useState(copyIcon);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await navigator.clipboard.writeText(props.textValue);
      setCopied(checkIcon);
      setLoading(false);
    } catch (err) {
      setCopied(failIcon);
      setLoading(false);
    }
  };

  return (
    // TODO: It's either we create button components or define our own classes
    <Button
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      size="sm"
      className={props.className}
      variant={props.variant}
    >
      {copied}
    </Button>
  );
};

// Export reusable components
export { CopyToClipBoardButton };

export const success_options = {
  position: "bottom-right",
  style: {
    backgroundColor: "#28A745",
    color: "white",
    textAlign: "center",
  },
};

export const error_options = {
  position: "bottom-right",
  style: {
    backgroundColor: "#DC3546",
    color: "white",
    textAlign: "center",
  },
};

export const error_options_top = {
  position: "top-right",
  style: {
    backgroundColor: "#DC3546",
    color: "white",
    textAlign: "center",
  },
};

export const success_options_top = {
  position: "top-right",
  style: {
    backgroundColor: "#28A745",
    color: "white",
    textAlign: "center",
  },
};

export const SNACK_DURATION = 5000;
export const PER_PAGE_COUNT = 20;
export const ERROR_MESSAGE =
  "The system encountered an error. Please reload the page.";

const actionFunc = (search_params, action, param, value, resetName) => {
  switch (action) {
    case "get":
      let a = search_params.get(param);
      let val = "";
      if (a) {
        val = a.toString();
      }
      return val;
    case "set":
      search_params.set(param, value);
      if (resetName && resetName.length > 0) {
        for (var i = 0; i < resetName.length; i++) {
          search_params.delete(resetName[i]);
        }
      }
      let val1 = search_params.toString();
      return val1;
    case "delete":
      search_params.delete(param);
      let val2 = search_params.toString();
      return val2;
    default:
      return null;
  }
};

export const urlActions = (url, action, param, value) => {
  var url1 = new URL(url);
  var query_string = url1 && url1.search ? url1.search : "";
  var search_params = new URLSearchParams(query_string);
  let actionResult = actionFunc(search_params, action, param, value);
  return actionResult;
};

export const paymentReason = {
  appointment: "Appointment",
  genetic_lab_test: "Lab tests (Choose)",
  others: "Others",
};
export const getPaymentReasonLabel = (reason) => {
  return paymentReason[reason.toLowerCase()];
};

export const isSuperUserFunc = () => {
  let userDetails = localStorage.getItem("user");
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  var IS_SUPERUSER = false;
  IS_SUPERUSER =
    userDetails && userDetails.isSuperuser ? userDetails.isSuperuser : false;
  return IS_SUPERUSER;
};

export const getPermissionForAction = (
  tab,
  action,
  returnAll,
  all_user_permissions
) => {
  var user_permissions = all_user_permissions
    ? all_user_permissions
    : localStorage.getItem("user_permissions");

  user_permissions = JSON.parse(user_permissions);
  const PERMISSIONS = user_permissions;
  var IS_SUPERUSER = isSuperUserFunc();

  // IF USER IS SUPERUSER THEN ALWAYS return permissions for all actions if requested for list of permissions
  if (IS_SUPERUSER && returnAll) {
    return ["approved", "add", "delete", "approve", "view", "list", "edit"];
  } else if (IS_SUPERUSER) {
    return true; // IF USER IS SUPERUSER THEN ALWAYS return permissions true for action requested for any Tab
  }
  // IF USER is not superuser and requests to return all permissions for specific tab
  if (returnAll && PERMISSIONS && PERMISSIONS[tab]) {
    return PERMISSIONS[tab];
  } else if (returnAll) {
    return [];
  }

  // IF USER is not superuser and requests to return  permissions for specific tab for specific action
  if (PERMISSIONS && PERMISSIONS[tab] && PERMISSIONS[tab].length > 0) {
    if (PERMISSIONS[tab].includes(action)) {
      return true;
    }
    return false;
  }
  return false;
};

export const createSlug = (text) => {
  let slugText = text.toString().toLowerCase().replace(/\s+/g, "-");
  return slugText;
};

export const isUserStaff = () => {
  if (localStorage.getItem("is_staff") === "true") {
    return true;
  } else if (localStorage.getItem("is_staff") === "false") {
    return false;
  }
};

export const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
  // Example July
  // daysInMonth(7,2009); // 31
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPatientsAge = (patientDob) => {
  let now = new Date();
  let todayToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let dobYear = new Date(patientDob).getFullYear();
  let dobMonth = new Date(patientDob).getMonth();
  let dobDay = new Date(patientDob).getDate();
  let dob = new Date(dobYear, dobMonth, dobDay);
  let dobnow = new Date(
    todayToday.getFullYear(),
    dob.getMonth(),
    dob.getDate()
  );
  let age = todayToday.getFullYear() - dob.getFullYear();

  return todayToday < dobnow ? age - 1 : age;
};

export const getValidDateStringForGraphQL = (value) => {
  if (value instanceof Date) {
    let date = value;
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0")
    );
  }

  const extractedDate = value.split("T")[0];
  return extractedDate;
};
export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v !== "" && v !== undefined && v !== null
    )
  );
};
export const generateUID = () => {
  //  generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
};

export const replaceUnderscoreAndDashBySpace = (string) => {
  return string.replace(/[-_]/g, " ");
};

export const getTotalRemaining = (paymentSources, price) => {
  let total = 0;
  if (paymentSources) {
    paymentSources
      .filter((paidSource) => {
        return paidSource.node.status === "Paid";
      })
      .forEach((item, index) => {
        total += parseFloat(item.node.amountAllocated);
      });
  }

  return [total.toFixed(3), (price - total).toFixed(3)];
};

export const displayMiddleName = (middleName) => {
  if (middleName) {
    return middleName + " ";
  }
  return "";
};

export const getErrorMessage = (errors, isFormBlockErrors) => {
  let errors_array = errors.map((error) => {
    let key = error.field.charAt(0).toUpperCase() + error.field.slice(1);
    if (key === "__all__") {
      return error.messages.join(",");
    } else {
      return key + " : " + error.messages.join(",");
    }
  });
  let merged_errors = [].concat.apply([], errors_array);
  let allErrors = [];
  let otherErrors = localStorage.getItem("errorMessages");
  if (otherErrors && isFormBlockErrors) {
    otherErrors = JSON.parse(otherErrors);
  } else {
    otherErrors = [];
  }
  allErrors = [...otherErrors, ...merged_errors];
  localStorage.setItem("errorMessages", JSON.stringify(allErrors));
  return allErrors.join(". \n");
};
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getPrescriptionDate = (prescription) => {
  let prescDate = "";
  if (prescription.form_block_instances.length > 0) {
    prescDate = prescription.form_block_instances.reduce((a, b) => {
      return new Date(a.form_block_created_date) >
        new Date(b.form_block_created_date)
        ? a
        : b;
    });
    if (prescDate) {
      prescDate = new Date(prescDate.form_block_created_date);
    }
  }
  return prescDate;
};

export const timeToDate = (date, dStr) => {
  var now = new Date(date);
  if (dStr && date) {
    let dStrArr = dStr.split(":");
    now.setHours(dStrArr[0]);
    now.setMinutes(dStrArr[1]);
    if (dStrArr[2]) {
      now.setSeconds(dStrArr[2]);
    }
    return now;
  }
};

export const getHoursToMinutes = (timeInHours) => {
  let time = timeInHours;
  var minutes = time * 60;

  return minutes;
};

export const getMinutesToHours = (timeInMinutes) => {
  let time = timeInMinutes;
  var hours = time / 60;

  return hours;
};

export const getFullMonthName = (date) => {
  var d = new Date(date).toLocaleString("default", { month: "long" });
  return d;
};

export const getObjKey = (obj, value) => {
  return Object.keys(obj).filter((key) => {
    if (
      typeof obj[key] === "string" &&
      obj[key]?.toLowerCase() === value.toLowerCase()
    ) {
      return key;
    }
  });
};

export const getSunday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? 0 : 0); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const concatAllErrors = (graphQLErrors) => {
  let errorMsg = "";
  graphQLErrors?.map((item) => {
    errorMsg += item.message + ". ";
  });
  return errorMsg;
};

export const removeAllBefore = (array, indexOfElement) => {
  if (indexOfElement !== -1) {
    return array.splice(indexOfElement, array.length - 1);
  }
  return array;
};

export const deepCopy = (obj) => {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
};

export const convertApolloNodeToFlatArray = (data) => {
  if (!data) return [];
  return data.map((item) => item.node);
};

export const addQueryParam = (key, value) => {
  // Use URLSearchParams with the current location's search string
  const searchParams = new URLSearchParams(window.location.search);

  // Set your new query parameter
  searchParams.set(key, value);

  return searchParams;
};

export const generateUrlLink = (link, i18n) => {
  if (!link) return;
  const currentLanguage = i18n.language;

  const url = new URL(link);
  const pathnameParts = url.pathname.split("/").filter((part) => part); // Remove empty parts

  // Insert the language part into the URL
  if (currentLanguage !== "en") {
    // Assuming 'en' is the default and does not need to be prefixed
    pathnameParts.splice(0, 0, currentLanguage);
  }

  // Reconstruct the URL with the language prefix
  url.pathname = "/" + pathnameParts.join("/");
  return url.toString();
};

export const getDateKuwait = (date) => {
  let today = new Date(date);
  return moment.utc(today).tz("Asia/Kuwait").format("YYYY-MM-DDTHH:mm:ss");
};

export const getStartDate = (date) => {
  date = moment.utc(date).tz("Asia/Kuwait");
  return date.startOf("day");
};

export const getEndDate = (date) => {
  date = moment.utc(date).tz("Asia/Kuwait");
  return date.endOf("day");
};

export const getDateKuwaitFormatted = (date) => {
  let today = new Date(date);
  today = moment.utc(today).tz("Asia/Kuwait").format("YYYY-MM-DDTHH:mm:ss");
  return getDate(today);
};

export const getDateDay = (date) => {
  let today = new Date(date);
  return moment(today).format("dddd");
};

export const getWeekDays = (date) => {
  return moment(date, 'DD/MMM/YYYY').format("dddd");
};

export const convertDateKuwaitToStr = (date) => {
  date = getDateKuwait(date);
  return moment(date).format("YYYY-MM-DD");
};
