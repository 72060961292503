//@ts-nocheck
import React from "react";
import { useField, useFormikContext } from "formik";
import StandAloneDatePicker from "./StandAloneDatePicker";
import dayjs from "dayjs";

const FormikDatePicker = ({
  name,
  label,
  required = false,
  helperTextKey = "",
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const handleChange = (date) => {
    setFieldValue(name, date);
  };

  return (
    <StandAloneDatePicker
      label={label}
      value={field.value ? field.value : null}
      onChange={handleChange}
      required={required}
      helperText={errorText || helperTextKey}
      error={!!errorText}
      {...props}
    />
  );
};

export default FormikDatePicker;
