import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import SearchForPatient from './SearchForPatient';
import { error_options, SNACK_DURATION, ERROR_MESSAGE,concatAllErrors} from '../../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
import * as Sentry from "@sentry/browser";
 
export const REQUEST_PATIENT = gql`
  query getPatients($search: String) {
    patients(searchText_Icontains: $search) {
      edges {
        node {
          firstName
          middleName
          lastName
          id
          email
          identifier
          phone
          civilId
          displayGender
          dob
          doctor{
            identifier
          }
          patientId
          discount
        }
      }
    }
  }
`;

const SearchForPatientHoc = (props) => {
  const {isModal,onSelectPatientFunc} = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [getPatients, { data }] = useLazyQuery(REQUEST_PATIENT, {
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      if(e?.message?.toLocaleLowerCase()?.indexOf("permission")<0){
        Sentry.captureException("REQUEST_PATIENT error "+e);

      }
      
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const {setShowPatientSearch}=props;
  return (
      <SearchForPatient getPatients={getPatients} data={data} setShowPatientSearch={setShowPatientSearch} isModal={isModal} onSelectPatientFunc={onSelectPatientFunc}/>
  );
};

export default SearchForPatientHoc;
