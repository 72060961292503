import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "./Common/helpers.js";
import {
  isLoggedInVar,
  dataPatientInVar,
  userDetailsVar,
  userPermissionsVar,
} from "./cache/cache";
import { withRouter } from "react-router-dom";
import { IdleTimeOutModal } from "./Views/IdleModal";
import { useIdleTimer } from "react-idle-timer";
import { useInterval } from "./component/UseInterval";
import * as Sentry from "@sentry/browser";

const VerifyToken = (props) => {
  const token = localStorage.getItem("token");
  const [openSnackbar] = useSnackbar(error_options);
  const TIMEOUT = 1000 * 19 * 60; // 20 * 60 seconds timeout before showing modal
  const MODAL_DISPLAY_DURATION = 1000 * 1 * 30;
  const REFRESH_BE_TOKEN_TIME = 1000 * 5 * 60;
  const [showModal, setShowModal] = useState(false);
  let isLoggedIn = token ? true : false;

  const client = useApolloClient();
  const VERIFY_TOKEN = gql`
    mutation verifyToken($token: String!) {
      verifyToken(input: { token: $token }) {
        success
        errors
      }
    }
  `;

  const REVOKE_TOKEN = gql`
    mutation revokeToken($refreshToken: String!) {
      refreshToken(input: { refreshToken: $refreshToken }) {
        success
        errors
        token
        refreshToken
      }
    }
  `;

  const REFRESH_AUTH_TOKEN = gql`
    mutation refreshToken($refreshToken: String!) {
      refreshToken(input: { refreshToken: $refreshToken }) {
        success
        errors
        token
        refreshToken
      }
    }
  `;

  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    client.resetStore();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_permissions");
    localStorage.removeItem("sessionExpiration");
    localStorage.removeItem("loginTokenExpiry");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    dataPatientInVar(null);
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      client
        .mutate({
          mutation: REVOKE_TOKEN,
          variables: {
            refreshToken,
          },
        })
        .then((data) => {
          localStorage.removeItem("refreshToken");
          setTimeout(() => {
            localStorage.removeItem("is_staff");
          }, 300);
        })
        .catch((error) => {
          localStorage.removeItem("refreshToken");
          setTimeout(() => {
            localStorage.removeItem("is_staff");
          }, 300);
        });
    } else {
      localStorage.removeItem("refreshToken");
      setTimeout(() => {
        localStorage.removeItem("is_staff");
      }, 300);
    }

    history.push("/");
  };
  // We need to check if now > sessionExpiration from local storage we force logout
  //  this will ensure that even after you closed all browser and 20mins pass since
  // last active youll be forced to logout.
  useEffect(() => {
    let sessionExpiration = localStorage.getItem("sessionExpiration");
    let now = new Date();
    if (sessionExpiration) {
      let sessionDate = new Date(sessionExpiration);
      if (now > sessionDate) {
        handleLogout();
      } else if (isLoggedIn) {
        start();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const REQUEST_USER_PERMISSION = gql`
    query {
      userPermission
    }
  `;

  const [GetUserPermissions] = useLazyQuery(REQUEST_USER_PERMISSION, {
    fetchPolicy: "network-only",
    onCompleted({ userPermission }) {
      // assing the original permissions on localstorage
      localStorage.setItem("original_permissions", userPermission);
      var user_permissions = {};
      if (userPermission) {
        let userprofile = userPermission.filter(
          (i) => i.indexOf("userprofile.") > -1 && i.indexOf("_can_") > -1
        );
        let can_block_calender = userPermission.filter(
          (i) => i.indexOf("appointment") > -1 && i.indexOf("_can_") > -1
        );
        let accountant = userPermission.filter(
          (i) => i.indexOf("userprofile.as_accountant") > -1
        );
        let practitioner_transaction = userPermission.filter(
          (i) => i.indexOf("userprofile.practitioner_transaction_can_list") > -1
        );
        let can_see_patient_vitals = userPermission.filter(
          (i) => i.indexOf("userprofile.can_see_vitals") > -1
        );
        let can_accept_manual_refund = userPermission.filter(
          (i) => i.indexOf("userprofile.can_do_manual_refund") > -1
        );
        let unpaid_manual_payment = userPermission.filter(
          (i) => i.indexOf("userprofile.manual_payment_can_add") > -1
        );
        let recurring = userPermission.filter(
          (i) => i.indexOf("recurring") > -1
        );
        let leave_tier_two = userPermission.filter(
          (i) => i.indexOf("leave_request_can_approve_two") > -1
        );
        let scheduler = userPermission.filter(
          (i) => i.indexOf("scheduler.") > -1
        );
        let patientRecord = userPermission.filter(
          (i) => i.indexOf("patientrecord.") > -1
        );
        userprofile = userprofile.map((i) => {
          return i.split(".")[1];
        });
        let userPerm = userprofile.map((i) => {
          let str = "_can_";
          let key = "";
          let val = "";
          if (i.indexOf(str) > -1) {
            key = i.split(str)[0];
            val = i.split(str)[1];
            return {
              key: key,
              value: val,
            };
          }
          return null;
        });
        let group = userPerm.reduce((r, a) => {
          r[a.key] = [...(r[a.key] || []), a.value];
          return r;
        }, {});
        user_permissions = group;

        if (accountant.length > 0) {
          user_permissions["manual_payment"] = [
            "add",
            "view",
            "delete",
            "list",
            "edit",
          ];
          user_permissions["account"] = [
            "add",
            "view",
            "delete",
            "list",
            "edit",
          ];
        }
        if (practitioner_transaction.length > 0) {
          user_permissions["practitioner_transaction"] = ["list"];
        }
        if (can_see_patient_vitals.length > 0) {
          user_permissions["patient_vital"] = ["view", "list"];
        }
        if (can_accept_manual_refund.length > 0) {
          user_permissions["accept_manual_refund"] = [
            "view",
            "list",
            "approve",
            "deny",
          ];
        }
        if (unpaid_manual_payment.length > 0) {
          user_permissions["unpaid_manual_payments"] = [
            "view",
            "list",
            "approve",
            "deny",
          ];
        }
        if (recurring.length > 0) {
          user_permissions["recurring_event"] = [
            "add",
            "view",
            "delete",
            "list",
            "edit",
          ];
        }
        if (can_block_calender.length > 0) {
          user_permissions["can_block_calender"] = [
            "add",
            "view",
            "delete",
            "list",
            "edit",
          ];
        }
        if (leave_tier_two.length > 0) {
          user_permissions["leave_request_tier_two"] = [
            "add",
            "view",
            "delete",
            "list",
            "edit",
            "approve",
            "deny",
          ];
        }
        if (scheduler) {
          let waitingPermArr = [];
          if (scheduler.includes("scheduler.add_waitinglist")) {
            waitingPermArr.push("add");
          }
          if (scheduler.includes("scheduler.view_waitinglist")) {
            waitingPermArr.push("view");
            waitingPermArr.push("list");
          }
          if (scheduler.includes("scheduler.change_waitinglist")) {
            waitingPermArr.push("edit");
          }
          if (scheduler.includes("scheduler.delete_waitinglist")) {
            waitingPermArr.push("delete");
          }
          user_permissions["waiting_list"] = waitingPermArr;
        }

        if (patientRecord.length > 0) {
          let patientRecordPerms = patientRecord.map((i) => {
            return i.split(".")[1];
          });
          let recordPerms = patientRecordPerms.map((i) => {
            let str = "_";
            let key = "";
            let val = "";
            if (i.indexOf(str) > -1) {
              key = i.split(str)[0];
              val = i.split(str)[1];
              return {
                key: val,
                value: key,
              };
            }
            return null;
          });
          let recordPermsgroup = recordPerms.reduce((r, a) => {
            r[a.key] = [...(r[a.key] || []), a.value];
            return r;
          }, {});
          user_permissions["encounter"] = recordPermsgroup["encounter"];
          user_permissions["encounternote"] = recordPermsgroup["encounternote"];
        }
      }
      localStorage.setItem(
        "user_permissions",
        JSON.stringify(user_permissions)
      );
      userPermissionsVar(JSON.stringify(user_permissions));
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      if (err?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("user_permissions error " + err);
      }
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const refreshAuthToken = (errors) => {
    // Get refresh token from cookies
    const refreshToken = localStorage.getItem("refreshToken");
    // Get new auth token from server

    if (refreshToken) {
      client
        .mutate({
          mutation: REFRESH_AUTH_TOKEN,
          variables: {
            refreshToken,
          },
        })
        .then((data) => {
          if (data.data.refreshToken.success) {
            localStorage.setItem("token", data.data.refreshToken.token);
            localStorage.setItem(
              "refreshToken",
              data.data.refreshToken.refreshToken
            );
            const loginTokenExpiry = new Date(Date.now());
            localStorage.setItem("loginTokenExpiry", loginTokenExpiry);
          } else {
            if (errors?.length > 0) {
              for (let i in errors) {
                let error = errors[i];
                openSnackbar(error.message, [SNACK_DURATION]);
              }
            }
            handleLogout();
          }
        })
        .catch((error) => {
          if (errors?.length > 0) {
            for (let i in errors) {
              let error = errors[i];
              openSnackbar(error.message, [SNACK_DURATION]);
            }
          }
          handleLogout();
        });
    } else {
      handleLogout();
    }
  };

  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    onCompleted({ verifyToken }) {
      if (!verifyToken.success) {
        let errors = verifyToken?.errors.nonFieldErrors;
        refreshAuthToken(errors);
      } else {
        GetUserPermissions();
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("verifyToken error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    if (token) {
      verifyToken({ variables: { token: token } });
    }
  }, [token, verifyToken]);

  useEffect(() => {
    localStorage.setItem("errorMessages", "");
  }, []);

  // AUTO LOGOUT CODE

  const handleClose = () => {
    setShowModal(false);
    message("closeAndRefresh");
    refreshAuthToken();
    reset();
  };

  const handleLogoutModal = () => {
    setShowModal(false);
    message("closeAndLogout");
    handleLogout();
  };

  // const onAction = () => {
  //   const newExpiration = new Date(Date.now() + TIMEOUT);
  //   localStorage.setItem("sessionExpiration", newExpiration);
  // };
  const onAction = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);
    const expirationTime = lastDayOfMonth.getTime() - now.getTime();
    
    const newExpiration = expirationTime < TIMEOUT ? lastDayOfMonth : new Date(Date.now() + TIMEOUT);

    localStorage.setItem("sessionExpiration", newExpiration);
};

  const onIdle = () => {
    handleLogout();
  };

  const onPrompt = () => {
    setShowModal(true);
  };

  const onMessage = (data) => {
    if (data === "closeAndRefresh") {
      setShowModal(false);
      refreshAuthToken();
      reset();
    } else if (data === "closeAndLogout") {
      setShowModal(false);
      handleLogout();
    }
  };

  const { start, message, reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onAction,
    onMessage,
    timeout: TIMEOUT,
    promptTimeout: MODAL_DISPLAY_DURATION,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: false,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 10000,
    leaderElection: false,
  });

  useInterval(() => {
    let loginTokenExpiry = localStorage.getItem("loginTokenExpiry");
    let loginTokenExpiryDate = new Date(loginTokenExpiry);
    let now = new Date();
    if (now > loginTokenExpiryDate) {
      refreshAuthToken();
    }
  }, REFRESH_BE_TOKEN_TIME);

  return (
    <>
      {isLoggedIn ? (
        <IdleTimeOutModal
          showModal={showModal}
          handleClose={handleClose}
          handleLogoutSystem={handleLogout}
          handleLogout={handleLogoutModal}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  );
};

export default withRouter(VerifyToken);
