import { Card, Table, Badge, Button } from "react-bootstrap";
import React from "react";
import { CopyToClipBoardButton, isUserStaff } from "Common/helpers.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function PaymentDetail(props) {
  /**
   * This component is for appointment payment details
   * It will be a list of payments needed for this event.
   *
   * It expects a prop event for its data. That should have sources
   * **/

  const { event } = props;
  const isStaff = isUserStaff();
  const { t } = useTranslation();

  const printPaymentDetail = () => {
    window.print();
  };

  const renderPaymentRow = (item, index) => {
    return (
      <tr key={index} style={{ height: "61px" }}>
        <td className="align-middle">{item.node.reference}</td>
        <td className="align-middle">{item.node.paymentObject.identifier}</td>
        <td className="align-middle">{item.node.amountAllocated}</td>
        <td className="align-middle">{item.node.amountAvailableForRefund}</td>
        <td className="align-middle">{item.node.status}</td>
        <td className="payment-table-cell">
          {item.node.status === "Paid" ? null : (
            <>
              <Button variant="link">
                <a
                  href={`${item.node.paymentLink}`}
                  target="_blank"
                  rel="noreferrer"
                  className="payment-link-icon"
                >
                  <FontAwesomeIcon icon={faLink} />
                </a>
              </Button>
              <CopyToClipBoardButton
                textValue={item.node.linkMessage}
                variant="link"
              />
            </>
          )}
        </td>
        <td className="align-middle">
          {isStaff ? (
            <h5>
              {item.node.paymentLink && item.node.status !== "Paid" ? (
                item.node.smsSent ? (
                  <Badge variant="primary">
                    {" "}
                    {t("patientPaymentDetails.notificationSent")}
                  </Badge>
                ) : (
                  <Badge variant="danger">
                    {" "}
                    {t("patientPaymentDetails.notificationNotSent")}
                  </Badge>
                )
              ) : null}
            </h5>
          ) : item.node.status === "Paid" ? (
            <Button variant="primary" size="md" onClick={printPaymentDetail}>
              {t("patientPaymentDetails.print")}
            </Button>
          ) : null}
        </td>
      </tr>
    );
  };

  const renderPaymentItems = () => {
    const sources = event?.paymentSources?.edges;
    if (sources?.length > 0) {
      return sources.map((item, index) =>
        event.status === "CONFIRMED"
          ? item.node.status !== "Pending"
            ? renderPaymentRow(item, index)
            : null
          : renderPaymentRow(item, index)
      );
    } else {
      return (
        <tr>
          <td colSpan={3} className="align-middle">
            {t("patientPaymentDetails.noPaymentWasMade")}.
          </td>
        </tr>
      );
    }
  };

  return (
    // TODO: always ready all static text for translations.
    <Card>
      <Card.Body>
        <Card.Title>{t("patientPaymentDetails.payments")}</Card.Title>
        <Table responsive>
          <thead>
            <tr>
              <th>{t("patientPaymentDetails.reference")}</th>
              <th>{t("patientPaymentDetails.xeroInvoice")}</th>
              <th>{t("patientPaymentDetails.amount")}</th>
              <th>{t("patientPaymentDetails.refundAmount")}</th>
              <th>{t("patientPaymentDetails.status")}</th>
              <th>{t("patientPaymentDetails.paymentLink")}</th>
              <th>
                {isStaff
                  ? t("patientPaymentDetails.smsStatus")
                  : t("patientPaymentDetails.print")}
              </th>
            </tr>
          </thead>
          <tbody>{event.paymentSources ? renderPaymentItems() : null}</tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default PaymentDetail;
