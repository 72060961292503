import { Row, Col, Form, Button } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import Base from "Views/base.js";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Formik } from "formik";
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { MultiSelect } from "react-multi-select-component";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  urlActions,
  getValidDateStringForGraphQL,
  concatAllErrors,
} from "Common/helpers";

import { useTranslation } from "react-i18next";

import * as Sentry from "@sentry/browser";
import { useAppDispatch } from "redux/reducers/hooks";
import { resetState } from "redux/slices/appointmentSlice";
import Preloader from "../../Common/Preloder/Preloader";

export const REQUEST_DOCTOR = gql`
  query {
    doctors(active: true) {
      edges {
        node {
          firstName
          lastName
          id
          identifier
          doctorId
          arabicName
        }
      }
    }
  }
`;

const REQUEST_LOCATION = gql`
  query {
    listEventLocation {
      edges {
        node {
          title
          id
          titleEn
        }
      }
    }
  }
`;

const REQUEST_PATIENT_EVENTS = gql`
  query (
    $doctors: [String]
    $search: String
    $patient_Identifier: String
    $status: String
    $active: Boolean!
    $location: ID
    $start: Date
    $end: Date
  ) {
    patientAppointments(
      doctors: $doctors
      search: $search
      patient_Identifier: $patient_Identifier
      status: $status
      active: $active
      location_Id_Icontains: $location
      start: $start
      end: $end
    ) {
      edges {
        node {
          title
          id
          start
          end
          identifier
          groupStartDate
          groupEndDate
          groupDays
          displayStatus
          payments {
            edges {
              node {
                paid
                amount
              }
            }
          }
          children {
            edges {
              node {
                id
                start
                end
                eventId
              }
            }
          }
          doctorEventType {
            groupSession

            color {
              hexCode
            }
          }
          doctor {
            id
            firstName
            lastName
            arabicName
            identifier
          }
        }
      }
    }
  }
`;


const SELECTED_PATIENT_DATA = gql`
query receivePatientData {
  dataPatient @client
}
`;

function MyAppointments(props) {
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const { i18n, t } = useTranslation();
  const { data } = useQuery(USER_DETAILS);
  const fullCalendarRef = useRef();
  var userDetails = data.userDetails;

  const IS_CHECK_APPOINTMENT = gql`
  query AppointmentFlag($id: ID!) {
    AppointmentFlag(id: $id) {
      isAppointment
    }
  }
`;
const patientId = patientData?.dataPatient?.id;

const { data: hasAppointment } = useQuery(IS_CHECK_APPOINTMENT, {
  variables: { id: patientId },
  skip: !patientId, 
});

if (!patientId) {
  console.log("Patient ID is missing, not calling the API.");
}

console.log("hasAppointment------------ ", hasAppointment);

  
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
    
  }
  
  const refs = patientData?.dataPatient?.referrals?.edges?.filter(
    (ref) => {
      return !ref.node.recommendedDate;
    }
  );
  // console.log("dataPatient------------- ",patientData);

  const followUps = patientData?.dataPatient?.referrals?.edges?.filter(
    (ref) => {
      return ref.node.recommendedDate;
    }
  );
 
  let ref_link =
      "/patientPortal/referrals/" +
      (patientData && patientData.dataPatient
        ? patientData.dataPatient.id
        : "");
  let follow_up_link =
        "/patientPortal/followups/" +
        (patientData && patientData.dataPatient
          ? patientData.dataPatient.id
          : "");

  const isStaff = isUserStaff();
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const [radio, setRadio] = useState("new");
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [variablesToBeFetched, setVariablesToBeFetched] = useState({
    start: getValidDateStringForGraphQL(new Date()),
    end: getValidDateStringForGraphQL(
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    ),
    active: true,
    status: null
  });

  const [appointmets, setAppointments] = useState([]);
  const [selectedDocList, setSelectedDocList] = useState([]);
  const dispatch = useAppDispatch();

  const defaultStrings = {
    selectSomeItems: t("multiSelectDoc.select"),
    search: t("multiSelectDoc.search"),
  };

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", networkError?.statusCode);
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + networkError);
      }
      if (networkError.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // eslint-disable-next-line no-empty-pattern
  const [GetEvents, { }] = useLazyQuery(REQUEST_PATIENT_EVENTS, {
    fetchPolicy: "network-only",

    notifyOnNetworkStatusChange: true,
    // pollInterval: 16000,
    onCompleted: (data) => {
      let appointments = data.patientAppointments.edges.map((i) => i.node);
      if (radio == 'new') {
        appointments = appointments.filter((appointment) => appointment.displayStatus	!= "Completed")
      }
      setAppointments(appointments);
    },
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + networkError);
      }
      if (networkError?.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    setLoadingPage(true);
    GetEvents({
      variables: variablesToBeFetched,
    }).finally(() => {
      setLoadingPage(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesToBeFetched]);

  const getAppointments = (value) => {
    let data = { ...variablesToBeFetched };

    if (value === "new") {
      data = {
        ...data,
        start: getValidDateStringForGraphQL(new Date()),
        end: getValidDateStringForGraphQL(
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        ),
        active: true,
        status: null,
      };
    } else if (value === "cancelled") {
      data = {
        ...data,
        active: false,
        status: "cancelled",
        start: null,
        end: null,
      };
    } else {
      data = {
        ...data,
        start: getValidDateStringForGraphQL(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        ),
        end: getValidDateStringForGraphQL(
          new Date(new Date().setDate(new Date().getDate()))
        ),
        active: true,
        status: null
      };
    }

    setVariablesToBeFetched(data);
  };

  const doctors =
    doctorsList && doctorsList?.doctors ? doctorsList?.doctors?.edges : [];

  const { data: location = [] } = useQuery(REQUEST_LOCATION, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    doctor: "",
    patient: "",
    status: "",
  };

  let fullCalendarView = fullCalendarRef?.current?.getApi()?.view;
  let fullCalendarViewTitle = fullCalendarView?.title;

  useEffect(() => {
    if (fullCalendarView) {
      let newUrlParams = urlActions(
        window.location.href,
        "set",
        "calendarInitView",
        fullCalendarView.type
      );
      let dateToPush = new Date(fullCalendarView.activeStart);
      dateToPush.setDate(dateToPush.getDate() + 1);
      newUrlParams = urlActions(
        window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
        "set",
        "calendarInitDate",
        dateToPush.toISOString().split("T")[0]
      );
      window.history.pushState("", "", "?" + newUrlParams);
    }
  }, [fullCalendarView, fullCalendarViewTitle]);

  const locationFilterChanged = (e) => {
    setSelectedLocation(e.target.value);
    let newUrlParams = "";
    if (e.target.value) {
      let locationParams = location.listEventLocation.edges.find(
        (i) => i.node.id === e.target.value
      );
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "location",
        locationParams?.node?.titleEn
      );

      setVariablesToBeFetched({
        ...variablesToBeFetched,
        location: e.target.value,
      });
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "location");
      const { location, ...rest } = variablesToBeFetched;
      setVariablesToBeFetched(rest);
    }
    window.history.pushState("", "", "?" + newUrlParams);
  };

  var is_my_appointments = false;
  var has_add_permission = getPermissionForAction("appointment", "add");

  const createNewApp = () => {
    localStorage.setItem("appointment_flow", true);
    dispatch(resetState());
  };

  function getDaysUntilAppointment(appointmentDate) {
    if (!appointmentDate) return 0;

    const currentDate = new Date();
    const appointment = new Date(appointmentDate);

    // Set both dates to midnight to avoid time differences within a day
    currentDate.setHours(0, 0, 0, 0);
    appointment.setHours(0, 0, 0, 0);

    const differenceInTime = appointment.getTime() - currentDate.getTime();

    // Calculate the difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  }
  var docOptions = doctors?.map((doctor) => {
    return {
      label:
        i18n.language === "en"
          ? doctor.node.firstName + " " + doctor.node.lastName
          : doctor.node.arabicName,
      value: doctor.node.identifier,
      doctorId: doctor.node.doctorId,
    };
  });
  docOptions = docOptions.sort((a, b) => a?.label?.localeCompare(b?.label));

  if (docOptions.length === 1) {
    docOptions.push({
      label: t("multiSelectDoc.selectDoc"),
      value: "0",
      placeholder: "",
      disabled: true,
      doctorId: "00",
    });
  }

  const handleChangeDoctor = (selectedDoc) => {
    let selected = [];

    if (selectedDoc.length > 0) {
      selected = selectedDoc.map((item) => item.doctorId);
    }

    setVariablesToBeFetched({
      ...variablesToBeFetched,
      doctors: selected,
    });

    let docs = [];
    docs.push(...selectedDoc);
    setSelectedDocList(docs);
    let newUrlParams = window.location.search;

    if (docs.length > 0) {
      let doc_list = docs.map((i) => i.doctorId + "").join("__");
      if (doc_list) {
        newUrlParams = urlActions(
          window.location.href,
          "set",
          "doctor",
          doc_list
        );
      }
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "doctor");
    }
    window.history.pushState("", "", "?" + newUrlParams);
  };

  const renderAppointmentDetails = (appointments) => {
    if (appointments?.length <= 0) {
      return (
        <Col className="mb-4" xs={12}>
          <p>{t("noData")}</p>
        </Col>
      );
    }

    return appointments.map((appointment, index) => (
      <Col className="mb-4" key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card"
          style={{
            minHeight: "10rem",
            backgroundColor: "white",
            border: 0,
          }}
        >
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5
                className="card-title"
                style={{
                  color: "#0096FF",
                }}
              >
                {getDaysUntilAppointment(appointment?.start) === 0 ? (
                  <>{t("calendar.today")}</>
                ) : i18n.language === "en" ? (
                  `In ${getDaysUntilAppointment(appointment?.start)} days`
                ) : (
                  `بعد ${getDaysUntilAppointment(appointment?.start)} يوم`
                )}
              </h5>
              <div>
                {new Date(appointment?.start).toLocaleDateString(
                  i18n.language === "en" ? "en-US" : "ar-EG",
                  {
                    month: "short",
                    day: "numeric",
                  }
                )}
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted">
              {t("with")}{" "}
              {i18n.language == "en"
                ? "Dr. " +
                appointment.doctor.firstName +
                " " +
                appointment.doctor.lastName
                : appointment.doctor.arabicName}
            </h6>
            <p className="card-text">{appointment?.title?.split("-")[1]}</p>
            <p className="card-text">{appointment?.displayStatus}</p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a href="#" className="card-link">
                <b>
                  {appointment?.payments.edges[0]?.node?.amount}{" "}
                  {i18n.language == "en" ? "KWD" : "دينار "}
                </b>

                {appointment?.payments.edges[0]?.node?.paid ? (
                  <></>
                ) : (
                  <cite
                    style={{
                      color: "#BDBDBD",
                      marginLeft: "10px",
                    }}
                    title="Source Title"
                  >
                    {t("patientDashboard.unpaidAppointemnt")}
                  </cite>
                )}
              </a>
              <Link
                to={`/patientPortal/appointment/detail/${appointment?.id}`}
                className="card-link"
              >
                <button
                  style={{
                    backgroundColor: "white",
                    borderRadius: "40%",
                    padding: "5px",
                    color: "#0096FF",
                    border: 0,
                  }}
                >
                  <i className="fa fa-arrow-right "></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    ));
  };
  return (
    <Base
      title={
        is_my_appointments
          ? " My Appointments "
          : t("patientAppointments.appointments")
      }
      showHeader={true}
      isPatientPortal={!isStaff}
      rightChild={
        <>
          <Link
            to={
              has_add_permission && isStaff
                ? "/search/patient"
                : "/patientPortal/create/appointment"
            }
            className="create-app-btn"
          >
            {(!isStaff || (has_add_permission && isStaff)) && radio == 'new' ? (
           appointmets.length == 0 && hasAppointment?.AppointmentFlag?.isAppointment===false ?  <Button
                variant="primary"
                className="back mb-2 mr-2"
                onClick={createNewApp}
                // disabled={appointmets && appointmets.length ? true : false}
              >
                <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                {t("patientAppointments.newAppointment")}
              </Button> : refs?.length > 0 ?  <Link
          to={ref_link}
        > <Button
                variant="primary"
                className="back mb-2 mr-2"
              >
                <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                {t("patientDashboard.bookReferral")}
              </Button>
           </Link> : followUps?.length > 0 &&   <Link
          to={follow_up_link}
        > <Button
                variant="primary"
                className="back mb-2 mr-2"
              >
                <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                {t("patientDashboard.bookFollowUp")}
              </Button>
           </Link>
            ) : null}
          </Link>
          {has_add_permission && isStaff ? (
            <Link to={"/create/appointment/?group=true"}>
              {!isStaff || (has_add_permission && isStaff) ? (
                <Button
                  variant="primary"
                  className="back mb-2 create-group-app-btn"
                  onClick={createNewApp}
                >
                  <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                  {t("patientAppointments.newGroupAppointment")}
                </Button>
              ) : null}
            </Link>
          ) : null}
        </>
      }
    >

      {loadingPage ? (
        <Preloader />
      ) : (
        <Row className="mt-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mx-auto mb-2"
            style={{ minHeight: "114vh" }}
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              innerRef={formikRef}
            >
              {({ values, handleChange }) => (
                <Form autoComplete="off" className="mb-4">
                  <Row className="filter-block">
                    {!is_my_appointments ? (
                      <Col md={12} xl={4}>
                        <Form.Group
                          as={Row}
                          className={
                            "multiSelectContainer " +
                            "docOptions_" +
                            docOptions.length
                          }
                        >
                          <Form.Label
                            column
                            sm={3}
                            md={3}
                            xl={4}
                            className="text-left"
                          >
                            {t("patientAppointments.practitioner")}
                          </Form.Label>
                          <Col sm={12} md={9} xl={8}>
                            <MultiSelect
                              options={docOptions}
                              value={selectedDocList}
                              required
                              ClearIcon=""
                              hasSelectAll={false}
                              placeholder="{t('patientAppointments.selectPractitioner')}"
                              onChange={(doc) => {
                                handleChangeDoctor(doc);
                              }}
                              labelledBy="Select"
                              overrideStrings={defaultStrings}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    ) : null}

                    <Col md={12} xl={4}>
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          {t("patientAppointments.location")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="location"
                            value={selectedLocation}
                            onChange={(e) => locationFilterChanged(e)}
                          >
                            <option value="">
                              {t("patientAppointments.selectLocation")}
                            </option>
                            {location && location.listEventLocation
                              ? location.listEventLocation?.edges.map(
                                (item, index) => {
                                  return (
                                    <option
                                      value={item.node.id}
                                      key={item.node.titleEn}
                                    >
                                      {item.node.title}
                                    </option>
                                  );
                                }
                              )
                              : null}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <div
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
              onChange={(e) => {
                let k = e.target.value;
                setAppointments([]);
                if (k === "new") {
                  setRadio("new");
                  getAppointments("new");
                } else if (k === "cancelled") {
                  setRadio("cancelled");
                  getAppointments("cancelled");
                } else {
                  setRadio("previous");
                  getAppointments("previous");
                }
              }}
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autocomplete="off"
                value="new"
              />
              <label
                className={
                  "btn  " +
                  (radio === "new" ? "btn-primary" : "btn-outline-primary")
                }
                for="btnradio1"
              >
                {t("patientDashboard.upcomingAppointments")}
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autocomplete="off"
                value="previous"
              />
              <label
                className={
                  "btn " +
                  (radio === "previous" ? "btn-primary" : "btn-outline-primary")
                }
                for="btnradio2"
              >
                {t("patientDashboard.previousAppointments")}
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio3"
                autocomplete="off"
                value="cancelled"
              />
              <label
                className={
                  "btn  " +
                  (radio === "cancelled" ? "btn-primary" : "btn-outline-primary")
                }
                for="btnradio3"
              >
                {t("patientAppointmentDetails.appointmentCancelled")}
              </label>
            </div>

            <Row spacing={3}>{renderAppointmentDetails(appointmets)}</Row>
          </Col>
        </Row>
      )}
    </Base>
  );
}
export default withRouter(MyAppointments);
