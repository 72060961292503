import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import Base from "./base";
import { Link } from "react-router-dom";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  concatAllErrors,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { gql, useQuery } from "@apollo/client";
import Preloader from "../Common/Preloder/Preloader";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

const SurveyFormsList = () => {
  const [openSnackbar] = useSnackbar(error_options);
  const { t } = useTranslation();

  const REQUEST_SURVEY_FORMS = gql`
    query {
      surveys {
        edges {
          node {
            id
            created
            submitted
            patient {
              firstName
              middleName
              lastName
            }
            data
            submitted
            dynamicForm {
              name
              id
              formType
              formBlocks {
                edges {
                  node {
                    name
                    id
                    staticId
                    formFields {
                      edges {
                        node {
                          id
                          name
                          subText
                          fieldName
                          variant
                          staticId
                          code
                          blockFormFields {
                            edges {
                              node {
                                formField {
                                  name
                                }
                                formBlock {
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data: survey_forms_obj, loading: loadingSurvey } = useQuery(
    REQUEST_SURVEY_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_SURVEY_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const renderSurveyForms = () => {
    let finalSurveyFormsList = [];

    let survey_forms =
      survey_forms_obj &&
      survey_forms_obj.surveys &&
      survey_forms_obj.surveys.edges.length > 0
        ? survey_forms_obj.surveys.edges
        : [];

    let survey_forms_elem = survey_forms.map((form, index) => {
      return {
        link: "/patientPortal/surveyform/" + form.node.id,
        // onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: t("survey.subtitle"),
        buttonText: t("survey.fillForm"),
        icon: faSmile,
        backgroundClass: "green",
      };
    });

    finalSurveyFormsList.push(...survey_forms_elem);
    return finalSurveyFormsList.map((item, index) =>
      item?.passData?.node?.submitted === true ? null : (
        <Col xs={12} sm={6} md={6} lg={4} key={index}>
          <Card>
            <Card.Body className="p-4">
              <div className="mb-2 text-uppercase"> {item?.title}</div>
              <div className="textGrey">
                <b>{getDate(item?.passData?.node?.created)}</b>{" "}
                {/* <b>{getForamttedTime(new Date(item.passData.node.created))}</b> */}
              </div>
              <Link
                to={item?.link}
                onClick={() =>
                  item?.onClickFunc && item?.passData
                    ? item.onClickFunc(item?.passData)
                    : ""
                }
              >
                <Button variant="primary" block className="back mt-4">
                  {t("survey.fillForm")}
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      )
    );
  };

  return (
    <Base
      title={"Survey Forms List"}
      showHeader={true}
      isPatientPortal={true}
      containerClass="mt-0"
    >
      {loadingSurvey ? <Preloader /> : <Row>{renderSurveyForms()}</Row>}
    </Base>
  );
};

export default SurveyFormsList;
