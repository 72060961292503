import React, { useState, useEffect, } from 'react';
import { Row, Col, Pagination} from 'react-bootstrap';
import Base from './base.js';
import { withRouter } from 'react-router-dom';
import { gql, useQuery} from '@apollo/client';
import UpcomingAppointmentCard from '../component/upcomingAppointmentCard';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  urlActions,
  displayMiddleName,
  // getPermissionForAction,
  isUserStaff,concatAllErrors
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import * as Sentry from "@sentry/browser";
 
var today = new Date();
today = today.getFullYear() +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(today.getDate()).padStart(2, "0")

function PatientAppointments(props) {
  const isStaff = isUserStaff();
  const identifier = props.match.params.identifier;
  const [activeUpcomingPage, setActiveUpcomingPage] = useState(1);
  const [upcomingApointments, setUpcomingApointments] = useState([]);
  const [pastApointments, setPastApointments] = useState([]);
  const { history } = props;

  const REQUEST_PATIENT = gql`
  query {
    patients(identifier:"${identifier}"){
      totalCount
      edges {
        node {
          firstName
          middleName
          lastName
          id
          phone
          email
          gender
          civilId
          appointment{
            edges{
              node{
                doctor{
                  identifier
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const REQUEST_UPCOMING_EVENTS = gql`
  query {
    events( patient_Identifier:"${identifier}", orderby:"start", start:"${today}"){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          doctorEventType {
            title {
              name
            }
          }
          eventType{
          name
          id
          }
        }
      }
    }
  }
`;

  const REQUEST_PAST_EVENTS = gql`
  query {
    events( patient_Identifier:"${identifier}", end:"${today}", orderby:"-start"){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          doctorEventType {
            title {
              name
            }
          }
          eventType{
          name
          id
          }
        }
      }
    }
  }
`;

  const [openSnackbar] = useSnackbar(error_options);

  // GET PATIENT DATA
  const { data, loading } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      if(e?.message?.toLocaleLowerCase()?.indexOf("permission")<0){
        Sentry.captureException("REQUEST_PATIENT error "+e);
      }
      
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const patient_record = data && data.patients ? data.patients.edges[0] : null;

  // // GET UPCOMING APPOINTMENT DATA
  const { data: upcoming_events_obj = null, loading: loading_upcoming = false } = useQuery(
    REQUEST_UPCOMING_EVENTS,
    {
      fetchPolicy: 'network-only',
      onError: (e) => {
        Sentry.setContext('error', e?.networkError?.result);
        Sentry.setContext('ERROR OBJ ', {errorObj : e});
        Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
        if(e?.message?.toLocaleLowerCase()?.indexOf("permission")<0){
          Sentry.captureException("REQUEST_UPCOMING_EVENTS error "+e);
        }
        
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    },
  );

  // // GET UPCOMING APPOINTMENT DATA
  const { data: past_events_obj = null, loading: loading_past = false } = useQuery(
    REQUEST_PAST_EVENTS,
    {
      fetchPolicy: 'network-only',
      onError: (e) => {
        Sentry.setContext('error', e?.networkError?.result);
        Sentry.setContext('ERROR OBJ ', {errorObj : e});
        Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
        if(e?.message?.toLocaleLowerCase()?.indexOf("permission")<0){
          Sentry.captureException("REQUEST_PAST_EVENTS error "+e);
        }
        
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    },
  );

  useEffect(() => {
    let upcoming_events = [];
    if (upcoming_events_obj) {
      if (upcoming_events_obj.events) {
        upcoming_events = upcoming_events_obj.events.edges;
        setUpcomingApointments(upcoming_events);
      }
    }
  }, [upcoming_events_obj]);

  useEffect(() => {
    let past_events = [];
    if (past_events_obj) {
      if (past_events_obj.events) {
        past_events = past_events_obj.events.edges;
        setPastApointments(past_events);
      }
    }
  }, [past_events_obj]);


  const renderUpcomingAppointments = (events_list) => {
    let appointments = events_list;
    return appointments.map((appointment, index) => {
      let event = appointment.node;
      return (
        <Col md={6} lg={3}>
          <UpcomingAppointmentCard patient_record={patient_record?.node} event={event} key={index} showStatus={true} />
        </Col>
      )
    });
  };
  useEffect(() => {
    let page = urlActions(window.location.href, 'get', 'page');
    if (page) {
      setActiveUpcomingPage(parseInt(page));
    }
  }, []);

  const handlePageChange = (number) => {
    setActiveUpcomingPage(number);
    history.push(props.location.pathname+'?page=' + number);
  };

  const renderPages = () => {
    let total_pages = upcoming_events_obj && upcoming_events_obj.events && upcoming_events_obj.events.totalCount ? Math.ceil(upcoming_events_obj.events.totalCount / PER_PAGE_COUNT) : 1;
    let items = [];
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === activeUpcomingPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }
  const isPast= history.location.search?.indexOf("past=true")>-1;
  return (
    <Base
      title={patient_record && patient_record.node ? patient_record.node.firstName + ' ' + displayMiddleName(patient_record.node.middleName) +patient_record.node.lastName + (isPast? " Past Appointments": " Upcoming Appointments" ): ''}
      showHeader={true}
      back="/patients"
      page_loading={loading}
      isPatientPortal={!isStaff}
    >
      {loading ? (
        <Preloader />
      ) : (
        <Row >
          {isPast? 
          (pastApointments && pastApointments.length > 0 ? (
            <>
              {loading_past ? (
                <Preloader />
              ) : (
                renderUpcomingAppointments(pastApointments, true)
              )}
            </>
          ) : (
            'No Past Appointments'
          ))
          :
          (upcomingApointments && upcomingApointments.length > 0 ? (
            <>
              {loading_upcoming ? (
                <Preloader />
              ) : (
                renderUpcomingAppointments(upcomingApointments)
              )}
            </>
          ) : (
            'No Upcoming Appointments'
          ))}
        </Row>
      )}
      {upcoming_events_obj && upcoming_events_obj.events && upcoming_events_obj.events.totalCount > PER_PAGE_COUNT ? <div className="d-flex justify-content-center pt-5">
        <Pagination>{renderPages()}</Pagination>
      </div> : null}
    </Base>
  );
}
export default withRouter(PatientAppointments);
