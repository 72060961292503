import { ApolloClient } from "@apollo/client";
import { cache } from "cache/cache";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
import { createUploadLink } from "apollo-upload-client";

export const urlBackend = process.env.REACT_APP_BACKEND_ENDPOINT;
export const httpLink = createUploadLink({
  uri: (op: any) =>
    urlBackend + "graphql/" + (op.variables["__uriExtra"] || ""),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  // headers['Accept-Language'] = 'ar'
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      "Accept-Language": localStorage.getItem("appLanguage"),
    },
  };
});

const corsOptions = {
  credentials: false,
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  //@ts-ignore
  cors: corsOptions,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});
