
import React from 'react';
import SearchForPatient from './SearchForPatientHoc';
import { Button,Modal} from 'react-bootstrap';

const SelectPatientModal=(props)=>{
  const {showPatientSearch, setShowPatientSearch,onSelectPatientFunc}=props;
  return(
    <Modal show={showPatientSearch} onHide={()=>setShowPatientSearch(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-60w"
          centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <SearchForPatient setShowPatientSearch={setShowPatientSearch} isModal={true} onSelectPatientFunc={onSelectPatientFunc} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowPatientSearch(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default SelectPatientModal;
