import React, { useEffect, useState } from "react";

function CountdownTimer({ targetDate, onComplete }: any) {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      //@ts-ignore
      const difference = targetDate - now;

      if (difference < 0) {
        clearInterval(timer);
        setTimeLeft("");
        if (onComplete) {
          onComplete();
        }
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        let timeString = "";
        if (days > 0) timeString += `${days}d `;
        if (hours > 0) timeString += `${hours}h `;
        if (minutes > 0) timeString += `${minutes}m `;
        if (seconds > 0) timeString += `${seconds}s`;

        setTimeLeft(timeString.trim());
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, onComplete]);

  return <>{timeLeft}</>;
}

export default CountdownTimer;
