import { Row, Col, Button, Form, Container } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import Base from "./base.js";
import { Formik } from "formik";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  paymentReason,
  getDate,
  getForamttedTime,
  getTotalRemaining,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers.js";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import _ from "lodash";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers.js";

function ManualPayment(props) {
  const [selectedPatient, setSelectedPatient] = useState({});
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [selectedLabTest, setSelectedLabTest] = useState([]);
  const [totalTestPrice, setTotalTestPrice] = useState(0);
  // appointment input enable/disable
  const [appointmentEnable, setAppointmentEnable] = useState(false);
  const formikRef = useRef();
  const appointment_detail = props.location.state
    ? props.location.state.appointment_detail
    : null;
  const isAppointment = props.location.state
    ? props.location.state.isAppointment
    : null;
  const [identifier, setIdentifier] = useState(null);
  const [check, setCheck] = useState(false);
  const [actualDate, setActualDate] = useState(new Date());
  const [options, setOptions] = useState([]);
  const [doctorsList, setDoctorList] = useState(null);

  const PaymentTypes = {
    manual: "Offline",
    online: "Online",
  };
  const SpecificPaymentTypes = {
    link: "TAP (Go Collect)",
    iban: "IBAN",
    knet: "Knet",
  };

  const REQUEST_DOCTOR = gql`
    query {
      doctors(active: true) {
        edges {
          node {
            firstName
            lastName
            arabicName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const REQUEST_EVENT = gql`
    query {
      event(id:"${identifier}"){
        price
        paymentSources{
          edges{
            node{
              amountAllocated
              status
            }
          }
        }
      }
    }
  `;

  const REQUEST_UNPAID_APPOINTMENT = gql`
    query getUnpaidAppointments(
      $patient_identifier: String!
      $unpaid: Boolean!
    ) {
      events(patient_Identifier: $patient_identifier, unpaid: $unpaid) {
        edges {
          node {
            title
            id
            start
            end
            status
          }
        }
      }
    }
  `;

  const LAB_TEST_SERVICES = gql`
    query {
      labTestServices(other: false) {
        edges {
          node {
            id
            name
            price
          }
        }
      }
    }
  `;

  const OTHER_SERVICES = gql`
    query {
      labTestServices(other: true) {
        edges {
          node {
            id
            name
            price
          }
        }
      }
    }
  `;

  const MANUAL_PAYMENT = gql`
    mutation createUpdatePayment(
      $patient: ID!
      $reason: String!
      $doctor: ID
      $order: ID!
      $amountAllocated: Float!
      $sourceType: String!
      $specificPaymentType: String
      $notes: String
      $labTestService: [ID]
      $actualDate: DateTime
    ) {
      createUpdatePayment(
        input: {
          patient: $patient
          reason: $reason
          doctor: $doctor
          appointment: $order
          amount: $amountAllocated
          paymentType: $sourceType
          specificPaymentType: $specificPaymentType
          notes: $notes
          labTestService: $labTestService
          actualDate: $actualDate
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
          paid
          paymentType
        }
        activeSource {
          sourceType {
            name
          }
          paymentType
          specificPaymentType
        }
      }
    }
  `;

  const { data: rawDoctorList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setDoctorList(deepCopy(rawDoctorList));
  }, [rawDoctorList]);

  const doctors =
    doctorsList && doctorsList.doctors
      ? doctorsList.doctors.edges.sort((a, b) =>
          a?.node?.firstName?.localeCompare(b?.node?.firstName)
        )
      : [];

  const [manualPayment] = useMutation(MANUAL_PAYMENT, {
    onCompleted: ({ createUpdatePayment }) => {
      if (
        createUpdatePayment?.errors &&
        createUpdatePayment?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdatePayment?.errors
        );
        openSnackbarError(error_messages_string, [SNACK_DURATION]);
      } else if (createUpdatePayment.obj) {
        if (
          createUpdatePayment.activeSource.specificPaymentType === "LINK" &&
          createUpdatePayment?.obj?.paymentType === "ONLINE"
        ) {
          openSnackbarSuccess("Payment link has been sent.");
        } else {
          openSnackbarSuccess("Payment Successsful.");
        }
        formikRef.current.handleReset();
        setSelectedPatient(null);
        setCheck(false);
        setAppointmentEnable(false);
        if (isAppointment) {
          props.history.push("/appointment/detail/" + appointment_detail.id);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("MANUAL_PAYMENT error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [getUnpaidAppointments, { data: unpaidAppointments = [] }] =
    useLazyQuery(REQUEST_UNPAID_APPOINTMENT, {
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_UNPAID_APPOINTMENT error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    });

  const [getLabTestServices, { data: labTestServices = [] }] = useLazyQuery(
    LAB_TEST_SERVICES,
    {
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("LAB_TEST_SERVICES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getOtherTestServices, { data: otherServices = [] }] = useLazyQuery(
    OTHER_SERVICES,
    {
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("OTHER_SERVICES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [
    getSelectedAppontmentInfo,
    { data: selectedAppointmentInfo, loading },
  ] = useLazyQuery(REQUEST_EVENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENT error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const searchPatientRef = useRef();

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );
    setSelectedPatient(patientData);
    getUnpaidAppointments({
      variables: { patient_identifier: patientData.identifier, unpaid: true },
    });
  };

  useEffect(() => {
    if (isAppointment) {
      setSelectedPatient(appointment_detail.patient);
    }
  }, [isAppointment]);

  const initialValues = {
    patient: isAppointment
      ? appointment_detail.patient.firstName +
        " " +
        displayMiddleName(appointment_detail.patient.middleName) +
        appointment_detail.patient.lastName
      : "",
    reason: isAppointment ? paymentReason.appointment : "",
    doctor: "",
    order: "",
    amount: "",
    appointment: isAppointment ? appointment_detail.id : "",
    payment_method: "",
    specific_payment_type: "",
    note: "",
  };

  const handleChangeReason = (event) => {
    if (event.target.value === "appointment") {
      setAppointmentEnable(true);
      setCheck(true);
    } else {
      setAppointmentEnable(false);
      setCheck(false);
    }
    if (event.target.value === "genetic_lab_test") {
      getLabTestServices();
      setOptions([]);
      setSelectedLabTest([]);
    } else if (event.target.value === "others") {
      getOtherTestServices();
      setOptions([]);
      setSelectedLabTest([]);
    }
    formikRef.current.setFieldValue("reason", event.target.value);
    formikRef.current.setFieldValue("appointment", "");
    formikRef.current.setFieldTouched("appointment", true, true);
  };

  const handleChangeAppointment = (e) => {
    if (e.target.value === "Select appointment" || e.target.value === "") {
      setAppointmentEnable(false);
    }
    formikRef.current.setFieldValue("appointment", e.target.value);
    setIdentifier(e.target.value);
    setAppointmentEnable(true);
    getSelectedAppontmentInfo();
  };

  const onSubmit = (values, { resetForm }) => {
    let val = {
      patient: selectedPatient.id,
      reason: isAppointment ? "appointment" : values.reason,
      order: isAppointment ? appointment_detail.id : values.appointment,
      amountAllocated:
        values.reason === "genetic_lab_test" ||
        (values.reason === "others" && totalTestPrice)
          ? totalTestPrice
          : values.amount,
      sourceType: values.payment_method,
    };

    if (values.specific_payment_type) {
      val["specificPaymentType"] = values.specific_payment_type;
    }
    if (values.note) {
      val["notes"] = values.note;
    }
    if (values.doctor) {
      val["doctor"] = values.doctor;
    }

    if (
      values.reason === "genetic_lab_test" ||
      (values.reason === "others" && selectedLabTest)
    ) {
      val["labTestService"] = selectedLabTest.map((i) => i.value);
      val["reason"] = values.reason;
    }

    if (values.reason !== "appointment" && actualDate) {
      val["actualDate"] = new Date(
        actualDate.getTime() - actualDate.getTimezoneOffset() * 60000
      ).toISOString();
    }
    if (values.reason !== "appointment" && !actualDate) {
      alert("Please add actual date");
      return;
    }
    manualPayment({ variables: val });
  };

  const app_name = (app) => {
    let date = getDate(isAppointment ? app?.start : app?.start);
    let startHours = getForamttedTime(
      new Date(isAppointment ? app?.start : app?.start)
    );
    let endHours = getForamttedTime(
      new Date(isAppointment ? app?.start : app?.end)
    );
    return `${date} ${startHours} to ${endHours}`;
  };

  const renderAppointmentDropdown = () => {
    var date_arr = unpaidAppointments?.events?.edges;
    date_arr = date_arr?.map((item) => {
      let obj = JSON.parse(JSON.stringify(item?.node));
      obj["start_date"] = obj.start.split("T")[0];
      obj["start_time"] = obj.start.split("T")[1];
      return obj;
    });
    date_arr = _.orderBy(
      date_arr,
      ["start_date", "start_time"],
      ["desc", "asc"]
    );

    if (date_arr) {
      return date_arr.map((app, index) => {
        if (app?.status !== "COMPLETED" && app?.status !== "CANCELLED") {
          return (
            <option value={app?.id} key={index}>
              {app_name(app)}
            </option>
          );
        }
      });
    }
  };

  const price = appointment_detail
    ? parseFloat(appointment_detail.price)
    : null;
  const paymentSources =
    appointment_detail &&
    appointment_detail.paymentSources &&
    appointment_detail.paymentSources.edges
      ? appointment_detail.paymentSources.edges
      : null;

  const unpaidAppointmentPrice =
    selectedAppointmentInfo && selectedAppointmentInfo.event
      ? parseFloat(selectedAppointmentInfo.event.price)
      : null;
  const selectedUnpaidAppointment =
    check &&
    appointmentEnable &&
    selectedAppointmentInfo &&
    selectedAppointmentInfo.event &&
    selectedAppointmentInfo.event.paymentSources &&
    selectedAppointmentInfo.event.paymentSources.edges
      ? selectedAppointmentInfo.event.paymentSources.edges
      : null;

  useEffect(() => {
    if (labTestServices?.labTestServices?.edges.length > 0) {
      const choices = labTestServices?.labTestServices?.edges?.map((item) => {
        return {
          label: item.node.name,
          value: item.node.id,
          price: item.node.price,
        };
      });

      setOptions(choices);
    }
  }, [labTestServices?.labTestServices]);

  useEffect(() => {
    if (otherServices?.labTestServices?.edges.length > 0) {
      const choices = otherServices?.labTestServices?.edges?.map((item) => {
        return {
          label: item.node.name,
          value: item.node.id,
          price: item.node.price,
        };
      });

      setOptions(choices);
    }
  }, [otherServices?.labTestServices]);

  useEffect(() => {
    if (selectedLabTest?.length > 0) {
      let price = _.sumBy(selectedLabTest, function (test) {
        return parseFloat(test.price);
      });
      setTotalTestPrice(parseFloat(price));
    } else {
      setTotalTestPrice(0);
    }
  }, [selectedLabTest]);

  return (
    <Base title={"Manual Payment"} showHeader={true}>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleBlur, handleChange, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      Patient
                    </Form.Label>
                    <Col
                      sm={8}
                      md={8}
                      className="payment-section__patient-search"
                      ref={searchPatientRef}
                    >
                      <Form.Control
                        autoComplete="off"
                        placeholder="Select Patient"
                        type="input"
                        onBlur={handleBlur}
                        name="patient"
                        required
                        onChange={handleChange}
                        value={values.patient}
                        className="mr-sm-2 pr-0 float-left pr-5"
                        disabled={isAppointment ? true : false}
                      />
                      <span
                        onClick={() =>
                          setShowPatientSearch(isAppointment ? false : true)
                        }
                        className={`search-icon ${
                          isAppointment ? "search-icon-disabled" : ""
                        }`}
                      >
                        <i className="fa fa-search"></i>
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      <span className="">Reason </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="reason"
                        value={values.reason}
                        onChange={handleChangeReason}
                        required
                        disabled={isAppointment}
                      >
                        {isAppointment ? (
                          <option>{paymentReason.appointment}</option>
                        ) : (
                          <>
                            <option value="">Select reason</option>
                            {Object.entries(paymentReason).map(
                              ([key, value], index) => {
                                return (
                                  <option value={key} key={index}>
                                    {value}
                                  </option>
                                );
                              }
                            )}
                          </>
                        )}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {values.reason === "genetic_lab_test" ||
                  values.reason === "others" ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0 required"
                      >
                        <span className="">Specify Reason</span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <MultiSelect
                          options={options}
                          value={selectedLabTest}
                          required
                          onChange={setSelectedLabTest}
                          labelledBy="Select"
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  {values.reason &&
                  values.reason != "appointment" &&
                  !isAppointment ? (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={4}
                          md={4}
                          className="text-right pr-0 required"
                        >
                          Practitioner
                        </Form.Label>
                        <Col sm={8} md={8}>
                          <Form.Control
                            autoComplete="off"
                            onBlur={handleBlur}
                            as="select"
                            name="doctor"
                            required
                            value={values.doctor}
                            onChange={handleChange}
                          >
                            <option value="">Select Practitioner</option>
                            {doctors.map((doctor) => {
                              return (
                                <option
                                  value={doctor.node.id}
                                  key={doctor.node.id}
                                >
                                  {doctor.node.firstName} {doctor.node.lastName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </>
                  ) : null}

                  {check || isAppointment ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0 required"
                      >
                        <span className="">Appointment </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          onBlur={handleBlur}
                          name="appointment"
                          required
                          value={values.appointment}
                          onChange={handleChangeAppointment}
                          disabled={!appointmentEnable}
                        >
                          {isAppointment ? (
                            <option>{app_name(appointment_detail)}</option>
                          ) : (
                            <option>Select appointment</option>
                          )}

                          {unpaidAppointments?.events?.edges
                            ? renderAppointmentDropdown()
                            : null}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}
                  {isAppointment ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        Total Remaining
                      </Form.Label>
                      <Col
                        sm={8}
                        md={8}
                        className="payment-section__patient-search d-flex align-items-center"
                      >
                        {getTotalRemaining(paymentSources, price)[1]} KWD
                      </Col>
                    </Form.Group>
                  ) : null}
                  {values.reason === "genetic_lab_test" ||
                  (values.reason === "others" &&
                    selectedLabTest?.length > 0 &&
                    totalTestPrice) ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        Total
                      </Form.Label>
                      <Col
                        sm={8}
                        md={8}
                        className="payment-section__patient-search d-flex align-items-center"
                      >
                        {totalTestPrice} KWD
                      </Col>
                    </Form.Group>
                  ) : null}
                  {formikRef?.current?.values?.appointment && !isAppointment ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        Total Remaining
                      </Form.Label>
                      <Col
                        sm={8}
                        md={8}
                        className="payment-section__patient-search d-flex align-items-center"
                      >
                        {
                          getTotalRemaining(
                            selectedUnpaidAppointment,
                            unpaidAppointmentPrice
                          )[1]
                        }{" "}
                        KWD
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      Actual date
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        autoComplete="off"
                        selected={actualDate}
                        required
                        onChange={(date) => setActualDate(date)}
                        className="form-control"
                        dateFormat="dd/MMM/yyyy"
                        id="actual-date"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      <span className="">Amount </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        onBlur={handleBlur}
                        name="amount"
                        value={
                          values.reason === "genetic_lab_test" ||
                          (values.reason === "others" && totalTestPrice)
                            ? totalTestPrice
                            : values.amount
                        }
                        onChange={handleChange}
                        disabled={
                          values.reason === "genetic_lab_test" ||
                          values.reason === "others"
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      <span className="">Payment Method </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        onBlur={handleBlur}
                        name="payment_method"
                        value={values.payment_method}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select payment method</option>
                        {Object.entries(PaymentTypes).map(
                          ([key, value], index) => {
                            return (
                              <option value={key} key={index}>
                                {value}
                              </option>
                            );
                          }
                        )}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {values.payment_method === "manual" ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0 required"
                      >
                        <span className="">Specific Payment Type </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          onBlur={handleBlur}
                          name="specific_payment_type"
                          value={values.specific_payment_type}
                          onChange={handleChange}
                        >
                          <option value="">Select Specific Payment Type</option>
                          {Object.entries(SpecificPaymentTypes).map(
                            ([key, value], index) => {
                              return (
                                <option value={key} key={index}>
                                  {value}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={12}
                      md={4}
                      className="text-right pr-0 "
                    >
                      <span className="">Note</span>
                    </Form.Label>
                    <Col sm={12} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        type="textarea"
                        onBlur={handleBlur}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-4 mb-2 manual-payment-button-block">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => props.history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" size="sm" type="submit">
                      Submit
                    </Button>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default ManualPayment;
