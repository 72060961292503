import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import "./styles.scss";
import "./style2.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { BrowserRouter } from "react-router-dom";

import { ignoreErrors } from "sentryUtils";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { client } from "apolloSettings";

// import i18n
import "./locales/i18n.js";
import { CircularProgress } from "@mui/material";

const ENV = process.env.REACT_APP_ENV;

if (ENV === "production") {
  Sentry.init({
    dsn: "https://ec839c05c8c14a489409ea9a3c6e1a1d@o4505582634729472.ingest.sentry.io/4505582659698688",
    environment: ENV,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    release: "mindwellfe@1.0.5",
    ignoreErrors: ignoreErrors,
  });
}

ReactDOM.render(
  <Suspense fallback={<CircularProgress />}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
