import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Table,
  Pagination,
} from "react-bootstrap";
import Base from "./base.js";
import { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  gql,
  useQuery,
  useLazyQuery,
  useReactiveVar,
  useMutation,
} from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  displayMiddleName,
  PER_PAGE_COUNT,
  urlActions,
  concatAllErrors,
  success_options,
  getErrorMessage,
} from "../Common/helpers";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import Preloader from "../Common/Preloder/Preloader";
import { Link } from "react-router-dom";
import { accountsFilterVar } from "../cache/cache.js";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { paymentsFilterVar } from "../cache/cache.js";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers";

export const REQUEST_DOCTOR = gql`
  query {
    doctors(active: true) {
      edges {
        node {
          firstName
          lastName
          arabicName
          id
          email
          identifier
          doctorId
        }
      }
    }
  }
`;

const CANCEL_PAYMENT = gql`
  mutation cancelPayment($id: ID!) {
    cancelPayment(id: $id) {
      obj {
        id
        cancelled
      }
    }
  }
`;

export default function UnpaidManualPayments(props) {
  const patient_record = props.location.state
    ? props.location.state.patient_record
    : null;
  const identifier = patient_record ? patient_record.node.identifier : null;
  const searchPatientRef = useRef();
  const formikRef = useRef();
  const [activePage, setActivePage] = useState(1);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const accountsFilter = useReactiveVar(accountsFilterVar);
  const { t } = useTranslation();
  const paymetnsFilter = useReactiveVar(paymentsFilterVar);
  const original_permissions = localStorage.getItem("original_permissions");
  const [doctorsList, setDoctorList] = useState(null);
  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }
  }, []);

  const REQUEST_UNPAID_MANUAL_PAYMENTS_FILTER = gql`
  query($start:DateTime, $end:DateTime, $doctor:String, $patient:String, $amount:Float)  {
    unpaidPayments(
      start: $start, end: $end, 
      doctor_Identifier: $doctor, 
      patient_Identifier: $patient,
      amount:$amount,
      first:${PER_PAGE_COUNT}, 
      offset:${PER_PAGE_COUNT * (activePage - 1)}
      )
    {
      totalCount   
		totalAmount
    edges{
      node{
				id
        identifier
				displayReason
				reason
        created
        datePaid
        paid
        patient{
          firstName
          lastName
          identifier
          displayGender
        }
				paymentType
        amount
        currency
        notes
        appointment{
          id
        }
        sources{
          edges{
            node{
              paymentLink
            }
          }
        }
      }
    }
  }
    }
  
  `;
  const [
    getPaymentsFilter,
    { data: unpaidManualPaymentsObjFilter = null, loading },
  ] = useLazyQuery(REQUEST_UNPAID_MANUAL_PAYMENTS_FILTER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (accountsFilter) {
      if (accountsFilter.doctorIdentifier) {
        formikRef.current.setFieldValue(
          "doctor",
          accountsFilter.doctorIdentifier
        );
      }
      if (accountsFilter.amount) {
        formikRef.current.setFieldValue("amount", accountsFilter.amount);
      }
      if (accountsFilter.patient) {
        formikRef.current.setFieldValue(
          "patient",
          `${accountsFilter.patient.firstName} ${displayMiddleName(
            accountsFilter.patient.middleName
          )}${accountsFilter.patient.lastName}`
        );
        setSelectedPatient(accountsFilter.patient);
      }
      if (accountsFilter.startDate) {
        setStartDate(accountsFilter.startDate);
      }
      if (accountsFilter.endDate) {
        setEndDate(accountsFilter.endDate);
      }
      if (Object.keys(accountsFilter).length > 0) {
        formikRef.current.handleSubmit();
        setTimeout(() => {
          accountsFilterVar(null);
        }, 500);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cancelPayment] = useMutation(CANCEL_PAYMENT, {
    awaitRefetchQueries: true,
    onCompleted: ({ cancelPayment }) => {
      if (cancelPayment?.errors && cancelPayment?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(cancelPayment?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        resetFilter();
        openSnackbarSuccess("Payment Cancelled", [SNACK_DURATION]);
      }
    },

    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("cancelEvent error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const { data: rawDoctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setDoctorList(deepCopy(rawDoctorsList));
  }, [rawDoctorsList]);

  const doctors =
    doctorsList && doctorsList.doctors
      ? doctorsList.doctors.edges.sort((a, b) =>
          a?.node?.firstName?.localeCompare(b?.node?.firstName)
        )
      : [];

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );
    setSelectedPatient(patientData);
  };

  const onSubmit = (values) => {
    let startDateStr = "";
    let endDateStr = "";

    if (startDate) {
      let startDateObj = new Date(startDate);
      startDateStr = new Date(
        startDateObj.getTime() - startDateObj.getTimezoneOffset() * 60000
      ).toISOString();
    }
    if (endDate) {
      let endDateObj = new Date(endDate);
      endDateStr = new Date(
        endDateObj.getTime() - endDateObj.getTimezoneOffset() * 60000
      ).toISOString();
    }

    let variables = {};
    let doctorId = "";
    let patientId = "";
    if (values.doctor) {
      variables["doctor"] = values.doctor;
      let doctorIdObj = doctors.find(
        (i) => i.node.identifier === values.doctor
      );
      if (doctorIdObj) {
        doctorId = doctorIdObj.node.doctorId;
      }
    }
    if (patient_record) {
      variables["patient"] = patient_record
        ? patient_record.node.identifier
        : "";
      patientId = patient_record.node.patientId;
    }
    if (selectedPatient) {
      variables["patient"] = selectedPatient ? selectedPatient.identifier : "";
      patientId = selectedPatient.patientId;
    }
    if (startDateStr) {
      variables["start"] = startDateStr;
    }
    if (endDateStr) {
      variables["end"] = endDateStr;
    }
    if (values.amount) {
      variables["amount"] = values.amount;
    }

    getPaymentsFilter({
      variables: variables,
    });
  };

  const initialValues = {
    doctor: "",
    patient: "",
    paidby: "",
  };

  const goToDetailPage = () => {
    if (
      selectedPatient ||
      formikRef.current.values.doctor ||
      formikRef.current.values.amount ||
      startDate ||
      endDate
    ) {
      accountsFilterVar({
        patient: selectedPatient,
        doctorIdentifier: formikRef.current.values.doctor,
        startDate: startDate,
        endDate: endDate,
        amount: formikRef.current.values.amount,
      });
    } else {
      accountsFilterVar(null);
    }
  };

  const renderList = (unpaidManualPaymentsList) => {
    let payment_list =
      unpaidManualPaymentsList && unpaidManualPaymentsList.unpaidPayments
        ? unpaidManualPaymentsList.unpaidPayments.edges
        : [];
    if (payment_list.length > 0) {
      const canCancel = original_permissions.includes("can_cancel_payment");

      return payment_list.map((itemNode, index) => {
        let item = itemNode.node;
        let date = new Date(item.created);
        return (
          <tr key={index} className="patient_table_row">
            <td>
              <Link
                to={
                  item?.appointment === null
                    ? "/unpaid/manual/payments/detail/" + item.id
                    : "/appointment/detail/" + item?.appointment?.id
                }
                onClick={goToDetailPage}
                className="d-inline-block"
              >
                {getDate(date)}
              </Link>
            </td>
            <td>{item.identifier}</td>
            {!patient_record ? (
              <td>
                {item.patient
                  ? item.patient.firstName +
                    " " +
                    displayMiddleName(item.patient.middleName) +
                    item.patient.lastName
                  : ""}
              </td>
            ) : null}
            <td>
              {item.appointment
                ? item.appointment?.eventType?.name
                : item.displayReason}{" "}
            </td>
            <td>
              {item.appointment ? item.appointment.originalPrice : item.amount}{" "}
              {item.currency}
            </td>
            <td>
              {item.amount} {item.currency}
            </td>
            <td>{item.paymentType}</td>
            <td>
              {canCancel ? (
                <Button
                  onClick={() => cancelPayment({ variables: { id: item.id } })}
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </Button>
              ) : (
                "-"
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>{t("No payment data available")}</td>
        </tr>
      );
    }
  };

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
      setSelectedPatient(null);
      setStartDate("");
      setEndDate("");
    }
    accountsFilterVar(null);
    getPaymentsFilter();
  };

  useEffect(() => {
    if (identifier) {
      let variables = {};
      variables["patient"] = identifier;
      getPaymentsFilter({
        variables: variables,
      });
    } else {
      getPaymentsFilter();
    }
  }, [identifier]);

  const process_query_string = (data = {}) => {
    let query_string_obj = queryString.parse(window.location.search);

    // current filter key, values
    if (Object.keys(data).length <= 0) {
      data = paymetnsFilter;
    }

    for (const item in data) {
      query_string_obj[item] = data[item];
    }

    return query_string_obj;
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    let query_string = process_query_string();
    query_string["page"] = number;
    const stringified = queryString.stringify(query_string);
    window.history.pushState(null, "", "?" + stringified);
  };

  const renderPages = () => {
    let total_pages = unpaidManualPaymentsObjFilter?.payments?.totalCount
      ? Math.ceil(
          unpaidManualPaymentsObjFilter?.payments?.totalCount / PER_PAGE_COUNT
        )
      : 1;

    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  return (
    <Base
      title={`${
        patient_record
          ? `${patient_record.node.firstName} ${displayMiddleName(
              patient_record.node.middleName
            )}${patient_record.node.lastName} Payment Records`
          : "Unpaid Manual Payments"
      }`}
      showHeader={true}
    >
      <Row className="payment-section">
        <Col md={12} lg={12} className="mr-auto">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="justify-content-end">
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        From
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        To
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        Practitioner
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={handleChange}
                        >
                          <option value="">Select Practitioner</option>
                          {doctors.map((doctor) => {
                            return (
                              <option
                                value={doctor.node.identifier}
                                key={doctor.node.id}
                              >
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col
                    md={12}
                    lg={6}
                    xl={4}
                    sm={12}
                    className={`${patient_record ? "d-none" : "d-block"}`}
                  >
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        Patient
                      </Form.Label>
                      <Col
                        sm={12}
                        md={9}
                        lg={9}
                        className="payment-section__patient-search"
                        ref={searchPatientRef}
                      >
                        <Form.Control
                          autoComplete="off"
                          placeholder="Select Patient"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="patient"
                          value={values.patient}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                        <span
                          onClick={() => setShowPatientSearch(true)}
                          className="search-icon"
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12} className="ml-auto">
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        Amount
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Control
                          autoComplete="off"
                          placeholder="Enter Amount"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="amount"
                          value={values.amount}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12}>
                    <div
                      className={`d-flex justify-content-end submitPayment ${
                        patient_record ? "pt-3" : ""
                      }`}
                    >
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={resetFilter}
                      >
                        {" "}
                        Reset{" "}
                      </Button>
                      <Button type="submit" variant="primary">
                        {" "}
                        Submit{" "}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={12} className="mb-4">
          {loading ? (
            <Preloader />
          ) : (
            <Card className="paymentListCard">
              <Card.Body className="p-0">
                <Table responsive className=" table-borderless">
                  <thead>
                    <tr className="patient_table_row tableHeader">
                      <th scope="col " className="text-uppercase">
                        Date
                      </th>
                      <th scope="col " className="text-uppercase">
                        Xero Invoice #
                      </th>
                      {!patient_record ? (
                        <th scope="col " className="text-uppercase">
                          Patient Name{" "}
                        </th>
                      ) : null}
                      <th scope="col " className="text-uppercase">
                        Service
                      </th>
                      <th scope="col " className="text-uppercase">
                        Amount
                      </th>
                      <th scope="col " className="text-uppercase">
                        Total
                      </th>
                      <th scope="col " className="text-uppercase">
                        Payment Type
                      </th>
                      <th scope="col " className="text-uppercase">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unpaidManualPaymentsObjFilter
                      ? renderList(unpaidManualPaymentsObjFilter)
                      : null}
                    {unpaidManualPaymentsObjFilter ? (
                      <tr>
                        <td colSpan="4"></td>
                        <td className="text-uppercase font-bold">
                          Total Amount
                        </td>
                        <td className="text-uppercase font-bold">
                          {
                            unpaidManualPaymentsObjFilter.unpaidPayments
                              .totalAmount
                          }{" "}
                          KWD
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <div className="text-center">
        {unpaidManualPaymentsObjFilter?.payments?.totalCount >
        PER_PAGE_COUNT ? (
          <div className="paginationContainer pt-5">
            <Pagination>{renderPages()}</Pagination>
          </div>
        ) : null}
      </div>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
