import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Base from './base.js';

function ManageIntakeForms(props) {
  return (
    <Base title={'MANAGE INTAKE FORMS'} showHeader={true}>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold">FORM 1</span>
          <span>Description of form 1</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold">FORM 2</span>
          <span>Description of form 2</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold">FORM 3</span>
          <span>Description of form 3</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-4">
          <span className="textPrimaryBold">FORM 4</span>
          <span>Description of form 4</span>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-end button-below">
          <Button
            variant="primary"
            size="md"
            className="mr-5 btn-pg-low"
            onClick={() => props.history.push('/manage/intake/forms/form')}>
            ADD NEW
          </Button>
        </Col>
      </Row>
    </Base>
  );
}
export default ManageIntakeForms;
