import React, { useEffect } from "react";
import Navigation from "../component/Navigation/Navigation";
// import { gql, useQuery } from '@apollo/client';
import { Row, Col, Container, Button } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { isUserStaff } from "../Common/helpers";
import Preloader from "Common/Preloder/Preloader";
import { appLanguageVar } from "../cache/cache";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { getPatient } from "redux/slices/patientSlice";
import { useAppDispatch } from "redux/reducers/hooks";

const Base = (props) => {
  const appLanguage = useReactiveVar(appLanguageVar);

  let history = useHistory();
  const isStaff = isUserStaff();
  const { t } = useTranslation();
  let nav_classes = " navigation_conainer ";
  let right_container_classes = "right_container no-print ";
 
  if (props.isPatientPortal) {
    nav_classes += " patient_nav ";
    right_container_classes += " greyBg ";
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props?.isPatientPortal) {
      dispatch(getPatient());
    }
  }, [dispatch, props?.isPatientPortal]);

  const backClicked = () => {
    if (
      props.location?.state?.from === "patient_record" &&
      props.location?.state?.identifier &&
      props.location?.state?.id
    ) {
      history.push(
        "/patient/record/" +
          props.location?.state?.id +
          "/?identifier=" +
          props.location?.state?.identifier
      );
    } else if (props?.back && props.isPatientPortal) {
      history.push(props.back);
    } else {
      history.goBack();
    }
  };
  return (
    <Row className={"h100vh mx-0"}>
      <Col
        //  md={3}
        lg={3}
        xl={3}
        className={nav_classes}
      >
        <Navigation isPatientPortal={props.isPatientPortal} />
      </Col>
      <Col
        // md={9}
        lg={9}
        xl={9}
        className={right_container_classes + props.rightContainerClass}
      >
        {props.showHeader && !props.page_loading ? (
          <Container
            className="no-col-padding d-print-none"
            style={{ minHeight: props.isView? "" : "140px" }}
            fluid
          >
            {props.isAppointmentDetailPage ? (
              <Row
                className="page_header  d-print-none "
                style={{ ...props.pageHeaderSx }}
              >
                <Col xs={12} md={4} className="px-0">
                  {props.title && (
                    <h4 className="base_title section-header" id="page_title">
                      {props.page_loading ? "" : props.title}
                    </h4>
                  )}
                  {!props.isDashboard ? (
                    <Button
                      variant="link"
                      className={`d-print-none px-0 mt-2 mb-1 ${
                        !isStaff ? "back-button-color" : ""
                      }`}
                      onClick={backClicked}
                    >
                      <FontAwesomeIcon
                        icon={appLanguage === "ar" ? faAngleRight : faAngleLeft}
                        className="icon_large mx-2"
                      />
                      <div className="backBtn">{t("base.back")}</div>
                    </Button>
                  ) : null}
                </Col>
                <Col xs={12} md={8}>
                  {props.rightChild && !props.page_loading
                    ? props.rightChild
                    : null}
                </Col>
              </Row>
            ) : (
              <Row
                className="page_header  d-print-none "
                style={{ ...props.pageHeaderSx }}
              >
                <Col
                  sm={{ span: 7, order: "first" }}
                  xs={{ span: 12, order: "last" }}
                  md={{ span: 7, order: "first" }}
                  className="px-0"
                >
                  <h4 className="base_title section-header" id="page_title">
                    {props.page_loading ? "" : props.title}
                  </h4>
                  {!props.isDashboard ? (
                    <Button
                      variant="link"
                      className={`d-print-none px-0 mt-2 mb-1 ${
                        !isStaff ? "back-button-color" : ""
                      }`}
                      onClick={backClicked}
                    >
                      <FontAwesomeIcon
                        icon={appLanguage === "ar" ? faAngleRight : faAngleLeft}
                        className="icon_large mx-2"
                      />
                      <div className="backBtn">{t("base.back")}</div>
                    </Button>
                  ) : null}
                </Col>
                <Col
                  sm={{ span: 5, order: "last" }}
                  xs={{ span: 12, order: "first" }}
                  md={{ span: 5, order: "last" }}
                  className="d-flex justify-content-end right-child-buttons-1"
                >
                  {props.rightChild && !props.page_loading
                    ? props.rightChild
                    : null}
                </Col>
              </Row>
            )}
          </Container>
        ) : null}
        <Container
          fluid
          // style={{marginTop:'1rem'}}

          className={
            props.containerClass
              ? props.containerClass
              : "mt-8 " +
                (appLanguage === "ar"
                  ? " float-container-right"
                  : " float-container")
          }
        >
          {!props.page_loading ? props.children : <Preloader />}
        </Container>
      </Col>
    </Row>
  );
};

export default withRouter(Base);
