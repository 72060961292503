import { Card, Table, Button } from "react-bootstrap";
import React from "react";
import {
  getDate,
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  success_options,
  getErrorMessage,
  concatAllErrors,
} from "Common/helpers.js";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "react-simple-snackbar";
import { gql, useMutation } from "@apollo/client";
import { getPermissionForAction } from "Common/helpers";
import * as Sentry from "@sentry/browser";

function RefundRequests(props) {
  /**
   * This component is for appointment payment details
   * It will be a list of payments needed for this event.
   *
   * It expects a prop event for its data. That should have sources
   * **/

  const { event, REQUEST_EVENT, appointment } = props;
  const { t } = useTranslation();
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const MANUAL_REFUND = gql`
    mutation manualRefund($refundRequest: ID!) {
      manualRefund(input: { refundRequest: $refundRequest }) {
        errors {
          field
          messages
        }
        obj {
          id
          amount
          status
          displayStatus
          notes
          dateRefunded
          refundId
          requiresApproval
          approvalStatus
          displayApprovalStatus
        }
      }
    }
  `;

  const [manualRefund] = useMutation(MANUAL_REFUND, {
    onCompleted: ({ manualRefund }) => {
      if (manualRefund?.errors && manualRefund?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(manualRefund?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = manualRefund.obj.displayStatus;
        setTimeout(() => {
          openSnackbarSuccess("Refund Request " + status, [SNACK_DURATION]);
        }, 1000);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("manualRefund error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
    refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
  });

  const postManualRefund = (refundId) => {
    if (window.confirm("Are you sure to Refund Manually?")) {
      manualRefund({
        variables: { refundRequest: refundId },
      });
    }
  };
  const renderRefundRequestItems = () => {
    const sources =
      event && appointment
        ? event?.refundRequests?.edges
        : event?.refundRequest?.edges;
    if (sources?.length > 0) {
      return sources.map((item, index) => (
        <tr key={index}>
          <td className="align-middle">
            {item.node.refundId ? item.node.refundId : "-"}
          </td>
          <td className="align-middle">
            {item.node.amount ? item.node.amount : "-"}
          </td>

          <td className="align-middle">
            {item.node.requiresApproval === true &&
            item.node.approvalStatus === null
              ? "Pending Approval"
              : item.node.requiresApproval === true &&
                item.node.approvalStatus === "DECLINED"
              ? item.node.displayStatus
              : item.node.displayStatus}
          </td>
          <td className="align-middle">
            {item.node.created ? getDate(new Date(item.node.created)) : "-"}
          </td>
          <td className="align-middle">
            {item.node.dateRefunded
              ? getDate(new Date(item.node.dateRefunded))
              : "-"}
          </td>
          <td className="align-middle">
            {item.node.notes ? item.node.notes : "-"}
          </td>
          <td className="align-middle">
            {item.node.staffNotes ? item.node.staffNotes : "-"}
          </td>
          <td className="align-middle">
            {item.node.status === "OFFLINE_REFUND" &&
            getPermissionForAction("accept_manual_refund", "approve") ? (
              <Button
                onClick={() => postManualRefund(item.node.id)}
                size="md"
                variant="primary"
              >
                {t("patientPaymentDetails.refundManually")}
              </Button>
            ) : null}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={3} className="align-middle">
            {t("patientPaymentDetails.noRefundRequested")}
          </td>
        </tr>
      );
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("patientPaymentDetails.refundRequests")}</Card.Title>
        <Table responsive>
          <thead>
            <tr>
              <th>{t("patientPaymentDetails.refundId")}</th>
              <th>{t("patientPaymentDetails.amount")}</th>
              <th>{t("patientPaymentDetails.status")}</th>
              <th>{t("patientPaymentDetails.requestCreated")}</th>
              <th>{t("patientPaymentDetails.dateRefunded")}</th>
              <th>{t("patientPaymentDetails.notes")}</th>
              <th>{t("patientPaymentDetails.staffNotes")}</th>
              <th>{t("patientPaymentDetails.action")}</th>
            </tr>
          </thead>
          <tbody>{renderRefundRequestItems()}</tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default RefundRequests;
