import React from 'react';
import { Spinner } from 'react-bootstrap';

const Preloader = (props) => {
  return (
    <div className={'spinner-block' + (props.positionAbsoluteLoader? ' positionAbsoluteLoader ' :'')}>
      <Spinner animation="grow" className='spinner-block__elem' />
      <Spinner animation="grow" className='spinner-block__elem' />
      <Spinner animation="grow" className='spinner-block__elem' />
    </div>
  );
};

export default Preloader;
