import { Row, Col, Button, Form, Container, Spinner } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getErrorMessage,
  getDate,
  concatAllErrors,
  getStartDate,
  getEndDate,
} from "../Common/helpers.js";
import _ from "lodash";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../cache/cache";
import DatePicker from "react-datepicker";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import * as Sentry from "@sentry/browser";

function RecurringEventModal(props) {
  const {
    doctorObj,
    showEventModal,
    setShowEventModal,
    recurringEventItem,
    REQUEST_DOCTOR,
    setStartLoading,
  } = props;
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectEveryday, setSelectEveryday] = useState(false);
  const [allDaySelected, setAllDaySelected] = useState(false);
  const [multiDaysSelected, setMultiDaysSelected] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dummy, setDummy] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doctorFromEdit, setDoctorFromEdit] = useState(false);

  var userDetails = useReactiveVar(userDetailsVar);
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  useEffect(() => {
    if (recurringEventItem) {
      setStartDate(new Date(recurringEventItem.node.startDate));
      if (recurringEventItem.node.dateUntil) {
        setEndDate(new Date(recurringEventItem.node.dateUntil));
      }

      let sTime = new Date();
      let startTimeArr = recurringEventItem?.node?.startTime.split(":");
      sTime.setHours(startTimeArr[0]);
      sTime.setMinutes(startTimeArr[1]);

      setStartDate1(sTime);
      getStartTime(sTime);

      let eTime = new Date();
      let endTimeArr = recurringEventItem?.node?.endTime.split(":");
      eTime.setHours(endTimeArr[0]);
      eTime.setMinutes(endTimeArr[1]);
      setEndDate1(eTime);
      getEndTime(eTime);
      setSelectEveryday(recurringEventItem.node.everyday);
      if (recurringEventItem.node.days) {
        let recArr = recurringEventItem?.node?.days;
        let arr = daysArr.filter((i) => recArr.includes(i.key));
        if (arr.length > 0) {
          arr = arr.map((item) => item);
        }
        setSelectedDays(arr);
      }
      if (
        recurringEventItem?.node?.startTime === "00:00:00" &&
        recurringEventItem?.node?.endTime === "23:59:00"
      ) {
        setAllDaySelected(!allDaySelected);
      }
      if (
        getDate(new Date(recurringEventItem?.node?.startDate)) !==
        getDate(new Date(recurringEventItem?.node?.dateUntil))
      ) {
        setMultiDaysSelected(!multiDaysSelected);
      }

      setDoctorFromEdit(recurringEventItem?.doctor?.node?.id);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikRef, recurringEventItem]);

  const CREATE_UPDATE_RECURRING_EVENT = gql`
    mutation createUpdateRecurringEvent(
      $id: ID
      $doctor: ID!
      $title: String!
      $startDate: DateTime!
      $startTime: Time!
      $endTime: Time!
      $everyday: Boolean
      $days: [String]
      $dateUntil: DateTime
    ) {
      createUpdateRecurringEvent(
        input: {
          id: $id
          doctor: $doctor
          title: $title
          startDate: $startDate
          startTime: $startTime
          endTime: $endTime
          everyday: $everyday # if everyday from start date and time
          days: $days # if only specific days in a wekk
          dateUntil: $dateUntil # if only you need a enddate
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
          title
          description
          everyday
          days
          startDate
          startTime
          endTime
          dateUntil
          modified
        }
      }
    }
  `;

  const [createUpdateRecurringEvent] = useMutation(
    CREATE_UPDATE_RECURRING_EVENT,
    {
      onCompleted: ({ createUpdateRecurringEvent }) => {
        if (
          createUpdateRecurringEvent?.errors &&
          createUpdateRecurringEvent?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            createUpdateRecurringEvent?.errors
          );
          openSnackbarError(error_messages_string, [SNACK_DURATION]);
        } else if (createUpdateRecurringEvent.obj) {
          openSnackbarSuccess("Recurring Event saved", [SNACK_DURATION]);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 2000);
          setTimeout(() => {
            setStartLoading(false);
          }, 2000);
          if (formikRef && formikRef.current) {
            formikRef.current.handleReset();
          }
        }
      },
      refetchQueries: [{ query: REQUEST_DOCTOR, fetchPolicy: "network-only" }],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("CREATE_UPDATE_RECURRING_EVENT error " + e);
        }
        if (e.message) {
          openSnackbarError(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbarError(msgToDisplay, [SNACK_DURATION]);
        }
        setIsSubmitting(false);
      },
    }
  );

  let daysArr = [
    {
      key: "0",
      value: "Sunday",
      initial: "S",
    },
    {
      key: "1",
      value: "Monday",
      initial: "M",
    },
    {
      key: "2",
      value: "Tuesday",
      initial: "T",
    },
    {
      key: "3",
      value: "Wednesday",
      initial: "W",
    },
    {
      key: "4",
      value: "Thursday",
      initial: "Th",
    },
    {
      key: "5",
      value: "Friday",
      initial: "F",
    },
    {
      key: "6",
      value: "Saturday",
      initial: "S",
    },
  ];

  const initialValues = {
    id: "",
    doctor: doctorFromEdit ? doctorFromEdit : userDetails?.doctor?.id,
    title: recurringEventItem?.node?.title
      ? recurringEventItem?.node?.title
      : "",
    startDate: "",
    startTime: "",
    endTime: "",
    everyday: "",
    days: "",
    dateUntil: "",
  };

  const onSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);
    setStartLoading(true);
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    let et = [];
    et = endTime.split(":");
    let st = [];
    st = startTime.split(":");

    if (values.doctor) {
      if (startDate >= today) {
        if (multiDaysSelected) {
          if (startDate <= endDate) {
            if (parseInt(et[0]) >= parseInt(st[0])) {
              if (
                endDate &&
                startDate &&
                startTime &&
                endTime &&
                values.title &&
                values.doctor
              ) {
                let val = {
                  // doctor: userDetails?.doctor?.id,
                  title: values.title,
                  startDate: getStartDate(startDate),
                  startTime: startTime,
                  endTime: endTime,
                };
                let dateUntil = getEndDate(endDate);

                val["dateUntil"] = dateUntil;
                val["doctor"] = values.doctor;

                if (recurringEventItem?.node?.id) {
                  val["id"] = recurringEventItem.node.id;
                }

                if (
                  startDate &&
                  endDate &&
                  getDate(new Date(startDate)) === getDate(new Date(endDate))
                ) {
                  val["days"] = [new Date(startDate).getDay()];
                  createUpdateRecurringEvent({ variables: val });
                  resetAll();
                } else if (selectedDays.length > 0 || selectEveryday) {
                  if (selectedDays.length > 0) {
                    val["days"] = selectedDays.map((i) => i.key);
                  } else if (selectEveryday) {
                    val["everyday"] = selectEveryday;
                  }

                  createUpdateRecurringEvent({ variables: val });
                  resetAll();
                } else {
                  openSnackbarError("Please select the days to be blocked", [
                    SNACK_DURATION,
                  ]);
                  setStartLoading(false);

                  setIsSubmitting(false);
                }
              } else {
                openSnackbarError("Please fill all required fields", [
                  SNACK_DURATION,
                ]);
                setStartLoading(false);

                setIsSubmitting(false);
              }
            } else {
              openSnackbarError(
                "'End time' should be greater than 'Start time'",
                [SNACK_DURATION]
              );
              setStartLoading(false);

              setIsSubmitting(false);
            }
          } else {
            openSnackbarError(
              "'To date' should be greater than or same as 'From date'",
              [SNACK_DURATION]
            );
            setStartLoading(false);

            setIsSubmitting(false);
          }
        } else {
          if (parseInt(et[0]) >= parseInt(st[0])) {
            if (
              startDate &&
              startTime &&
              endTime &&
              values.title &&
              values.doctor
            ) {
              let val = {
                // doctor: userDetails?.doctor?.id,
                title: values.title,
                startDate: getStartDate(startDate),
                startTime: startTime,
                endTime: endTime,
              };

              val["dateUntil"] = getEndDate(startDate);

              val["doctor"] = values.doctor;

              if (recurringEventItem?.node?.id) {
                val["id"] = recurringEventItem.node.id;
              }

              val["days"] = [new Date(startDate).getDay()];
              createUpdateRecurringEvent({ variables: val });
              resetAll();
            } else {
              openSnackbarError("Please fill all required fields", [
                SNACK_DURATION,
              ]);
              setStartLoading(false);
              setIsSubmitting(false);
            }
          } else {
            openSnackbarError(
              "'End time' should be greater than 'Start time'",
              [SNACK_DURATION]
            );
            setStartLoading(false);
            setIsSubmitting(false);
          }
        }
      } else {
        openSnackbarError("'From date' cannot be less than today", [
          SNACK_DURATION,
        ]);
        setStartLoading(false);
        setIsSubmitting(false);
      }
    } else {
      openSnackbarError("Please select a Practitioner", [SNACK_DURATION]);
      setStartLoading(false);
      setIsSubmitting(false);
    }
  };

  const resetAll = () => {
    setEndDate();
    setEndDate1();
    setStartDate();
    setStartDate1();
    setSelectEveryday(false);
    setSelectedDays([]);
    setEndTime();
    setStartTime();
    setShowEventModal(false);
    setAllDaySelected(false);
    setMultiDaysSelected(false);
  };

  const selectedDaysFunc = (item) => {
    let selDays = selectedDays;
    if (selDays.some((i) => i.key === item.key)) {
      selDays = selDays.filter((i) => i.key !== item.key);
      setSelectedDays(selDays);
    } else {
      selDays.push(item);
      setSelectedDays(selDays);
    }
    setDummy(!dummy);
  };

  const getStartTime = (date) => {
    setStartDate1(date);
    setStartTime(new Date(date).getHours() + ":" + new Date(date).getMinutes());
  };
  const getEndTime = (date) => {
    setEndDate1(date);
    setEndTime(new Date(date).getHours() + ":" + new Date(date).getMinutes());
  };

  const allDayTime = () => {
    setAllDaySelected(!allDaySelected);
    let st = new Date();
    // let dateUntil = new Date(endDate);
    st.setHours("00");
    st.setMinutes("00");
    getStartTime(st);

    let et = new Date();
    et.setHours(23);
    et.setMinutes(59);
    getEndTime(et);
  };

  const everydaySelected = () => {
    setSelectEveryday(!selectEveryday);

    setSelectedDays([]);
  };

  return (
    <Row className="">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ handleSubmit, handleBlur, handleChange, values }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Container className="m-0 p-0">
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={3}
                    md={3}
                    className="text-right pr-0 required"
                  >
                    Practitioner
                  </Form.Label>
                  <Col sm={9} md={9} className="">
                    <Form.Control
                      autoComplete="off"
                      onBlur={handleBlur}
                      as="select"
                      name="doctor"
                      required
                      value={values.doctor}
                      onChange={handleChange}
                    >
                      <option value="">Select Practitioner</option>
                      {doctorObj.map((doctor) => {
                        return (
                          <option value={doctor.node.id} key={doctor.node.id}>
                            {doctor.node.firstName} {doctor.node.lastName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={3}
                    md={3}
                    className="text-right pr-0 required"
                  >
                    Title
                  </Form.Label>
                  <Col sm={9} md={9} className="">
                    <Form.Control
                      autoComplete="off"
                      type="input"
                      onBlur={handleBlur}
                      name="title"
                      required
                      onChange={handleChange}
                      value={values.title}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={3}
                    md={3}
                    className="text-right pr-0 required"
                  >
                    <span className=""> From Date </span>
                  </Form.Label>
                  <Col sm={9} md={9}>
                    <DatePicker
                      autoComplete="off"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                    <input
                      required
                      name="start_date"
                      value={startDate}
                      className="hide-input"
                    />
                  </Col>
                </Form.Group>

                {/* {multiDaysSelected ? 
                
                :null} */}
                {/* {startDate === "" || endDate === "" || (getDate(new Date(startDate)) !== getDate(new Date(endDate))) ? null :  */}

                {/* } */}
                <Form.Group as={Row} className="align-items-center">
                  <Col sm={3} md={3} className="text-right pr-0">
                    <Form.Check
                      type="checkbox"
                      name="all_day"
                      value={multiDaysSelected}
                      checked={multiDaysSelected}
                      onChange={() => setMultiDaysSelected(!multiDaysSelected)}
                    />
                  </Col>
                  <Form.Label column sm={9} md={9} className="">
                    Block Multiple Days
                  </Form.Label>
                </Form.Group>

                {multiDaysSelected &&
                new Date(startDate) !== new Date(endDate) ? (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        className="text-right pr-0 required"
                      >
                        <span className=""> To Date </span>
                      </Form.Label>
                      <Col sm={9} md={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                        />
                        <input
                          required
                          name="end_date"
                          value={endDate}
                          className="hide-input"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="align-items-center">
                      <Col sm={3} md={3} className="text-right pr-0">
                        <Form.Check
                          type="checkbox"
                          name="Everyday"
                          value={selectEveryday}
                          checked={selectEveryday}
                          onChange={() => everydaySelected()}
                        />
                      </Col>
                      <Form.Label column sm={9} md={9}>
                        Everyday
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        className="text-right pr-0"
                      >
                        Days
                      </Form.Label>
                      <Col
                        sm={9}
                        md={9}
                        className=" d-flex align-items-center justify-content-start flex-wrap "
                      >
                        <ButtonGroup>
                          {daysArr.map((item, index) => (
                            <Button
                              key={index}
                              variant={
                                selectedDays.some((i) => i.key === item.key) ||
                                selectEveryday
                                  ? "primary"
                                  : "outline-primary"
                              }
                              className={
                                "mr-1 mb-2" +
                                (selectEveryday ? " pointerEventsNone " : "")
                              }
                              size="sm"
                              onClick={() => selectedDaysFunc(item)}
                              disabled={selectEveryday}
                            >
                              {item.initial}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </Col>
                    </Form.Group>
                  </>
                ) : null}

                <Form.Group as={Row} className="align-items-center">
                  <Col sm={3} md={3} className="text-right pr-0">
                    <Form.Check
                      type="checkbox"
                      name="all_day"
                      value={allDaySelected}
                      checked={allDaySelected}
                      onChange={() => allDayTime()}
                    />
                  </Col>
                  <Form.Label column sm={9} md={9}>
                    All Day
                  </Form.Label>
                </Form.Group>
                {allDaySelected ? null : (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        className="text-right pr-0 required"
                      >
                        Start Time
                      </Form.Label>
                      <Col sm={9} md={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={startDate1}
                          readOnly={allDaySelected ? true : false}
                          onChange={(date) => getStartTime(date)}
                          className="form-control"
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={30}
                          timeCaption="Time"
                          dateFormat="HH:mm "
                          timeFormat="HH:mm"
                        />
                        <input
                          required
                          name="start_time"
                          value={startDate1}
                          className="hide-input"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        className="text-right pr-0 required"
                      >
                        <span className=""> End Time </span>
                      </Form.Label>
                      <Col sm={9} md={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate1}
                          readOnly={allDaySelected ? true : false}
                          onChange={(date) => getEndTime(date)}
                          className="form-control"
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                        <input
                          required
                          name="end_time"
                          value={endDate1}
                          className="hide-input"
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}

                <div className="d-flex mt-3 mb-2 justify-content-center border-top">
                  <Button
                    variant="primary mt-3"
                    size="sm"
                    onClick={() => setShowEventModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary mt-3"
                    size="sm"
                    className="ml-5"
                    disabled={isSubmitting}
                    onClick={() => (isSubmitting ? null : handleSubmit())}
                    id="file-submit"
                  >
                    {" "}
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
    // </Base>
  );
}
export default RecurringEventModal;
