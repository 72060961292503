import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Base from "./base.js";
import { gql, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  isUserStaff,
  getPermissionForAction,
  success_options,
  concatAllErrors,
} from "../Common/helpers.js";
import FormBlock from "../component/DynamicFormBlock/formBlock";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import cloneDeep from "lodash/cloneDeep";

function isArrayEqual(arr, arr2) {
  return arr.every((i) => arr2.includes(i));
}

// This form is used for both intake and survey forms
const IntakeForm = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const intake_id = props.match.params.intake_id || props.intake_id;
  const survey_id = props.match.params.survey_id;
  const inline = props.inline ?? false;
  const [allFormikRefs, setAllFormikRefs] = useState([]);
  const page_form_type = intake_id ? "intake" : "survey";
  const returnAllSectionPermissions = true;
  const intake_permission = getPermissionForAction(
    "intake_form",
    "",
    returnAllSectionPermissions
  );
  const survey_permission = getPermissionForAction(
    "survey",
    "",
    returnAllSectionPermissions
  );
  const page_permissions =
    page_form_type === "intake" ? intake_permission : survey_permission;
  const [submittedBlockIds, setSubmittedBlockIds] = useState([]);
  const [allFormsSubmitted, setAllFormsSubmitted] = useState(false);
  const [buttonSubmitting, setButtonSubmitting] = useState(false);
  const [score, setScore] = useState(0);
  const isStaff = isUserStaff();

  if (page_permissions.includes("change")) {
    page_permissions.push("edit");
  }

  useEffect(() => {
    if (allFormsSubmitted) {
      props.history.goBack();
      let formType = "Intake";
      if (page_form_type === "survey") {
        formType = "Survey";
      }
      openSnackbarSuccess(formType + " Form Submitted.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFormsSubmitted]);

  const submittedFormUpdate = (blockId) => {
    let submittedArr = submittedBlockIds;
    submittedArr.push(blockId);
    setSubmittedBlockIds(submittedArr);
    let allFormikArr = allFormikRefs.map((i) => {
      return i.blk_id;
    });
    let isArrayEq = isArrayEqual(allFormikArr, submittedArr);
    setAllFormsSubmitted(isArrayEq);
  };

  const REQUEST_SURVEY_FORMS = gql`
  query {
    survey(id:"${survey_id}"){
      id
      patient{
        firstName
        middleName
        lastName
      }
      dynamicForm{
        name
        id
        formType
        dynamicFormBlocks {
          edges {
            node {
              id
              formBlock {
                name
                id
                staticId
                code
                blockFormFields(enabled:true) {
                  edges {
                    node {
                      formField {
                        id
                        name
                        subText
                        fieldName
                        variant
                        staticId
                        defaultBooleanVariant
                        options {
                          edges {
                            node {
                              id
                              staticId
                              value
                              default
                            }
                          }
                        }
                      }
                      formBlock {
                        id
                      }
                      required
                      enabled
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
      data
      }
    }
  `;
  const REQUEST_INTAKE_FORMS = gql`
  query {
    intakeForm(id:"${intake_id}"){
      id
      patient{
        firstName
        middleName
        lastName
      }
      dynamicForm{
        name
        id
        formType
        scoreDescription
        reference
        hasScore
        patientOnboarding
        dynamicFormBlocks {
          edges {
            node {
              id
              formBlock {
                name
                id
                staticId
                code
                blockFormFields(enabled:true) {
                  edges {
                    node {
                      formField {
                        id
                        name
                        subText
                        fieldName
                        variant
                        staticId
                        defaultBooleanVariant
                        options {
                          edges {
                            node {
                              id
                              staticId
                              value
                              default
                              scoreValue
                            }
                          }
                        }
                      }
                      formBlock {
                        id
                      }
                      required
                      enabled
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
      data
      }
    }
  `;

  var FORM_OBJ = null;

  var DYNAMIC_FORM = null;

  useEffect(() => {
    if (page_form_type === "survey") {
      GetUserSurveyForms();
    } else {
      GetUserIntakeForms();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page_form_type]);

  const [GetUserIntakeForms, { data: intake_forms_obj }] = useLazyQuery(
    REQUEST_INTAKE_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_INTAKE_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );
  const [GetUserSurveyForms, { data: survey_forms_obj }] = useLazyQuery(
    REQUEST_SURVEY_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_SURVEY_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  if (
    survey_forms_obj &&
    survey_forms_obj.survey &&
    page_form_type === "survey"
  ) {
    DYNAMIC_FORM =
      survey_forms_obj &&
      survey_forms_obj.survey &&
      survey_forms_obj.survey.dynamicForm
        ? survey_forms_obj.survey.dynamicForm
        : null;

    FORM_OBJ =
      survey_forms_obj && survey_forms_obj.survey
        ? survey_forms_obj.survey
        : null;
  } else if (
    intake_forms_obj &&
    intake_forms_obj.intakeForm &&
    page_form_type === "intake"
  ) {
    DYNAMIC_FORM =
      intake_forms_obj &&
      intake_forms_obj.intakeForm &&
      intake_forms_obj.intakeForm.dynamicForm
        ? intake_forms_obj.intakeForm.dynamicForm
        : null;
    FORM_OBJ =
      intake_forms_obj && intake_forms_obj.intakeForm
        ? intake_forms_obj.intakeForm
        : null;
  }

  useEffect(() => {
    if (FORM_OBJ?.data && page_form_type === "intake") {
      let score = 0;
      Object.keys(FORM_OBJ.data).map((key) => {
        let blk = FORM_OBJ.data[key];
        blk.form_block_instances[0].form_block_fields.map((flds) => {
          if (
            flds.form_field_variant === "choices" ||
            flds.form_field_variant === "radio"
          ) {
            let dynamicBlk = DYNAMIC_FORM.dynamicFormBlocks.edges.find(
              (i) =>
                parseInt(i.node.formBlock.staticId) ===
                parseInt(blk.form_block_id)
            );
            if (dynamicBlk) {
              let blockField =
                dynamicBlk.node.formBlock.blockFormFields.edges.find(
                  (i) =>
                    parseInt(i.node.formField.staticId) ===
                    parseInt(flds.form_field_id)
                );
              if (blockField) {
                let matchOption = blockField?.node.formField.options.edges.find(
                  (i) =>
                    parseInt(i.node.staticId) ===
                    parseInt(flds.form_field_value_id)
                );
                if (matchOption?.node.scoreValue) {
                  score += matchOption.node.scoreValue;
                }
              }
            }
          }
        });
      });
      setScore(score);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FORM_OBJ]);

  useEffect(() => {
    if (DYNAMIC_FORM && DYNAMIC_FORM.dynamicFormBlocks) {
      let all_refs = DYNAMIC_FORM.dynamicFormBlocks.edges.map(
        (blockNode, index) => {
          let blk_id = blockNode.node.formBlock.id;
          let blk_ref = React.createRef();
          return {
            blk_ref: blk_ref,
            blk_id: blk_id,
          };
        }
      );
      setAllFormikRefs(all_refs);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DYNAMIC_FORM]);

  const saveAllForms = useCallback(async () => {
    setButtonSubmitting(true);

    const toSubmit = [];

    for (let item in allFormikRefs) {
      toSubmit.push(cloneDeep(allFormikRefs[item]?.blk_ref?.current));
    }

    for (let index in toSubmit) {
      const item = toSubmit[index];
      await item?.submitForm();
    }

    setButtonSubmitting(false);
  }, [allFormikRefs]);

  const Body = () => (
    <>
      {page_form_type === "intake" && DYNAMIC_FORM?.hasScore && isStaff ? (
        <Row className="my-4">
          <Col md={12}>
            <h5>Score: {score} </h5>
          </Col>
          <Col md={6}>{DYNAMIC_FORM.scoreDescription}</Col>
          <Col md={6}>{DYNAMIC_FORM.reference}</Col>
        </Row>
      ) : null}
      <Row>
        {DYNAMIC_FORM && DYNAMIC_FORM.dynamicFormBlocks
          ? DYNAMIC_FORM.dynamicFormBlocks.edges.map((blockNode, index) => {
              let block = blockNode.node.formBlock;
              let initialValues = {};
              let forms = block.blockFormFields.edges;
              let current_blk_ref = allFormikRefs.find(
                (i) => i.blk_id === block.id
              );
              if (current_blk_ref) {
                current_blk_ref = current_blk_ref.blk_ref;
              }
              forms.map((form) => {
                let default_val = "";
                if (form.node.formField.variant === "BOOLEAN") {
                  default_val = false;
                }
                return (initialValues[form.node.formField.fieldName] =
                  default_val);
              });
              let json_data = FORM_OBJ && FORM_OBJ.data ? FORM_OBJ.data : null;
              let blockData = {
                blockId: block.id,
                blockCode: block.code,
                title: block.name,
                id: block.id,
                initialValues: initialValues,
                forms: forms,
                staticId: block.staticId,
                relatedModelId: FORM_OBJ ? FORM_OBJ.id : null,
                json_data: json_data,
              };

              return (
                <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                  <FormBlock
                    blockData={blockData}
                    intake_form={page_form_type === "survey" ? false : true}
                    survey_form={page_form_type === "survey" ? true : false}
                    formBlockRef={current_blk_ref}
                    submit_button_id={"submit_" + block.id}
                    medical_record_permission={
                      isStaff
                        ? page_permissions
                        : ["add", "view", "list", "edit"]
                    }
                    // isLastBlock={index === DYNAMIC_FORM.dynamicFormBlocks.edges.length - 1 ? true : false}
                    submittedFormUpdate={submittedFormUpdate}
                    allFormsSubmitted={allFormsSubmitted}
                    form_card={true}
                    REQUEST_UPDATE={
                      page_form_type === "survey"
                        ? REQUEST_SURVEY_FORMS
                        : REQUEST_INTAKE_FORMS
                    }
                  />
                </Col>
              );
            })
          : null}
      </Row>
      {(page_permissions && page_permissions.includes("add")) || !isStaff ? (
        <Row className=" mt-5  py-5 justify-content-center justify-content-sm-end">
          <Col className="d-flex justify-content-center justify-content-sm-end">
            <Button
              variant="primary"
              size="md"
              className="mx-2"
              type="button"
              onClick={() => props.history.goBack()}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="md"
              className="mx-2"
              onClick={saveAllForms}
            >
              {buttonSubmitting ? (
                <div
                  className="spinner-border text-light spinner-border-sm"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );

  return (
    <>
      {inline ? (
        <Body />
      ) : (
        <Base
          title={
            FORM_OBJ && FORM_OBJ.dynamicForm
              ? FORM_OBJ.dynamicForm.name
              : (page_form_type === "survey" ? "SURVEY" : "INTAKE") + " FORM"
          }
          showHeader={true}
          isPatientPortal={!isStaff}
        >
          <Body />
        </Base>
      )}
    </>
  );
};

export default withRouter(IntakeForm);
