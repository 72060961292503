import { Row, Col, Button, Card } from "react-bootstrap";
import Base from "../base.js";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedo,
  faClipboard,
  faCalendar,
  faSmile,
  faHandHoldingMedical,
  faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  getForamttedTime,
  getPrescriptionDate,
  concatAllErrors,
  isUserStaff,
  deepCopy,
  getErrorMessage,
} from "../../Common/helpers";
import { currentPageDataVar, patientBadgesVar,userDetailsVar } from "../../cache/cache.js";
import { useSnackbar } from "react-simple-snackbar";
import { gql, useQuery, useMutation,useReactiveVar,useLazyQuery } from "@apollo/client";
import Preloader from "../../Common/Preloder/Preloader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useAppSelector } from "redux/reducers/hooks";

import * as Sentry from "@sentry/browser";
import { urlBackend } from "apolloSettings";

function toGlobalID(type, id) {
  // Combine the type and ID with a separator (usually ':')
  const separator = ":";
  const globalID = `${type}${separator}${id}`;

  // Base64 encode the global ID
  const base64GlobalID = Buffer.from(globalID, "utf8").toString("base64");

  // Prepend the type name to the base64 encoded ID
  return `${type}${separator}${base64GlobalID}`;
}

var startDate = new Date();
startDate =
  startDate.getFullYear() +
  "-" +
  String(startDate.getMonth() + 1).padStart(2, "0") +
  "-" +
  String(startDate.getDate()).padStart(2, "0");

function Dashboard(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const { t, i18n } = useTranslation();
  const [blockPrintData, setBlockPrintData] = useState(null);
  const isStaff = isUserStaff();
  const history = useHistory();
  const identifier = props.match.params.identifier;
  const patientBadgesObject = useReactiveVar(patientBadgesVar);
  const [rawLabRequests, setLabRequests] = useState([]);

  var userDetails = useReactiveVar(userDetailsVar);
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const USER_DETAILS = gql`
  query receiveDate {
    userDetails @client
  }
`;

const REQUEST_ACCOUNT_DETAILS = gql`
query {
  me {
    id
    isStaff
    patient {
      firstName
      middleName
      lastName
      id
      idFile
      identifier
      dob
      phone
      email
      civilId
      prescriptionData {
        dynamicFormData
      }
    }
  }
}
`;

const { data: accountDetails } = useQuery(REQUEST_ACCOUNT_DETAILS, {
  fetchPolicy: "network-only",
  onCompleted: (data) => {},
  onError: (e) => {
    Sentry.setContext("error", e?.networkError?.result);
    Sentry.setContext("ERROR OBJ ", { errorObj: e });
    Sentry.setContext("ERROR CODE statusCode ", {
      code: e?.networkError?.statusCode,
    });
    if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
      Sentry.captureException("REQUEST_ACCOUNT_DETAILS error " + e);
    }

    let errorMsg = concatAllErrors(e?.graphQLErrors);
    let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
    openSnackbar(msgToDisplay, [SNACK_DURATION]);
  },
});

const { data } = useQuery(USER_DETAILS);
userDetails = data && data.userDetails ? data.userDetails : null;
if (userDetails && userDetails.indexOf("username") > -1) {
  userDetails = JSON.parse(userDetails);
} else {
  userDetails = accountDetails?.me?.patient;
}

const IS_CHECK_APPOINTMENT = gql`
query AppointmentFlag($id: ID!) {
  AppointmentFlag(id: $id) {
    isAppointment
  }
}
`;
const patientId = userDetails?.patient?.id;

const { data: hasAppointment } = useQuery(IS_CHECK_APPOINTMENT, {
variables: { id: patientId },
skip: !patientId, 
});


if (!patientId) {
  console.log("Patient ID is missing, not calling the API.");
}

const patientIdentifier = userDetails?.patient?.identifier || accountDetails?.me?.patient?.identifier;
console.log("patientIdentifier--------- ",patientIdentifier);
  const REQUEST_LAB_REQUESTS = gql`
  query {
    labRequests(patient_Identifier:"${patientIdentifier}"){
      edges {
        node {
            id
            searchText
            usedTemplate{
              name
            }
          patient{
            id
            identifier
            firstName
            middleName
            lastName
          }
          groupTests{
            edges{
              node{
                name  
                labTests{
                  edges{
                    node{
                      name
                    }
                  }
                }
                children{
                  edges{
                    node{
                      name
                    }
                  }
                }
              }
            }
          }
          
          specificTests{
            edges{
              node{
                name
                
              }
            }
          }
          testResults{
            edges{
              node{
                specificTest{
                  name
                }
                result
              }
            }
          }
        }
      }
    }
  }
`;

  const DELETE_LAB_REQUEST = gql`
    mutation deleteLabRequest($id: ID!) {
      deleteLabRequest(id: $id) {
        deleted
      }
    }
  `;




  const { loading } = useQuery(REQUEST_LAB_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLabRequests(deepCopy(data));
    },

    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_LAB_REQUESTS error " + e);
      }
    },
  });

  // DELETE_ LAB REQ
  const [deleteLabRequest] = useMutation(DELETE_LAB_REQUEST, {
    onCompleted: ({ deleteLabRequest }) => {
      if (deleteLabRequest?.errors && deleteLabRequest?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteLabRequest?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    refetchQueries: [
      { query: REQUEST_LAB_REQUESTS, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_LAB_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  var labRequests =
  rawLabRequests && rawLabRequests.labRequests
    ? rawLabRequests.labRequests.edges
    : [];

const deleteRefFunc = (id) => {
  if (window.confirm("Delete Lab Request?")) {
    let val = {};
    val["id"] = id;
    deleteLabRequest({ variables: val });
  }
};

const REQUEST_PATIENT_BADGES = gql`
query {
  patientBadges
}
`;

 // eslint-disable-next-line no-empty-pattern
 const [getPatientBadges, {}] = useLazyQuery(REQUEST_PATIENT_BADGES, {
  fetchPolicy: "network-only",
  onCompleted: ({ patientBadges }) => {
    patientBadgesVar(patientBadges);
  },
  onError: (e) => {
    Sentry.setContext("error", e?.networkError?.result);
    Sentry.setContext("ERROR OBJ ", { errorObj: e });
    Sentry.setContext("ERROR CODE statusCode ", {
      code: e?.networkError?.statusCode,
    });
    if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
      Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
    }

    let errorMsg = concatAllErrors(e?.graphQLErrors);
    let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
    openSnackbar(msgToDisplay, [SNACK_DURATION]);
  },
});

const POST_PATIENTS_LABREQ_READ = gql`
  mutation labRequestReadView($labs: [ID]!) {
    labRequestReadView(input: { labs: $labs }) {
      updated
    }
  }
`;

 // EVENT PRINT CODE---------------------
 const [postLabReqReadView] = useMutation(POST_PATIENTS_LABREQ_READ, {
  onCompleted: ({ labRequestReadView }) => {
    getPatientBadges();
  },
  onError: (e) => {
    Sentry.setContext("error", e?.networkError?.result);
    Sentry.setContext("ERROR OBJ ", { errorObj: e });
    Sentry.setContext("ERROR CODE statusCode ", {
      code: e?.networkError?.statusCode,
    });
    if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
      Sentry.captureException("POST_PATIENTS_LABREQ_READ error " + e);
    }
    if (e.message) {
      openSnackbar(e.message, [SNACK_DURATION]);
    } else {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    }
  },
});


  const { patientData: newPatientData } = useAppSelector(
    ({ rootPatientSlice }) => rootPatientSlice
  );

  const GENERTE_ENCOUNTER_PRINT_CODE = gql`
    mutation generateEncounterPrintCode($id: ID!) {
      generateEncounterPrintCode(id: $id) {
        code
      }
    }
  `;

  const [generateEncounterPrintCode] = useMutation(
    GENERTE_ENCOUNTER_PRINT_CODE,
    {
      onCompleted: ({ generateEncounterPrintCode }) => {
        let oneTimeCode = generateEncounterPrintCode.code;
        if (blockPrintData) {
          setTimeout(() => {
            window.open(
              urlBackend +
                "pdf/reports/prescription/report/" +
                blockPrintData.encounterId +
                "/" +
                oneTimeCode +
                "/"
            );
          }, 300);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("GENERTE_ENCOUNTER_PRINT_CODE error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  useEffect(() => {
    if (blockPrintData) {
      const globalId = toGlobalID("EncounterNode", blockPrintData?.encounterId);
      const parts = globalId.split(":");

      let variables = { id: parts[1] };
      generateEncounterPrintCode({ variables });
    }
  }, [blockPrintData, generateEncounterPrintCode]);

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;
  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const REQUEST_EVENTS = gql`
    query{
      events(start:"${startDate}",orderby:"start", active: true) {
        totalCount
        edges {
          node {
            title
            id
            status
            start
            end
            cancelledReason
            intakeForms{
              edges{
                node{
                  id
                }
              }
            }
            doctor {
              id
              firstName
              lastName
              arabicName
            }
            eventType{
              name
              id
            }
            location{
              id
              title
            }
            patient {
              id
              firstName
              middleName
              lastName
              identifier
              dob
            }
          }
        }
      }
    }
  `;

  const REQUEST_PATIENT_EVENTS = gql`
    query{
      patientAppointments(start:"${startDate}",orderby:"start", active: true) {
        totalCount
        edges {
          node {
            title
            id
            status
            start
            end
            cancelledReason
            intakeForms{
              edges{
                node{
                  id
                }
              }
            }
            doctor {
              id
              firstName
              lastName
              arabicName
            }
            eventType{
              name
              id
            }
            location{
              id
              title
            }
  
      
          }
        }
      }
    }
  `;

  var EVENT_REQUEST_QUERY = isStaff ? REQUEST_EVENTS : REQUEST_PATIENT_EVENTS;

  const { data: eventsList, loading: loadingEvents } = useQuery(
    EVENT_REQUEST_QUERY,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_EVENTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const REQUEST_SURVEY_FORMS = gql`
    query {
      surveys {
        edges {
          node {
            id
            patient {
              firstName
              middleName
              lastName
            }
            data
            submitted
            dynamicForm {
              name
              id
              formType
              formBlocks {
                edges {
                  node {
                    name
                    id
                    staticId
                    formFields {
                      edges {
                        node {
                          id
                          name
                          subText
                          fieldName
                          variant
                          staticId
                          blockFormFields {
                            edges {
                              node {
                                formField {
                                  name
                                }
                                formBlock {
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data: survey_forms_obj, loading: loadingSurvey } = useQuery(
    REQUEST_SURVEY_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_SURVEY_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const REQUEST_INTAKE_FORMS = gql`
    query {
      intakeForms {
        edges {
          node {
            id
            patient {
              firstName
              middleName
              lastName
            }
            dynamicForm {
              name
              id
              formType
              formBlocks {
                edges {
                  node {
                    name
                    id
                    staticId
                    formFields {
                      edges {
                        node {
                          id
                          name
                          subText
                          fieldName
                          variant
                          staticId
                          blockFormFields {
                            edges {
                              node {
                                formField {
                                  name
                                }
                                formBlock {
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            data
            submitted
          }
        }
      }
    }
  `;



  // eslint-disable-next-line no-unused-vars
  const { data: patientBadges } = useQuery(REQUEST_PATIENT_BADGES, {
    fetchPolicy: "network-only",
    onCompleted: ({ patientBadges }) => {
      patientBadgesVar(patientBadges);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const DYNAMIC_PRESCRIPTION_DATA =
    accountDetails?.me?.patient?.prescriptionData?.dynamicFormData;

  var sortedPresc = [];
  for (var key in DYNAMIC_PRESCRIPTION_DATA) {
    let data = { ...DYNAMIC_PRESCRIPTION_DATA[key] };

    // add the encounter id on prescription data.
    try {
      const match = key.match(/\d+/);

      if (match) {
        const numString = match[0];
        const num = parseInt(numString);

        if (num) {
          data = { ...data, encounterId: num };
        }
      }
    } catch {}

    sortedPresc.push({ prescription: data });
  }
  sortedPresc = sortedPresc.sort(function (a, b) {
    let presc_date_a = getPrescriptionDate(a.prescription);
    let presc_date_b = getPrescriptionDate(b.prescription);
    return presc_date_b - presc_date_a;
  });

  const { data: intake_forms_obj, loading: loadingIntake } = useQuery(
    REQUEST_INTAKE_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_INTAKE_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const saveFormInfo = (form) => {
    currentPageDataVar(form);
  };

  let finalEventList = [];
  let event = null;
  let unpaidAppointments = null;

  if (isStaff) {
    event =
      eventsList && eventsList.events && eventsList.events.edges.length > 0
        ? eventsList.events.edges[0].node
        : null;
    unpaidAppointments = eventsList?.events?.edges.filter(
      (i) => i.node.status !== "CONFIRMED"  && i.node.status !== "COMPLETED"
    );

    finalEventList = eventsList?.events;
  } else {
    event =
      eventsList &&
      eventsList.patientAppointments &&
      eventsList.patientAppointments.edges.length > 0
        ? eventsList.patientAppointments.edges[0].node
        : null;
    unpaidAppointments = eventsList?.patientAppointments?.edges.filter(
      (i) => i.node.status !== "CONFIRMED" && i.node.status !== "COMPLETED"
    );
    finalEventList.edges =  eventsList?.patientAppointments?.edges.filter(
      (i) =>  i.node.status !== "COMPLETED"
    );;
  }

  let date = null;
  if (event) {
    date =
      getDate(new Date(event.start)) +
      " " +
      getForamttedTime(new Date(event.start));
  }

  const renderGridContent = () => {
    let final_dashboard_arr = [];
    // Intake forms for this patient
    let intake_forms =
      intake_forms_obj &&
      intake_forms_obj.intakeForms &&
      intake_forms_obj.intakeForms.edges.length > 0
        ? intake_forms_obj.intakeForms.edges
        : [];
    let intake_forms_elem = intake_forms.map((form, index) => {
      return {
        link: "/patientPortal/consultation/form",
        onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: t("patientDashboard.pleaseFill"),
        buttonText: t("patientDashboard.fillForm"),
        icon: faClipboard,
        backgroundClass: "green",
      };
    });

    // Survey forms for this patient
    let survey_forms =
      survey_forms_obj &&
      survey_forms_obj.surveys &&
      survey_forms_obj.surveys.edges.length > 0
        ? survey_forms_obj.surveys.edges
        : [];
    survey_forms = survey_forms.filter((i) => i.node.submitted != true);
    let form = survey_forms[0];
    let survey_forms_elem;
    if (survey_forms.length === 1) {
      survey_forms_elem = {
        link: "/patientPortal/surveyform/" + form.node.id,
        onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: t("patientDashboard.letUsKnowHowyYourLastAppointmentWas"),
        buttonText: t("patientDashboard.fillForm"),
        icon: faSmile,
        backgroundClass: "green",
      };
    } else if (survey_forms.length > 1) {
      survey_forms_elem = {
        link: "/patientPortal/surveyformlist",
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: "You have multiple survey forms. Click here to view.",
        buttonText: t("patientDashboard.book"),
        icon: faSmile,
        backgroundClass: "green",
      };
    }

    let ref_link =
      "/patientPortal/referrals/" +
      (patientData && patientData.dataPatient
        ? patientData.dataPatient.id
        : "");
    let follow_up_link =
      "/patientPortal/followups/" +
      (patientData && patientData.dataPatient
        ? patientData.dataPatient.id
        : "");
    let has_referrals =
      patientData &&
      patientData.dataPatient &&
      patientData.dataPatient.referrals &&
      patientData.dataPatient.referrals.edges.length > 0
        ? true
        : false;

    const refs = patientData?.dataPatient?.referrals?.edges?.filter(
      (ref, index) => {
        return !ref.node.recommendedDate;
      }
    );

    const followUps = patientData?.dataPatient?.referrals?.edges?.filter(
      (ref, index) => {
        return ref.node.recommendedDate;
      }
    );

    let referrals_elem = {
      link: ref_link,
      title: t("patientDashboard.bookReferral"),
      subTitle: t("patientDashboard.youReceived"),
      buttonText: t("patientDashboard.book"),
      icon: faRedo,
      backgroundClass: "orange1",
    };

    let follow_up_elem = {
      link: follow_up_link,
      title: t("patientDashboard.bookFollowUp"),
      subTitle: t("patientDashboard.youReceivedFollow"),
      buttonText: t("patientDashboard.book"),
      icon: faRedo,
      backgroundClass: "orange1",
    };

    if (has_referrals) {
      if (refs.length > 0) {
        final_dashboard_arr.push(referrals_elem);
      }
      if (followUps.length > 0) {
        final_dashboard_arr.push(follow_up_elem);
      }
    }

    let req_app_elem = {
      link: "/patientPortal/create/appointment",
      title: t("patientDashboard.requestAnAppointment"),
      subTitle: t("patientDashboard.checkAvailableAppointments"),
      buttonText: t("patientDashboard.book"),
      icon: faHandHoldingMedical,
      skipCountOnBadge: true,
      backgroundClass: "blue",
    };
    if(finalEventList?.edges?.length == 0 && hasAppointment?.AppointmentFlag?.isAppointment===false){
      final_dashboard_arr.push(req_app_elem);
    }

    if (survey_forms_elem) {
      final_dashboard_arr.push(survey_forms_elem);
    }

    if(newPatientData &&  newPatientData?.profileComplete === true &&
        newPatientData?.completedOnboarding === false
      ) 
    {
    final_dashboard_arr.push(...intake_forms_elem);
    }
    // final_dashboard_arr.push(...referralDynamicFormsElem);

    return final_dashboard_arr.map((item, index) => (
      <Col xs={12} sm={12} md={6} lg={4} key={index}>
        <Link
          to={item.link}
          onClick={() =>
            item.onClickFunc && item.passData
              ? item.onClickFunc(item.passData)
              : ""
          }
        >
          <Card
            className={
              item.backgroundClass +
              " patient-card " +
              (item.skipCountOnBadge ? "" : "count-badge-patient-dashboard")
            }
          >
            <Card.Body className="text-center patient-card-body">
              <div>
                <h6 className="text-center font-bold text-uppercase patient-card-title">
                  {item.title}
                </h6>
                <FontAwesomeIcon
                  icon={item.icon}
                  className="icon_large mx-3 my-4"
                />
                <h4 className="mb-0">
                  {" "}
                  <b> {item.date} </b>{" "}
                </h4>
                <p className="mb-0 text-uppercase font-bold">{item.subTitle}</p>
              </div>
              <div className="text-center">
                {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                <div className="mt-2 text_container">
                  <Button
                    block
                    variant="link"
                    className="font-bold d-flex justify-content-end align-items-center"
                  >
                    {item.buttonText}{" "}
                    <i className="fa fa-long-arrow-right ml-3"></i>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    ));
  };

  // navigate patient to consultation form if needed
  useEffect(() => {
    if (newPatientData) {
      if (newPatientData?.profileComplete === false) {
        history.push("/registration/complete/form?profileEdit=true");
      } else if (
        newPatientData?.profileComplete === true &&
        newPatientData?.completedOnboarding === false
      ) {
        history.push("/patientPortal/consultation/form");
      } else if (
        newPatientData?.profileComplete === true &&
        newPatientData?.completedOnboarding === true &&
        newPatientData?.completedTherapyPreference === false
      ) {
        console.log("newPatientData------------ ",newPatientData);
        history.push("/patientPortal/therapy/form");
      }
    }
  }, [history, newPatientData]);

  let loadingPage = loadingEvents || loadingIntake || loadingSurvey;
  useEffect(() => {
    if (labRequests.length > 0 && patientBadgesObject?.labs) {
      let labs = [];
      labs = labRequests.map((lab) => {
        return lab.node.id;
      });
      postLabReqReadView({ variables: { labs: labs } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labRequests]);


  return (
    <>
      <Base
        title={t("patientDashboard.dashboard")}
        isDashboard={true}
        showHeader={true}
        isPatientPortal={true}
        containerClass="mt-0"
        rightContainerClass={loadingPage ? "page_loader_base" : ""}
      >
        {loadingPage ? (
          <Preloader />
        ) : (
          <Row className="px-4 justify-content-center patient_dashboard">
            <Col
              xs={12}
              md={{ span: 12, order: "last" }}
              lg={12}
              sm={{ span: 12, order: "last" }}
              className="patient-cards-container"
            >
              <Row>
                {finalEventList?.edges?.length > 1 ? (
                  <Col xs={12} sm={12} md={6} lg={4}>
                    <Link
                      to={
                        "/patientPortal/appointment/detail/" +
                        finalEventList?.edges[0]?.node?.id
                      }
                    >
                      <Card
                        className={
                          "blue patient-card count-badge-patient-dashboard"
                        }
                      >
                        <Card.Body className="text-center patient-card-body">
                          <div className="">
                            <h6 className="text-center font-bold text-uppercase patient-card-title">
                              {t("patientDashboard.upcomingAppointments")}
                            </h6>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="icon_large mx-3 my-4"
                            />
                            <h6 className="mb-0">
                              {" "}
                              <b> {date} </b>{" "}
                            </h6>
                            <p className="mb-0 text-uppercase font-bold upcoming-appointments-items">
                              {t("patientUpcomingAppointments.practitioner")}:{" "}
                              <EllipsisWithTooltip
                                placement="top"
                                style={{ display: "inline" }}
                              >
                                {i18n.language == "en"
                                  ? event.doctor.firstName +
                                    " " +
                                    event.doctor.lastName
                                  : event.doctor.arabicName}
                                <br />
                              </EllipsisWithTooltip>
                              {t("patientUpcomingAppointments.sessionType")}:{" "}
                              <EllipsisWithTooltip
                                placement="top"
                                style={{ display: "inline" }}
                              >
                                {event.eventType.name} <br />
                              </EllipsisWithTooltip>
                              {t("patientUpcomingAppointments.location")}:{" "}
                              {event.location.title}
                            </p>
                          </div>
                          <div>
                            <div
                              className={
                                "mt-2 text_container d-flex " +
                                (event && event.intakeForms.edges.length > 0
                                  ? "justify-content-between"
                                  : "justify-content-end")
                              }
                            >
                              <Button
                                variant="link"
                                className="font-bold d-flex justify-content-between align-items-center"
                              >
                                {t("patientRecordFiles.view")}{" "}
                                <i className="fa fa-long-arrow-right ml-3"></i>
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  <>
                    {finalEventList?.edges?.length === 1 ? (
                      <Col xs={12} sm={12} md={6} lg={4}>
                        <Link
                          to={"/patientPortal/appointment/detail/" + event.id}
                        >
                          <Card
                            className={
                              "blue patient-card count-badge-patient-dashboard"
                            }
                          >
                            <Card.Body className="text-center patient-card-body">
                              <div className="">
                                <h6 className="text-center font-bold text-uppercase patient-card-title">
                                  {" "}
                                  {t(
                                    "patientDashboard.upcomingAppointments"
                                  )}{" "}
                                </h6>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="icon_large mx-3 my-4"
                                />
                                <h6 className="mb-0">
                                  {" "}
                                  <b> {date} </b>{" "}
                                </h6>
                                <p className="mb-0 text-uppercase font-bold upcoming-appointments-items">
                                  {t(
                                    "patientUpcomingAppointments.practitioner"
                                  )}
                                  :{" "}
                                  <EllipsisWithTooltip
                                    placement="top"
                                    style={{ display: "inline" }}
                                  >
                                    {i18n.language == "en"
                                      ? event.doctor.firstName +
                                        " " +
                                        event.doctor.lastName
                                      : event.doctor.arabicName}
                                    <br />
                                  </EllipsisWithTooltip>
                                  {t("patientUpcomingAppointments.sessionType")}
                                  :{" "}
                                  <EllipsisWithTooltip
                                    placement="top"
                                    style={{ display: "inline" }}
                                  >
                                    {event.eventType.name} <br />
                                  </EllipsisWithTooltip>
                                  {t("patientUpcomingAppointments.location")}:{" "}
                                  {event.location.title}
                                </p>
                              </div>
                              {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                              <div>
                                <div
                                  className={
                                    "mt-2 text_container d-flex " +
                                    (event && event.intakeForms.edges.length > 0
                                      ? "justify-content-between"
                                      : "justify-content-end")
                                  }
                                >
                                  <Button
                                    variant="link"
                                    className="font-bold d-flex justify-content-between align-items-center"
                                  >
                                    {t("patientDashboard.view")}{" "}
                                    <i className="fa fa-long-arrow-right ml-3"></i>
                                  </Button>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ) : null}
                  </>
                )}
                {unpaidAppointments?.map((app, index) => {
                  let event = app.node;
                  return (
                    <Col xs={12} sm={12} md={6} lg={4} key={index}>
                      <Link
                        to={"/patientPortal/appointment/detail/" + event.id}
                      >
                        <Card
                          className={
                            "blue patient-card count-badge-patient-dashboard"
                          }
                        >
                          <Card.Body className="text-center patient-card-body">
                            <div className="">
                              <h6 className="text-center font-bold text-uppercase patient-card-title">
                                {" "}
                                {t("patientDashboard.unpaidAppointemnt")}{" "}
                              </h6>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className="icon_large mx-3 my-4"
                              />
                              <h6 className="mb-0">
                                {" "}
                                <b>
                                  {" "}
                                  {getDate(new Date(event.start)) +
                                    " " +
                                    getForamttedTime(
                                      new Date(event.start)
                                    )}{" "}
                                </b>{" "}
                              </h6>
                              <p className="mb-0 text-uppercase font-bold upcoming-appointments-items">
                                {t("patientUpcomingAppointments.practitioner")}:{" "}
                                <EllipsisWithTooltip
                                  placement="top"
                                  style={{ display: "inline" }}
                                >
                                  {i18n.language == "en"
                                    ? event.doctor.firstName +
                                      " " +
                                      event.doctor.lastName
                                    : event.doctor.arabicName}
                                  <br />
                                </EllipsisWithTooltip>
                                {t("patientUpcomingAppointments.sessionType")}:{" "}
                                <EllipsisWithTooltip
                                  placement="top"
                                  style={{ display: "inline" }}
                                >
                                  {event.eventType.name} <br />
                                </EllipsisWithTooltip>
                                {t("patientUpcomingAppointments.location")}:{" "}
                                {event.location.title}
                              </p>
                            </div>
                            <div>
                              <div
                                className={
                                  "mt-2 text_container d-flex " +
                                  (event && event.intakeForms.edges.length > 0
                                    ? "justify-content-between"
                                    : "justify-content-end")
                                }
                              >
                                {event && event.intakeForms.edges.length > 0 ? (
                                  <Button
                                    variant="link"
                                    className="font-bold pl-0 d-flex justify-content-between align-items-center"
                                  ></Button>
                                ) : null}
                                <Button
                                  variant="link"
                                  className="font-bold d-flex justify-content-between align-items-center"
                                >
                                  {t("patientRecordFiles.view")}{" "}
                                  <i className="fa fa-long-arrow-right ml-3"></i>
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
                {renderGridContent()}
                {sortedPresc.length > 0
                  ? sortedPresc.map((prescriptionItem, index) => {
                      let prescription = prescriptionItem.prescription;
                      let presc_date = getPrescriptionDate(prescription);
                      let valid_until = presc_date ? new Date(presc_date) : "";
                      if (valid_until) {
                        valid_until.setDate(valid_until.getDate() + 30);
                        valid_until.setHours(23);
                        valid_until.setMinutes(59);
                      }
                      let current_date = new Date();

                      let p_date = getDate(presc_date);
                      let v_date = getDate(valid_until);

                      if (
                        prescription.form_block_instances.length > 0 &&
                        current_date <= valid_until
                      ) {
                        return (
                          <Col lg={4} key={index}>
                            <Card
                              className={
                                " green patient-card count-badge-patient-dashboard"
                              }
                            >
                              <Card.Body className="text-center patient-card-body">
                                <div>
                                  <h6 className="text-center font-bold text-uppercase patient-card-title">
                                    {t("patientNavigation.prescription")}{" "}
                                    {presc_date ? "- " + p_date : ""}{" "}
                                  </h6>
                                  <FontAwesomeIcon
                                    icon={faNotesMedical}
                                    className="icon_large mx-3 my-4"
                                  />
                                  <p className="mb-0 text-uppercase font-bold">
                                    {t(
                                      "patientNavigation.prescriptionsValidUntil"
                                    )}{" "}
                                    <span style={{ direction: "ltr" }}>
                                      {v_date}
                                    </span>
                                  </p>
                                </div>
                                <div className="text-center">
                                  {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                                  <div className="mt-2 text_container">
                                    <Button
                                      block
                                      variant="link"
                                      onClick={() =>
                                        setBlockPrintData(prescription)
                                      }
                                      className="font-bold d-flex justify-content-end align-items-center"
                                    >
                                      {t("patientPaymentDetails.print")}{" "}
                                      <i className="fa fa-print mx-3"></i>
                                    </Button>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      }
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        )}

{loading ? (
  <></>
      ) : (
        <Row>
          {labRequests && labRequests.length > 0 ? (
            labRequests.map((lab, index) => {
              let sortedLabReq = lab?.node?.testResults?.edges?.sort((a, b) =>
                a?.node?.specificTest?.name?.localeCompare(
                  b?.node?.specificTest?.name
                )
              );
              return lab.node.testResults.edges.length > 0 ? (
                <Col
                  key={index}
                  xs={12}
                  md={4}
                  className="d-flex-column-str  mb-4"
                >
                  <Card>
                    <Card.Body className="p-4">
                      <Row className="mb-2">
                        <Col
                          md={8}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <h6 className="textPrimaryBold mb-0 ">
                            {t("reports.labRequest")}
                          </h6>
                        </Col>
                        <Col
                          md={2}
                          className="d-flex justify-content-end ml-auto"
                        >
                          <Link
                            to={
                              isStaff
                                ? "/patient/labrequests/detail/" + lab.node.id
                                : "/patientPortal/dashboard/detailV2/" +
                                  lab.node.id
                            }
                          >
                            <Button variant="link" className=" p-0">
                              {t("reports.view")}
                            </Button>
                          </Link>
                        </Col>
                        {isStaff ? (
                          <Col md={2} className="d-flex justify-content-end">
                            <Button
                              variant="link"
                              className="danger-color  p-0"
                              onClick={() => deleteRefFunc(lab.node.id)}
                            >
                              {t("reports.delete")}
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col md={12}>
                          <h6 className="capitalize">
                            <b>{lab.node.usedTemplate.name}</b>
                          </h6>
                        </Col>
                      </Row>
                      {sortedLabReq?.map((result, index) => {
                        return (
                          <Row key={index}>
                            <Col md={12}>
                              <h6> {result?.node?.specificTest?.name} </h6>
                            </Col>
                          </Row>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </Col>
              ) : null;
            })
          ) : (
            <Col xs={12} md={6}>
              {t("reports.noLabRequest")}
            </Col>
          )}
        </Row>
      )}
      </Base>
    </>
  );
}
export default Dashboard;
