import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Table, Col } from "react-bootstrap";
import { gql, useQuery } from "@apollo/client";
import Base from "./base.js";
import Preloader from "../Common/Preloder/Preloader";
import { displayMiddleName } from "../Common/helpers";
import * as Sentry from "@sentry/browser";

const DisplayIntakeForms = (props) => {
  const identifier = props.match.params.identifier;
  const { patient_record } = props.location.state;

  const REQUEST_INTAKE_FORMS = gql`
    query {
      intakeForms(patient_Identifier:"${identifier}") {
        edges {
          node {
            id
            created
            patient {
              firstName
              middleName
              lastName
              identifier
            }
            dynamicForm{
              name
              id
              formType
              formBlocks{
                edges{
                  node {
                    name
                    id
                    staticId
                    formFields{
                      edges{
                        node{
                          id
                          name
                          subText
                          fieldName
                          variant
                          staticId
                          blockFormFields{
                            edges{
                              node{
                                formField{
                                  name                  
                                }
                                formBlock{
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options{
                            edges{
                              node{
                                id
                                staticId                   
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            data
            submitted
          }
        }
      }
    }
  `;

  const { data, loading } = useQuery(REQUEST_INTAKE_FORMS, {
    fetchPolicy: "network-only",
    onCompleted() {},
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_INTAKE_FORMS error " + e);
      }
    },
  });

  let firstName =
    data && data.intakeForms && data.intakeForms.edges.length > 0
      ? data.intakeForms.edges[0].node.patient.firstName
      : patient_record.firstName
      ? patient_record.firstName
      : "Patient";
  let lastName =
    data && data.intakeForms && data.intakeForms.edges.length > 0
      ? data.intakeForms.edges[0].node.patient.lastName
      : patient_record.lastName
      ? patient_record.lastName
      : "";
  let middleName =
    data && data.intakeForms && data.intakeForms.edges.length > 0
      ? displayMiddleName(data.intakeForms.edges[0].node.patient.middleName)
      : patient_record.middleName
      ? displayMiddleName(patient_record.middleName)
      : "";

  return (
    <Base
      title={`${firstName} ${middleName}${lastName} INTAKE FORM`}
      showHeader={true}
    >
      {loading ? (
        <Preloader />
      ) : (
        <Row>
          {data.intakeForms.edges && data.intakeForms.edges.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Submitted</th>
                    <th>Created</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {data.intakeForms.edges.map((intakeForm, index) => (
                    <tr key={index}>
                      <td className="align-middle patient-capitalize-first-letter">
                        {intakeForm.node.patient.firstName}{" "}
                        {displayMiddleName(intakeForm.node.patient.middleName)}
                        {intakeForm.node.patient.lastName}
                      </td>
                      <td>
                        {intakeForm.node.submitted
                          ? "Submitted"
                          : "Not submitted"}
                      </td>
                      <td>
                        {new Date(intakeForm.node.created).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      {intakeForm.node.submitted ? (
                        <td>
                          <Link
                            to={`/patient/intakeform/${intakeForm.node.id}`}
                          >
                            View
                          </Link>
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <Col xs={12} md={6}>
              {" "}
              No Clinical Forms for this patient
            </Col>
          )}
        </Row>
      )}
    </Base>
  );
};

export default withRouter(DisplayIntakeForms);
