import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Typography,
  TextField,
  Box,
  List,
  ListItem,
  Button,
  ListItemText,
  Divider,
  LinearProgress,
  debounce,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "redux/reducers/hooks";
import {
  getPublicServices,
  getServiceDoctors,
} from "redux/slices/appointmentSlice";
import {
  incrementSlideCounter,
  updateService,
} from "redux/slices/appointmentSlice";
//@ts-ignore
import { useSnackbar } from "react-simple-snackbar";
import { error_options } from "Common/helpers";

const Services = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [openErrorSnackBar] = useSnackbar(error_options);
  const lang = i18n.language;
  const [textInput, setTextInput] = useState("");

  const { loading, services, error } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  useEffect(() => {
    // get all public services
    dispatch(getPublicServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      openErrorSnackBar(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSelectSubmit = useCallback(
    async (item: any) => {
      await dispatch(incrementSlideCounter());
      await dispatch(updateService(item));
      await dispatch(getServiceDoctors({ servicePk: item.pk }));
    },
    [dispatch]
  );

  const renderList = useMemo(() => {
    if (loading) {
      return <LinearProgress data-testid="loader-testid" />;
    } else if (services && services?.length > 0) {
      return (
        <Box className="mt-2">
          <List data-testid="list-testid" dense={false}>
            {services.map((item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    disableGutters={true}
                    className="py-3"
                    data-testid={`list-item-${index}`}
                    secondaryAction={
                      <Button
                        variant="contained"
                        className="bg-blue-light app-btn standard-btn ff-priamry"
                        onClick={() => onSelectSubmit(item)}
                      >
                        {t("createEventForm.select")}
                      </Button>
                    }
                  >
                    <ListItemText
                      primary={lang === "en" ? item.nameEn : item.nameAr}
                      disableTypography
                      className="ff-priamry"
                    />
                  </ListItem>

                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      );
    } else {
      return (
        <Typography variant="body1" data-testid="nodata-testid" mt={2}>
          {t("noData")}
        </Typography>
      );
    }
  }, [lang, loading, onSelectSubmit, services, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounceSearch = useCallback(
    debounce(async (value: string) => {
      await dispatch(getPublicServices({ search: value }));
    }, 500),
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (event: any) => {
      const value = event.target.value;

      setTextInput(value);
      handleDebounceSearch(value);
    },
    [handleDebounceSearch]
  );

  const handleSearchClear = useCallback(() => {
    setTextInput("");
    dispatch(getPublicServices());
  }, [dispatch]);

  return (
    <>
      <h3 className="slide-header mb-4">
        {t("createEventForm.selectService")}
      </h3>
      <TextField
        variant="outlined"
        placeholder={t("createEventForm.quickSearch")}
        data-testid="search-testid"
        size="small"
        className="w-100 custom-search"
        value={textInput}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: textInput && (
            <IconButton onClick={handleSearchClear}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />

      {renderList}
    </>
  );
};

export default Services;
