import React, { useMemo, useState, useCallback } from "react";
import {
  Typography,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  debounce,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";

import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "redux/reducers/hooks";
import {
  getServiceDoctors,
  incrementSlideCounter,
  updatePractitioner,
  getSelectedService,
} from "redux/slices/appointmentSlice";

const Practitioner = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useAppDispatch();

  const [textInput, setTextInput] = useState("");

  const { loading, stepsData, doctors, buttonLoading } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  const loadDoctors = useCallback(
    (search?: string) => {
      // check what service is selected
      const service = stepsData.find((item) => item.title === "service");

      if (service?.data) {
        //@ts-ignore
        const payload = { servicePk: service?.data?.pk, search: search || "" };

        dispatch(getServiceDoctors(payload));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepsData]
  );

  const onSelectSubmit = useCallback(
    async (item: any) => {
      await dispatch(updatePractitioner(item));

      const service = stepsData.find((item) => item.title === "service");
      if (service?.data) {
        await dispatch(
          //@ts-ignore
          getSelectedService({ serviceId: service.data.pk, doctorId: item.pk })
        );
      }

      await dispatch(incrementSlideCounter());
    },
    [dispatch, stepsData]
  );

  const renderList = useMemo(() => {
    if (loading) {
      return <LinearProgress data-testid="loader-testid" />;
    } else if (doctors && doctors?.length > 0) {
      return (
        <Box className="mt-2">
          <List data-testid="list-testid" dense={false}>
            {doctors.map((item: any, index: number) => {
              let drName = `${item.firstName} ${item.lastName}`;

              if (currentLang === "ar" && item.arabicName) {
                drName = item.arabicName;
              }

              return (
                <React.Fragment key={index}>
                  <ListItem
                    disableGutters={true}
                    className="py-3"
                    data-testid={`list-item-${index}`}
                    secondaryAction={
                      <LoadingButton
                        variant="contained"
                        className="bg-blue-light app-btn standard-btn ff-priamry"
                        onClick={() => onSelectSubmit(item)}
                        loading={buttonLoading}
                        disabled={buttonLoading}
                      >
                        {t("createEventForm.select")}
                      </LoadingButton>
                    }
                  >
                    <ListItemText
                      primary={t("doctorName", { name: drName })}
                      disableTypography
                      className="ff-priamry"
                    />
                  </ListItem>

                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      );
    } else {
      return (
        <Typography variant="body1" data-testid="nodata-testid" mt={2}>
          {t("noData")}
        </Typography>
      );
    }
  }, [buttonLoading, doctors, loading, onSelectSubmit, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounceSearch = useCallback(
    debounce(async (value: string) => {
      loadDoctors(value);
    }, 500),
    [dispatch, loadDoctors]
  );

  const handleSearchChange = useCallback(
    (event: any) => {
      const value = event.target.value;

      setTextInput(value);
      handleDebounceSearch(value);
    },
    [handleDebounceSearch]
  );

  const handleSearchClear = useCallback(() => {
    setTextInput("");
    loadDoctors();
  }, [loadDoctors]);

  return (
    <>
      <h3 className="slide-header mb-4">{t("createEventForm.practitioner")}</h3>
      <TextField
        variant="outlined"
        placeholder={t("createEventForm.quickSearch")}
        data-testid="search-testid"
        size="small"
        className="w-100 custom-search"
        value={textInput}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: textInput && (
            <IconButton onClick={handleSearchClear}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />

      {renderList}
    </>
  );
};

export default Practitioner;
