import React, { useRef, useEffect, useState, useMemo } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Button, Divider } from "@mui/material";
import Base from "./../base.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useSnackbar } from "react-simple-snackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import ReactCountryFlag from "react-country-flag";
import {
  success_options,
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getValidDateStringForGraphQL,
  getErrorMessage,
  concatAllErrors,
} from "../../Common/helpers.js";

import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import Logo from "../../assets/images/mindwell-logo-not-text.svg";
import civilMock from "../../assets/images/civilid_mock@3x.webp";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import {
  getPatientProfile,
  updatePatientProfile,
} from "redux/slices/patientSlice";

const getDirection = (lang) => {
  return lang === "ar";
};

const ProfileView = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const [startDate, setStartDate] = useState("");

  const [ageError, setAgeError] = useState("");

  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);

  const dispatch = useAppDispatch();
  const { loading, patientProfile } = useAppSelector(
    ({ rootPatientSlice }) => rootPatientSlice
  );

  const [changedFile, setChangeFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState({
    name: "",
    url: ''
  });

  useEffect(() => {
    dispatch(getPatientProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientProfile && patientProfile.dob) {
      setStartDate(dayjs(patientProfile.dob));
    }
  }, [patientProfile]);

  const GET_COUNTRIES = gql`
    query {
      countries
    }
  `;

  const GET_GOVERNORATES = gql`
    query {
      governorates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const GET_AREAS = gql`
    query areas($governorate: ID!) {
      areas(governorate_Id: $governorate) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const UploadMutation = gql`
    mutation updatePatientSelfFile($file: Upload!) {
      updatePatientSelfFile(file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  const [uploadFile, { loading: uploadFileLoading }] = useMutation(
    UploadMutation,
    {
      onCompleted({ updatePatientSelfFile }) {
        if (
          updatePatientSelfFile?.errors &&
          updatePatientSelfFile?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            updatePatientSelfFile?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
          return;
        }
        openSnackbarSuccess(t("patientrequestReport.submitted"), [
          SNACK_DURATION,
        ]);
        dispatch(getPatientProfile());
      },
      onError: (e) => {
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("uploadFile error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);
  const [getAreasForGov, { data: areasData = [] }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("GET_AREAS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const onSubmit = async () => {
    const formData = getValues(); // Retrieve all form values
    if (!formData.middleName) {
      formData.middleName = "";
    }
    console.log("formData-----",formData.middleName);


    const { civil_id, email, idFile, otp, phone, ...rest } = formData;

    if (rest.country !== "KW") {
      delete rest.area;
      delete rest.governorate;
      delete rest.governorate;
    }

    const res = await dispatch(
      updatePatientProfile({
        ...rest,
        dob: startDate
          ? getValidDateStringForGraphQL(new Date(startDate))
          : rest?.dob,
      })
    );

    if (res?.error) {
      openSnackbar(res?.payload, [SNACK_DURATION]);
      return;
    }
    openSnackbarSuccess(t("patientrequestReport.submitted"), [SNACK_DURATION]);
  };

  const onSubmitEmail = () => {
    const formData = getValues(); // Retrieve all form values
    console.log(formData);
  };

  const onSubmitPhone = () => {
    const formData = getValues(); // Retrieve all form values
    console.log(formData);
  };

  const onSubmitCivil = async () => {
    await uploadFile({
      variables: { file: changedFile },
    });
  };

  const handleDateChange = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    const minAgeDate = new Date(
      now.getFullYear() - 13,
      now.getMonth(),
      now.getDate()
    );
    if (selectedDate >= minAgeDate) {
      setAgeError(t("patientRegForm.age13"));
    } else {
      setAgeError(""); // Clear any error messages
    }

    setStartDate(date);
  };

  const handleButtonClick = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click();
    } else {
      console.warn("hiddenInputRef.current is null");
    }
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...patientProfile,
      governorate: patientProfile?.governorate?.id,
      area: patientProfile?.area?.id,
      gender: patientProfile?.gender.toLocaleLowerCase(),
      civilId: patientProfile?.civilId,
    },
  });

  useEffect(() => {
    if (patientProfile) {
      methods.reset({
        ...patientProfile,
        governorate: patientProfile?.governorate?.id || "",
        area: patientProfile?.area?.id || "",
        gender: patientProfile?.gender?.toLocaleLowerCase() || "",
        civilId: patientProfile?.civilId || "",
        country: patientProfile?.country?.code,
      });
    }
  }, [patientProfile, methods]);

  const { watch, register, control, getValues } = methods;
  const { ref: registerRef, ...rest } = register("civil_id");
  const hook_governorate = watch("governorate");
  const hook_country = watch("country");
  const hook_civil = watch("civil_id");

  useEffect(() => {
    if (hook_civil && hook_civil.length > 0) {
      const file = hook_civil[0]; // Accessing the first file in the FileList
      setChangeFile(file);

      const urlImage = URL.createObjectURL(file);
      setPreview(urlImage);
      setUploadedFileName(prevState => ({
        ...prevState,
        name: file.name,
        url: file.url
      }));
    }
  }, [hook_civil]);

  useEffect(() => {
    if (governoratesData && hook_governorate) {
      // console.log("watch", governoratesData)

      const governorateObj = governoratesData.governorates.edges.find(
        (item) => item.node.id === hook_governorate
      );
      if (governorateObj) {
        // console.log("Governorate Object:", governorateObj.node);
        let val = {
          governorate: governorateObj.node.id,
        };
        getAreasForGov({ variables: val });
      }
    }
  }, [getAreasForGov, governoratesData, hook_governorate]);

  const [isEdit, setIsEdit] = useState(false);
  const [preview, setPreview] = useState(null);
  const hiddenInputRef = useRef(null);

  const renderImagePreview = useMemo(() => {
    if (isEdit && preview) {
      return (
        <img alt={"mock"} className="img-fluid civil-mock" src={preview} />
      );
    } else if (patientProfile?.idFile) {
      return (
        <img
          alt={"mock"}
          className="img-fluid civil-mock"
          src={patientProfile?.idFile}
        />
      );
    } else {
      return (
        <img alt={"mock"} className="img-fluid civil-mock" src={civilMock} />
      );
    }
  }, [isEdit, patientProfile?.idFile, preview]);

  const splitByLength = (inputString) => {
    const maxLength = 50;
    let result = [];
    if(!inputString ){
      return""
    }
    for (let i = 0; i < inputString.length; i += maxLength) {
      result.push(inputString.substring(i, i + maxLength));
    }
    return result.join("\n");

  };
  return (
    <Base title={""} showHeader={true} isView={true} isPatientPortal={true}>
      <div style={{ paddingBottom: "2rem" }}>
        <FormProvider {...methods}>
          <form>
            <Row className="match-height">
              <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                <div className="d-flex flex-column justify-content-around pb-4  align-items-center">
                  {/* LOGO */}
                  <div
                    className="rounded-circle bg-clr-primary p-4 d-flex justify-content-center  align-items-center"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <img
                      alt={"Logo"}
                      className="img-fluid navLogo"
                      src={Logo}
                    />
                  </div>

                  {/* PROFILE HEADING*/}
                  {patientProfile && (
                    <div className="mt-4 text-center">
                      <h1 className="text-clr-primary fw-bold fs-1">
                        {patientProfile?.firstName +
                          " " +
                          (patientProfile?.middleName ? patientProfile.middleName + " " : "") +
                          " " +
                          patientProfile?.lastName}
                      </h1>

                      <span>
                        <small className={`${direction && "btn-fs-rtl"}`}>
                          {t("profileView.id_number")}
                        </small>
                        <span className="text-clr-primary fw-bold px-4">
                          {patientProfile?.civilId}
                        </span>
                      </span>
                    </div>
                  )}

                  {/* CIVIL ID */}
                  <div
                    className="form-container d-flex flex-column justify-content-center  align-items-center   w-100"
                    style={{ marginTop: "3rem" }}
                  >
                    <header className="d-flex  justify-content-between align-items-center w-100 py-4">
                      <h5 className="cfw-bold">{t("profileView.id_copy")} </h5>

                      {!isEdit && (
                        <Button
                          className={`text-primary no-caps ${
                            direction && "btn-fs-rtl"
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsEdit(true)}
                        >
                          {t("profileView.edit")}
                        </Button>
                      )}
                    </header>
                    {!uploadedFileName.name  && <>

                    <div className="text-center">
                    {!patientProfile?.idFile.endsWith('.pdf') && renderImagePreview}
                    </div>
                    {patientProfile?.idFile.endsWith('.pdf') &&
                      <>
                        <Button
                          variant="link"
                          className="px-0 mt-2 "
                          onClick={() => window.open(patientProfile.idFile)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16">
                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                            <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                          </svg>
                        </Button>
                        
                      </>
                    }
                       </>}

                       {uploadedFileName?.name.endsWith('.pdf') && 
                   <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16">
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                  <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                  </svg>
                     }
                   {
                    uploadedFileName.name &&!uploadedFileName?.name.endsWith('.pdf') &&
                   renderImagePreview
                   }

                    <div className="mt-2">
                    {splitByLength(uploadedFileName.name) || (splitByLength(patientProfile?.idFile).split('/').pop())}
    </div>
                    {isEdit && (
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <Form.Group
                          controlId="formFileLg"
                          className="mt-4 pl-0"
                        >
                          <Form.Control
                            type="file"
                            style={{ display: "none" }}
                            size="lg"
                            name="civil_id"
                            {...rest}
                            ref={(e) => {
                              registerRef(e);
                              hiddenInputRef.current = e;
                            }}
                            accept=".jpg,.jpeg,.png,.pdf"
                          />
                          <Form.Text className="text-muted">
                            {t("patientRegForm.click")}
                          </Form.Text>
                          <Button
                            variant="contained"
                            onClick={handleButtonClick}
                            className="mt-2 no-caps bg-blue-light"
                          >
                            {t("profileView.upload_civil")}
                          </Button>
                        </Form.Group>
                      </div>
                    )}
                    {isEdit && (
                      <div className="d-flex w-100 justify-content-end">
                        <Button
                          className={`no-caps mx-4 text-clr-primary ${
                            direction && "btn-fs-rtl"
                          }`}
                          onClick={() => {
                            setIsEdit(false);
                            setPreview(null);
                          }}
                          variant="text"
                        >
                          {t("profileView.cancel")}
                        </Button>

                        <LoadingButton
                          className={`no-caps bg-blue-light px-4 ${
                            direction && "btn-fs-rtl"
                          }`}
                          variant="contained"
                          onClick={onSubmitCivil}
                          loading={uploadFileLoading}
                          disabled={uploadFileLoading}
                        >
                          {t("profileView.save")}
                        </LoadingButton>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                <div className="d-flex flex-column bg-clr-secondary form-radius-1 ">
                  {/* ID INFO */}
                  <div className="form-padding-inline">
                    <header className="d-flex  justify-content-between align-items-center w-100 py-4">
                      <h5 className="cfw-bold">{t("profileView.id_info")}</h5>
                      {!isEdit && (
                        <Button
                          className={`text-primary no-caps ${
                            direction && "btn-fs-rtl"
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsEdit(true)}
                        >
                          {t("profileView.edit")}
                        </Button>
                      )}
                    </header>

                    <Form.Group
                      as={Row}
                      className="mb-3 align-items-center"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.id_number")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          <Form.Control type="text" {...register("civilId")} />
                        ) : (
                          <span className="text-clr-primary">
                            {" "}
                            {patientProfile?.civilId}{" "}
                          </span>
                        )}
                      </Col>
                    </Form.Group>
                  </div>
                  <Divider className="custom-hr" />

                  {/* PERSONAL INFO */}
                  <div className="form-padding-inline">
                    <header className="py-4">
                      <h5 className="cfw-bold">
                        {t("profileView.personal_info")}
                      </h5>
                    </header>

                    {/* DOB */}

                    {isEdit && (
                      <>
                        <Form.Group
                          as={Row}
                          className="mb-3 align-items-center"
                          controlId="formPlaintextEmail"
                        >
                          <Form.Label
                            column
                            sm="4"
                            className={`${direction && "fs-rtl"}`}
                          >
                            {t("patientRegister.firstName")}
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              {...register("firstName")}
                              type="text"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3 align-items-center"
                          controlId="formPlaintextEmail"
                        >
                          <Form.Label
                            column
                            sm="4"
                            className={`${direction && "fs-rtl"}`}
                          >
                            {t("patientRegister.middleName")}
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              {...register("middleName")}
                              type="text"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3 align-items-center"
                          controlId="formPlaintextEmail"
                        >
                          <Form.Label
                            column
                            sm="4"
                            className={`${direction && "fs-rtl"}`}
                          >
                            {t("patientRegister.lastName")}
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              {...register("lastName")}
                              type="text"
                            />
                          </Col>
                        </Form.Group>
                      </>
                    )}
                    <Form.Group
                      as={Row}
                      className={`mb-3 align-items-center ${
                        !isEdit && `field-border ${direction && "hrRtl"}`
                      } `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.dob")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={startDate || null}
                                name="dob"
                                format="DD-MM-YYYY"
                                onChange={(date) => handleDateChange(date)}
                                maxDate={dayjs()}
                                sx={{
                                  backgroundColor: "white",
                                  ".MuiInputBase-input": {
                                    padding: ".375rem .75rem",
                                    backgroundColor: "white",
                                  },
                                }}
                                slotProps={{
                                  textField: { fullWidth: true },
                                }}
                              />
                            </LocalizationProvider>
                            {ageError && (
                              <div style={{ color: "red" }}>{ageError}</div>
                            )}
                          </>
                        ) : (
                          <span className="text-clr-primary">
                            {" "}
                            {dayjs(patientProfile?.dob).format("DD-MM-YYYY")}
                          </span>
                        )}
                      </Col>
                    </Form.Group>

                    {/* GENDER */}
                    <Form.Group
                      as={Row}
                      className={`mb-3 align-items-center ${
                        !isEdit && `field-border ${direction && "hrRtl"}`
                      } `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.gender")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          <Form.Control
                            as="select"
                            name="gender"
                            className={`custom-select ${
                              direction && "selectRtl"
                            }`}
                            {...register("gender")}
                          >
                            <option value="">
                              {t("patientRegForm.selectGender")}
                            </option>
                            <option value={"female"}>
                              {t("patientRegForm.female")}
                            </option>
                            <option value={"male"}>
                              {t("patientRegForm.male")}
                            </option>
                          </Form.Control>
                        ) : (
                          <span className="text-clr-primary">
                            {patientProfile?.gender}
                          </span>
                        )}
                      </Col>
                    </Form.Group>

                    {/* COUNTRY */}
                    <Form.Group
                      as={Row}
                      className={`mb-3 align-items-center ${
                        !isEdit && `field-border ${direction && "hrRtl"}`
                      } `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.country")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          <Form.Control
                            as="select"
                            className={`custom-select ${
                              direction && "selectRtl"
                            }`}
                            name="country"
                            {...register("country")}
                          >
                            <option value="">
                              {t("patientRegForm.selectCountry")}
                            </option>
                            {countriesData &&
                              Object.entries(countriesData.countries).map(
                                ([key, value], index) => {
                                  if (key !== "IL") {
                                    return (
                                      <option value={key} key={index}>
                                        {value}
                                      </option>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                          </Form.Control>
                        ) : (
                          <span className="text-clr-primary">
                            {" "}
                            {patientProfile?.country.name}{" "}
                          </span>
                        )}
                      </Col>
                    </Form.Group>

                    {/* GOVERNORATE */}
                    <Form.Group
                      as={Row}
                      className="mb-3 align-items-center "
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.governorate")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          governoratesData && hook_country === "KW" ? (
                            <Form.Control
                              className={`custom-select ${
                                direction && "selectRtl"
                              }`}
                              as="select"
                              {...register("governorate")}
                            >
                              <option value="">
                                {t("patientRegForm.selectGovernorate")}
                              </option>
                              {governoratesData.governorates.edges.map(
                                (item, index) => (
                                  <option value={item.node.id} key={index}>
                                    {item.node.name}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              as="select"
                              {...register("governorate")}
                            >
                              <option value="">No Option</option>
                            </Form.Control>
                          )
                        ) : (
                          <span className="text-clr-primary">
                            {patientProfile?.governorate?.name || "-"}
                          </span>
                        )}
                      </Col>
                    </Form.Group>
                  </div>
                  <Divider className="custom-hr" />

                  {/* CONTACT INFO */}
                  <div className="form-padding-inline">
                    <header className="py-4">
                      <h5 className="cfw-bold">
                        {t("profileView.contact_info")}
                      </h5>
                    </header>

                    {/* AREA */}
                    <Form.Group
                      as={Row}
                      className={`mb-3 align-items-center ${
                        !isEdit && `field-border ${direction && "hrRtl"}`
                      } `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.area")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          areasData.areas && hook_country === "KW" ? (
                            <Form.Control
                              className={`custom-select ${
                                direction && "selectRtl"
                              }`}
                              as="select"
                              {...register("area")}
                            >
                              <option value="">
                                {t("patientRegForm.selectArea")}
                              </option>
                              {areasData.areas.edges.map((item, index) => (
                                <option value={item.node.id} key={index}>
                                  {item.node.name}
                                </option>
                              ))}
                            </Form.Control>
                          ) : (
                            <Form.Control as="select" {...register("area")}>
                              <option value="">No Option</option>
                            </Form.Control>
                          )
                        ) : (
                          <span className="text-clr-primary">
                            {patientProfile?.area?.name || "-"}
                          </span>
                        )}
                      </Col>
                    </Form.Group>

                    {/* ADDRESS */}
                    <Form.Group
                      as={Row}
                      className="mb-3 align-items-center"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.address")}
                      </Form.Label>
                      <Col sm="7">
                        {isEdit ? (
                          <Form.Control {...register("address")} type="text" />
                        ) : (
                          <span className="text-clr-primary">
                            {patientProfile?.address}
                          </span>
                        )}
                      </Col>
                    </Form.Group>

                    {isEdit && (
                      <div className="d-flex justify-content-end py-4">
                        <Button
                          className={`no-caps mx-4 text-clr-primary ${
                            direction && "btn-fs-rtl"
                          }`}
                          onClick={() => setIsEdit(false)}
                          variant="text"
                        >
                          {t("profileView.cancel")}
                        </Button>
                        <LoadingButton
                          className={`no-caps bg-blue-light px-4 ${
                            direction && "btn-fs-rtl"
                          }`}
                          onClick={() => onSubmit()}
                          variant="contained"
                          loading={loading}
                          disabled={loading}
                        >
                          {t("profileView.save")}
                        </LoadingButton>
                      </div>
                    )}
                  </div>

                  <Divider className="custom-hr" />

                  {/* CHANGE PHONE NUMBER */}
                  <div className="form-padding-inline pb-2">
                    <header className="py-4">
                      <h5 className="cfw-bold">
                        {t("profileView.change_mobile")}
                      </h5>
                    </header>

                    {/* PHONE NUMBER */}
                    <Form.Group
                      as={Row}
                      className={`mb-3 align-items-center `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.phone_number")}
                      </Form.Label>
                      <Col sm="7">
                        {false ? (
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <PhoneInput
                                excludeCountries={["il"]}
                                {...field}
                                country={"kw"}
                                onChange={(value) => field.onChange(value)} // Ensure the phone number is updated correctly
                              />
                            )}
                          />
                        ) : (
                          <>
                            <ReactCountryFlag
                              style={{
                                fontSize: "2em",
                              }}
                              countryCode={hook_country}
                              svg
                            />
                            <span className="px-2 text-clr-primary">
                              {patientProfile?.phone}
                            </span>
                          </>
                        )}
                      </Col>
                    </Form.Group>

                    {/* OTP */}
                    {false && (
                      <Form.Group
                        as={Row}
                        className={`mb-3 align-items-center `}
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label
                          column
                          sm="4"
                          className={`${direction && "fs-rtl"}`}
                        >
                          {t("profileView.otp")}
                        </Form.Label>
                        <Col sm="7">
                          <div className="d-flex align-items-center">
                            <Form.Control type="email" {...register("otp")} />
                            <Button
                              className={`no-caps bg-blue-light px-4   ${
                                direction ? "mr-2" : "ml-2"
                              }`}
                              onClick={() => onSubmitPhone()}
                              style={{ whiteSpace: "nowrap" }}
                              variant="contained"
                            >
                              {t("profileView.send_otp")}
                            </Button>
                          </div>
                        </Col>
                      </Form.Group>
                    )}

                    {false && (
                      <div className="d-flex justify-content-end py-4">
                        <Button
                          className={`no-caps bg-blue-light px-4 ${
                            direction && "btn-fs-rtl"
                          }`}
                          onClick={() => onSubmitPhone()}
                          variant="contained"
                        >
                          {t("profileView.save")}
                        </Button>
                      </div>
                    )}
                  </div>

                  <Divider className="custom-hr" />

                  {/* CHANGE EMAIL */}
                  <div className="form-padding-inline pb-4">
                    <header className="py-4">
                      <h5 className="cfw-bold">
                        {t("profileView.change_email")}
                      </h5>
                    </header>

                    {/* EMAIL */}
                    <Form.Group
                      as={Row}
                      className={`mb-0 align-items-center `}
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className={`${direction && "fs-rtl"}`}
                      >
                        {t("profileView.email")}
                      </Form.Label>
                      <Col sm="7">
                        {false ? (
                          <Form.Control type="email" {...register("email")} />
                        ) : (
                          <span className="text-clr-primary">
                            {patientProfile?.email}
                          </span>
                        )}
                      </Col>
                      {false && (
                        <>
                          <Col sm="4" className="mt-4"></Col>
                          <Col sm="7" className="mt-4">
                            <p className="text-warning">
                              {t("profileView.pending_veri")}
                            </p>
                          </Col>
                        </>
                      )}
                    </Form.Group>

                    {false && (
                      <div className="d-flex justify-content-end pb-4 pt-0">
                        <Button
                          className={`no-caps bg-blue-light px-4 ${
                            direction && "btn-fs-rtl"
                          }`}
                          onClick={() => onSubmitEmail()}
                          variant="contained"
                        >
                          {t("profileView.save")}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </div>
    </Base>
  );
};

export default ProfileView;
