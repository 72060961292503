import { configureStore } from "@reduxjs/toolkit";
import appointmentSlice from "./slices/appointmentSlice";
import patientSlice from "./slices/patientSlice";

export const store = configureStore({
  reducer: {
    rootAppointmentSlice: appointmentSlice,
    rootPatientSlice: patientSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
