import { Row, Col} from 'react-bootstrap';
import Base from './base.js';


function LinkGoogleAccount(props) {
  
  return (
    <Base title={'LINK GOOGLE ACCOUNT'} showHeader={true}>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={10}>
            <Row>
              <p>Link Google account</p>
            </Row>
          </Col>
        </Row>
    </Base>
  );
}
export default LinkGoogleAccount;
