import React, { useRef, useEffect, useState } from "react";
import style from "./LoginForm/LoginForm.module.css";
import { Button, Form, Col, Row } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options_top,
  success_options_top,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../Common/helpers.js";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RegisterForm = (props) => {
  const [openSnackbarError] = useSnackbar(error_options_top);
  const [openSnackbarSuccess] = useSnackbar(success_options_top);
  const formikRef = useRef();
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState({
    minLength: false,
    hasSymbol: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
  });

  useEffect(() => {
    const isProduction = process.env.ENV === "production";
    const isPatientPage = window.location.href.indexOf("patient") !== -1;

    if (isProduction || isPatientPage) {
      setDisableButton(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const REGISTER = gql`
    mutation register(
      $firstName: String!
      $lastName: String!
      $middleName: String!
      $email: String!
      $password1: String!
      $password2: String!
      $username: String!
    ) {
      register(
        input: {
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          email: $email
          password1: $password1
          password2: $password2
          username: $username
        }
      ) {
        success
        errors
      }
    }
  `;

  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register.success) {
        formikRef.current.resetForm();
        props.history.push({
          pathname: "/",
          state: { registerSuccess: true }, //pass variable to the login page to show the success message
        });
      } else {
        let errors = register?.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let emailErrors = errors.email;
        let password1Errors = errors.password1;
        let password2Errors = errors.password2;
        let firstNameErrors = errors.firstName;
        let middleNameErrors = errors.middleName;
        let lastNameErrors = errors.lastName;
        let usernameErrors = errors.username;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in emailErrors) {
          let e = emailErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in firstNameErrors) {
          let e = firstNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in lastNameErrors) {
          let e = lastNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in middleNameErrors) {
          let e = middleNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in usernameErrors) {
          let e = usernameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in password1Errors) {
          let e = password1Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        if (password2Errors) {
          let passwordError = [];

          for (let i = 0; i < password2Errors.length; i++) {
            passwordError = passwordError + "" + password2Errors[i].message;
          }
          openSnackbarError(passwordError, [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("register error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    username: "",
    email: "",
    password1: "",
    password2: "",
  };

  const onSubmit = (values) => {
    register({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        username: values.email, // passing email as the default username for the patients
        email: values.email,
        password1: values.password1,
        password2: values.password2,
      },
    });
  };

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    value = value.replace(/[^a-z '']/gi, "");
    formikRef.current.setFieldValue(fieldName, value);
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    console.log("hasSymbol-------- ", hasSymbol);

    setPasswordValidity({
      minLength,
      hasSymbol,
      hasNumber,
      hasUppercase,
      hasLowercase,
    });
  };
  console.log("setPasswordValidity--------- ", setPasswordValidity);

  const onPasswordChange = (e) => {
    const { value } = e.target;
    console.log("value--------", value);
    formikRef.current.setFieldValue("password1", value);
    validatePassword(value);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      innerRef={formikRef}
    >
      {({ handleSubmit, handleChange, values, handleReset }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.firstName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="firstName"
                onChange={onChangeEnglishInput}
                value={values.firstName}
                autoComplete="given-name"
                required
                id="firstName-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={"text-white text-right"}>
              {t("patientRegister.middleName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="middleName"
                onChange={onChangeEnglishInput}
                value={values.middleName}
                autoComplete="family-name"
                id="middleName-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.lastName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="lastName"
                onChange={onChangeEnglishInput}
                value={values.lastName}
                autoComplete="family-name"
                required
                id="lastName-test"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.email")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                autoComplete="email"
                required
                id="email-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.password")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                name="password1"
                onChange={onPasswordChange}
                value={values.password1}
                autoComplete="new-password"
                required
                id="pass1-test"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="password-toggle-btn"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "10%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
              <ol className={"pass-text-white passReq"}>
                {t("passwordRequirement.mustContain")}
                <li
                  className={
                    passwordValidity.minLength
                      ? "validPassword"
                      : "invalidPassword"
                  }
                >
                  {t("passwordRequirement.8characters")}
                </li>
                <li
                  className={
                    passwordValidity.hasSymbol
                      ? "validPassword"
                      : "invalidPassword"
                  }
                >
                  {t("passwordRequirement.1symbol")}
                </li>
                <li
                  className={
                    passwordValidity.hasNumber
                      ? "validPassword"
                      : "invalidPassword"
                  }
                >
                  {t("passwordRequirement.1numeric")}
                </li>
                <li
                  className={
                    passwordValidity.hasUppercase
                      ? "validPassword"
                      : "invalidPassword"
                  }
                >
                  {t("passwordRequirement.1uppercase")}
                </li>
                <li
                  className={
                    passwordValidity.hasLowercase
                      ? "validPassword"
                      : "invalidPassword"
                  }
                >
                  {t("passwordRequirement.1lowercase")}
                </li>
              </ol>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.confirmPassword")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type={confirmPasswordVisible ? "text" : "password"}
                name="password2"
                onChange={handleChange}
                value={values.password2}
                autoComplete="new-password"
                required
                id="pass2-test"
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="password-toggle-btn"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </Col>
          </Form.Group>
          <Button
            variant="link"
            block
            className="forgot_password"
            id="register-back-test"
            onClick={() => props.history.push("/")}
          >
            {t("patientRegister.backToLogin")}
          </Button>
          <Form.Group as={Row}></Form.Group>
          <Form.Group as={Row}>
            <Col className={style.login_form__submit}>
              <Button
                disabled={loading || disableButton}
                className="login_form__button col-lg-3 col-sm-12"
                id="register-submit-test"
                type="submit"
              >
                {loading ? "Loading ..." : t("patientRegister.submit")}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(RegisterForm);
