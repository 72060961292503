import { Card } from 'react-bootstrap';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { getForamttedTime, getDay, getDate , isUserStaff, displayMiddleName} from '../Common/helpers.js';
import { Link } from 'react-router-dom';

function UpcomingAppointmentCard(props) {
  let { event, detail, location, extraClasses, patient_record,  showStatus } = props;
  const { pathname } = location;
  const isStaff = isUserStaff();
  const getCard = () => {
    let date = new Date(event.start);
    return (
      <Card className={extraClasses ? extraClasses : ''}>
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap">
            <span> {getDay(date.getDay())} </span>
            <span>{getDate(date)}</span>
            <span>{getForamttedTime(date)}</span>
          </div>
          <div className='mt-3'>
            <span className="text-capitalize"> {patient_record?.firstName}{' '}{displayMiddleName(patient_record?.middleName)}{patient_record?.lastName} </span>
          </div>
          <div className="d-flex justify-content-between">
            <span> {event?.doctorEventType && event?.doctorEventType?.title?.name ? event?.doctorEventType?.title?.name : null} </span>
            {showStatus ? 
              <span className="text-capitalize"> {event.displayStatus} </span>
            : null}
          </div>
          {detail ? (
            <div className="d-flex justify-content-between mt-3">
              {event.location ? (
                <span className="text-capitalize"> Location: {event.location.title} </span>
              ) : null}
              <span className="text-capitalize"> Duration: {event.duration} Minutes </span>
            </div>
          ) : null}
        </Card.Body>
      </Card>
    );
  };

  if (event) {
    if (pathname.indexOf('/appointment/detail/') > -1) {
      return getCard();
    } else {
      return (
        <Link to={(isStaff? '/appointment/detail/' :'/patientPortal/appointment/detail/')+ event.id} className="dark_link">
          {getCard()}
        </Link>
      );
    }
  }
  return null;
}
export default withRouter(UpcomingAppointmentCard);
