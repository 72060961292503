import React, { useEffect, useState } from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  urlActions,
  success_options_top,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers";
import * as Sentry from "@sentry/browser";

const VerifyAccount = (props) => {
  const [loading, setLoading] = useState(true);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options_top);
  const VERIFY_ACCOUNT = gql`
    mutation verifyAccount($token: String!) {
      verifyAccount(input: { token: $token }) {
        success
        errors
      }
    }
  `;

  // CREATE ENCOUNTER
  const [verifyAccount] = useMutation(VERIFY_ACCOUNT, {
    onCompleted: ({ verifyAccount }) => {
      setLoading(false);
      if (
        verifyAccount?.errors &&
        verifyAccount?.errors.nonFieldErrors &&
        verifyAccount?.errors.nonFieldErrors.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          verifyAccount?.errors.nonFieldErrors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (verifyAccount.success) {
        openSnackbarSuccess("Account Verified");
        props.history.push("/");
      }
    },
    onError: (e) => {
      setLoading(false);
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("VERIFY_ACCOUNT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    const verifyAccountFunc = () => {
      let verify_token = urlActions(window.location.href, "get", "verify_token");
      let val = {
        token: verify_token,
      };
      verifyAccount({ variables: val });
    };

    verifyAccountFunc();
  }, [verifyAccount]);

  return (
    <Container fluid>
      <Row className={"login_page varifyPage"}>
        <Col
          md="12"
          className={"d-flex flex-column align-items-center"}
        >
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>Verification complete. Redirecting...</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(VerifyAccount);
