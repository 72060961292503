import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const Modals = (props) => {
  const { showModal, setShowModal, modalHeader ,dialogClassName,contentClassName} = props;
  return (
    <Modal
      show={showModal}
      contentClassName={"modals "+ contentClassName}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={dialogClassName}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100 text-uppercase">{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container fluid>
          {props.children}
        </Container>
      </Modal.Body>
      {/* <Modal.Footer>
        {props.footer}
      </Modal.Footer> */}
    </Modal>
  );
};

export default withRouter(Modals);
