import { gql } from "@apollo/client";

export const PATIENT_SERVICES = gql`
  query GetPublicServices($search: String) {
    services(search: $search) {
      edges {
        node {
          pk
          id
          nameEn
          nameAr
        }
      }
    }
  }
`;

export const SERVICE_DOCTORS = gql`
  query GetServiceDoctors($servicePk: String, $search: String) {
    doctors(servicePk: $servicePk, search: $search) {
      edges {
        node {
          id
          pk
          firstName
          lastName
          arabicName
        }
      }
    }
  }
`;

export const DOCTOR_SERVICE = gql`
  query GetSpecificDoctorService($serviceId: String!, $doctorId: String!) {
    doctorService(serviceId: $serviceId, doctorId: $doctorId) {
      id
      pk
      duration
      price
      eventLocation {
        title
      }
    }
  }
`;

export const GET_PATIENT_AVAILABLE_SLOTS = gql`
  query GetPatientAvailableSlots($serviceId: String!, $date: Date!) {
    getPatientAvailableSchedules(serviceId: $serviceId, date: $date)
  }
`;

export const CREATE_EVENT_PATIENT = gql`
  mutation createEvent(
    $doctor: ID!
    $start: DateTime!
    $description: String
    $doctorEventType: ID!
    $require_payment: Boolean!
    $mainSlot: Boolean!
    $overrideValidation: Boolean!
    $waitinglist: Boolean
    $referral: ID
  ) {
    createEvent(
      input: {
        doctor: $doctor
        start: $start
        description: $description
        doctorEventType: $doctorEventType
        requirePayment: $require_payment
        mainSlot: $mainSlot
        overrideValidation: $overrideValidation
        waitinglist: $waitinglist
        referral: $referral
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        start
        end
        title
        description
        id
        price
        patient {
          id
          identifier
          firstName
          middleName
          lastName
        }
        doctorEventType {
          groupSession
        }
        parent {
          id
        }
        paymentSources {
          edges {
            node {
              paymentLink
            }
          }
        }
        patientRequirePayment
      }
    }
  }
`;
