import React, { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Container,
  ProgressBar,
} from "react-bootstrap";
import Base from "./base.js";
import { Formik } from "formik";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Button as MuiButton } from "@mui/material";
import Select from 'react-select';

import {
  success_options,
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  urlActions,
  capitalizeFirstLetter,
  getValidDateStringForGraphQL,
  displayMiddleName,
  getErrorMessage,
  getAge,
  concatAllErrors,
} from "../Common/helpers.js";
import FileUpload from "../component/upload";
import { useTranslation } from "react-i18next";
import CountrySelect from "../Views/countrySelect";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";
import CountdownTimer from "components/patient/profile/CountDownTimer";
import { useAppDispatch } from "redux/reducers/hooks";
import { getPatient } from "redux/slices/patientSlice";
import { useHistory } from "react-router-dom";

const RegistrationCompleteForm = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const [currentImage, setCurrentImage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [focus, setFocus] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [phoneNotVerified, setPhoneNotVerified] = useState(true);
  const [ageError, setAgeError] = useState("");
  const [phoneTimer, setPhoneTimer] = useState(null);
  const [phoneId, setPhoneId] = useState(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [governorateOptions, setGovernorateOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const { t } = useTranslation();
  let profileEdit = urlActions(window.location.href, "get", "profileEdit");
  const GET_COUNTRIES = gql`
    query {
      countries
    }
  `;

  const GET_GOVERNORATES = gql`
    query {
      governorates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const GET_AREAS = gql`
    query areas($governorate: ID!) {
      areas(governorate_Id: $governorate) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const UPDATE_PATIENT = gql`
    mutation createUpdateSelfPatient(
      $firstName: String!
      $middleName: String!
      $lastName: String!
      $phone: String
      $dob: Date!
      $gender: String!
      $email: String!
      $civil_id: String
      $doctor: ID
      $address: String
      $governorate: ID
      $country: String!
      $area: ID
      $diagnosis: String
      $referred_by: String
      $medication: String
      $identifier: String
      $relatedPhone: ID
    ) {
      createUpdateSelfPatient(
        input: {
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          phone: $phone
          dob: $dob
          gender: $gender
          civilId: $civil_id
          doctor: $doctor
          address: $address
          governorate: $governorate
          country: $country
          area: $area
          impressionDiagnosis: $diagnosis
          medication: $medication
          referredBy: $referred_by
          email: $email
          identifier: $identifier
          relatedPhone: $relatedPhone
        }
      ) {
        obj {
          firstName
          lastName
          email
          identifier
          phone
          id
          profileComplete
          discount
          doctor {
            identifier
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;

  const REQUEST_INTAKE_FORMS = gql`
    query {
      intakeForms {
        edges {
          node {
            id
            patient {
              firstName
              middleName
              lastName
            }
            dynamicForm {
              name
              id
              formType
              patientOnboarding
            }
            data
            submitted
          }
        }
      }
    }
  `;

  const { data: intake_forms_obj } = useQuery(REQUEST_INTAKE_FORMS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_INTAKE_FORMS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [UpdatePatient, { loading: updatePatientLoading }] = useMutation(
    UPDATE_PATIENT,
    {
      onCompleted: async ({ createUpdateSelfPatient }) => {
        if (
          createUpdateSelfPatient?.errors &&
          createUpdateSelfPatient?.errors?.length > 0
        ) {
          if (
            createUpdateSelfPatient.errors.some(
              (error) => error.field === "phone"
            )
          ) {
            openSnackbar(t("patientRegForm.phoneExists"), [SNACK_DURATION]);
            return;
          }
          let error_messages_string = getErrorMessage(
            createUpdateSelfPatient?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          const res = await dispatch(getPatient());
          //@ts-ignore
          if (res?.error) {
            openSnackbar(res?.payload, [SNACK_DURATION]);
            return;
          } else if (res?.payload?.errors?.length > 0) {
            openSnackbar(res?.payload?.errors?.[0]?.messages, [SNACK_DURATION]);
            return;
          } else if (res) {
            openSnackbarSuccess(t("patientrequestReport.submitted"), [
              SNACK_DURATION,
            ]);
            if (res?.completedOnboarding && res.completedOnboarding === false) {
              history.push("/patientPortal/consultation/form");
            } else {
              // redirect to dashboard.
              history.push("/patientPortal/dashboard");
            }
          }

          history.push("/patientPortal/dashboard");
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("UPDATE_PATIENT error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);
  const [getAreasForGov, { data: areasData = null }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("GET_AREAS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  useEffect(() => {
    if (governoratesData) {
      const sortedGovernorateOptions = governoratesData?.governorates?.edges
        ?.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setGovernorateOptions(sortedGovernorateOptions);
    }
  }, [governoratesData]);

  useEffect(() => {
    if (countriesData) {
      const countryOptions = Object.entries(countriesData.countries)
        .filter(([key]) => key !== "IL")
        .map(([key, value]) => ({
          value: key,
          label: value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setCountryOptions(countryOptions);
    }
  }, [countriesData]);

  useEffect(() => {
    if (areasData) {
      const sortedAreaOptions = areasData?.areas?.edges
        ?.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setAreaOptions(sortedAreaOptions);
    }
  }, [areasData]);
  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const onChangeCountry = (selectedOption) => {
    formikRef.current.setFieldValue("country", selectedOption.value);
  };
  const [step, setStep] = useState(1);

  const handleNext = () => {
    document.querySelector("form").reportValidity();
    if (document.querySelector("form").checkValidity()) setStep(step + 1);
  };
  const handlePrevious = () => setStep(step - 1);

  useEffect(() => {
    if (patientData && patientData.dataPatient) {
      if (
        patientData.dataPatient.governorate &&
        patientData.dataPatient.governorate.id
      ) {
        let val = {
          governorate: patientData.dataPatient.governorate.id,
        };
        getAreasForGov({ variables: val });
      }
      if (patientData.dataPatient.idFile) {
        setCurrentImage(patientData.dataPatient.idFile);
        setUploaded(true);
      }
      if (patientData.dataPatient.dob) {
        setStartDate(dayjs(patientData.dataPatient.dob));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  const onChangeGovernorate = (e) => {
    formikRef.current.setFieldValue("governorate", e.value);
    let gov = governoratesData.governorates.edges.find(
      (i) => i.node.id === e.value
    );
    if (gov) {
      let gov_id = gov.node.id;
      let val = {
        governorate: gov_id,
      };
      getAreasForGov({ variables: val });
    }
  };

  const onChangeArea = (e) => {
    formikRef.current.setFieldValue("area", e.value);
  };

  const validationSchema = Yup.object().shape({
    civil_id: Yup.string().required(t("patientRegForm.civilRequired")),
    // .matches(/^[0-9]+$/, t("patientRegForm.onlyDigits"))
    // .min(12, t("patientRegForm.min12"))
    // .max(12, t("patientRegForm.max12")),
  });

  let initialValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    gender: "",
    civil_id: "",
    email: "",
    phone: "",
    address: "",
    governorate: "",
    country: "KW",
  };

  if (patientData && patientData.dataPatient) {
    initialValues["firstName"] = patientData.dataPatient.firstName;
    initialValues["middleName"] = displayMiddleName(
      patientData.dataPatient.middleName
    );
    initialValues["lastName"] = patientData.dataPatient.lastName;
    initialValues["dob"] = new Date(patientData.dataPatient.dob);
    initialValues["phone"] = patientData.dataPatient.phone;
    initialValues["email"] = patientData.dataPatient.email;
    initialValues["gender"] = String(
      patientData.dataPatient.displayGender
    ).toLowerCase();
    initialValues["civil_id"] = patientData.dataPatient.civilId;
    initialValues["address"] = patientData.dataPatient.address;
    initialValues["id"] = patientData.dataPatient.id
      ? patientData.dataPatient.id
      : "";
    initialValues["file_number"] = patientData.dataPatient.identifier;
    initialValues["country"] = patientData.dataPatient.country?.code
      ? patientData.dataPatient.country.code
      : "KW";
    initialValues["governorate"] = patientData.dataPatient.governorate
      ? patientData.dataPatient.governorate.id
      : "";
    initialValues["area"] = patientData.dataPatient.area
      ? patientData.dataPatient.area.id
      : "";
  }

  const onSubmit = (values) => {
    let val = {
      firstName: capitalizeFirstLetter(values.firstName),
      middleName: capitalizeFirstLetter(values.middleName),
      lastName: capitalizeFirstLetter(values.lastName),
      phone: values.phone,
      dob: getValidDateStringForGraphQL(new Date(startDate)),
      email: values.email,
      gender: values.gender,
      civil_id: values.civil_id,
      address: values.address,
      country: values.country ? values.country : "KW",
      profile_complete: false,
      identifier: values.file_number,
      id: values.id,
      relatedPhone: phoneId,
    };
    if (val.country === "KW") {
      val["governorate"] = values.governorate;
      val["area"] = values.area;
    }
    let variables = val;
    let age = getAge(variables.dob);
    if (age >= 13) {
      UpdatePatient({ variables: variables });
    } else {
      openSnackbar(t("patientRegForm.ageNotMeet"), [SNACK_DURATION]);
    }
  };

  const handleNextButtonClick = () => {
    if (step === 4) {
      if (!formikRef.current.values.code) {
        openSnackbar(t("patientRegForm.enterOtp"), [SNACK_DURATION]);
        return;
      }

      verifyOtp({
        variables: { otp: formikRef.current.values.code, id: phoneId },
      });
    } else if (step === 5) {
      onSubmit(formikRef.current.values);
    } else {
      setStep(step + 1);
    }
  };

  var patientId =
    patientData && patientData.dataPatient && patientData.dataPatient.id
      ? patientData.dataPatient.id
      : null;

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    value = value.replace(/[^a-z '']/gi, "");
    formikRef.current.setFieldValue(fieldName, value);
  };

  const updateFocus = (data) => {
    if (data) {
      setFocus(data);
    }
  };

  const handleSignupPhoneChange = (value) => {
    let num = "+" + value;
    formikRef.current.setFieldValue("phone", num);
    // setPhoneNumber(num);
  };
  const GENERATE_OTP = gql`
    mutation PatientGenerateOtp($id: ID!, $phone: String!) {
      patientGenerateOtp(patient: $id, phone: $phone) {
        obj {
          phoneOtpValidDate
          id
        }
      }
    }
  `;
  const VERIFY_OTP = gql`
    mutation VerifyOtp($otp: String!, $id: ID!) {
      verifyOtp(otp: $otp, id: $id) {
        phoneVerified
      }
    }
  `;
  const [generateOtp, { loading: generateOtpLoading }] = useMutation(
    GENERATE_OTP,
    {
      onCompleted: ({ patientGenerateOtp }) => {
        if (
          patientGenerateOtp?.errors &&
          patientGenerateOtp?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            patientGenerateOtp?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          const message = t("otpSent");

          setPhoneTimer(new Date(patientGenerateOtp.obj.phoneOtpValidDate));
          setPhoneId(patientGenerateOtp.obj.id);
          openSnackbarSuccess(message, [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("generateOtp error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );
  const [verifyOtp, { loading: verifyOtpLoading }] = useMutation(VERIFY_OTP, {
    onCompleted: ({ verifyOtp }) => {
      if (verifyOtp?.errors && verifyOtp?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(verifyOtp?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (verifyOtp.phoneVerified) {
          setPhoneNotVerified(false);
          openSnackbarSuccess(t("otpVerified"), [SNACK_DURATION]);
          onSubmit(formikRef.current.values);
          // history.push("/patientPortal/consultation/form");
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("verifyOtp error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const sendCode = async () => {
    const values = formikRef.current.values;

    if (!values.phone) {
      const message = t("patientRegForm.missingPhoneNumber");
      openSnackbar(message, [SNACK_DURATION]);
      return;
    }

    await generateOtp({ variables: { id: patientId, phone: values.phone } });
  };
  const verifyCode = () => {
    verifyOtp({
      variables: { otp: formikRef.current.values.code, id: phoneId },
    });
  };

  const handleDateChange = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    const minAgeDate = new Date(
      now.getFullYear() - 13,
      now.getMonth(),
      now.getDate()
    );

    if (selectedDate >= minAgeDate) {
      // User is younger than 13 years old
      setAgeError(t("patientRegForm.age13"));
      // Optional: Reset the date picker or keep the selected date
      // setStartDate(null);
    } else {
      setAgeError(""); // Clear any error messages
    }
    setStartDate(date);
  };

  return (
    <Base
      title={
        profileEdit && profileEdit === "true"
          ? t("patientRegForm.profileEdit")
          : t("patientRegForm.registerForm")
      }
      showHeader={true}
      isPatientPortal={true}
    >
      <Col xs={12} sm={12} md={12} lg={12} xl={11}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
              <div className="d-flex justify-content-between align-items-center">
      <ProgressBar now={(step / 5) * 100} className="flex-grow-1" />
      <span className="text-purple-dark ml-4">{step}/4</span>
    </div>

                <Form.Group as={Row}></Form.Group>
                {step === 1 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.patientFirstName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            required
                            autoComplete="nope"
                            type="text"
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.firstName || ""}
                            id="reg-first-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          // className="required"
                        >
                          {t("patientRegForm.patientMiddleName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            autoComplete="nope"
                            type="text"
                            name="middleName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.middleName || ""}
                            id="reg-middle-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.patientLastName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            required
                            autoComplete="nope"
                            type="text"
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.lastName || ""}
                            id="reg-last-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.dateOfBirth")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={startDate || null}
                              name="dob"
                              format="DD-MM-YYYY"
                              onChange={(date) => handleDateChange(date)}
                              maxDate={dayjs()}
                              sx={{
                                backgroundColor: "white",
                                ".MuiInputBase-input": {
                                  padding: ".375rem .75rem",
                                  backgroundColor: "white",
                                },
                              }}
                              slotProps={{
                                textField: { fullWidth: true },
                              }}
                            />
                          </LocalizationProvider>

                          {ageError && (
                            <div style={{ color: "red" }}>{ageError}</div>
                          )}

                          <Form.Control.Feedback type="invalid">
                            {errors.dob}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.gender")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            onBlur={handleBlur}
                            as="select"
                            name="gender"
                            value={values.gender}
                            required
                            onChange={handleChange}
                            id="reg-gender-test"
                          >
                            <option value="">
                              {t("patientRegForm.selectGender")}
                            </option>
                            <option value={"female"}>
                              {t("patientRegForm.female")}
                            </option>
                            <option value={"male"}>
                              {t("patientRegForm.male")}
                            </option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 2 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.idNumber")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="civil_id"
                            required
                            autoComplete="civil_id"
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
                              handleChange({ target: { name: "civil_id", value } }); 
                            }}
                            value={values.civil_id || ""}
                            id="reg-id-test"
                          // isValid={touched.civil_id && !errors.civil_id}
                          // isInvalid={!!errors.civil_id}
                          />
                          {touched.civil_id && errors.civil_id ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.civil_id}
                            </Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="id-label"
                        >
                          {/* QUESTION translation? */}
                          {/* {t('patientRegForm.id')} */}
                          <span className="required">
                            {t("patientRegForm.id")}
                          </span>
                          <small>{t("patientRegForm.click")}</small>
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          {patientId ? (
                            <FileUpload
                              patientId={patientId}
                              currentImage={currentImage}
                              setCurrentImage={setCurrentImage}
                              setUploaded={setUploaded}
                            />
                          ) : null}
                          <input
                            required
                            name="id"
                            value={uploaded ? "uploaded" : ""}
                            className="hide-input"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 3 && (
                  <Row >
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      {countriesData ? (
                        <Form.Group as={Row} >
                          <Form.Label column xs={12} sm={4} md={5} className="required text-right">
                            {t("patientRegForm.country")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Select
                              options={countryOptions}
                              onChange={onChangeCountry}
                              value={countryOptions.find(
                                (option) => option.value === values.country
                              )}
                              placeholder={t("patientRegForm.selectCountry")}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {governoratesData &&
                        governoratesData.governorates &&
                        governoratesData.governorates.edges &&
                        values.country === "KW" ? (
                        <Form.Group as={Row} >
                          <Form.Label
                            column
                            xs={12}
                            sm={4}
                            md={5}
                            className="required text-right"
                          >
                            {t("patientRegForm.governorate")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Select
                              options={governorateOptions}
                              onChange={onChangeGovernorate}
                              value={governorateOptions.find(
                                (option) => option.value === values.governorate
                              )}
                              placeholder={t("patientRegForm.selectGovernorate")}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {areasData &&
                        areasData.areas &&
                        areasData.areas.edges &&
                        values.country === "KW" ? (
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            xs={12}
                            sm={4}
                            md={5}
                            className="required text-right"
                          >
                            {t("patientRegForm.area")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Select
                              options={areaOptions}
                              onChange={onChangeArea}

                              value={areaOptions.find(
                                (option) => option.value === values.area
                              )}
                              placeholder={t("patientRegForm.selectArea")}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}


                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.address")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <div style={{display:"flex",justifyContent:"start"}}>
                          <textarea
                            type="text"
                            onBlur={handleBlur}
                            name="address"
                            className="form-control"
                            required
                            autoComplete="address"
                            onChange={handleChange}
                            value={values.address || ""}
                            id="reg-addr-test"
                          />
                          </div>
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 4 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          xs={12}
                          sm={2}
                          md={3}
                          className="required text-right"
                        >
                          {t("patientRegForm.phoneNumber")}
                        </Form.Label>
                        <Col xs={12} sm={6} md={5} className="text-left">
                          <CountrySelect
                            required={false}
                            value={values.phone || ""}
                            onChange={handleSignupPhoneChange}
                            type="number"
                            name="phone"
                            onFocus={() => updateFocus("phone")}
                            onBlur={() => updateFocus("blur")}
                            placeholder={
                              focus === "phone" ? "" : t("Account.phone")
                            }
                            id="reg-phone-test"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} sm={2} md={4} className="text-left">
                          <LoadingButton
                            name="sendCode"
                            type="button"
                            variant="contained"
                            onClick={sendCode}
                            loading={generateOtpLoading}
                            disabled={
                              (generateOtpLoading || !values.phone, phoneTimer)
                            }
                            sx={{ mt: { xs: "0.5rem", sm: 0 } }}
                          >
                            {phoneTimer ? (
                              <CountdownTimer
                                targetDate={phoneTimer}
                                onComplete={() => setPhoneTimer(null)}
                              />
                            ) : (
                              t("sendCode")
                            )}
                          </LoadingButton>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={2}
                          md={3}
                          className="required"
                        >
                          {t("code")}
                        </Form.Label>
                        <Col xs={12} sm={6} md={5}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="code"
                            required={false}
                            autoComplete="code"
                            onChange={handleChange}
                            value={values.code || ""}
                            id="reg-addr-test"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.code}
                          </Form.Control.Feedback>
                        </Col>
                        {/* <Col xs={12} sm={2} md={4} className="text-left">
                          <MuiButton
                            variant="contained"
                            disabled={verifyOtpLoading || !values.code}
                            onClick={verifyCode}
                            sx={{ mt: { xs: "0.5rem", sm: 0 } }}
                          >
                            {t("verify")}
                          </MuiButton>
                        </Col> */}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <div className="d-flex justify-content-between">
                  {step < 5 && (
                    <Button
                      variant="secondary"
                      onClick={handlePrevious}
                      disabled={step === 1}
                    >
                      {t("previous")}
                    </Button>
                  )}
                  {step < 4 && (
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      disabled={!!ageError || !startDate}
                    >
                      {t("next")}
                    </Button>
                  )}

                    {step === 4 && (
                    <LoadingButton
                      name="appointment"
                      type="submit"
                      variant="contained"
                      loading={updatePatientLoading}
                      // disabled={phoneNotVerified || !values.phone}
                      disabled={verifyOtpLoading || !values.code}
                      onClick={handleNextButtonClick}
                    >
                      {t("next")}
                    </LoadingButton>
                  )}
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </Col>
    </Base>
  );
};

export default RegistrationCompleteForm;
