import React, { useEffect, useRef, useState } from "react";
import style from "./CreateEventForm.module.css";
import {
  Button,
  Form,
  Col,
  Row,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Formik } from "formik";
import {
  dataPatientInVar,
  dateEventInVar,
  potentialAppointmentSlotVar,
  calendarActiveMonthVar,
  currentSlotIndexVar,
  displayLoaderVar,
  appFromCalVar,
  availaleSlotsArrayDisplayInCalVar,
  eventsDataVar,
} from "../../../cache/cache";
import {
  getForamttedTime,
  isUserStaff,
  SNACK_DURATION,
  ERROR_MESSAGE,
  success_options,
  error_options,
  getDate,
  displayMiddleName,
  getErrorMessage,
  getValidDateStringForGraphQL,
  concatAllErrors,
  urlActions,
} from "../../../Common/helpers.js";
import SelectPatientModal from "../../../Views/SearchForPatient/selectPatientModal";
import { withRouter } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import { useReactiveVar, useMutation } from "@apollo/client";
import {
  calendarSlotsVar,
  editableAppointmentVar,
} from "../../../cache/cache.js";
import { Spinner } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/browser";

export const REQUEST_DOCTOR = gql`
  query doctor($id: ID!) {
    doctor(id: $id) {
      firstName
      lastName
      id
      email
      identifier
      unavailableDates {
        edges {
          node {
            startTime
            endTime
            date
            leaveRequest {
              description
              leaveType
            }
          }
        }
      }
      overrideSchedules {
        edges {
          node {
            startDate
            endDate
            shifts {
              edges {
                node {
                  startTime
                  endTime
                  id
                }
              }
            }
          }
        }
      }
      eventType(isActive: true) {
        edges {
          node {
            id
            title {
              name
            }
            duration
            buffer
            price
            notification {
              downPaymentNotificationCutoff
            }
            eventType {
              id
              name
              color {
                hexCode
                name
                colorId
              }
            }
            eventLocation {
              id
              title
            }
            groupSession
            availability {
              edges {
                node {
                  day
                  shifts {
                    edges {
                      node {
                        startTime
                        endTime
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      recurringEvents {
        edges {
          node {
            startDate
            startTime
            endTime
            title
            description
            everyday
            days
            dateUntil
          }
        }
      }
      availability {
        edges {
          node {
            id
            day
            shifts {
              edges {
                node {
                  startTime
                  endTime
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

function DatePickerList({ onDateChange }) {
  const [datePickers, setDatePickers] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const { t } = useTranslation();

  const addDatePicker = () => {
    const newDate = new Date();
    setDatePickers([...datePickers, newDate]);

    const updatedSelectedDates = [...selectedDates, newDate];

    setSelectedDates(updatedSelectedDates);
    onDateChange(updatedSelectedDates); // Notify the parent component of the change
  };

  const deleteDatePicker = (index) => {
    const updatedDatePickers = [...datePickers];
    updatedDatePickers.splice(index, 1);

    const updatedSelectedDates = [...selectedDates];
    updatedSelectedDates.splice(index, 1);

    setDatePickers(updatedDatePickers);
    setSelectedDates(updatedSelectedDates);
    onDateChange(updatedSelectedDates); // Notify the parent component of the change
  };

  const handleDateChange = (date, index) => {
    const updatedSelectedDates = [...selectedDates];
    updatedSelectedDates[index] = date;
    setSelectedDates(updatedSelectedDates);
    onDateChange(updatedSelectedDates); // Notify the parent component of the change
  };

  return (
    <div>
      {datePickers.map((date, index) => (
        <div key={index}>
          <Form.Group
            as={Row}
            className="text-right create-event-datepicker"
            style={{ marginBottom: 0 }}
          >
            <Form.Label column sm={4} md={4} className="required">
              #{index + 2} {t("createEventForm.date")} &{" "}
              {t("createEventForm.time")}
            </Form.Label>
            <Col
              sm={8}
              md={8}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DatePicker
                selected={selectedDates[index]}
                onChange={(date) => handleDateChange(date, index)}
                shouldCloseOnSelect={false}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                defaultValue=""
              />
              <Button
                variant="link"
                style={{ color: "red" }}
                onClick={() => deleteDatePicker(index)}
              >
                <i className="fa fa-times"></i>
              </Button>
            </Col>
          </Form.Group>
        </div>
      ))}
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button variant="link" onClick={addDatePicker}>
          Add more day
        </Button>
      </div>
    </div>
  );
}

const CreateEventForm = (props) => {
  const {
    createEvent,
    doctorList,
    patientDataOfSearchPage,
    selectedDateEvent,
    selectedDoctor,
    setSelectedDoctor,
    doctorLeaves,
    fullCalendarRef,
    setSelectedSession,
    setSelectedPatient,
    selectedPatient,
    selectedSession,
    calendarData,
    formikRef,
    GetEvents,
    referralObjectData,
    history,
    serviceList,
    getDoctorsListFromService,
    setIsAppointmentSubmitting,
    isAppointmentSubmitting,
    settings_obj,
    activeDocShiftArr,
    setReqPrevNextData,
    createNewEvent,
  } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const searchPatientRef = useRef();
  const [selectedDate, setSelectedDate] = useState({});
  const [showWaitingList, setShowWaitingList] = useState(false);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [fromReferral, setFromReferral] = useState(false);
  const isStaff = isUserStaff();
  // eslint-disable-next-line no-unused-vars
  const calendarActiveMonth = useReactiveVar(calendarActiveMonthVar);
  const currentSlotIndex = useReactiveVar(currentSlotIndexVar);
  const editableAppointment = useReactiveVar(editableAppointmentVar);
  const availaleSlotsArrayDisplayInCal = useReactiveVar(
    availaleSlotsArrayDisplayInCalVar
  );
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const appFromCal = useReactiveVar(appFromCalVar);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;
  const isGroupAppointment = window.location.href.indexOf("group=true") > -1;
  const isGroupAddPatient =
    window.location.href.indexOf("groupAddPatient=true") > -1;
  const isGroupEditPatient =
    window.location.href.indexOf("groupEditPatient=true") > -1;
  const iseditGroupTherapy =
    window.location.href.indexOf("editGroupTherapy=true") > -1;
  // eslint-disable-next-line no-unused-vars
  const isEditAppointment =
    window.location.href.indexOf("isEditAppointment=true") > -1;
  const [recurringGroupDays, setRecurringGroupDays] = useState([]);
  const [skipDates, setSkipDates] = useState([]);
  const [allowedSkipDates, setAllowedSkipDates] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState();
  // eslint-disable-next-line no-unused-vars
  const [patientCurrentServiceSelected, setpatientCurrentServiceSelected] =
    useState({});
  const [time, setTime] = React.useState(null);
  const eventsDataMain = useReactiveVar(eventsDataVar);
  const [selectedDates, setSelectedDates] = useState([]);
  const original_permissions = localStorage.getItem("original_permissions");

  const handleDateChange = (newSelectedDates) => {
    setSelectedDates(newSelectedDates);
  };

  const { t } = useTranslation();
  const daysArr = [
    {
      value: "0",
      label: "Sunday",
    },
    {
      value: "1",
      label: "Monday",
    },
    {
      value: "2",
      label: "Tuesday",
    },
    {
      value: "3",
      label: "Wednesday",
    },
    {
      value: "4",
      label: "Thursday",
    },
    {
      value: "5",
      label: "Friday",
    },
    {
      value: "6",
      label: "Saturday",
    },
  ];

  const [getDoctor, { data: doctor, loading: loadingDoctors }] = useLazyQuery(
    REQUEST_DOCTOR,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_DOCTOR error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    if (startDate && endDate) {
      let tempRecurrGroupSlots = [];
      var start = new Date(startDate);
      let slotEnd = new Date(start);
      slotEnd.setMinutes(selectedSession.node.duration);
      var end = new Date(endDate);
      for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
        let recurrSessionStart =
          getValidDateStringForGraphQL(d) +
          "T" +
          startDate.toISOString().split("T")[1];
        let currentDay = d.getDay(); // javascript returns sunday as 0 but backend considers monday as 0 and sunday as 6 so this calculation is to match the currect day
        if (recurringGroupDays.find((i) => i.value === currentDay + "")) {
          let newCreatedEventRecurr = {};
          newCreatedEventRecurr["label"] = getDate(
            new Date(recurrSessionStart)
          );
          newCreatedEventRecurr["value"] = getValidDateStringForGraphQL(
            new Date(recurrSessionStart)
          );
          let currSlot = editableAppointment?.recurringGroupSlots?.find(
            (i) => i.start === recurrSessionStart
          );
          if (currSlot && currSlot.numOfPatients > 0) {
            newCreatedEventRecurr["disabled"] = true;
          }
          tempRecurrGroupSlots.push(newCreatedEventRecurr);
        }
      }
      setAllowedSkipDates(tempRecurrGroupSlots);
      if (editableAppointment?.blockedDates) {
        let blockedDatesStr = editableAppointment?.blockedDates;
        let validJSON = blockedDatesStr?.replace(/'/g, '"');
        let validJSONBlockedDates = validJSON ? JSON.parse(validJSON) : [];
        let datesToSkip = tempRecurrGroupSlots.filter((i) =>
          validJSONBlockedDates.some((j) => j === i.value)
        );
        setSkipDates(datesToSkip);
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endDate,
    selectedDate,
    selectedSession,
    recurringGroupDays,
    editableAppointment,
  ]);

  const UPDATE_APPOINTMENT = gql`
    mutation updateEvent(
      $id: ID!
      $title: String!
      $description: String
      $start: DateTime!
      $doctor: ID
      $doctorEventType: ID!
      $patient: ID
      $require_payment: Boolean!
      $mainSlot: Boolean!
      $overrideValidation: Boolean!
      $parent: ID
      $discount: Float
      $shouldBeSeen: DateTime
      $waitinglist: Boolean
      $referral: ID
      $groupStartDate: Date
      $slotsAvailable: Int
      $groupEndDate: Date
      $groupDays: [String]
      $blockedDates: String
    ) {
      updateEvent(
        input: {
          id: $id
          title: $title
          description: $description
          start: $start
          doctorEventType: $doctorEventType
          patient: $patient
          shouldBeSeen: $shouldBeSeen
          mainSlot: $mainSlot
          overrideValidation: $overrideValidation
          parent: $parent
          doctor: $doctor
          requirePayment: $require_payment
          discount: $discount
          waitinglist: $waitinglist
          referral: $referral
          groupStartDate: $groupStartDate
          groupEndDate: $groupEndDate
          groupDays: $groupDays
          slotsAvailable: $slotsAvailable
          blockedDates: $blockedDates
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
          patient {
            id
            identifier
            firstName
            middleName
            lastName
          }
          doctorEventType {
            groupSession
          }
          parent {
            id
          }
        }
      }
    }
  `;

  useEffect(() => {
    if (appFromCal && appCreateFromCal) {
      //appCreateFromCal boolean to check if this variable can be used or not
      if (appFromCal?.patientData) {
        setSelectedPatient(appFromCal?.patientData);
        formikRef.current.setFieldValue(
          "patient",
          `${appFromCal?.patientData?.firstName} ${appFromCal?.patientData?.lastName} (${appFromCal?.patientData?.identifier})`
        );
      }

      if (appFromCal.selectedDocToCreateEvent) {
        let selectedDoc = doctorList?.doctors?.edges?.find(
          (i) => i?.node?.identifier === appFromCal?.selectedDocToCreateEvent
        );
        formikRef.current.setFieldValue(
          "doctor",
          appFromCal.selectedDocToCreateEvent
        );

        getDoctor({ variables: { id: selectedDoc?.node?.id } });
      }
      if (appFromCal.selectedDateStr && fullCalendarRef.current) {
        let dd = String(appFromCal.selectedDateStr.getDate()).padStart(2, "0");
        let mm = String(appFromCal.selectedDateStr.getMonth() + 1).padStart(
          2,
          "0"
        );
        let yy = appFromCal.selectedDateStr.getFullYear();
        let time =
          "T" +
          String(appFromCal.selectedDateStr.getHours()).padStart(2, "0") +
          ":" +
          String(appFromCal.selectedDateStr.getMinutes()).padStart(2, "0") +
          ":00+03:00";
        let calDate = yy + "-" + mm + "-" + dd + time;
        // calDate="2022-01-11T15:00:00+03:00";
        fullCalendarRef?.current?.getApi()?.changeView("timeGridDay", calDate);
      }
    }
  }, [
    appFromCal,
    fullCalendarRef,
    appCreateFromCal,
    setSelectedPatient,
    formikRef,
    doctorList?.doctors?.edges,
    getDoctor,
  ]);

  const [updateEvent] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: ({ updateEvent }) => {
      setIsAppointmentSubmitting(false);
      if (updateEvent?.errors && updateEvent?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(updateEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Appointment Updated", [SNACK_DURATION]);
        if (isStaff) {
          if (updateEvent?.obj?.doctorEventType?.groupSession) {
            if (updateEvent.obj?.parent?.id) {
              history.push({
                pathname: "/appointment/detail/" + updateEvent.obj.parent.id,
                search: "passDateInURL=" + updateEvent.obj.start,
              });
            } else {
              history.push({
                pathname: "/appointment/detail/" + updateEvent.obj.id,
                search: "passDateInURL=" + updateEvent.obj.start,
              });
            }
          } else {
            let patient_url = "/appointment/detail/" + updateEvent.obj.id;
            history.push(patient_url);
          }
        } else {
          if (updateEvent.obj.paymentSources.edges.length > 0) {
            window.location.href =
              updateEvent.obj.paymentSources.edges[0].node.paymentLink;
          } else {
            history.push("/");
          }
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("updateEvent error " + e);
      }
      setIsAppointmentSubmitting(false);
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const getNextMonth = (editIdObj) => {
    displayLoaderVar(true);
    let slotsUntil =
      calendarData && calendarData.calendarSlots
        ? calendarData.calendarSlots.slotsUntil
        : null;
    let current_month = new Date(slotsUntil).getMonth();
    let current_year = new Date(slotsUntil).getFullYear();
    calendarActiveMonthVar(current_month);
    let dateStart = new Date(current_year, current_month, 1);
    let dateEnd = new Date(current_year, current_month + 1, 1);
    dateEnd.setDate(dateEnd.getDate() + 7);
    dateEnd.setHours(23);
    if (dateStart && dateEnd && selectedDoctor) {
      let variables = {
        doctor_identifier: selectedDoctor?.node?.identifier,
        start: getValidDateStringForGraphQL(dateStart),
        end: getValidDateStringForGraphQL(dateEnd),
        action: "next",
        ...editIdObj,
      };
      setReqPrevNextData(variables);
    }
  };

  const getPrevMonth = (editIdObj) => {
    displayLoaderVar(true);
    let slotsUntil =
      calendarData && calendarData.calendarSlots
        ? calendarData.calendarSlots.slotsUntil
        : null;
    let current_month = new Date(slotsUntil).getMonth();
    let current_year = new Date(slotsUntil).getFullYear();
    calendarActiveMonthVar(current_month - 2);
    let dateStart = new Date(current_year, current_month - 2, 1);
    let dateEnd = new Date(current_year, current_month - 1, 1);
    dateEnd.setDate(dateEnd.getDate() + 7);
    dateEnd.setHours(23);

    if (dateStart && dateEnd && selectedDoctor) {
      let variables = {
        doctor_identifier: selectedDoctor?.node?.identifier,
        start: getValidDateStringForGraphQL(dateStart),
        end: getValidDateStringForGraphQL(dateEnd),
        action: "prev",
        ...editIdObj,
      };
      setReqPrevNextData(variables);
    }
  };

  // ON NEXT AVAILABLE APPOINTMENT BUTTON CLICK
  const onChoseAppointment = (action) => {
    let nextIndex = currentSlotIndex !== null ? currentSlotIndex : 0;
    let allAvailableEvents =
      calendarData && calendarData.calendarSlots
        ? calendarData.calendarSlots.allFilters
        : [];

    let editIdObj = {};
    if (editableAppointment?.eventId) {
      editIdObj = { excludeId: editableAppointment.eventId };
    }
    if (allAvailableEvents && allAvailableEvents.length > 0) {
      if (
        (nextIndex < allAvailableEvents.length && action === "next") ||
        (nextIndex <= allAvailableEvents.length && action === "prev")
      ) {
        if (
          selectedDateEvent &&
          selectedDateEvent.dateEvent &&
          selectedDateEvent.dateEvent.start
        ) {
          let current_date = selectedDateEvent.dateEvent.start;
          let current_index = allAvailableEvents.findIndex((i) => {
            let slotStart = new Date(i.start).toString();
            let currentStart = new Date(current_date).toString();
            if (slotStart === currentStart) {
              return true;
            }
            return false;
          });
          if (action === "next") {
            if (current_index < allAvailableEvents.length - 1) {
              nextIndex = current_index + 1;
            } else {
              getNextMonth(editIdObj);
              return;
            }
          } else if (action === "prev") {
            if (current_index > 0) {
              nextIndex = current_index - 1;
            } else {
              let slotsUntil =
                calendarData && calendarData.calendarSlots
                  ? calendarData.calendarSlots.slotsUntil
                  : null;
              let current_month = new Date(slotsUntil).getMonth();
              if (current_month - 2 >= new Date().getMonth()) {
                getPrevMonth(editIdObj);
                return;
              }
            }
          }
        }
        let nextAvailable = allAvailableEvents[nextIndex];
        if (nextAvailable && nextAvailable.start) {
          if (fullCalendarRef && fullCalendarRef.current) {
            setTimeout(() => {
              let currentView = fullCalendarRef?.current?.getApi()?.view;
              let calDate = currentView.activeStart;
              if (
                currentView.type !== "timeGridDay" ||
                new Date(nextAvailable.start).getDate() !== calDate.getDate()
              ) {
                fullCalendarRef?.current
                  ?.getApi()
                  ?.changeView("timeGridDay", nextAvailable.start);
              } else {
                let currentTimeDisplay = nextAvailable.start.split("T")[1];
                if (currentTimeDisplay) {
                  currentTimeDisplay += ":00";
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.scrollToTime(currentTimeDisplay);
                }
              }
            }, 200);
          }
          let dateToSave = {
            start: nextAvailable.start,
            end: nextAvailable.end,
          };
          if (nextAvailable?.doctorEventType?.groupSession) {
            dateToSave["groupSession"] = true;
            dateToSave["slotsAvailable"] = nextAvailable.slotsAvailable;
            dateToSave["appointmentID"] = nextAvailable.id;
          }
          dateEventInVar(dateToSave);

          let newIndex = nextIndex;
          if (action === "next") {
            newIndex = newIndex + 1;
          } else if (action === "prev" && newIndex > 0) {
            newIndex = newIndex - 1;
          }
          currentSlotIndexVar(newIndex);
        }
      } else if (
        selectedDoctor &&
        selectedDoctor.node &&
        calendarData.calendarSlots
      ) {
        if (action === "next") {
          getNextMonth(editIdObj);
          return;
        } else {
          getPrevMonth(editIdObj);
          return;
        }
      }
    } else if (
      selectedDoctor &&
      selectedDoctor.node &&
      calendarData.calendarSlots
    ) {
      if (action === "next") {
        getNextMonth(editIdObj);
        return;
      } else {
        getPrevMonth(editIdObj);
        return;
      }
    }
    localStorage.setItem("calendarViewStored", "timeGridDay");
  };

  const handleDoctor = (event) => {
    formikRef.current.setFieldValue("doctor", event.target.value);
    let act_doc = eventData.doctors.edges.find(
      (i) => i.node.identifier === event.target.value
    );
    if (act_doc && act_doc?.node?.identifier) {
      getDoctor({ variables: { id: act_doc.node.id } });
      doctorLeaves({
        variables: { doctor_identifier: act_doc.node.identifier },
      });
      let currentView = fullCalendarRef?.current?.getApi()?.view;
      let dateStart = currentView.activeStart;
      let dateEnd = new Date(currentView.activeEnd);
      dateEnd.setDate(dateEnd.getDate() - 1);
      let getEventsVar = {
        doctor_identifier: act_doc.node.identifier,
        start: getValidDateStringForGraphQL(dateStart),
        end: getValidDateStringForGraphQL(dateEnd),
      };
      if (editableAppointment?.eventId) {
        getEventsVar["excludeId"] = editableAppointment.eventId + "";
      }
      let status = urlActions(window.location.href, "get", "status");
      let patient = urlActions(window.location.href, "get", "patient");

      getEventsVar["patient_Identifier"] = patient;
      getEventsVar["status"] = status;
      getEventsVar["active"] =
        status === "no_show" || status === "cancelled" ? false : true;
      getEventsVar["doctor_identifier"] = act_doc.node.identifier;

      if (currentView?.type !== "dayGridMonth") {
        GetEvents({ variables: getEventsVar });
      }
    }
    if (!isStaff) {
      let currentService = formikRef.current.values.service;

      let serviceObject = serviceList.find((item) => {
        return item.node.id === currentService;
      });

      let sessionObj = serviceObject?.node.doctorServices?.edges.find((et) => {
        if (
          et.node.doctor &&
          et.node.doctor.identifier === event.target.value
        ) {
          return et;
        } else {
          return null;
        }
      });
      if (sessionObj?.node) {
        formikRef.current.setFieldValue("session", sessionObj.node.id);
      } else sessionObj = null;
      setSelectedSession(sessionObj);
    } else {
      formikRef.current.setFieldValue("session", "");
      setSelectedSession(null);
    }
    dateEventInVar([]);
  };

  const handleSession = (event) => {
    formikRef.current.setFieldValue("session", event.target.value);
    let session_obj = selectedDoctor.node.eventType.edges.find(
      (i) => i.node.id === event.target.value
    );
    setSelectedSession(session_obj);
    dateEventInVar([]);
  };

  const onSelectPatient = (patientData) => {
    setSelectedPatient(patientData);
    dataPatientInVar(patientData);
  };

  const onCancelForm = (handleReset) => {
    handleReset();
    setSelectedPatient(null);
    setSelectedDoctor(null);
    dataPatientInVar(null);
    dateEventInVar([]);
    history.goBack();
  };

  const updateDateEventUsingEditApp = (editableAppointment) => {
    let eAppEnd = new Date(editableAppointment.end);
    eAppEnd.setMinutes(eAppEnd.getMinutes());
    let dd = String(eAppEnd.getDate()).padStart(2, "0");
    let mm = String(eAppEnd.getMonth() + 1).padStart(2, "0");
    let yy = eAppEnd.getFullYear();
    let time =
      "T" +
      String(eAppEnd.getHours()).padStart(2, "0") +
      ":" +
      String(eAppEnd.getMinutes()).padStart(2, "0");
    let eAppEndStr = yy + "-" + mm + "-" + dd + time;

    setTimeout(() => {
      let dateToSave = {
        start: editableAppointment.start,
        end: eAppEndStr,
      };
      if (editableAppointment?.doctorEventType?.groupSession) {
        dateToSave["groupSession"] = true;
        dateToSave["slotsAvailable"] = editableAppointment.slotsAvailable;
        dateToSave["appointmentID"] = editableAppointment.id;
      }
      dateEventInVar(dateToSave);
      setStartDate(new Date(editableAppointment.start));
    }, 200);

    let eventTitle =
      selectedPatient?.firstName +
      " " +
      displayMiddleName(selectedPatient?.middleName) +
      selectedPatient?.lastName;
    if (editableAppointment) {
      eventTitle = editableAppointment.title;
    }
    let slotObj = {
      title: isGroupAppointment
        ? selectedSession?.node?.title?.name
        : eventTitle,
      start: editableAppointment.start,
      end: editableAppointment.end,
      resourceId: selectedDoctor?.node?.id,
      groupId: "potentialSlot",
    };
    potentialAppointmentSlotVar([slotObj]);
    fullCalendarRef?.current
      ?.getApi()
      ?.changeView("timeGridDay", editableAppointment.start);
  };

  useEffect(() => {
    if (editableAppointment && formikRef) {
      if (editableAppointment?.patient?.firstName) {
        formikRef.current.setFieldValue(
          "patient",
          `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName} (${editableAppointment?.patient?.identifier})`
        );
      }
      formikRef.current.setFieldValue(
        "doctor",
        editableAppointment?.doctor.identifier
      );

      setSelectedDoctor({ node: editableAppointment.doctor });
      doctorLeaves({
        variables: {
          doctor_identifier: editableAppointment?.doctor?.identifier,
        },
      });
      let session_obj = editableAppointment.doctor?.eventType?.edges.find(
        (i) => i.node.id === editableAppointment?.doctorEventType?.id
      );
      let currentView = fullCalendarRef?.current?.getApi()?.view;
      let dateStart = currentView.activeStart;
      let dateEnd = currentView.activeEnd;
      let getEventsVar = {
        doctor_identifier: editableAppointment?.doctor?.identifier,
        start: getValidDateStringForGraphQL(dateStart),
        end: getValidDateStringForGraphQL(dateEnd),
      };
      if (editableAppointment?.eventId) {
        getEventsVar["excludeId"] = editableAppointment.eventId + "";
      }
      GetEvents({ variables: getEventsVar });
      setSelectedSession(session_obj);
      formikRef.current.setFieldValue(
        "session",
        editableAppointment?.doctorEventType?.id
      );
      formikRef.current.setFieldValue(
        "require_payment",
        editableAppointment.requirePayment
      );
      if (isGroupAddPatient) {
        formikRef.current.setFieldValue("require_payment", true);
      }
      formikRef.current.setFieldValue(
        "descriptionEn",
        editableAppointment.description
      );
      if (editableAppointment.waitinglist?.waitingList?.id) {
        formikRef.current.setFieldValue(
          "waiting_list",
          editableAppointment.waitinglist?.waitingList?.id
        );
        setShowWaitingList(true);
      }
      if (editableAppointment.discount) {
        formikRef.current.setFieldValue(
          "discount_value",
          editableAppointment.discount
        );
        setShowDiscount(true);
      }
      if (editableAppointment.slotsAvailable) {
        formikRef.current.setFieldValue(
          "slotsAvailable",
          editableAppointment.slotsAvailable
        );
      }

      if (editableAppointment.groupStartDate) {
        formikRef.current.setFieldValue("recurring_group", true);
      } else {
        formikRef.current.setFieldValue("recurring_group", false);
      }

      if (editableAppointment.groupEndDate) {
        setEndDate(new Date(editableAppointment.groupEndDate));
      }

      if (editableAppointment.groupDays) {
        let selectedDays = daysArr.filter((i) =>
          editableAppointment.groupDays.some((j) => j === i.value)
        );
        setRecurringGroupDays(selectedDays);
      }
    }
    if (editableAppointment) {
      updateDateEventUsingEditApp(editableAppointment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableAppointment, formikRef]);

  // WHEN PATIENT IS SELECTED FROM THE MODAL
  useEffect(() => {
    if (patientDataOfSearchPage && patientDataOfSearchPage?.dataPatient) {
      setSelectedPatient(patientDataOfSearchPage.dataPatient);
      if (patientDataOfSearchPage.dataPatient.discount) {
        formikRef.current.setFieldValue(
          "discount_value",
          patientDataOfSearchPage.dataPatient.discount
        );
        if (patientDataOfSearchPage.dataPatient.discount > 0) {
          formikRef.current.setFieldValue("show_discount", true);
          setShowDiscount(true);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataOfSearchPage, setSelectedPatient]);

  // INITIALIZING DATA FOR APPOINTMENT WHEN IT IS BEING CREATED FROM REFERRALS
  useEffect(() => {
    if (referralObjectData && referralObjectData.referralObj) {
      if (referralObjectData.referralObj.patient) {
        setSelectedPatient(referralObjectData.referralObj.patient);
      }
      if (
        referralObjectData.referralObj.referral &&
        referralObjectData.referralObj.referral.referredDoctor
      ) {
        setSelectedPatient(referralObjectData.referralObj.patient);
        let patient_doctor_identifier =
          referralObjectData.referralObj.referral.referredDoctor.identifier;
        if (referralObjectData.referralObj.referral.referredDoctor) {
          getDoctor({
            variables: {
              id: referralObjectData.referralObj.referral.referredDoctor.id,
            },
          });

          if (formikRef && formikRef.current) {
            formikRef.current.setFieldValue(
              "doctor",
              patient_doctor_identifier
            );
          }
          if (patient_doctor_identifier) {
            doctorLeaves({
              variables: { doctor_identifier: patient_doctor_identifier },
            });
          }
          if (
            referralObjectData.referralObj.referral &&
            referralObjectData.referralObj.referral.referredDoctorSession
          ) {
            let session_id =
              referralObjectData.referralObj.referral.referredDoctorSession.id;
            formikRef.current.setFieldValue("session", session_id);
            setSelectedSession({
              node: referralObjectData.referralObj.referral
                .referredDoctorSession,
            });
          }
        }
      }

      if (
        isGroupAddPatient ||
        isGroupEditPatient ||
        (iseditGroupTherapy && isGroupAppointment) ||
        isEditAppointment
      ) {
        setFromReferral(false);
      } else {
        setFromReferral(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doctorLeaves,
    isEditAppointment,
    isGroupAddPatient,
    isGroupAppointment,
    isGroupEditPatient,
    iseditGroupTherapy,
  ]);

  // On change of patient
  useEffect(() => {
    if (selectedPatient && selectedPatient.id) {
      formikRef.current.setFieldValue(
        "patient",
        `${selectedPatient.firstName} ${displayMiddleName(
          selectedPatient.middleName
        )}${selectedPatient.lastName} (${selectedPatient.identifier})`
      );
    }
  }, [selectedPatient, formikRef]);

  const eventData = {
    doctors: doctorList.doctors,
    listEventLocation: [],
    listEventType: [],
  };

  const createEventSubmitFunc = async (values, overrideValidation) => {
    // IF GROUP SLOT IS BEING CREATED
    if (isGroupAppointment) {
      let val = {
        doctor: selectedDoctor.node.id,
        start: new Date(startDate).toISOString(),
        title: "Group " + selectedSession?.node?.title?.name,
        doctorEventType: values.session,
        slotsAvailable: values.slotsAvailable,
        require_payment: false,
        mainSlot: true,
        overrideValidation: overrideValidation,
      };
      if (values.recurring_group) {
        val["groupStartDate"] = getValidDateStringForGraphQL(
          new Date(startDate)
        );
        val["groupEndDate"] = getValidDateStringForGraphQL(new Date(endDate));
        val["groupDays"] = recurringGroupDays?.map((i) => i.value);
      }
      if (values.paymentTransferId)
        val["paymentTransferId"] = values.paymentTransferId;

      if (skipDates.length > 0) {
        let skipDatesVal = skipDates.map((i) => i.value);
        val["blockedDates"] = skipDatesVal.join(",");
      }

      if (editableAppointment && iseditGroupTherapy) {
        val["id"] = editableAppointment.id;
        updateEvent({
          variables: val,
        });
      } else {
        createEvent({
          variables: val,
        });
      }
      return;
    }

    if (editableAppointment) {
      let title_edit = editableAppointment.title;
      if (selectedPatient) {
        title_edit =
          selectedPatient.firstName +
          " " +
          displayMiddleName(selectedPatient.middleName) +
          selectedPatient.lastName;
      }
      let val = {
        id: editableAppointment?.id,
        title: title_edit,
        description: values.descriptionEn ? values.descriptionEn : `-`,
        start: new Date(startDate).toISOString(),
        doctorEventType: values.session,
        patient: selectedPatient.id
          ? selectedPatient.id
          : editableAppointment?.patient?.id,
        doctor: editableAppointment.doctor.id,
        require_payment: values.require_payment,
        overrideValidation: overrideValidation,
      };

      if (showWaitingList) {
        val["waitinglist"] = showWaitingList;
      }
      if (showDiscount) {
        val["discount"] = values.discount_value;
      }
      if (
        referralObjectData &&
        referralObjectData.referralObj &&
        referralObjectData.referralObj.referral &&
        referralObjectData.referralObj.referral.id
      ) {
        val["referral"] = referralObjectData.referralObj.referral.id;
      }
      val["mainSlot"] = false;
      if (editableAppointment?.doctorEventType?.groupSession) {
        if (editableAppointment.parent && isGroupEditPatient) {
          val["parent"] = editableAppointment.parent.id;
        } else {
          val["parent"] = editableAppointment.id;
        }
      }

      if (
        selectedDateEvent?.dateEvent?.groupSession &&
        selectedDateEvent?.dateEvent?.appointmentID
      ) {
        val["parent"] = selectedDateEvent.dateEvent.appointmentID;
      }

      if (values.require_payment) {
        let paymentCutOffTime =
          selectedSession?.node.notification?.downPaymentNotificationCutoff;
        if (!paymentCutOffTime) {
          paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
        }
        if (paymentCutOffTime) {
          let currentTime = new Date();
          currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
          let proposedAppDate = new Date(startDate);
          if (proposedAppDate > currentTime) {
            if (val.patient && val.doctor && val.start && val.doctorEventType) {
              setIsAppointmentSubmitting(true);
              if (
                editableAppointment?.doctorEventType?.groupSession &&
                !editableAppointment.patient &&
                !iseditGroupTherapy
              ) {
                delete val["id"];

                createEvent({
                  variables: val,
                });
              } else {
                updateEvent({
                  variables: val,
                });
              }
              return;
            } else {
              alert("Please fill all fields");
            }
          } else {
            alert(
              "You are about to book an appointment that will be cancelled due to payment cut off time. Remove required payment to continue booking an appointment"
            );
          }
        }
      } else {
        if (val.patient && val.doctor && val.start && val.doctorEventType) {
          setIsAppointmentSubmitting(true);
          if (
            editableAppointment?.doctorEventType?.groupSession &&
            !editableAppointment.patient
          ) {
            delete val["id"];
            createEvent({
              variables: val,
            });
          } else {
            updateEvent({
              variables: val,
            });
          }
          return;
        } else {
          alert("Please fill all fields");
          return;
        }
      }
      return;
    }

    let title =
      selectedPatient.firstName +
      " " +
      displayMiddleName(selectedPatient.middleName) +
      selectedPatient.lastName;

    let val = {
      patient: selectedPatient.id,
      doctor: selectedDoctor.node.id,
      start: new Date(startDate).toISOString(),
      title: title,
      doctorEventType: values.session,
      description: values.descriptionEn ? values.descriptionEn : `-`,
      require_payment: values.require_payment,
      mainSlot: false,
      overrideValidation: overrideValidation,
      // onlineLink: values.onlineLink,
    };

    if (values.paymentTransferId)
      val["paymentTransferId"] = values.paymentTransferId;
    if (showWaitingList) {
      val["waitinglist"] = showWaitingList;
    }
    if (showDiscount) {
      val["discount"] = values.discount_value;
    }
    if (
      referralObjectData &&
      referralObjectData.referralObj &&
      referralObjectData.referralObj.referral &&
      referralObjectData.referralObj.referral.id
    ) {
      val["referral"] = referralObjectData.referralObj.referral.id;
    }

    if (
      selectedDateEvent?.dateEvent?.groupSession &&
      selectedDateEvent?.dateEvent?.appointmentID
    ) {
      val["parent"] = selectedDateEvent.dateEvent.appointmentID;
    }

    // new condition added to alert user of the downpayment cut off
    if (values.require_payment) {
      let paymentCutOffTime =
        selectedSession?.node.notification?.downPaymentNotificationCutoff;
      if (!paymentCutOffTime) {
        paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
      }
      if (paymentCutOffTime) {
        let currentTime = new Date();
        currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
        let proposedAppDate = new Date(startDate);
        if (proposedAppDate > currentTime) {
          if (val.patient && val.doctor && val.start && val.doctorEventType) {
            setIsAppointmentSubmitting(true);

            // If multiple dates exists
            if (selectedDates.length > 0) {
              let newVal = { ...val };

              for (let i = 0; i < selectedDates.length; i++) {
                const dateItem = selectedDates[i];
                newVal.start = new Date(dateItem).toISOString();

                await createNewEvent({
                  variables: newVal,
                });
              }
            }

            createEvent({
              variables: val,
            });
          } else {
            alert("Please fill all fields");
          }
        } else {
          alert(
            "You are about to book an appointment that will be cancelled due to payment cut off time. Remove required payment to continue booking an appointment"
          );
        }
      }
    } else {
      if (val.patient && val.doctor && val.start && val.doctorEventType) {
        setIsAppointmentSubmitting(true);
        if (selectedDates.length > 0) {
          let newVal = { ...val };

          for (let i = 0; i < selectedDates.length; i++) {
            const dateItem = selectedDates[i];
            newVal.start = new Date(dateItem).toISOString();

            await createNewEvent({
              variables: newVal,
            });
          }
        }

        createEvent({
          variables: val,
        });
      } else {
        alert("Please fill all fields");
      }
    }
    // new condition added to alert user of the downpayment cut off
  };

  const onSubmit = (values, { resetForm }) => {
    var overrideValidation = false;
    let returnAfterCheck = true;
    var createWithoutAlert = false;
    if (returnAfterCheck) {
      let recurringGroupArr = activeDocShiftArr.filter(
        (i) => i.groupId === "recurringGroupId"
      );
      let shiftGroupArr = activeDocShiftArr.filter(
        (i) => i.groupId === "shiftGroupId"
      );

      let recurringGroupEventsArr = availaleSlotsArrayDisplayInCal.filter(
        (i) => i?.doctorEventType?.groupSession
      );
      let createEventStartTime = new Date(startDate).toISOString();
      let createEventEndTime = new Date(startDate);
      if (selectedSession?.node?.duration + selectedSession?.node?.buffer) {
        createEventEndTime.setMinutes(
          createEventEndTime.getMinutes() +
            selectedSession?.node?.duration +
            selectedSession?.node?.buffer
        );
      }
      createEventEndTime = createEventEndTime.toISOString();

      let isWithinShift = shiftGroupArr.find(
        (i) =>
          new Date(i.start) <= new Date(createEventStartTime) &&
          new Date(createEventEndTime) <= new Date(i.end)
      );

      let hasOverlappingRecurringEvent = recurringGroupArr.find(
        (i) =>
          (new Date(i.start) <= new Date(createEventStartTime) &&
            new Date(createEventStartTime) <= new Date(i.end)) ||
          (new Date(i.start) < new Date(createEventEndTime) &&
            new Date(createEventEndTime) < new Date(i.end))
      );

      let hasOverlappingRecurringGroupEvent = recurringGroupEventsArr.find(
        (i) =>
          (new Date(i.start) <= new Date(createEventStartTime) &&
            new Date(createEventStartTime) <= new Date(i.end)) ||
          (new Date(i.start) < new Date(createEventEndTime) &&
            new Date(createEventEndTime) < new Date(i.end))
      );

      let hasOverlappingEvent = eventsDataMain.find(
        (i) =>
          (new Date(i.start) <= new Date(createEventStartTime) &&
            new Date(createEventStartTime) <= new Date(i.end)) ||
          (new Date(i.start) < new Date(createEventEndTime) &&
            new Date(createEventEndTime) < new Date(i.end))
      );

      if (
        isWithinShift &&
        !hasOverlappingRecurringEvent &&
        !hasOverlappingRecurringGroupEvent &&
        !hasOverlappingEvent
      ) {
        createWithoutAlert = true;
      }
    }
    if (!createWithoutAlert && isStaff) {
      const canOverride = original_permissions.includes("can_by_pass_schedule");

      if (canOverride) {
        if (
          window.confirm(
            "The practitioner is not available at this time. Are you sure you would like to proceed?"
          )
        ) {
          overrideValidation = true;
          createEventSubmitFunc(values, overrideValidation);
        }
      } else {
        alert("The practitioner is not available at this time.");
      }
    } else {
      createEventSubmitFunc(values, overrideValidation);
    }
  };

  const initialValues = {
    doctor: "",
    patient: "",
    start: "",
    date: "",
    title: "",
    descriptionEn: "",
    eventType: "",
    session: "",
    discount_value: "0",
    show_discount: false,
    require_payment: true,
    should_be_seen: "",
    recurring_group: "",
  };

  useEffect(() => {
    if (selectedDateEvent.dateEvent.start) {
      setSelectedDate(selectedDateEvent.dateEvent);
      // formatEventDate(selectedDateEvent.dateEvent,formikRef);
      let eventDate_st = new Date(selectedDateEvent.dateEvent.start);
      let eventDate_en = new Date(selectedDateEvent.dateEvent.end);
      let time_st = getForamttedTime(eventDate_st);
      let time_en = getForamttedTime(eventDate_en);

      let date = eventDate_st.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      formikRef.current.setFieldValue("start", time_st + " - " + time_en);
      formikRef.current.setFieldValue("date", date);

      let currentView = fullCalendarRef?.current?.getApi()?.view;
      let calDate = currentView.activeStart;
      if (calDate) {
        if (
          currentView.type !== "timeGridDay" ||
          new Date(selectedDateEvent.dateEvent.start).getDate() !==
            calDate.getDate()
        ) {
          fullCalendarRef?.current
            ?.getApi()
            .changeView("timeGridDay", selectedDateEvent.dateEvent.start);
        }
      }
      setStartDate(new Date(selectedDateEvent.dateEvent.start));
    } else if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue("date", "");
      formikRef.current.setFieldValue("start", "");
      currentSlotIndexVar(0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateEvent, formikRef]);

  useEffect(() => {
    if (doctor) {
      setSelectedDoctor({ node: doctor.doctor });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor]);

  const getDoctorSessionDetails = (session, values) => {
    if (selectedDoctor) {
      let selected_session = selectedSession;
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              {t("createEventForm.location")}:{" "}
              {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              {t("createEventForm.duration")}: {selected_session.node.duration}{" "}
              {t("createEventForm.mins")}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              {t("createEventForm.price")}:{" "}
              {values.discount_value > 0 && showDiscount
                ? getDiscountPrice(
                    values.discount_value,
                    selected_session.node.price
                  )
                : getDiscountPrice(0, selected_session.node.price)}{" "}
              KWD
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const handleDiscountChange = (e) => {
    if (e.target.value <= 100) {
      formikRef.current.setFieldValue("discount_value", e.target.value);
    }
  };

  const getDiscountPrice = (discount_value, price) => {
    let disc = (discount_value / 100) * price;
    return parseFloat(price - disc).toFixed(2);
  };

  const handleService = (event) => {
    let value = event.target.value;
    let serviceObjectArray = serviceList.filter((item) => {
      return item.node.id == value;
    });
    let service = serviceObjectArray[0]?.node;
    formikRef.current.setFieldValue("service", value);
    let val = {
      ...event.target.value,
    };
    setSelectedDoctor(null);
    getDoctorsListFromService({ ...service });
    formikRef.current.setFieldValue("doctor", "");
    formikRef.current.setFieldValue("date", "");
    formikRef.current.setFieldValue("start", "");
    calendarSlotsVar(null);
    setpatientCurrentServiceSelected(service);
  };

  const validate = (values) => {
    let errors = {};
    if (
      values.onlineLink &&
      values.onlineLink.substr(0, 7) !== "http://" &&
      values.onlineLink.substr(0, 8) !== "https://"
    ) {
      errors.onlineLink = `Wrong format`;
    }
    return errors;
  };

  const isSubmitDisabled = (formikFormValues) => {
    if (
      isStaff &&
      formikFormValues &&
      formikFormValues.doctor &&
      formikFormValues.patient &&
      startDate &&
      formikFormValues.session
    ) {
      if (formikFormValues.require_payment) {
        let paymentCutOffTime =
          selectedSession?.node.notification?.downPaymentNotificationCutoff;
        if (!paymentCutOffTime) {
          paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
        }
        if (paymentCutOffTime) {
          let currentTime = new Date();
          currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
          currentTime.setMinutes(currentTime.getMinutes() + 30);
          let proposedAppDate = new Date(startDate);
          if (proposedAppDate > currentTime) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    } else if (
      !isStaff &&
      formikFormValues &&
      formikFormValues.doctor &&
      startDate &&
      formikFormValues.session
    ) {
      return false;
    } else if (isGroupAppointment) {
      if (
        formikFormValues &&
        formikFormValues.doctor &&
        formikFormValues.session &&
        startDate
      ) {
        return false;
      }
    }
    return true;
  };

  const displayCalendarDay = (date) => {
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        if (selectedSession?.node?.duration && date) {
          let dd = String(date.getDate()).padStart(2, "0");
          let mm = String(date.getMonth() + 1).padStart(2, "0");
          let yy = date.getFullYear();
          let h = String(date.getHours()).padStart(2, "0");
          let m = String(date.getMinutes()).padStart(2, "0");
          let datePickerDate = yy + "-" + mm + "-" + dd;
          let calendarDateObj =
            fullCalendarRef?.current?.getApi()?.view?.activeStart;

          if (calendarDateObj) {
            let ddC = String(calendarDateObj.getDate()).padStart(2, "0");
            let mmC = String(calendarDateObj.getMonth() + 1).padStart(2, "0");
            let yyC = date.getFullYear();
            let calendarDateStr = yyC + "-" + mmC + "-" + ddC;

            if (calendarDateStr && calendarDateStr !== datePickerDate) {
              fullCalendarRef?.current
                ?.getApi()
                ?.changeView("timeGridDay", date.toISOString());
            }
          }

          let slotEnd = new Date(date);
          slotEnd.setMinutes(
            slotEnd.getMinutes() + selectedSession.node.duration
          );
          let enH = String(slotEnd.getHours()).padStart(2, "0");
          let enM = String(slotEnd.getMinutes()).padStart(2, "0");

          let eventTitle =
            selectedPatient?.firstName +
            " " +
            displayMiddleName(selectedPatient?.middleName) +
            selectedPatient?.lastName;
          if (editableAppointment) {
            eventTitle = editableAppointment.title;
          }
          let slotObj = {
            title: isGroupAppointment
              ? selectedSession?.node?.title?.name
              : eventTitle,
            start: datePickerDate + "T" + h + ":" + m,
            end: datePickerDate + "T" + enH + ":" + enM,
            resourceId: selectedDoctor?.node?.id,
            groupId: "potentialSlot",
          };
          potentialAppointmentSlotVar([slotObj]);
        }
      }, 1000)
    );
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        innerRef={formikRef}
        validate={validate}
      >
        {({ handleSubmit, handleChange, values, handleReset, errors }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Container>
              {isStaff && !isGroupAppointment ? (
                <Form.Group as={Row} className="text-right mb-4">
                  <Form.Label column sm={4} md={4}>
                    Patient
                  </Form.Label>
                  <Col
                    sm={8}
                    md={8}
                    className={style.form__search}
                    ref={searchPatientRef}
                  >
                    <Form.Control
                      required
                      autoComplete="off"
                      placeholder="Select Patient"
                      type="text"
                      name="patient"
                      disabled={fromReferral}
                      value={values.patient}
                      onChange={handleChange}
                      className={`mr-sm-2 pr-0 float-left pr-5 ${style.bg_img_none}`}
                    />
                    <span
                      onClick={() => {
                        setShowPatientSearch(!fromReferral);
                      }}
                      className="search-icon"
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </Col>
                </Form.Group>
              ) : null}
              {!isStaff ? (
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4}>
                    {t("createEventForm.service")}
                  </Form.Label>
                  <Col sm={8} md={8} className="d-flex align-items-center">
                    <Form.Control
                      className={style.bg_img_none}
                      autoComplete="off"
                      as="select"
                      name="service"
                      value={values.service}
                      // disabled={editableAppointment ? true : false}
                      onChange={(value) => {
                        handleService(value);
                      }}
                      id="service-test"
                      required
                    >
                      <option value="">
                        {t("createEventForm.selectService")}
                      </option>
                      {serviceList &&
                        serviceList.map((service) => {
                          return (
                            <option
                              value={service.node.id}
                              key={service.node.id}
                            >
                              {service.node.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              ) : null}
              {referralObjectData?.referralObj?.referral?.recommendedDate ? (
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4}>
                    {t("createEventForm.shouldBeSeen")}
                  </Form.Label>
                  <Col sm={8} md={8} className="d-flex align-items-center">
                    <h6 className="text-left mb-0">
                      {getDate(
                        new Date(
                          referralObjectData.referralObj.referral.recommendedDate
                        )
                      )}
                    </h6>
                  </Col>
                </Form.Group>
              ) : null}
              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4} className="required">
                  {t("createEventForm.practitioner")}
                </Form.Label>
                <Col
                  sm={8}
                  md={8}
                  className={!isStaff ? "" : "d-flex align-items-center"}
                >
                  <Form.Control
                    className={style.bg_img_none}
                    autoComplete="off"
                    as="select"
                    name="doctor"
                    id="appointment-doc"
                    value={values.doctor}
                    disabled={fromReferral}
                    onChange={handleDoctor}
                    required
                  >
                    <option value="">
                      {t("createEventForm.selectPractitioner")}
                    </option>
                    {isStaff
                      ? eventData.doctors &&
                        eventData.doctors.edges.map((doctor) => {
                          return (
                            <option
                              value={doctor.node.identifier}
                              key={doctor.node.id}
                            >
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })
                      : null}
                    {!isStaff && values.service
                      ? eventData?.doctors?.edges?.length > 0 &&
                        eventData.doctors.edges.map((doctor) => {
                          return (
                            <option
                              value={doctor.node.identifier}
                              key={doctor.node.id}
                            >
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })
                      : null}
                  </Form.Control>
                  {!isStaff && selectedDoctor && values.session
                    ? getDoctorSessionDetails(values.session, values)
                    : null}
                </Col>
              </Form.Group>
              {isStaff ? (
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4} className="required">
                    Practitioner Session{" "}
                    {loadingDoctors && <Spinner animation="border" size="sm" />}
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      className={style.bg_img_none}
                      required
                      as="select"
                      name="session"
                      id="doctor-session"
                      value={values.session}
                      disabled={fromReferral || loadingDoctors}
                      onChange={handleSession}
                    >
                      <option value="">Select Session</option>
                      {selectedDoctor
                        ? selectedDoctor?.node?.eventType?.edges?.map(
                            (eventtype) => {
                              if (isGroupAppointment) {
                                if (eventtype.node.groupSession) {
                                  return (
                                    <option
                                      value={eventtype.node.id}
                                      key={eventtype.node.id}
                                    >
                                      {eventtype.node.title.name}
                                    </option>
                                  );
                                }
                              } else {
                                return (
                                  <option
                                    value={eventtype.node.id}
                                    key={eventtype.node.id}
                                  >
                                    {eventtype.node.title.name}
                                  </option>
                                );
                              }
                            }
                          )
                        : null}
                    </Form.Control>
                    {selectedDoctor && values.session
                      ? getDoctorSessionDetails(values.session, values)
                      : null}
                  </Col>
                </Form.Group>
              ) : null}

              {!isStaff ? (
                <>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={4} className="required">
                      {t("createEventForm.date")}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        onChange={(event) => event.preventDefault()}
                        required
                        autoComplete="off"
                        id="next-event-date"
                        data-readonly
                        type="text"
                        name="date"
                        disabled={
                          isGroupAddPatient ||
                          (iseditGroupTherapy && isGroupAppointment)
                        }
                        value={values.date}
                        className={`mr-sm-2  ${style.bg_img_none}`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={4} className="required">
                      {t("createEventForm.time")}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        required
                        autoComplete="off"
                        data-readonly
                        disabled={
                          isGroupAddPatient ||
                          (iseditGroupTherapy && isGroupAppointment)
                        }
                        onChange={(event) => event.preventDefault()}
                        type="text"
                        name="start"
                        value={values.start}
                        className={`mr-sm-2  ${style.bg_img_none}`}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : null}
              {isStaff ? (
                <>
                  <Form.Group
                    as={Row}
                    className="text-right create-event-datepicker"
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Label column sm={4} md={4} className="required">
                      {t("createEventForm.date")} & {t("createEventForm.time")}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          displayCalendarDay(date);
                        }}
                        shouldCloseOnSelect={false}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    </Col>
                  </Form.Group>
                  {!isGroupAppointment && (
                    <DatePickerList onDateChange={handleDateChange} />
                  )}
                </>
              ) : null}

              {isStaff ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={4} md={4} className="text-right ">
                    {t("createEventForm.paymentTransferId")}
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      as="input"
                      name="paymentTransferId"
                      value={values.paymentTransferId}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              {!isStaff ? (
                <Form.Group as={Row}>
                  <Col sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}>
                    <Button
                      className={style.form__button + " mb-2 "}
                      variant="primary"
                      id="prev-appointment"
                      onClick={() => onChoseAppointment("prev")}
                      disabled={
                        ((!selectedDoctor || !selectedSession) &&
                          (!referralObjectData ||
                            !referralObjectData.referralObj)) ||
                        (iseditGroupTherapy && isGroupAppointment) ||
                        isGroupAddPatient
                      }
                      size="sm"
                    >
                      {t("createEventForm.previous")}
                    </Button>
                  </Col>
                  <Col sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}>
                    <Button
                      className={style.form__button + " mb-2 "}
                      variant="primary"
                      id="next-appointment"
                      disabled={
                        ((!selectedDoctor || !selectedSession) &&
                          (!referralObjectData ||
                            !referralObjectData.referralObj)) ||
                        (iseditGroupTherapy && isGroupAppointment) ||
                        isGroupAddPatient
                      }
                      onClick={() => onChoseAppointment("next")}
                      size="sm"
                    >
                      {t("createEventForm.nextAvailable")}
                    </Button>
                  </Col>
                </Form.Group>
              ) : null}
              {isGroupAppointment ? (
                <>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right required"
                    >
                      {t("createEventForm.slotsAvailable")}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        required
                        disabled={iseditGroupTherapy && isGroupAppointment}
                        name="slotsAvailable"
                        value={values.slotsAvailable}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start position-relative"
                  >
                    <Col
                      className={
                        style.form__waiting_list + " text-left d-flex ml-auto"
                      }
                      xs={8}
                      sm={5}
                      md={9}
                      lg={9}
                      xl={8}
                    >
                      <Form.Check
                        className="pl-0 text-left ml-4"
                        type="checkbox"
                        name="recurring_group"
                        id="requireGroup"
                        onChange={handleChange}
                        disabled={iseditGroupTherapy && isGroupAppointment}
                        value={values.recurring_group}
                        checked={values.recurring_group}
                      />
                      <Form.Label>Recurring</Form.Label>
                    </Col>
                  </Form.Group>
                  {startDate ? (
                    <Form.Group
                      as={Row}
                      className={values.recurring_group ? " d-flex" : "d-none"}
                    >
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        lg={4}
                        className="text-right pr-0 required"
                      >
                        From
                      </Form.Label>
                      <Col sm={12} md={8} lg={8}>
                        <DatePicker
                          autoComplete="off"
                          selected={new Date(startDate)}
                          disabled={true}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group
                    as={Row}
                    className={values.recurring_group ? " d-flex" : "d-none"}
                  >
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      To
                    </Form.Label>
                    <Col sm={12} md={8} lg={8}>
                      <DatePicker
                        autoComplete="off"
                        selected={endDate}
                        minDate={
                          editableAppointment?.earliestEndDate
                            ? new Date(editableAppointment.earliestEndDate)
                            : new Date(startDate)
                        }
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                        dateFormat="dd/MMM/yyyy"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className={values.recurring_group ? " d-flex" : "d-none"}
                  >
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 required"
                    >
                      <span className="">Select Days</span>
                    </Form.Label>
                    <Col sm={8} md={8} className={"dropdownOnTop"}>
                      <MultiSelect
                        options={daysArr}
                        value={recurringGroupDays}
                        required
                        disabled={iseditGroupTherapy && isGroupAppointment}
                        onChange={setRecurringGroupDays}
                        labelledBy="Select"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className={values.recurring_group ? " d-flex" : "d-none"}
                  >
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0 "
                    >
                      <span className="">Skip Dates</span>
                    </Form.Label>
                    <Col sm={8} md={8} className={"dropdownOnTop"}>
                      <MultiSelect
                        options={allowedSkipDates}
                        value={skipDates}
                        onChange={setSkipDates}
                        labelledBy="Select"
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : null}
              {!isGroupAppointment ? (
                <Form.Group
                  as={Row}
                  className="d-flex justify-content-start position-relative"
                >
                  <Col
                    className={style.form__waiting_list + " text-right "}
                    xs={8}
                    sm={5}
                    md={9}
                    lg={9}
                    xl={7}
                  >
                    <Form.Label>
                      {t("createEventForm.addToWaitingList")}
                    </Form.Label>
                  </Col>
                  <Col
                    xs={2}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={3}
                    className={`${style.checkbox__container} pst-absolute-160px`}
                  >
                    <Form.Check
                      className="pl-0"
                      type="checkbox"
                      value={showWaitingList}
                      checked={showWaitingList}
                      onChange={() => setShowWaitingList(!showWaitingList)}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {isStaff
                            ? t(
                                "If an earlier appointment slot becomes available, notify this person may be selected to fill that slot"
                              )
                            : t(
                                "If an earlier appointment becomes available, you will be notified"
                              )}
                        </Tooltip>
                      }
                    >
                      <div className={style.tooltip__element}>i</div>
                    </OverlayTrigger>
                  </Col>
                </Form.Group>
              ) : null}

              {isStaff && !isGroupAppointment ? (
                <Form.Group
                  as={Row}
                  className="justify-content-start position-relative"
                >
                  <Col
                    className={style.form__waiting_list + " text-right "}
                    xs={8}
                    sm={5}
                    md={9}
                    lg={9}
                    xl={7}
                  >
                    <Form.Label>Require Payment</Form.Label>
                  </Col>
                  <Col
                    xs={4}
                    sm={7}
                    md={3}
                    lg={3}
                    xl={5}
                    className={`${style.checkbox__container} pst-absolute-160px`}
                  >
                    <Form.Check
                      className="pl-0"
                      type="checkbox"
                      name="require_payment"
                      id="requirePayment"
                      onChange={handleChange}
                      value={values.require_payment}
                      checked={values.require_payment}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              {isStaff && !isGroupAppointment ? (
                <Form.Group
                  as={Row}
                  className="justify-content-start position-relative"
                >
                  <Col
                    className={style.form__waiting_list + " text-right "}
                    xs={8}
                    sm={5}
                    md={9}
                    lg={9}
                    xl={7}
                  >
                    <Form.Label>Apply Discount</Form.Label>
                  </Col>
                  <Col
                    xs={4}
                    sm={7}
                    md={3}
                    lg={3}
                    xl={5}
                    className={`${style.checkbox__container} pst-absolute-160px`}
                  >
                    <Form.Check
                      className="pl-0"
                      type="checkbox"
                      value={showDiscount}
                      checked={showDiscount}
                      onChange={() => setShowDiscount(!showDiscount)}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              {showDiscount && isStaff && !isGroupAppointment ? (
                <Form.Group as={Row} className="text-right">
                  <Form.Label
                    column
                    sm={4}
                    md={9}
                    lg={9}
                    xl={7}
                    className="text-right"
                  >
                    Discount %
                  </Form.Label>
                  <Col sm={8} md={3} lg={3} xl={5} className="px-0">
                    <Form.Control
                      type="text"
                      name="discount_value"
                      autoComplete="discount_value"
                      onChange={handleDiscountChange}
                      value={values.discount_value ? values.discount_value : ""}
                    />
                  </Col>
                </Form.Group>
              ) : null}

              {!isGroupAppointment ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12}>
                    {isStaff
                      ? "Notes about the session"
                      : t("createEventForm.reasonForAppointmentRequest")}
                  </Form.Label>
                  <Col sm={12} md={12}>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={5}
                      maxLength={!isStaff ? "300" : null}
                      name="descriptionEn"
                      value={values.descriptionEn}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              ) : null}

              <Form.Group className="justify-content-between d-flex">
                <Button
                  className={style.form__button}
                  variant="primary"
                  onClick={() => onCancelForm(handleReset)}
                  size="sm"
                  id="cancel-appt-test"
                >
                  {t("createEventForm.cancel")}
                </Button>
                <Button
                  className={style.form__button}
                  variant="primary"
                  type="submit"
                  size="sm"
                  disabled={isSubmitDisabled(values)}
                  id="submit-appt-test"
                >
                  {isAppointmentSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : isStaff ? (
                    " Save "
                  ) : (
                    t("createEventForm.submit")
                  )}
                </Button>
              </Form.Group>
            </Container>
          </Form>
        )}
      </Formik>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </>
  );
};

export default withRouter(CreateEventForm);
