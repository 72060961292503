import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Table, Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  success_options,
  PER_PAGE_COUNT,
  urlActions,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers";
import { Formik } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";

function ManageRefundRequests(props) {
  const { history } = props;
  // const formikRef = useRef();
  const [startDate, setStartDate] = useState("");
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }
  }, []);

  const REFUND_REQUESTS = gql`
    query{
      refundRequestWithApproval(first:${PER_PAGE_COUNT}, offset:${
    PER_PAGE_COUNT * (activePage - 1)
  }) {
        totalCount
        edges{
          node{
            id
            amount
            status
            displayStatus
            staffNotes
            notes
            dateRefunded
            refundId
            requiresApproval
            created
            paymentModel {
              patient {
                firstName
                lastName
              }
            }
            appointment {
              start
            }
          }
        }
      }
    }
  `;

  const { data: refund_request_obj = null, loading } = useQuery(
    REFUND_REQUESTS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REFUND_REQUESTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const REFUND_REQUEST_APPROVAL = gql`
    mutation refundRequestApproval($refundRequest: ID!, $status: String!) {
      refundRequestApproval(
        input: { refundRequest: $refundRequest, status: $status }
      ) {
        obj {
          created
          id
          status
          displayStatus
          staffNotes
          displayApprovalStatus
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const [refundRequestApproval] = useMutation(REFUND_REQUEST_APPROVAL, {
    onCompleted: ({ refundRequestApproval }) => {
      if (
        refundRequestApproval?.errors &&
        refundRequestApproval?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          refundRequestApproval?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = refundRequestApproval.obj.displayApprovalStatus;
        // setTimeout(() => {
        openSnackbarSuccess("Refund Request " + status, [SNACK_DURATION]);
        // }, 1000);
      }
    },
    refetchQueries: [{ query: REFUND_REQUESTS, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REFUND_REQUEST_APPROVAL error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const submitRequest = (refund_obj, approve) => {
    if (approve === true) {
      let val = {
        refundRequest: refund_obj.id,
        status: "accepted",
      };
      refundRequestApproval({ variables: val });
    } else {
      if (window.confirm("Cancel Refund Request?")) {
        let val = {
          refundRequest: refund_obj.id,
          status: "declined",
        };
        refundRequestApproval({ variables: val });
      }
    }
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    history.push("/manage/refund/requests?page=" + number);
  };

  const renderPages = () => {
    let total_pages =
      refund_request_obj &&
      refund_request_obj.refundRequestWithApproval &&
      refund_request_obj.refundRequestWithApproval.totalCount
        ? Math.ceil(
            refund_request_obj.refundRequestWithApproval.totalCount /
              PER_PAGE_COUNT
          )
        : 1;
    let items = [];
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const getStatus = (status) => {
    if (status === "DECLINED") {
      return "DECLINED";
    } else {
      return status;
    }
  };

  return (
    <Base title={"MANAGE REFUND REQUESTS"} showHeader={true}>
      <Row>
        <Table responsive className=" table-borderless">
          <thead>
            <tr className="patient_table_row tableHeader">
              <th scope="col " className="text-uppercase">
                NAME
              </th>
              <th scope="col " className="text-uppercase">
                APPOINTMENT DATE
              </th>
              <th scope="col " className="text-uppercase">
                REFUND REQUEST DATE
              </th>
              <th scope="col " className="text-uppercase">
                STAFF NOTES
              </th>
              <th scope="col " className="text-uppercase">
                AMOUNT
              </th>
              <th scope="col " className="text-uppercase">
                STATUS
              </th>
              <th scope="col " className="text-uppercase text-center">
                APPROVE/DENY
              </th>
            </tr>
          </thead>
          <tbody>
            {refund_request_obj &&
            refund_request_obj.refundRequestWithApproval &&
            refund_request_obj.refundRequestWithApproval.edges.length > 0 ? (
              refund_request_obj.refundRequestWithApproval.edges.map(
                (refund, index) => {
                  let refundObj = refund.node;
                  return (
                    <tr key={index} className="patient_table_row">
                      <td>
                        {" "}
                        <Link to={"/refund/request/" + refundObj?.id}>
                          {" "}
                          {refundObj?.paymentModel?.patient?.firstName}{" "}
                          {refundObj?.paymentModel?.patient?.lastName}{" "}
                        </Link>{" "}
                      </td>
                      <td>
                        {refundObj?.appointment != null
                          ? getDate(new Date(refundObj?.appointment?.start))
                          : "No Appointment Available"}
                      </td>
                      <td>{getDate(new Date(refundObj?.created))}</td>
                      <td>
                        {refundObj?.staffNotes ? refundObj?.staffNotes : "-"}
                      </td>
                      <td>{refundObj?.amount}</td>
                      <td>{refundObj?.displayStatus}</td>
                      {refundObj?.status === "PENDING" ||
                      refundObj?.status === "OFFLINE_REFUND" ? (
                        <td className="icon">
                          <Button variant="link" className="py-0">
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              onClick={() => submitRequest(refundObj, true)}
                            />{" "}
                          </Button>
                          <Button variant="link" className="py-0">
                            {" "}
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={() => submitRequest(refundObj, false)}
                            />{" "}
                          </Button>
                        </td>
                      ) : (
                        <td className="text-center py-4">
                          {" "}
                          <span> {getStatus(refundObj?.status)} </span>
                        </td>
                      )}
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No Leaves Requested</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      {refund_request_obj &&
      refund_request_obj.refundRequestWithApproval &&
      refund_request_obj.refundRequestWithApproval.totalCount >
        PER_PAGE_COUNT ? (
        <div className="d-flex justify-content-center pt-5">
          <Pagination>{renderPages()}</Pagination>
        </div>
      ) : null}
    </Base>
  );
}
export default ManageRefundRequests;
