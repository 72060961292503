//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { convertApolloNodeToFlatArray } from "Common/helpers";
import { client } from "apolloSettings";
import {
  DOCTOR_SERVICE,
  PATIENT_SERVICES,
  SERVICE_DOCTORS,
  GET_PATIENT_AVAILABLE_SLOTS,
  CREATE_EVENT_PATIENT,
} from "redux/actions/graphqlQueries/appointment";

export const initialState = {
  loading: false,
  services: [],
  doctors: [],
  error: null,
  serviceFilters: null,
  slideCounter: 0,
  buttonLoading: false,
  completedSteps: [] as number[],
  availableSlots: [],
  stepsData: [
    { title: "service", data: {} },
    { title: "practitioner", data: {} },
    { title: "date", data: {} },
    { title: "waiting", data: {} },
  ],
  createdEvent: null,
  waitingList: [],
  patientReferral: null,
};

export const getPublicServices = createAsyncThunk(
  "appointment/getPublicServices",
  async (variables?: {}, thunkAPI?: any) => {
    const { serviceFilters } = thunkAPI.getState().rootAppointmentSlice;
    try {
      const response = await client.query({
        query: PATIENT_SERVICES,
        variables:
          {
            ...serviceFilters,
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return convertApolloNodeToFlatArray(response.data.services.edges);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getServiceDoctors = createAsyncThunk(
  "appointment/getServiceDoctors",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.query({
        query: SERVICE_DOCTORS,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return convertApolloNodeToFlatArray(response.data.doctors.edges);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSelectedService = createAsyncThunk(
  "appointment/getSelectedService",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.query({
        query: DOCTOR_SERVICE,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.doctorService;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSlots = createAsyncThunk(
  "appointment/getSlots",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.query({
        query: GET_PATIENT_AVAILABLE_SLOTS,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });      
      return response?.data?.getPatientAvailableSchedules || [];
    } catch (error: any) {      
      return [];
    }
  }
);

export const createPatientEvent = createAsyncThunk(
  "appointment/createPatientEvent",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_EVENT_PATIENT,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache",
      });
      return response.data.createEvent;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState,
  reducers: {
    incrementSlideCounter: (state) => {
      state.slideCounter += 1;
      const newStep = state.slideCounter;
      if (!state.completedSteps.includes(newStep)) {
        state.completedSteps.push(newStep);
      }
    },
    decrementSlideCounter: (state) => {
      state.slideCounter = Math.max(0, state.slideCounter - 1);
    },
    selectSlide: (state, action: PayloadAction<number>) => {
      state.slideCounter = action.payload;
    },
    updateService: (state, action: PayloadAction<any>) => {
      const itemToUpdateIndex = state.stepsData.findIndex(
        (item) => item.title === "service"
      );

      if (itemToUpdateIndex !== -1) {
        // Directly update the data of the item using Immer
        state.stepsData[itemToUpdateIndex].data = action.payload;
      }
    },
    updatePractitioner: (state, action: PayloadAction<string>) => {
      const itemToUpdateIndex = state.stepsData.findIndex(
        (item) => item.title === "practitioner"
      );

      if (itemToUpdateIndex !== -1) {
        // Directly update the data of the item using Immer
        //@ts-ignore
        state.stepsData[itemToUpdateIndex].data.doctor = action.payload;
      }
    },

    updateAppointmenTime: (state, action: PayloadAction<string>) => {
      const itemToUpdateIndex = state.stepsData.findIndex(
        (item) => item.title === "date"
      );

      if (itemToUpdateIndex !== -1) {
        // Directly update the data of the item using Immer
        //@ts-ignore
        state.stepsData[itemToUpdateIndex].data = action.payload;
      }
    },
    updateWaitingList: (state, action: PayloadAction<string>) => {
      const itemToUpdateIndex = state.stepsData.findIndex(
        (item) => item.title === "waiting"
      );

      if (itemToUpdateIndex !== -1) {
        // Directly update the data of the item using Immer
        //@ts-ignore
        state.stepsData[itemToUpdateIndex].data = action.payload;
      }
    },
    resetState: () => initialState,
    resetItemStepsData: (state, action: PayloadAction<number>) => {
      const title = action.payload;
      let dataToClear = [];

      if (title === "practitioner") {
        dataToClear = ["practitioner", "date", "waiting"];
        state.completedSteps = [1];
      } else if (title === "date") {
        dataToClear = ["date", "waiting"];
        state.completedSteps = [1, 2];
      } else if (title === "waiting") {
        dataToClear = ["waiting"];
        state.completedSteps = [1, 2, 3];
      }

      for (const itemTitle in dataToClear) {
        const itemToUpdateIndex = state.stepsData.findIndex(
          //@ts-ignore
          (item) => item.title === dataToClear[itemTitle]
        );

        if (itemToUpdateIndex !== -1) {
          // Directly update the data of the item using Immer
          //@ts-ignore
          state.stepsData[itemToUpdateIndex].data = {};
        }
      }
    },
    setPatientReferral: (state, action: PayloadAction<number>) => {
      state.patientReferral = action.payload;
    },
    setCompletedSteps: (state, action: PayloadAction<number>) => {
      state.completedSteps = action.payload;
    },
    setSlideCounter: (state, action: PayloadAction<number>) => {
      state.slideCounter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPublicServices.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.services = action.payload;
        }
      )
      .addCase(
        getPublicServices.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getServiceDoctors.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getServiceDoctors.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.doctors = action.payload;
        }
      )
      .addCase(
        getServiceDoctors.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(getSelectedService.pending, (state) => {
        state.buttonLoading = true;
      })
      .addCase(
        getSelectedService.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.buttonLoading = false;
          const itemToUpdateIndex = state.stepsData.findIndex(
            (item) => item.title === "practitioner"
          );

          if (itemToUpdateIndex !== -1) {
            // Directly update the data of the item using Immer
            //@ts-ignore
            state.stepsData[itemToUpdateIndex].data.service = action.payload;
          }
        }
      )
      .addCase(
        getSelectedService.rejected,
        (state, action: PayloadAction<any>) => {
          state.buttonLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(getSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSlots.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.availableSlots = action.payload;
      })
      .addCase(getSlots.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createPatientEvent.pending, (state) => {
        state.buttonLoading = true;
      })
      .addCase(
        createPatientEvent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.buttonLoading = false;
          state.createdEvent = action.payload;
        }
      )
      .addCase(
        createPatientEvent.rejected,
        (state, action: PayloadAction<any>) => {
          state.buttonLoading = false;
          state.error = action.payload;
        }
      );
  },
});

// export const {} = appointmentSlice.actions;
export const {
  incrementSlideCounter,
  decrementSlideCounter,
  selectSlide,
  updateService,
  updatePractitioner,
  updateAppointmenTime,
  updateWaitingList,
  resetState,
  resetItemStepsData,
  setPatientReferral,
  setCompletedSteps,
  setSlideCounter,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
