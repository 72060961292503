import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getErrorMessage,
  concatAllErrors,
  deepCopy,
} from "../../Common/helpers";
import { gql, useQuery, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

function Labs(props) {
  const {
    setShowModal,
    patient_id,
    labInitialValues,
    updateDetailPage,
    fromEncounter,
    history,
  } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [description, setDescription] = useState("");
  const [specificTestsArr, setSpecificTestsArr] = useState([]);
  const [profileTestsArr, setProfileTestsArr] = useState([]);
  const [dummy, setDummy] = useState(false);
  const [showTemplateDetail, setShowTemplateDetail] = useState(false);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [testTemplatesObj, setTestTemplate] = useState(null);

  useEffect(() => {
    if (labInitialValues) {
      setDescription(labInitialValues.description);
      setSelectedTemplate(labInitialValues.selectedTemplate);
      setShowTemplateDetail(true);
      setSpecificTestsArr(labInitialValues.specificTestReqArr);
      setProfileTestsArr(labInitialValues.profileTestReqArr);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labInitialValues]);

  const REQUEST_TEST_TEMPLATES = gql`
    query {
      testTemplates {
        edges {
          node {
            id
            name
            staticId
            testCategories {
              edges {
                node {
                  id
                  name
                  staticId
                  structure
                  testType
                  parent {
                    name
                  }
                  children {
                    edges {
                      node {
                        id
                        name
                        staticId
                        labTests {
                          edges {
                            node {
                              staticId
                              name
                            }
                          }
                        }
                      }
                    }
                  }

                  labTests {
                    edges {
                      node {
                        staticId
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(REQUEST_TEST_TEMPLATES, {
    fetchPolicy: "network-only",
    onCompleted: (rawData) => {
      setTestTemplate(deepCopy(rawData));
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_TEST_TEMPLATES error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const CREATE_UPDATE_LAB_REQUEST = gql`
    mutation createUpdateLabRequest(
      $id: ID
      $patient: ID!
      $usedTemplate: ID!
      $description: String!
      $specificTests: [ID]
      $profileTests: [ID]
    ) {
      createUpdateLabRequest(
        input: {
          id: $id
          usedTemplate: $usedTemplate
          patient: $patient
          description: $description
          specificTests: $specificTests
          groupTests: $profileTests
        }
      ) {
        obj {
          created
          id
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const [createUpdateLabRequest] = useMutation(CREATE_UPDATE_LAB_REQUEST, {
    onCompleted: ({ createUpdateLabRequest }) => {
      if (
        createUpdateLabRequest?.errors &&
        createUpdateLabRequest?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdateLabRequest?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (createUpdateLabRequest.obj) {
        if (fromEncounter) {
          history.push(
            "/patient/labrequests/detail/" + createUpdateLabRequest.obj.id
          );
        }
        setShowModal(false);
        if (updateDetailPage) {
          updateDetailPage();
          openSnackbarSuccess("Lab Request Updated");
        } else {
          openSnackbarSuccess("Lab Request Created");
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("CREATE_UPDATE_LAB_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const onSubmitLabReq = () => {
    let val = {
      patient: patient_id,
      description: description,
      usedTemplate: selectedTemplate.node.staticId,
      specificTests:
        specificTestsArr.length > 0
          ? specificTestsArr.map((item) =>
              item.includes("_") ? item.split("_")[1] : item
            )
          : null,
      profileTests: profileTestsArr.length > 0 ? profileTestsArr : null,
    };
    if (labInitialValues) {
      val["id"] = labInitialValues.id;
    }
    createUpdateLabRequest({ variables: val });
  };

  const handleCheck = (staticId, category_block) => {
    let arr = category_block === "profile" ? profileTestsArr : specificTestsArr;

    let specId = ""; // extract spec if from 'parent_specID' format if staticId is of that format during edit of lab req
    if (staticId.indexOf("_") > -1) {
      specId = staticId.split("_")[1];
    }

    if (arr.includes(staticId) || arr.includes(specId)) {
      arr = arr.filter((i) => i !== staticId);
      if (specId) {
        arr = arr.filter((i) => i !== specId);
      }
    } else {
      arr.push(staticId);
    }
    if (category_block === "profile") {
      setProfileTestsArr(arr);
      setDummy(!dummy);
    } else {
      setDummy(!dummy);
      setSpecificTestsArr(arr);
    }
  };

  useEffect(() => {
    if (selectedTemplate && !labInitialValues) {
      let normalTests = selectedTemplate.node.testCategories.edges.filter(
        (i) => i.node.testType === "NORMAL"
      );
      let profileTests = selectedTemplate.node.testCategories.edges.filter(
        (i) => i.node.testType === "PROFILE"
      );
      let normalTestsArr = [];
      let profileTestsArr = [];
      normalTestsArr = normalTests.map((item) => {
        if (item?.node?.children?.edges.length > 0) {
          let dataToReturn = item?.node?.children?.edges.map((child) =>
            child.node.labTests.edges.map((i) => i.node.staticId)
          );
          return [].concat.apply([], dataToReturn);
        } else {
          return item.node.labTests.edges.map((i) => i.node.staticId);
        }
      });
      profileTestsArr = profileTests.map((item) => {
        if (item.node.structure === "standalone") {
          return item.node.staticId;
        } else if (item?.node?.children?.edges.length > 0) {
          let dataToReturn = item?.node?.children?.edges.map(
            (child) => child.node.staticId
          );
          return [].concat.apply([], dataToReturn);
        }
      });
      var mergedNormal = [].concat.apply([], normalTestsArr);
      var mergedProfile = [].concat.apply([], profileTestsArr);
      setSpecificTestsArr(mergedNormal);
      setProfileTestsArr(mergedProfile);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, labInitialValues]);

  const renderTemplateOne = () => {
    let testNormalCategoryArr =
      selectedTemplate &&
      selectedTemplate.node.testCategories &&
      selectedTemplate.node.testCategories.edges
        ? selectedTemplate.node.testCategories.edges.filter(
            (i) => i.node.testType === "NORMAL"
          )
        : [];
    let testProfileCategoryArr =
      selectedTemplate &&
      selectedTemplate.node.testCategories &&
      selectedTemplate.node.testCategories.edges
        ? selectedTemplate.node.testCategories.edges.filter(
            (i) => i.node.testType === "PROFILE"
          )
        : [];
    if (testProfileCategoryArr.length > 0) {
      testProfileCategoryArr = testProfileCategoryArr.filter(
        (i) => i.node.structure !== "child"
      );
    }
    return (
      <div className="template_one">
        <div className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={() => {
              setSpecificTestsArr([]);
              setProfileTestsArr([]);
            }}
          >
            Deselect All
          </Button>
        </div>
        <h5 className="category_title text-left px-2"> Main Tests </h5>
        <div className="category_section grid-wrapper px-2">
          {testNormalCategoryArr && testNormalCategoryArr.length > 0
            ? testNormalCategoryArr.map((normalCat, index) => {
                let lab_tests = normalCat.node.labTests.edges;
                lab_tests = lab_tests.sort((a, b) =>
                  a.node.name.localeCompare(b.node.name)
                );
                let children = normalCat.node.children.edges;
                return (
                  <div key={index}>
                    <h5 className="group_title">{normalCat.node.name}</h5>
                    {lab_tests.length > 0
                      ? lab_tests.map((lab, index) => {
                          return (
                            <div
                              key={index}
                              className="create-new-patient-checkboxes d-flex"
                            >
                              <Form.Check
                                type={"checkbox"}
                                name={lab.node.name}
                                value={
                                  specificTestsArr.includes(
                                    normalCat.node.staticId +
                                      "_" +
                                      lab.node.staticId +
                                      ""
                                  ) ||
                                  specificTestsArr.includes(
                                    lab.node.staticId + ""
                                  )
                                    ? true
                                    : false
                                }
                                checked={
                                  specificTestsArr.includes(
                                    normalCat.node.staticId +
                                      "_" +
                                      lab.node.staticId +
                                      ""
                                  ) ||
                                  specificTestsArr.includes(
                                    lab.node.staticId + ""
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleCheck(
                                    normalCat.node.staticId +
                                      "_" +
                                      lab.node.staticId,
                                    "specific"
                                  )
                                }
                              />
                              <Form.Label>{lab.node.name}</Form.Label>
                            </div>
                          );
                        })
                      : null}
                    {children.length > 0
                      ? children.map((child, index) => {
                          let child_lab_test = child.node.labTests.edges;
                          child_lab_test = child_lab_test?.sort((a, b) =>
                            a.node.name.localeCompare(b.node.name)
                          );
                          return (
                            <div key={index}>
                              <h6 className="child_title">
                                {child.node.name}{" "}
                              </h6>
                              {child_lab_test.length > 0
                                ? child_lab_test.map((child_lab, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="create-new-patient-checkboxes d-flex"
                                      >
                                        <Form.Check
                                          type={"checkbox"}
                                          name={child_lab.node.name}
                                          value={
                                            specificTestsArr.includes(
                                              child.node.staticId +
                                                "_" +
                                                child_lab.node.staticId
                                            ) ||
                                            specificTestsArr.includes(
                                              child_lab.node.staticId + ""
                                            )
                                              ? true
                                              : false
                                          }
                                          checked={
                                            specificTestsArr.includes(
                                              child.node.staticId +
                                                "_" +
                                                child_lab.node.staticId
                                            ) ||
                                            specificTestsArr.includes(
                                              child_lab.node.staticId + ""
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleCheck(
                                              child.node.staticId +
                                                "_" +
                                                child_lab.node.staticId,
                                              "specific"
                                            )
                                          }
                                        />
                                        <Form.Label>
                                          {child_lab.node.name}
                                        </Form.Label>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                );
              })
            : null}
        </div>
        {testProfileCategoryArr && testProfileCategoryArr.length > 0 ? (
          <h5 className="category_title text-left px-2 mt-3"> Profiles </h5>
        ) : null}
        <div className="category_section px-2">
          {testProfileCategoryArr && testProfileCategoryArr.length > 0
            ? testProfileCategoryArr.map((normalCat, index) => {
                let lab_tests = normalCat.node.labTests.edges;
                lab_tests = lab_tests?.sort((a, b) =>
                  a.node.name.localeCompare(b.node.name)
                );
                let children = normalCat.node.children.edges;
                return (
                  <div key={index} className="mb-2">
                    {normalCat.node.structure === "standalone" ? (
                      <>
                        <div
                          key={index}
                          className="create-new-patient-checkboxes d-flex"
                        >
                          <Form.Check
                            type={"checkbox"}
                            name={normalCat.node.name}
                            value={profileTestsArr.includes(
                              normalCat.node.staticId
                            )}
                            checked={profileTestsArr.includes(
                              normalCat.node.staticId
                            )}
                            onChange={() =>
                              handleCheck(normalCat.node.staticId, "profile")
                            }
                          />
                          <Form.Label className="group_title mb-0">
                            {normalCat.node.name}
                          </Form.Label>
                        </div>
                        {lab_tests.length > 0
                          ? lab_tests.map((lab, index) => {
                              return (
                                <span key={index}>
                                  {" "}
                                  {lab.node.name}{" "}
                                  {index !== lab_tests.length - 1 ? ", " : ""}{" "}
                                </span>
                              );
                            })
                          : null}
                      </>
                    ) : (
                      <>
                        <h5 className="group_title">{normalCat.node.name}</h5>
                        {children.length > 0
                          ? children.map((child, index) => {
                              let child_lab_test = child.node.labTests.edges;
                              child_lab_test = child_lab_test?.sort((a, b) =>
                                a.node.name.localeCompare(b.node.name)
                              );
                              return (
                                <div key={index}>
                                  <div className="create-new-patient-checkboxes d-flex">
                                    <Form.Check
                                      type={"checkbox"}
                                      name={child.node.name}
                                      value={profileTestsArr.includes(
                                        child.node.staticId
                                      )}
                                      checked={profileTestsArr.includes(
                                        child.node.staticId
                                      )}
                                      onChange={() =>
                                        handleCheck(
                                          child.node.staticId,
                                          "profile"
                                        )
                                      }
                                    />
                                    <Form.Label className="color-primary">
                                      <i> {child.node.name}</i>
                                    </Form.Label>
                                  </div>
                                  {child_lab_test.length > 0
                                    ? child_lab_test.map((child_lab, index) => {
                                        return (
                                          <span key={index}>
                                            {child_lab.node.name}
                                            {index !== child_lab_test.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}
                      </>
                    )}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };

  const onChangeTemplate = (e) => {
    let temp_id = e.target.value;
    let templateObj =
      testTemplatesObj && testTemplatesObj.testTemplates
        ? testTemplatesObj.testTemplates.edges.find(
            (i) => i.node.id === temp_id
          )
        : null;
    setSelectedTemplate(templateObj);
  };

  const templateSelected = () => {
    setShowTemplateDetail(true);
  };

  const onClickAdd = () => {
    if (profileTestsArr.length > 0 || specificTestsArr.length > 0) {
      onSubmitLabReq();
    }
  };

  return (
    <>
      <Row className="">
        <Col xs={12} sm={12} md={12} className="mx-auto">
          {showTemplateDetail ? (
            renderTemplateOne()
          ) : (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm={4} md={2} className="text-right">
                  Template
                </Form.Label>
                <Col sm={8} md={10}>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    name="request"
                    required
                    onChange={onChangeTemplate}
                    id="lab-select-test"
                  >
                    {/* QUESTION order of templates appearing is broken
                      maybe we can set some order to gql request?
                    */}
                    <option value="">Select Template</option>
                    {testTemplatesObj &&
                      testTemplatesObj.testTemplates &&
                      testTemplatesObj.testTemplates.edges.map(
                        (item, index) => {
                          return (
                            <option key={index} value={item.node.id}>
                              {item.node.name}
                            </option>
                          );
                        }
                      )}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={4} md={2} className="text-right">
                  {"Description"}
                </Form.Label>
                <Col sm={8} md={10}>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    rows={5}
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="lab-desc-test"
                  />
                </Col>
              </Form.Group>
            </>
          )}
        </Col>
      </Row>
      <Row className="justify-content-end mt-4">
        <Col
          xs={12}
          sm={12}
          md={12}
          className="d-flex flex-wrap justify-content-center justify-content-sm-between"
        >
          <Button
            variant="primary"
            size="md"
            className="mb-1"
            onClick={() => setShowModal(false)}
          >
            CANCEL
          </Button>
          <Button
            className="mb-1 addLabTest"
            variant="primary"
            onClick={showTemplateDetail ? onClickAdd : templateSelected}
            size="md"
          >
            ADD
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default withRouter(Labs);
