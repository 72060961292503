import React, { useState } from "react";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import Base from "./base.js";
import { withRouter } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
  PER_PAGE_COUNT,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import Preloader from "../Common/Preloder/Preloader";
import * as Sentry from "@sentry/browser";
import queryString from "query-string";

function ApprovedBlocksList(props) {
  const [approvedEncounter, setApprovedEncounter] = useState();
  const [openSnackbar] = useSnackbar(error_options);
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(1);

  const REQUEST_APPROVED_BLOCKS = gql`
    query {
      approvedBlockNotifications (first:${PER_PAGE_COUNT}, 
        offset:${PER_PAGE_COUNT * (activePage - 1)}) {
        totalCount
        edges {
          node {
            id
            created
            encounter {
              id
              doctor {
                firstName
                lastName
              }
            }
            patient {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const DELETE_APPROVED_BLOCK = gql`
    mutation deleteBlockApprovedNotification($id: ID!) {
      deleteBlockApprovedNotification(id: $id) {
        deleted
      }
    }
  `;

  const { data: approvedBlocks = null, loading } = useQuery(
    REQUEST_APPROVED_BLOCKS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_APPROVED_BLOCKS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [deleteBlockApprovedNotification] = useMutation(DELETE_APPROVED_BLOCK, {
    onCompleted: ({ deleteBlockApprovedNotification }) => {
      if (
        deleteBlockApprovedNotification?.errors &&
        deleteBlockApprovedNotification?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deleteBlockApprovedNotification?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (deleteBlockApprovedNotification.deleted) {
        props.history.push(`/encounter/detail/${approvedEncounter}`);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_APPROVED_BLOCK error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const removeApprovedBlock = (block) => {
    deleteBlockApprovedNotification({ variables: { id: block.node.id } });
    setApprovedEncounter(block.node.encounter.id);
  };

  const process_query_string = (data = {}) => {
    let query_string_obj = queryString.parse(window.location.search);

    // current filter key, values
    if (Object.keys(data).length <= 0) {
      data = approvedBlocks;
    }

    for (const item in data) {
      query_string_obj[item] = data[item];
    }

    return query_string_obj;
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    let query_string = process_query_string();
    query_string["page"] = number;
    const stringified = queryString.stringify(query_string);
    window.history.pushState(null, "", "?" + stringified);
  };

  const renderPages = () => {
    let total_pages = approvedBlocks?.approvedBlockNotifications?.totalCount
      ? Math.ceil(
          approvedBlocks?.approvedBlockNotifications?.totalCount /
            PER_PAGE_COUNT
        )
      : 1;

    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  return (
    <Base title={t("patientrequestReport.approvedBlocks")} showHeader={true}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Row>
            <Col sm={12} md={12}>
              {approvedBlocks?.approvedBlockNotifications?.edges.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Patient</th>
                      <th>Created</th>
                      <th>Doctor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedBlocks.approvedBlockNotifications.edges.map(
                      (block, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() => removeApprovedBlock(block)}
                            className="card-approved-block"
                          >
                            <td>
                              {block.node.patient.firstName}{" "}
                              {displayMiddleName(block.node.patient.middleName)}
                              {block.node.patient.lastName}
                            </td>

                            <td>{getDate(block.node.created)}</td>

                            <td>
                              {block.node.encounter?.doctor?.firstName}{" "}
                              {block.node.encounter?.doctor?.lastName}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              ) : (
                t("patientrequestReport.noApprovedBlocks")
              )}
            </Col>
          </Row>
          <div className="text-center">
            {approvedBlocks?.approvedBlockNotifications?.totalCount >
            PER_PAGE_COUNT ? (
              <div className="paginationContainer pt-5">
                <Pagination>{renderPages()}</Pagination>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Base>
  );
}

export default withRouter(ApprovedBlocksList);
