import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  getDate,
  getForamttedTime,
  displayMiddleName,
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getErrorMessage,
  concatAllErrors,
  // getPermissionForAction,
} from "../Common/helpers";
import Preloader from "../Common/Preloder/Preloader";
import Logo from "../assets/images/Mindwell-Logo.svg";
import RefundRequests from "component/AppointmentDetails/refundRequests";
import { useSnackbar } from "react-simple-snackbar";
import RefundRequestModal from "./refundRequestModal.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CustomModals from "../component/Modal/modal";
import * as Sentry from "@sentry/browser";
import { CopyToClipBoardButton, isUserStaff } from "Common/helpers.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

var today = new Date();
today = getDate(today) + " " + getForamttedTime(today);

const UnpaidManualPaymentDetailPrintPage = (props) => {
  const paymentId = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [amountAvailableRefund, setAmountAvailableRefund] = useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const { t, i18n } = useTranslation();

  const REQUEST_PAYMENT_DETAIL = gql`
    query {
      payment(id:"${paymentId}") {
        id
        created
        amount
        currency
        notes
        reason
        displayReason
        identifier
        doctor {
          id
          firstName
          lastName
          titlePrefix
        }
        labTestService {
          totalCount
          edges {
            node {
              name
              price
              created
            }
          }
        }
        displayPaymentType
        refundRequest {
          edges {
            node {
              id
              amount
              status
              displayStatus
              notes
              dateRefunded
              staffNotes
              refundId
              created
              requiresApproval
              approvalStatus
              source {
                amountAvailableForRefund
              }
            }
          }
          
        }
        sources{
          edges{
            node{
              amountAvailableForRefund
              reference
              paymentType
              paymentLink
              linkMessage
              sourceType{
                name
                code
              }
            }
          }
        }
        patient{
          id
          firstName
          middleName
          lastName
          identifier
          displayGender
          civilId
          phone
        }
        appointment{
          created
          start
          id
          doctor{
            firstName
            lastName
          }
          payments{
            edges{
              node{
                sources{
                  edges{
                    node{
                      amountDebited
                      status
                      reference
                      id
                      currency
                      paymentType
                      paymentLink
                      sourceType{
                        name
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REFUND_PAYMENT = gql`
    mutation manualPaymentRequestRefund($id: ID!) {
      manualPaymentRequestRefund(id: $id) {
        error
        obj {
          id
          reason
          created
          displayPaymentType
          amount
          currency
          notes
          paymentType

          refundRequest {
            edges {
              node {
                status
                approvalStatus
              }
            }
          }
        }
      }
    }
  `;
  const [manualPaymentRequestRefund] = useMutation(REFUND_PAYMENT, {
    onCompleted: ({ manualPaymentRequestRefund }) => {
      if (
        manualPaymentRequestRefund?.errors &&
        manualPaymentRequestRefund?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          manualPaymentRequestRefund?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Refund request in process", [SNACK_DURATION]);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REFUND_PAYMENT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const { data, loading } = useQuery(REQUEST_PAYMENT_DETAIL, {
    fetchPolicy: "network-only",
    onCompleted: ({ payment }) => {
      if (!payment) {
        props.history.push("/404-not-found");
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PAYMENT_DETAIL error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const paymentDetailData = data && data.payment ? data.payment : null;

  useEffect(() => {
    let amountForRefund = paymentDetailData?.sources?.edges;
    amountForRefund = _.sumBy(amountForRefund, function (amount) {
      return parseFloat(amount.node.amountAvailableForRefund);
    });
    setAmountAvailableRefund(amountForRefund);
  }, [paymentDetailData]);

  const printPaymentDetail = () => {
    window.print();
  };

  const renderPaymentDetail = () => {
    const appointmentPayments =
      paymentDetailData &&
      paymentDetailData.appointment &&
      paymentDetailData.appointment.payments
        ? paymentDetailData.appointment.payments.edges
        : null;

    const sources = appointmentPayments
      ? appointmentPayments.map((item, index) => {
          return item.node.sources.edges;
        })
      : null;

    return (
      <tr className="page-table-cell">
        <td className="page-table-cell">
          <div className="page">
            <Row>
              {paymentDetailData && paymentDetailData.patient ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    Patient Name:
                    <Link
                      className="d-inline-block ml-1 patient-name-print-mode-link"
                      to={`/patient/record/${
                        paymentDetailData.patient.id +
                        "/?identifier=" +
                        paymentDetailData.patient.identifier
                      }`}
                    >
                      <Button
                        variant="link"
                        className="p-0 patient-name-print-mode-btn"
                      >
                        {paymentDetailData.patient.firstName}{" "}
                        {displayMiddleName(
                          paymentDetailData.patient.middleName
                        )}
                        {paymentDetailData.patient.lastName}
                      </Button>
                    </Link>
                  </h6>
                  {paymentDetailData?.patient?.phone ? (
                    <h6 className="capitalize">
                      Phone: {paymentDetailData.patient.phone}
                    </h6>
                  ) : null}
                  {paymentDetailData?.patient?.civilId ? (
                    <h6 className="capitalize">
                      Civil ID: {paymentDetailData.patient.civilId}
                    </h6>
                  ) : null}
                </Col>
              ) : null}
              {paymentDetailData && paymentDetailData.appointment ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    Appointment Date:{" "}
                    {getDate(paymentDetailData.appointment.start)}{" "}
                    {getForamttedTime(paymentDetailData.appointment.start)}
                  </h6>
                  <h6 className="capitalize">
                    Practitioner:{" "}
                    {paymentDetailData.appointment.doctor.firstName}{" "}
                    {paymentDetailData.appointment.doctor.lastName}
                  </h6>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs={12}>
                <h6 className="mb-0 mt-3">
                  <b>Payments:</b>
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="">
                {paymentDetailData && paymentDetailData.appointment ? (
                  // here goes appointment
                  <>
                    {sources
                      ? sources.map((itemNode, index) => {
                          return (
                            <div key={index} className="payment-container">
                              {itemNode.map((item, index) => {
                                return item.node.status === "Paid" ? (
                                  <div key={index} className="payment-block">
                                    <h6 className="capitalize">
                                      Amount: {item.node.amountDebited}{" "}
                                      {item.node.currency}
                                    </h6>
                                    <h6 className="capitalize">
                                      Transaction Date:{" "}
                                      {getDate(paymentDetailData.created)}{" "}
                                      {getForamttedTime(
                                        paymentDetailData.appointment.start
                                      )}
                                    </h6>
                                    <h6 className="capitalize">
                                      Reference Number: {item.node.reference}
                                    </h6>

                                    {/* <h6 className="capitalize">
                                        Transaction ID: {item.node.id}
                                      </h6> */}
                                    {item.node.sourceType ? (
                                      <>
                                        {item.node.sourceType.code ===
                                        "cash" ? (
                                          <h6 className="capitalize">
                                            Payment Type:{" "}
                                            {item.node.sourceType.name}
                                          </h6>
                                        ) : (
                                          <h6 className="capitalize">
                                            Payment Type:{" "}
                                            {item.node.sourceType.name} (TAP)
                                          </h6>
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                ) : null;
                              })}
                            </div>
                          );
                        })
                      : null}
                  </>
                ) : (
                  // here goes payment if no appointment
                  <>
                    <div className="payment-container">
                      <div className="payment-block">
                        <h6 className="capitalize">
                          <h6 className="capitalize">
                            Xero Invoice #:{" "}
                            {paymentDetailData ? (
                              <b>{paymentDetailData?.identifier}</b>
                            ) : (
                              "-"
                            )}
                          </h6>
                          Amount:{" "}
                          {paymentDetailData ? paymentDetailData.amount : null}{" "}
                          {paymentDetailData
                            ? paymentDetailData.currency
                            : null}
                        </h6>
                        <h6 className="capitalize">
                          Reason:{" "}
                          {paymentDetailData ? (
                            <b>{paymentDetailData?.displayReason}</b>
                          ) : null}
                        </h6>
                        <h6 className="capitalize">
                          Transaction Date:{" "}
                          {paymentDetailData && paymentDetailData.created
                            ? getDate(paymentDetailData.created)
                            : null}{" "}
                          {paymentDetailData && paymentDetailData.created
                            ? getForamttedTime(paymentDetailData.created)
                            : null}
                        </h6>
                        <h6 className="capitalize">
                          Reference Number:{" "}
                          {paymentDetailData
                            ? paymentDetailData.sources.edges[0].node.reference
                            : null}
                        </h6>

                        {paymentDetailData &&
                        paymentDetailData.sources.edges[0].node.sourceType ? (
                          <>
                            {paymentDetailData &&
                            paymentDetailData.sources.edges[0].node.sourceType
                              .code === "cash" ? (
                              <h6 className="capitalize">
                                Payment Type:{" "}
                                {paymentDetailData
                                  ? paymentDetailData.sources.edges[0].node
                                      .sourceType.name
                                  : null}
                              </h6>
                            ) : (
                              <h6 className="capitalize">
                                Payment Type:{" "}
                                {paymentDetailData
                                  ? paymentDetailData.sources.edges[0].node
                                      .sourceType.name
                                  : null}{" "}
                                (TAP)
                              </h6>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex align-items-center no-print">
                <h6 className="mb-0 mt-1 ">
                  <b>Payment Link :</b>
                </h6>
                <Button variant="link">
                  <a
                    href={`${paymentDetailData?.sources?.edges[0]?.node?.paymentLink}`}
                    target="_blank"
                    rel="noreferrer"
                    className="payment-link-icon pl-2"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </a>
                </Button>
                <CopyToClipBoardButton
                  textValue={
                    paymentDetailData?.sources?.edges[0]?.node?.linkMessage
                  }
                  variant="link"
                  className="pl-1"
                />
              </Col>
              <Col xs={12}>
                <h6 className="mb-0 mt-3">
                  <b>Practitioner :</b>
                </h6>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12} className="">
                {paymentDetailData?.doctor?.titlePrefix}{" "}
                {paymentDetailData?.doctor?.firstName}{" "}
                {paymentDetailData?.doctor?.lastName}
              </Col>
            </Row>
            {paymentDetailData?.reason === "GENETIC_LAB_TEST" ||
            (paymentDetailData?.reason === "OTHERS" &&
              paymentDetailData?.labTestService?.totalCount > 0) ? (
              <Row className="">
                <Col lg={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        {/* <th>Created</th> */}
                        <th>Service</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetailData?.labTestService?.edges.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.node.name}</td>
                              <td>{item.node.price}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}
          </div>
        </td>
      </tr>
    );
  };

  const PrintPaymentComponent = () => {
    return (
      <div className="print prescriptions-print-page">
        <div className="lab-request-header">
          <Row className="h100">
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={"Logo"} className="img-fluid printLogo" src={Logo} />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>

        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderPaymentDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  const onRefundPayment = () => {
    manualPaymentRequestRefund({
      variables: { id: paymentDetailData?.id },
    });
  };

  return (
    <>
      <Base
        title={"Unpaid Manual Payment Detail"}
        showHeader={true}
        rightChild={
          <div className="d-flex flex-column d-print-none">
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="mx-2"
                onClick={printPaymentDetail}
              >
                <b>Print</b>
              </Button>
              {paymentDetailData?.sources?.edges?.some(
                (i) => i?.node?.amountAvailableForRefund != 0
              ) ? (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={() => setShowRefundModal(true)}
                >
                  <b>Refund</b>
                </Button>
              ) : null}
            </div>
          </div>
        }
      >
        {loading ? (
          <Preloader />
        ) : (
          <Row className="d-print-none">
            <Col md={12}>{renderPaymentDetail()}</Col>
          </Row>
        )}

        {paymentDetailData?.refundRequest?.edges?.length == 0 ? null : (
          <RefundRequests
            event={paymentDetailData}
            REQUEST_EVENT={REQUEST_PAYMENT_DETAIL}
            appointment={false}
          />
        )}

        <CustomModals
          dialog
          ClassName="modal60h"
          showModal={showRefundModal}
          modalHeader="Refund Request"
          setShowModal={setShowRefundModal}
        >
          {paymentDetailData && paymentDetailData.id ? (
            <>
              <Row className="my-4">
                <Col xs={12} sm={12} md={12}>
                  {t("patientAppointmentDetails.availableRefund")} :{" "}
                  {amountAvailableRefund} KWD
                </Col>
              </Row>
              <RefundRequestModal
                paymentDetailData_id={paymentDetailData.id}
                setShowRefundModal={setShowRefundModal}
                showRefundModal={showRefundModal}
                REQUEST_PAYMENT_DETAIL={REQUEST_PAYMENT_DETAIL}
                amountAvailableRefund={amountAvailableRefund}
              />
            </>
          ) : null}
        </CustomModals>
      </Base>

      <PrintPaymentComponent />
    </>
  );
};

export default withRouter(UnpaidManualPaymentDetailPrintPage);
