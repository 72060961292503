import { Row, Col, Table } from "react-bootstrap";
import React from "react";
import Base from "./base.js";
import { Link, withRouter } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  displayMiddleName,
  concatAllErrors,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import Preloader from "../Common/Preloder/Preloader";
import * as Sentry from "@sentry/browser";

function BlocksForApprovementList(props) {
  const [openSnackbar] = useSnackbar(error_options);

  const { t } = useTranslation();

  // no arguments here to list all blocks
  const REQUEST_FORM_BLOCK_APPROVALS = gql`
    query {
      formBlockApprovals # last: 5 # first: 3 # after: "" # before: "" # offset: 1
      # patient_SearchText: ""
      # patient_SearchText_Icontains: ""
      # patient_SearchText_Istartswith: ""
      {
        edges {
          node {
            created
            modified
            patient {
              firstName
              middleName
              lastName
              id
              identifier
            }
            encounter {
              id
              date
              doctor {
                firstName
                lastName
              }
            }
            formBlock {
              name
            }
          }
        }
      }
    }
  `;

  const REQUEST_MEDICAL_REPORTS = gql`
    query {
      patientReportsForApproval {
        totalCount
        edges {
          node {
            created
            status
            id
            reason
            displayStatus
            reportType {
              name
            }
            patient {
              firstName
              middleName
              lastName
            }
            doctor {
              firstName
              lastName
            }
          }
        }
      }
    }
  `;

  const { data: formBlockApprovalsObj = null, loading } = useQuery(
    REQUEST_FORM_BLOCK_APPROVALS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_FORM_BLOCK_APPROVALS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const { data: medical_reports_obj = null } = useQuery(
    REQUEST_MEDICAL_REPORTS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_MEDICAL_REPORTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const renderFormBlockApprovalsObj = () => {
    return formBlockApprovalsObj?.formBlockApprovals.edges.map(
      (formBlock, index) => {
        return (
          <tr key={index}>
            <td>
              {formBlock.node.patient.firstName}{" "}
              {displayMiddleName(formBlock.node.patient.middleName)}
              {formBlock.node.patient.lastName}
            </td>
            {formBlock.node.encounter ? (
              <td>Encounter/{formBlock.node.formBlock.name}</td>
            ) : null}

            <td>{getDate(formBlock.node.created)}</td>
            <td>
              {formBlock.node.encounter?.doctor?.firstName}{" "}
              {formBlock.node.encounter?.doctor?.lastName}
            </td>
            <td>
              <Link
                to={
                  formBlock.node.encounter
                    ? `/encounter/detail/${formBlock.node.encounter.id}`
                    : `/patient/record/${
                        formBlock.node.patient.id +
                        "/?identifier=" +
                        formBlock?.node?.patient?.identifier
                      }`
                }
              >
                {" "}
                View{" "}
              </Link>
            </td>
          </tr>
        );
      }
    );
  };

  const renderPatientReportForApproval = () => {
    return medical_reports_obj?.patientReportsForApproval?.edges.map(
      (reportForApproval, index) => {
        return (
          <tr key={index}>
            <td>
              {reportForApproval.node.patient.firstName}{" "}
              {displayMiddleName(reportForApproval.node.patient.middleName)}
              {reportForApproval.node.patient.lastName}
            </td>
            <td>Clinical Report</td>
            <td>{getDate(reportForApproval.node.created)}</td>
            <td>
              {reportForApproval?.node?.doctor?.firstName}{" "}
              {reportForApproval?.node?.doctor?.lastName}
            </td>
            <td>
              <Link
                to={
                  "/patient/report/request/update/" +
                  reportForApproval.node.id +
                  "?doctorFill=true"
                }
              >
                {" "}
                View{" "}
              </Link>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <Base
      title={t("patientrequestReport.blocksForApprovement")}
      showHeader={true}
    >
      {loading ? (
        <Preloader />
      ) : (
        <Row>
          <Col sm={12} md={12}>
            {formBlockApprovalsObj?.formBlockApprovals?.edges?.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Patient</th>
                    <th>Source</th>
                    {/* "created" comes not from encounter but from node itself */}
                    <th>Created</th>
                    <th>Doctor</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {renderFormBlockApprovalsObj()}
                  {renderPatientReportForApproval()}
                </tbody>
              </Table>
            ) : (
              t("patientrequestReport.noFormBlocksForApprovement")
            )}
          </Col>
        </Row>
      )}
    </Base>
  );
}

export default withRouter(BlocksForApprovementList);
