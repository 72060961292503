import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import Base from "./base.js";
import { withRouter } from "react-router-dom";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  success_options,
  getDate,
  isUserStaff,
  getErrorMessage,
  displayMiddleName,
  concatAllErrors,
} from "../Common/helpers";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import Preloader from "../Common/Preloder/Preloader";
import { Formik } from "formik";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { patientBadgesVar } from "../cache/cache";
import CustomModals from "../component/Modal/modal";
import PatientFileUploadModal from "./patientsFileUploadModal";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

function PatientRecordFiles(props) {
  const isStaff = isUserStaff();
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [dummy, setDummy] = useState(false);
  const [date, setDate] = useState("");
  const [modifiedLt, setModifiedLt] = useState(new Date());
  const [modifiedGt, setModifiedGt] = useState(new Date("2002-04-01"));
  const id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const { t } = useTranslation();
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const patientBadgesObject = useReactiveVar(patientBadgesVar);

  const REQUEST_PATIENT = gql`
  query {
    patient(id:"${id}"){
          firstName
          middleName
          lastName
          id
          files(name_Icontains:"${fileName}", modified_Lt:"${modifiedLt.toISOString()}", modified_Gt:"${modifiedGt.toISOString()}"){
            edges{
              node{
                name
                id
                file
                created
                description
              }
            }
          }
        }
  }
`;
  const DELETE_PATIENT_FILE = gql`
    mutation deletePatientFile($id: ID!) {
      deletePatientFile(id: $id) {
        deleted
      }
    }
  `;

  const REQUEST_PATIENT_BADGES = gql`
    query {
      patientBadges
    }
  `;

  const [getPatientBadges, { data: patientBadges }] = useLazyQuery(
    REQUEST_PATIENT_BADGES,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ patientBadges }) => {
        patientBadgesVar(patientBadges);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
        }
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const POST_PATIENTS_FILES_READ = gql`
    mutation patientFileReadView($files: [ID]!) {
      patientFileReadView(input: { files: $files }) {
        updated
      }
    }
  `;

  // EVENT PRINT CODE
  const [postPatientFileReadView] = useMutation(POST_PATIENTS_FILES_READ, {
    onCompleted: ({ patientFileReadView }) => {
      getPatientBadges();
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("POST_PATIENTS_FILES_READ error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [getPatients, { data, loading }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ patient }) => {
      if (!patient) {
        props.history.push("/404-not-found");
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PATIENT error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // delete form instance
  const [deletePatientFile] = useMutation(DELETE_PATIENT_FILE, {
    onCompleted: ({ deletePatientFile }) => {
      if (deletePatientFile?.errors && deletePatientFile?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deletePatientFile?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (deletePatientFile.deleted) {
        openSnackbarSuccess(t("patientRecordFiles.deletedFiles"));
        setTimeout(() => {
          setDummy(!dummy);
        }, 500);
      }
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_PATIENT_FILE error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    getPatients();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patient_record = data && data.patient ? data.patient : null;

  useEffect(() => {
    if (patient_record && patient_record.files.edges) {
      setAllFiles(patient_record.files.edges);
      if (patientBadgesObject?.files) {
        let files = [];
        if (patient_record.files.edges.length > 0) {
          files = patient_record.files.edges.map((file) => {
            return file.node.id;
          });
        }
        postPatientFileReadView({ variables: { files: files } });
      }
    }
  }, [
    patient_record,
    dummy,
    patientBadgesObject?.files,
    postPatientFileReadView,
  ]);

  const onSubmit = (values, { resetForm }) => {
    if (values.fileName) {
      setFileName(values.fileName);
    }
    if (date) {
      let dateGt = new Date(date);
      let dateLt = new Date(date);
      dateLt.setDate(dateLt.getDate() + 1);
      setModifiedGt(new Date(dateGt));
      setModifiedLt(new Date(dateLt));
    }
    getPatients();
  };

  const resetFilter = (handleReset) => {
    setFileName("");
    handleReset();
    setDate("");
    setModifiedGt(new Date("2002-04-01"));
    setModifiedLt(new Date());
  };

  const initialValues = {
    fileName: "",
  };
  const deleteSelectedFile = (fileId) => {
    if (window.confirm("Are you sure to Delete File?")) {
      deletePatientFile({
        variables: { id: fileId },
      });
    }
  };

  let patient_name = patient_record
    ? patient_record.firstName +
      " " +
      displayMiddleName(patient_record.middleName) +
      patient_record.lastName +
      " "
    : "";

  return (
    <Base
      isPatientPortal={!isStaff}
      title={
        patient_name
          ? patient_name + t("patientRecordFiles.yourFiles")
          : t("patientRecordFiles.yourFiles")
      }
      showHeader={true}
      rightChild={
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => setShowModal(true)}
          id="add-file-test"
        >
          {t("patientRecordFiles.addFiles")}
        </Button>
      }
    >
      <Row className="payment-section">
        <Col md={12} lg={12} className="mr-auto">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleReset, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-right pr-0"
                      >
                        {t("patientRecordFiles.date")}
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={dayjs(date) || null}
                            name="dob"
                            onChange={(date) => setDate(new Date(date))}
                            sx={{
                              backgroundColor: "white",
                              ".MuiInputBase-input": {
                                padding: ".375rem .75rem",
                                backgroundColor: "white",
                              },
                            }}
                            slotProps={{
                              textField: { fullWidth: true },
                            }}
                          />
                        </LocalizationProvider>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        <span className="">
                          {t("patientRecordFiles.fileName")}
                        </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChange}
                          required
                          id="file-name-test"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <div className="d-flex justify-content-end submitPayment">
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={() => resetFilter(handleReset)}
                        id="file-reset-test"
                      >
                        {" "}
                        {t("patientRecordFiles.reset")}{" "}
                      </Button>
                      <Button type="submit" id="file-submit-test">
                        {" "}
                        {t("patientRecordFiles.submit")}{" "}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col xs={12} md={12}>
          <Table responsive className=" table-borderless">
            <thead>
              <tr className="patient_table_row tableHeader">
                <th scope="col " className="text-uppercase">
                  {" "}
                  {t("patientRecordFiles.created")}
                </th>
                <th scope="col " className="text-uppercase">
                  {" "}
                  {t("patientRecordFiles.name")}
                </th>
                <th scope="col " className="text-uppercase">
                  {" "}
                  {t("patientRecordFiles.description")}
                </th>
                <th scope="col " className="text-uppercase">
                  {" "}
                  {t("patientrequestReport.actions")}
                </th>
              </tr>
            </thead>
            {loading ? (
              <Preloader />
            ) : (
              <tbody>
                {allFiles && allFiles.length > 0 ? (
                  allFiles.map((file, index) => {
                    return (
                      <tr key={index} className="patient_table_row">
                        <td>{getDate(file.node.created)}</td>
                        <td>{file.node.name}</td>
                        <td>{file.node.description}</td>
                        <td>
                          <div className="d-flex">
                            <a
                              href={file.node.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {t("patientRecordFiles.view")}{" "}
                            </a>
                            <Button
                              variant="link"
                              size="md"
                              className="helpers-fail-icon p-0 mx-4"
                              onClick={() => deleteSelectedFile(file.node.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                              {/* {t("patientReportRequest.delete")} */}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <h6 className="p-3">
                    {" "}
                    {t("patientRecordFiles.filesNotAdded")}{" "}
                  </h6>
                )}
              </tbody>
            )}
          </Table>
        </Col>
      </Row>
      <CustomModals
        dialogClassName="modal60h"
        showModal={showModal}
        modalHeader={t("patientRecordFiles.uploadPatientFiles")}
        setShowModal={setShowModal}
      >
        {patient_record && patient_record.id ? (
          <PatientFileUploadModal
            patient_id={patient_record.id}
            setShowModal={setShowModal}
            setAllFiles={setAllFiles}
            showModal={showModal}
            getPatients={getPatients}
            sendRefreshEvent={()=>getPatients() }
          />
        ) : null}
      </CustomModals>
    </Base>
  );
}

export default withRouter(PatientRecordFiles);
