import { Row, Col} from 'react-bootstrap';
import Base from './base.js';


function PasswordSettings(props) {
  
  return (
    <Base title={'PASSWORD SETTINGS'} showHeader={true}>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={10}>
            <Row>
              <p>PASSWORD SETTINGS</p>
            </Row>
          </Col>
        </Row>
    </Base>
  );
}
export default PasswordSettings;
