//@ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import {
  resetItemStepsData,
  resetState,
  selectSlide,
} from "redux/slices/appointmentSlice";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { IconButton } from "@mui/material";

interface StepObj {
  title: string;
  data?: any;
}

interface StepProps {
  steps: StepObj;
  slide: number;
}

export default function Step(props: StepProps) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { patientReferral } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  const onEdit = useCallback(
    async (title: string) => {
      if (title === "service") {
        dispatch(resetState());
      } else {
        dispatch(resetItemStepsData(title));
      }

      dispatch(selectSlide(props.slide));
    },
    [dispatch, props.slide]
  );

  const renderStep = useMemo(() => {
    const item = props.steps;
    let value;
    let transTitle = t(`createEventForm.${item.title}`);

    if (item.title === "service") {
      value = lang === "en" ? item?.data?.nameEn : item?.data?.nameAr;
    } else if (item.title === "practitioner") {
      const { doctor, service } = item.data;
      let doctorName = `${doctor.firstName} ${doctor.lastName}`;

      if (lang === "ar" && doctor.arabicName) {
        doctorName = doctor.arabicName;
      }

      const location = service?.eventLocation?.title;
      const duration = service?.duration;
      const price = service?.price;

      return (
        <>
          <div className="content">
            <span className="title">{transTitle}</span>
            <span className="sub">{t("doctorName", { name: doctorName })}</span>
          </div>
          <div className="content">
            <span className="title">{t("createEventForm.location")}</span>
            <span className="sub">{location}</span>
          </div>
          <div className="content">
            <span className="title">{t("createEventForm.duration")}</span>
            <span className="sub">{duration}</span>
          </div>
          <div className="content">
            <span className="title">{t("createEventForm.price")}</span>
            <span className="sub">{price}</span>
          </div>
          {/* @ts-ignore */}
          <IconButton
            onClick={() => onEdit(item.title)}
            sx={{ color: "#fff", fontSize: "1rem" }}
            disabled={
              patientReferral &&
              (item.title === "service" || item.title === "practitioner")
            }
          >
            {/* @ts-ignore */}
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        </>
      );
    } else if (item.title === "date") {
      value = item.data.display;
    } else if (item.title === "waiting") {
      value = item.data.display;
    }

    return (
      <>
        <div className="content">
          <span className="title">{transTitle}</span>
          <span className="sub">{value}</span>
        </div>
        {/* @ts-ignore */}
        <IconButton
          onClick={() => onEdit(item.title)}
          sx={{ color: "#fff", fontSize: "1rem" }}
          disabled={
            patientReferral &&
            (item.title === "service" || item.title === "practitioner")
          }
        >
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faEdit} />
        </IconButton>
      </>
    );
  }, [lang, onEdit, patientReferral, props.steps, t]);

  return <div className="step-complete-container">{renderStep}</div>;
}
