import { Row, Col, Button, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedo,
  faClipboard,
  faCalendar,
  faSmile,
  faHandHoldingMedical,
  faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import {
  gql,
  useQuery,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/reducers/hooks";
import * as Sentry from "@sentry/browser";
import { urlBackend } from "apolloSettings";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  getForamttedTime,
  getPrescriptionDate,
  concatAllErrors,
  isUserStaff,
  deepCopy,
  getErrorMessage,
} from "Common/helpers";
import Base from "./base.js";
import { useEffect, useState } from "react";

import { useSnackbar } from "react-simple-snackbar";

function PrescriptionRequest(props) {
  const [blockPrintData, setBlockPrintData] = useState(null);
  const [openSnackbar] = useSnackbar(error_options);

  const identifier = props.match.params.identifier;
  const isStaff = isUserStaff();
  const { t } = useTranslation();

  const REQUEST_ACCOUNT_DETAILS = gql`
    query {
      me {
        id
        isStaff
        patient {
          firstName
          middleName
          lastName
          id
          idFile
          identifier
          dob
          phone
          email
          civilId
          prescriptionData {
            dynamicFormData
          }
        }
      }
    }
  `;

  const GENERTE_ENCOUNTER_PRINT_CODE = gql`
  mutation generateEncounterPrintCode($id: ID!) {
    generateEncounterPrintCode(id: $id) {
      code
    }
  }
`;

  const { data: accountDetails, loading } = useQuery(REQUEST_ACCOUNT_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_ACCOUNT_DETAILS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      // openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const DYNAMIC_PRESCRIPTION_DATA =
    accountDetails?.me?.patient?.prescriptionData?.dynamicFormData;

  var sortedPresc = [];
  for (var key in DYNAMIC_PRESCRIPTION_DATA) {
    let data = { ...DYNAMIC_PRESCRIPTION_DATA[key] };

    // add the encounter id on prescription data.
    try {
      const match = key.match(/\d+/);

      if (match) {
        const numString = match[0];
        const num = parseInt(numString);

        if (num) {
          data = { ...data, encounterId: num };
        }
      }
    } catch {}

    sortedPresc.push({ prescription: data });
  }
  sortedPresc = sortedPresc.sort(function (a, b) {
    let presc_date_a = getPrescriptionDate(a.prescription);
    let presc_date_b = getPrescriptionDate(b.prescription);
    return presc_date_b - presc_date_a;
  });

  const [generateEncounterPrintCode] = useMutation(
    GENERTE_ENCOUNTER_PRINT_CODE,
    {
      onCompleted: ({ generateEncounterPrintCode }) => {
        let oneTimeCode = generateEncounterPrintCode.code;
        if (blockPrintData) {
          setTimeout(() => {
            window.open(
              urlBackend +
                "pdf/reports/prescription/report/" +
                blockPrintData.encounterId +
                "/" +
                oneTimeCode +
                "/"
            );
          }, 300);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("GENERTE_ENCOUNTER_PRINT_CODE error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  function toGlobalID(type, id) {
    // Combine the type and ID with a separator (usually ':')
    const separator = ":";
    const globalID = `${type}${separator}${id}`;
  
    // Base64 encode the global ID
    const base64GlobalID = Buffer.from(globalID, "utf8").toString("base64");
  
    // Prepend the type name to the base64 encoded ID
    return `${type}${separator}${base64GlobalID}`;
  }

  useEffect(() => {
    if (blockPrintData) {
      const globalId = toGlobalID("EncounterNode", blockPrintData?.encounterId);
      const parts = globalId.split(":");

      let variables = { id: parts[1] };
      generateEncounterPrintCode({ variables });
    }
  }, [blockPrintData, generateEncounterPrintCode]);

  return (
    <Base
      page_loading={loading}
      title={t("patientNavigation.prescriptions")}
      isPatientPortal={!isStaff}
      showHeader={true}
    >
      {sortedPresc.length > 0
        ? sortedPresc.map((prescriptionItem, index) => {
            let prescription = prescriptionItem.prescription;
            let presc_date = getPrescriptionDate(prescription);
            let valid_until = presc_date ? new Date(presc_date) : "";
            if (valid_until) {
              valid_until.setDate(valid_until.getDate() + 30);
              valid_until.setHours(23);
              valid_until.setMinutes(59);
            }
            let current_date = new Date();

            let p_date = getDate(presc_date);
            let v_date = getDate(valid_until);

            if (
              prescription.form_block_instances.length > 0 &&
              current_date <= valid_until
            ) {
              return (
                <Col lg={4} key={index}>
                  <Card
                    className={
                      " green patient-card count-badge-patient-dashboard"
                    }
                  >
                    <Card.Body className="text-center patient-card-body">
                      <div>
                        <h6 className="text-center font-bold text-uppercase patient-card-title">
                          {t("patientNavigation.prescription")}{" "}
                          {presc_date ? "- " + p_date : ""}{" "}
                        </h6>
                        <FontAwesomeIcon
                          icon={faNotesMedical}
                          style={{ fontSize: "4rem" }}
                          className="icon_large mx-3 my-4"
                        />
                        <p className="mb-0 text-uppercase font-bold">
                          {t("patientNavigation.prescriptionsValidUntil")}{" "}
                          <span style={{ direction: "ltr" }}>{v_date}</span>
                        </p>
                      </div>
                      <div className="text-center">
                        {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                        <div className="mt-2 text_container">
                          <Button
                            block
                            variant="link"
                            onClick={() => setBlockPrintData(prescription)}
                            className="font-bold d-flex justify-content-end align-items-center"
                          >
                            {t("patientPaymentDetails.print")}{" "}
                            <i className="fa fa-print mx-3"></i>
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            }
          })
        : null}
    </Base>
  );
}
export default withRouter(PrescriptionRequest);
