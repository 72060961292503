//@ts-nocheck
import React from "react";
import { Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const getDirection = (lang: string) => {
  return lang === "ar" ? "rtl" : "ltr";
};

const HistoryBackButton = () => {
  const handleGoBack = () => window.history.back();
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);

  // Show the button if history length is greater than 1
  const shouldShowBackButton = window.history.length > 1;

  return (
    <>
      {shouldShowBackButton && (
        <Button
          onClick={handleGoBack}
          startIcon={
            <>
              {/* @ts-ignore */}
              {direction === "ltr" && (
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-purple-midi"
                />
              )}
              {/* @ts-ignore */}
              {direction === "rtl" && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-purple-midi"
                />
              )}
            </>
          }
          sx={{ textTransform: "none" }}
        >
          <span className="ff-primary"> {t("base.back")}</span>
        </Button>
      )}
    </>
  );
};

export default HistoryBackButton;
