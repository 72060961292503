import { Row, Col, Form, Button } from "react-bootstrap";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Base from "Views/base.js";
import CalendarHoc from "./Calendar/CalendarHoc";
import Preloader from "Common/Preloder/Preloader";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Formik } from "formik";
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { MultiSelect } from "react-multi-select-component";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  displayMiddleName,
  urlActions,
  getValidDateStringForGraphQL,
  concatAllErrors,
} from "Common/helpers";
import {
  myAppointmentDetailsVar,
  appFromCalVar,
  eventsDataVar,
  displayLoaderVar,
  dataReferralObject,
  editableAppointmentVar,
} from "cache/cache";
import SelectPatientModal from "Views/SearchForPatient/selectPatientModal";
import { useTranslation } from "react-i18next";
import { useReactiveVar, useMutation } from "@apollo/client";
import { appLanguageVar } from "cache/cache";
import { urlBackend } from "apolloSettings";
import * as Sentry from "@sentry/browser";
import _ from "lodash";

export const REQUEST_DOCTOR = gql`
  query ($identifier: String) {
    doctors(active: true, identifier: $identifier) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          doctorId
          arabicName
          unavailableDates {
            edges {
              node {
                startTime
                endTime
                date
                leaveRequest {
                  description
                  leaveType
                }
              }
            }
          }
          overrideSchedules {
            edges {
              node {
                startDate
                endDate
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
          recurringEvents {
            edges {
              node {
                startDate
                startTime
                endTime
                title
                description
                everyday
                days
                dateUntil
              }
            }
          }
          eventType {
            edges {
              node {
                id
                title {
                  name
                }
                duration
                buffer
                price
                eventType {
                  id
                  name
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MY_APPOINTMENT_DETAILS = gql`
  query {
    myAppointmentDetails @client
  }
`;

const REQUEST_EVENTS = gql`
  query (
    $doctors: [String]
    $search: String
    $patient_Identifier: String
    $status: String
    $active: Boolean!
    $location: ID
    $start: Date
    $end: Date
  ) {
    events(
      doctors: $doctors
      search: $search
      patient_Identifier: $patient_Identifier
      status: $status
      active: $active
      location_Id_Icontains: $location
      start: $start
      end: $end
    ) {
      edges {
        node {
          title
          id
          start
          end
          status
          identifier
          groupStartDate
          groupEndDate
          groupDays
          children {
            edges {
              node {
                id
                start
                end
                eventId
              }
            }
          }
          doctorEventType {
            groupSession

            color {
              hexCode
            }
          }
          doctor {
            id
            firstName
            lastName
            arabicName
            identifier
          }
        }
      }
    }
  }
`;

function MyAppointments(props) {
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { i18n, t } = useTranslation();
  const { data } = useQuery(USER_DETAILS);
  const fullCalendarRef = useRef();
  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }
  const isStaff = isUserStaff();
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const searchPatientRef = useRef();
  const [selectedPatientFullName, setSelectedPatientFullName] = useState("");
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [doctorsLoad, setDoctorsLoad] = useState(0);
  const [forceFilterApply, setForceFilterApply] = useState(false); // just a state used to rerender / trigger useeffect
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [doctorIdentifier, setDoctorIdentifier] = useState(
    urlActions(window.location.href, "get", "doctor").includes("__")
      ? null
      : urlActions(window.location.href, "get", "docotorIdentifier")
  );
  const [active, setActive] = useState(!isStaff ? true : false);
  const [variablesToBeFetched, setVariablesToBeFetched] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedPatientFileNumber, setSelectedPatientFileNumber] =
    useState("");
  const [selectedDocList, setSelectedDocList] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const appLanguage = useReactiveVar(appLanguageVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const [patientDataObj, setPatientDataObj] = useState(null);
  const [applyDoctorList, setApplyDoctorList] = React.useState([]);

  const handleCompleted = ({ myAppointmentDetails }) => {
    const {
      selectedDocList,
      selectedPatientFullName,
      selectedStatus,
      forceFilterApply,
      patientDataObj,
    } = myAppointmentDetails;

    if (
      selectedDocList ||
      selectedPatientFullName ||
      selectedStatus ||
      forceFilterApply
    ) {
      setForceFilterApply(false);

      if (selectedPatientFullName) {
        setSelectedPatientFullName(selectedPatientFullName);
        formikRef.current.setFieldValue("patient", selectedPatientFullName);
      }

      setPatientDataObj(patientDataObj);
      setSelectedStatus(selectedStatus);

      const variables = {};
      const dataToPass = appFromCal ? appFromCal : {};

      if (patientDataObj) {
        dataToPass["patientData"] = patientDataObj;
        variables["patient_Identifier"] = patientDataObj.identifier;
      } else {
        dataToPass["patientData"] = "";
        variables["patient_Identifier"] = "";
      }

      if (selectedDocList?.length > 0) {
        setSelectedDocList(
          docOptions.filter((i) =>
            selectedDocList.some((j) => j.doctorId === i.doctorId)
          )
        );
        dataToPass["doctors"] = selectedDocList.map((i) => i.doctorId + "");
        variables["doctors"] = selectedDocList.map((i) => i.doctorId + "");
      } else {
        variables.doctors = [];
      }

      handleStatusUpdates(selectedStatus, variables);

      appFromCalVar(dataToPass);
      handleCalendarUpdates(variables);
    }
  };

  const handleStatusUpdates = (status, variables) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case "":
        setActive(false);
        variables["status"] = "";
        variables["active"] = true;
        break;
      case "cancelled":
        setActive(false);
        variables["status"] = "cancelled";
        variables["active"] = false;
        break;
      case "active":
        setActive(true);
        variables["status"] = "";
        variables["active"] = true;
        break;
    }
  };

  const handleCalendarUpdates = (variables) => {
    const calendarView = fullCalendarRef?.current?.getApi()?.view;
    if (calendarView) {
      setVariablesToBeFetched((prevState) => ({ ...prevState, ...variables }));
    }
  };

  const handleError = ({ networkError, message }, e) => {
    Sentry.setContext("error", e?.networkError?.result);
    Sentry.setContext("ERROR OBJ ", { errorObj: e });
    Sentry.setContext("ERROR CODE statusCode ", {
      code: e?.networkError?.statusCode,
    });
    if (e?.message?.toLowerCase()?.indexOf("permission") < 0) {
      Sentry.captureException("GetMyAppointments error " + networkError);
    }

    if (message) {
      openSnackbar(message, [SNACK_DURATION]);
    } else {
      const errorMsg = concatAllErrors(e?.graphQLErrors) || ERROR_MESSAGE;
      openSnackbar(errorMsg, [SNACK_DURATION]);
    }
  };

  const [GetMyAppointments, { loading: myAppointmentDetailsLoading }] =
    useLazyQuery(MY_APPOINTMENT_DETAILS, {
      fetchPolicy: "network-only",
      onCompleted: handleCompleted,
      onError: handleError,
    });

  const defaultStrings = {
    selectSomeItems: t("multiSelectDoc.select"),
    search: t("multiSelectDoc.search"),
  };

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    ...(doctorIdentifier
      ? { variables: { identifier: doctorIdentifier } }
      : {}),
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", networkError?.statusCode);
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + networkError);
      }
      if (networkError.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
    onCompleted: (data) => {
      if (doctorIdentifier) setDoctorIdentifier(null);
    },
  });

  const GENERTE_EVENT_PRINT_CODE = gql`
    mutation eventPrintCode($generate: Boolean) {
      eventPrintCode(generate: $generate) {
        code
      }
    }
  `;

  // EVENT PRINT CODE
  const [generateEventPrintCode] = useMutation(GENERTE_EVENT_PRINT_CODE, {
    onCompleted: ({ eventPrintCode }) => {
      let oneTimeCode = eventPrintCode.code;
      let doctorArrStr = urlActions(window.location.href, "get", "doctor");
      let status = urlActions(window.location.href, "get", "status");
      let patientId = urlActions(window.location.href, "get", "patientId");
      let docListArr = doctorArrStr.length > 0 ? doctorArrStr?.split("__") : [];
      let calendarView = fullCalendarRef.current?.getApi()?.view;
      let date = getValidDateStringForGraphQL(calendarView.currentStart);
      let urlForEventPrint =
        urlBackend + "pdf/events/" + oneTimeCode + "/?date=" + date;
      if (docListArr.length > 0) {
        urlForEventPrint += "&doctors=" + docListArr.join(",");
      }
      if (patientId) {
        urlForEventPrint += "&patient=" + patientId;
      }
      if (status === "no_show" || status === "cancelled") {
        urlForEventPrint += "&status=" + status;
      } else if (status) {
        urlForEventPrint += "&status=" + status + "&active=" + true;
      } else {
        urlForEventPrint += "&active=" + true;
      }
      setTimeout(() => {
        window.open(urlForEventPrint);
      }, 300);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("GENERTE_EVENT_PRINT_CODE error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [
    GetEventsCall,
    { data: eventsList, loading: eventsLoading, startPolling, stopPolling },
  ] = useLazyQuery(REQUEST_EVENTS, {
    fetchPolicy: "network-only",
    variables: {
      active:
        selectedStatus === "no_show" || selectedStatus === "cancelled"
          ? false
          : true,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 16000,
    onCompleted: (data) => {
      var events = [];
      if (data && data.events && data.events?.edges) {
        events = data.events?.edges.map((evt) => {
          let val = JSON.parse(JSON.stringify(evt.node));
          let color = evt?.node?.doctorEventType?.color?.hexCode;
          val["backgroundColor"] = color;
          val["borderColor"] = color;
          if (isStaff && evt?.node?.doctor && evt?.node?.status != 'CANCELLED') {
            val["resourceId"] = evt.node.doctor.id;
          }
          return val;
        });
      }
      var displayEventsList = events;
      if (userDetails?.isSuperuser && displayEventsList) {
        eventsDataVar(displayEventsList);
      } else {
        if (
          displayEventsList &&
          userDetails &&
          userDetails.canViewAppointments &&
          isStaff
        ) {
          let canViewAppointments = userDetails.canViewAppointments?.edges.map(
            (doc) => {
              return doc.node.identifier;
            }
          );
          displayEventsList = displayEventsList.filter((i) => {
            /* FIXME:  two issues here, 
            
            1. we are checking TOO late (the appointments should have not 
              been requested from the backend or should have been filtered out. 
            2. (Backend) How can there be appointments without a doctor?   
            3. I forgot, but there's a third thing wrong with this.
            */

            try {
              return canViewAppointments.includes(i.doctor.identifier) === true;
            } catch {
              return false;
            }
          });
          eventsDataVar(displayEventsList);
        } else if (!isStaff && displayEventsList) {
          eventsDataVar(displayEventsList);
        }
      }
      displayLoaderVar(false);
    },
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + networkError);
      }
      if (networkError?.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const GetEvents = useCallback(_.debounce(GetEventsCall, 100), []);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    displayLoaderVar(true);
    GetMyAppointments();
    // Specify how to clean up after this effect:
    return () => {
      if (props?.history?.location?.pathname?.indexOf("/appointment") < 0) {
        myAppointmentDetailsVar({});
      }
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (userDetails?.patient && !patientDataObj) {
      onSelectPatient(userDetails?.patient);
    }
  }, [userDetails, patientDataObj]);

  const onFocus = () => {
    if (startPolling) {
      startPolling(16000);
    }
  };
  const onBlur = () => {
    if (stopPolling) {
      stopPolling();
    }
  };
  useEffect(() => {
    if (doctorsList && doctorsList.doctors) {
      setDoctors(doctorsList.doctors.edges);
    }
  }, [doctorsList]);

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );

    let newUrlParams = "";
    if (patientData) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "patient",
        patientData.identifier
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patient_name",
        `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
          patientData.lastName
        }`
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patientId",
        `${patientData.patientId}`
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "patient");
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patient_name"
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patientId"
      );
    }
    window.history.pushState("", "", "?" + newUrlParams);

    setSelectedPatientFullName(
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );
    setSelectedPatientFileNumber(`${patientData.identifier}`);
    setPatientDataObj(patientData);
    let dataToPass = appFromCal ? appFromCal : {};
    dataToPass["patientData"] = patientData;
    appFromCalVar(dataToPass);
  };

  useEffect(() => {
    if (
      active ||
      selectedStatus ||
      selectedDocList ||
      selectedPatientFullName ||
      forceFilterApply
    ) {
      let myAppDetailsObj = {
        selectedDocList,
        selectedPatientFullName,
        selectedStatus,
        // docObj,
        patientDataObj,
        active,
        forceFilterApply,
      };
      myAppointmentDetailsVar(myAppDetailsObj);
    }
  }, [
    selectedPatientFullName,
    selectedDocList,
    selectedStatus,
    patientDataObj,
    active,
    forceFilterApply,
  ]);

  useEffect(() => {
    if (active) {
      if (isStaff) {
        setSelectedStatus("active");
      }
    }
  }, [active, isStaff]);

  // Create a USEEFFECT that will check if the eventslist is done loading, and set PAGE_LOADER to FALSE, only for the first time.
  useEffect(() => {
    if (!eventsLoading && firstTime) {
      setPageLoading(false);
      setFirstTime(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsLoading]);

  const initialValues = {
    doctor: "",
    patient: "",
    status: "",
  };

  const handleChangeStatus = (e) => {
    let newUrlParams = "";
    if (e.target.value) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "status",
        e.target.value
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "status");
    }
    window.history.pushState("", "", "?" + newUrlParams);

    if (e.target.value) {
      setSelectedStatus(e.target.value);
      setActive(false);
    } else {
      setSelectedStatus("");
      setActive(true);
      setForceFilterApply(true); // incase all selectedstatus and active is set back to default, the get events from myappVar is not triggered so we call the getevents here incase there is no select value passed
    }
  };

  // Event Status
  const eventStatus = {
    pending: t("status.pending"),
    confirmed: t("status.confirmed"),
    no_show: t("status.noShow"),
    cancelled: t("status.cancelled"),
    completed: t("status.completed"),
  };
  var is_my_appointments = false;
  var has_add_permission = getPermissionForAction("appointment", "add");
  const createNewApp = () => {
    localStorage.setItem("appointment_flow", true);
    dataReferralObject(null);
    editableAppointmentVar(null);
  };
  const resetPatient = () => {
    formikRef.current.setFieldValue("patient", "");
    setSelectedPatientFullName("");
    setSelectedPatientFileNumber("");
    setPatientDataObj(null);
    let dataToPass = appFromCal ? appFromCal : {};
    dataToPass["patientData"] = null;
    appFromCalVar(dataToPass);
    setForceFilterApply(true);
    let newUrlParams = urlActions(window.location.href, "delete", "patient");
    newUrlParams = urlActions(
      window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
      "delete",
      "patient_name"
    );
    newUrlParams = urlActions(
      window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
      "delete",
      "patientId"
    );
    window.history.pushState("", "", "?" + newUrlParams);
  };

  const getDocAsResources = () => {
    if (isStaff) {
      return doctors.map((doctor) => {
        return {
          id: doctor.node.id,
          doctorId: doctor.node.doctorId,
          title: doctor.node.firstName + " " + doctor.node.lastName,
          identifier: doctor.node.identifier,
        };
      });
    } else {
      return [];
    }
  };

  var docOptions = doctors?.map((doctor) => {
    return {
      label:
        i18n.language === "en"
          ? doctor.node.firstName + " " + doctor.node.lastName
          : doctor.node.arabicName,
      value: doctor.node.identifier,
      doctorId: doctor.node.doctorId,
    };
  });

  docOptions = docOptions.sort((a, b) => a?.label?.localeCompare(b?.label));

  if (docOptions.length === 1) {
    docOptions.push({
      label: t("multiSelectDoc.selectDoc"),
      value: "0",
      placeholder: "",
      disabled: true,
      doctorId: "00",
    });
  }

  const handleChangeDoctor = (selectedDoc) => {
    let docs = [];
    docs.push(...selectedDoc);
    setSelectedDocList(docs);
    let newUrlParams = window.location.search;
    if (docs.length > 0) {
      let doc_list = docs.map((i) => i.doctorId + "").join("__");
      if (doc_list) {
        newUrlParams = urlActions(
          window.location.href,
          "set",
          "doctor",
          doc_list
        );
      }

      const doctorIds = docs.map((doctor) => doctor.doctorId);

      setVariablesToBeFetched((prev) => ({ ...prev, doctors: doctorIds }));
    } else {
      if ("doctors" in variablesToBeFetched) {
        const { doctors, ...rest } = variablesToBeFetched;
        setVariablesToBeFetched({ ...rest });
      }
      newUrlParams = urlActions(window.location.href, "delete", "doctor");
    }
    window.history.pushState("", "", "?" + newUrlParams);
  };

  useEffect(() => {
    let doctorArrStr = urlActions(window.location.href, "get", "doctor");
    let status = urlActions(window.location.href, "get", "status");
    let patient_name = urlActions(window.location.href, "get", "patient_name");
    let patient = urlActions(window.location.href, "get", "patient");
    let docListArr = doctorArrStr?.split("__");

    let docArr = docOptions.filter((i) =>
      docListArr.some((j) => j === i.doctorId + "")
    );
    if (docArr.length > 0) {
      setSelectedDocList(docArr);
    }
    if (status) {
      formikRef.current.setFieldValue("status", status);
      setSelectedStatus(status);
    }
    if (patient_name) {
      formikRef.current.setFieldValue("patient", patient_name);
      setSelectedPatientFileNumber(patient);
    }
    if (
      docListArr.length === 1 &&
      docListArr[0] === "" &&
      docOptions.length === 2
    ) {
      let selDoc = docOptions.find((i) => !i.disabled);
      if (selDoc) {
        handleChangeDoctor([selDoc]);
      }
    }
    if (!doctors.length) return;
    if (doctorsLoad === 1) return setDoctorsLoad(doctorsLoad + 1);
    applyFilterToEvents();
    setDoctorsLoad(doctorsLoad + 1);
  }, [formikRef, doctors]);

  let fullCalendarView = fullCalendarRef?.current?.getApi()?.view;
  let fullCalendarViewTitle = fullCalendarView?.title;

  useEffect(() => {
    if (fullCalendarView) {
      let newUrlParams = urlActions(
        window.location.href,
        "set",
        "calendarInitView",
        fullCalendarView.type
      );
      let dateToPush = new Date(fullCalendarView.activeStart);
      dateToPush.setDate(dateToPush.getDate() + 1);
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "calendarInitDate",
        dateToPush.toISOString().split("T")[0]
      );
      window.history.pushState("", "", "?" + newUrlParams);
    }
  }, [fullCalendarView, fullCalendarViewTitle]);

  const printDayView = () => {
    let variables = {
      generate: true,
    };
    generateEventPrintCode({ variables });
  };

  const applyFilterToEvents = useCallback(() => {
    displayLoaderVar(true);
    let allVarsToBeFetched = variablesToBeFetched;
    let status = urlActions(window.location.href, "get", "status");
    if (status) {
      allVarsToBeFetched["status"] = status;
    }

    allVarsToBeFetched["active"] = active;

    GetEvents({ variables: allVarsToBeFetched });

    let doctorArrStr = urlActions(window.location.href, "get", "doctor");
    let doctorArr = doctorArrStr?.split("__");

    if (doctorArr) {
      let doctorObjArr = doctors?.filter((i) =>
        doctorArr.some((j) => i?.node?.doctorId + "" === j + "")
      );
      let doctorSelectedArr = doctorObjArr?.map((doc) => {
        return {
          label: doc.node.firstName + doc.node.lastName,
          value: doc.node.identifier,
          doctorId: doc.node.doctorId,
        };
      });
      setApplyDoctorList(doctorSelectedArr);
    } else {
      setApplyDoctorList([]);
    }
  }, [GetEvents, doctors, variablesToBeFetched]);

  return (
    <Base
      title={
        is_my_appointments
          ? " My Appointments "
          : t("patientAppointments.appointments")
      }
      showHeader={true}
      isPatientPortal={!isStaff}
      rightChild={
        <>
          {fullCalendarRef?.current?.getApi()?.view?.type ===
          "resourceTimeGridDay" ? (
            <Button
              variant="primary"
              className="back mx-2 mb-2 create-group-app-btn"
              onClick={() =>
                printDayView(fullCalendarRef?.current?.getApi()?.view)
              }
            >
              {" "}
              <i className="fa fa-print mr-2" aria-hidden="true"></i> Print{" "}
            </Button>
          ) : null}
          <Link
            to={
              has_add_permission && isStaff
                ? "/search/patient"
                : "/patientPortal/create/appointment"
            }
            className="create-app-btn"
          >
            {!isStaff || (has_add_permission && isStaff) ? (
              <Button
                variant="primary"
                className="back mb-2 mr-2"
                onClick={createNewApp}
              >
                {" "}
                <i
                  className="fa fa-plus-circle mr-2"
                  aria-hidden="true"
                ></i>{" "}
                {t("patientAppointments.newAppointment")}{" "}
              </Button>
            ) : null}
          </Link>
          {has_add_permission && isStaff ? (
            <Link to={"/create/appointment/?group=true"}>
              {!isStaff || (has_add_permission && isStaff) ? (
                <Button
                  variant="primary"
                  className="back mb-2 create-group-app-btn"
                  onClick={createNewApp}
                >
                  {" "}
                  <i
                    className="fa fa-plus-circle mr-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {t("patientAppointments.newGroupAppointment")}{" "}
                </Button>
              ) : null}
            </Link>
          ) : null}
        </>
      }
    >
      <Row className="mt-3">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mx-auto mb-2"
          style={{ minHeight: "114vh" }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ values, handleChange }) => (
              <Form autoComplete="off" className="mb-4">
                <Row className="filter-block">
                  {!is_my_appointments ? (
                    <Col
                      md={12}
                      xl={4}
                      className={appLanguage === "en" ? "ml-auto" : "mr-auto"}
                    >
                      <Form.Group
                        as={Row}
                        className={
                          "multiSelectContainer " +
                          "docOptions_" +
                          docOptions.length
                        }
                      >
                        <Form.Label
                          column
                          sm={3}
                          md={3}
                          xl={4}
                          className="text-left"
                        >
                          {t("patientAppointments.practitioner")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <MultiSelect
                            options={docOptions}
                            value={selectedDocList}
                            required
                            ClearIcon=""
                            hasSelectAll={true}
                            placeholder="{t('patientAppointments.selectPractitioner')}"
                            onChange={(doc) => {
                              handleChangeDoctor(doc);
                            }}
                            labelledBy="Select"
                            overrideStrings={defaultStrings}
                          />
                        </Col>
                      </Form.Group>

                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={applyFilterToEvents}
                          type="button"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  ) : null}
                  {isStaff ? (
                    <Col md={12} xl={4}>
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          {t("patientAppointments.patient")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8} ref={searchPatientRef}>
                          <Form.Control
                            placeholder="Select Patient"
                            autoComplete="off"
                            type="text"
                            name="patient"
                            value={values.patient}
                            onChange={handleChange}
                            className="mr-sm-2 pr-0 float-left pr-5"
                          />
                          <span
                            onClick={() => setShowPatientSearch(true)}
                            className="search-icon"
                          >
                            <i className="fa fa-search"></i>
                          </span>
                        </Col>
                        <Col md={12} className="d-flex justify-content-end">
                          <Button
                            variant="link"
                            className="back fontSmall"
                            onClick={resetPatient}
                          >
                            Clear Patient
                          </Button>
                        </Col>
                      </Form.Group>
                    </Col>
                  ) : null}
                  <Col md={12} xl={4}>
                    <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4}>
                        Status
                      </Form.Label>
                      <Col sm={12} md={9} xl={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="status"
                          value={selectedStatus}
                          onChange={handleChangeStatus}
                        >
                          <option value="">Select Status</option>
                          {Object.entries(eventStatus).map(
                            ([key, value], index) => {
                              return (
                                <option value={key} key={index}>
                                  {value}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>

          {pageLoading ? (
            <Preloader />
          ) : (
            <CalendarHoc
              myAppointments={true}
              userDetails={userDetails}
              stopPolling={stopPolling}
              applyFilterToEvents={applyFilterToEvents}
              eventsList={eventsList}
              loading={eventsLoading}
              fullCalendarRef={fullCalendarRef}
              GetEvents={GetEvents}
              GetEventsCall={GetEventsCall}
              selectedDoctor={selectedDoctor}
              doctorsList={doctorsList}
              doctorResources={getDocAsResources()}
              selectedDocList={applyDoctorList}
              variablesToBeFetched={variablesToBeFetched}
              setVariablesToBeFetched={setVariablesToBeFetched}
            />
          )}
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default withRouter(MyAppointments);
