//@ts-nocheck
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useAppSelector, useAppDispatch } from "redux/reducers/hooks";
import {
  getSlots,
  incrementSlideCounter,
  updateAppointmenTime,
} from "redux/slices/appointmentSlice";
import moment from "moment-timezone";
import "moment/locale/ar-sa";
import { useTranslation } from "react-i18next";

function getDatesInCalendar(
  year: number,
  month: number,
  day: number,
  lang: string
): { date: String; primary: string; secondary: string; fulldate: string }[] {
  const startDate = new Date(year, month, day);
  const endDate = new Date(year, 11, 31); // End of the year

  const dates: {
    date: String;
    primary: string;
    secondary: string;
    fulldate: string;
  }[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const date = new Date(currentDate);

    // tracker format
    const otherFormat = new Date(currentDate);
    const year = otherFormat.getFullYear();
    const month = ("0" + (otherFormat.getMonth() + 1)).slice(-2);
    const day = ("0" + otherFormat.getDate()).slice(-2);
    const otherResult = `${year}-${month}-${day}`;

    // primary
    const options: Intl.DateTimeFormatOptions = { weekday: "long" };
    const primaryLocal = lang === "rtl" ? "ar-EG" : "en-US";
    const primary = date.toLocaleDateString(primaryLocal, options);

    // secondary
    const dateString = date;
    const secondaryOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const secondary = dateString.toLocaleDateString(
      primaryLocal,
      secondaryOptions
    );

    // fulldate
    const optionsLong: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedFullDate = date.toLocaleDateString(
      primaryLocal,
      optionsLong
    );
    const parts = formattedFullDate.split(" ");
    const dayOfWeek = parts[0];
    const dayOfMonth = parts[2];
    const monthLong = parts[1];
    const desiredFulldate =
      lang === "rtl"
        ? `${dayOfWeek} ${monthLong}  ${dayOfMonth}`
        : `${dayOfWeek} ${dayOfMonth} ${monthLong}`;
    dates.push({
      date: otherResult,
      primary,
      secondary,
      fulldate: desiredFulldate.replace(/,/g, ""),
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

const getDirection = (lang: string) => {
  return lang === "ar" ? "rtl" : "ltr";
};

const Appointment = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);
  const listRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [dateTracker, setDateTracker] = useState<string>("");
  const [isLast, setIsLast] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const currentdate = dayjs();

  const year = currentdate.year();
  const month = currentdate.month();
  const day = currentdate.date();

  const { loading, stepsData, availableSlots } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  const loadSlots = useCallback(async () => {
    const practitionerData = stepsData.find(
      (item) => item.title === "practitioner"
    );
    const { service } = practitionerData.data || {};

    if (service) {
      //@ts-ignore
      const payload = {
        serviceId: service?.pk,
        date: selectedDate.toISOString().slice(0, 10),
      };

      dispatch(getSlots(payload));
    }
  }, [dispatch, selectedDate, stepsData]);

  useEffect(() => {
    loadSlots();
  }, [loadSlots]);

  const dateList = getDatesInCalendar(year, month, day, direction);

  useEffect(() => {
    setDateTracker(
      selectedDate
        ? `${selectedDate.getFullYear()}-${String(
            selectedDate.getMonth() + 1
          ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(
            2,
            "0"
          )}`
        : ""
    );

    // scroll to selected date
    const selectedItem = document.getElementById(`_${dateTracker}`);
    if (selectedItem && listRef.current) {
      const listContainer = document.querySelector(".date-list-container");
      //@ts-ignore
      const listWidth = listContainer.offsetWidth;
      const listItemWidth = selectedItem.offsetWidth;
      const itemIndex = dateList.findIndex((item) => item.date === dateTracker);

      // eslint-disable-next-line eqeqeq
      if (itemIndex != -1) {
        let scrollLeft =
          itemIndex * listItemWidth - listWidth / 2 + listItemWidth / 2;
        if (direction === "rtl") {
          scrollLeft = -scrollLeft;
        }
        //@ts-ignore
        listRef.current.scroll({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    }
  }, [selectedDate, dateTracker, dateList, availableSlots, direction]);

  const handleScrollLeft = () => {
    if (listRef.current) {
      const container = listRef.current;
      //@ts-ignore
      setIsFirst(container.scrollLeft === 0);
      //@ts-ignore
      setIsLast(
        container.scrollLeft + container.clientWidth === container.scrollWidth
      );

      //@ts-ignore
      listRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    if (listRef.current) {
      //@ts-ignore
      const container = listRef.current;
      //@ts-ignore
      setIsFirst(container.scrollLeft === 0);
      //@ts-ignore
      setIsLast(
        container.scrollLeft + container.clientWidth === container.scrollWidth
      );

      //@ts-ignore
      listRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const renderSlots = useMemo(() => {
    if (loading) {
      return <LinearProgress data-testid="loader-testid" />;
    } else if (availableSlots && availableSlots.length > 0) {
      return (
        <List className="w-100 time-list">
          {availableSlots?.map((item, index) => {
            const [start, end] = item;

            moment.locale(direction === "ltr" ? "en" : "ar-sa");
            let formattedDateTimeStart = moment(start)
              .tz(moment.tz.guess())
              .format("hh:mm A");

            let formattedDateTimeEnd = moment(end)
              .tz(moment.tz.guess())
              .format("hh:mm A");

            return (
              <ListItem
                key={index}
                className="text-center w-100 item"
                onClick={() => {
                  dispatch(incrementSlideCounter());
                  const gh = dateList.find(
                    (item1) => item1.date === dateTracker
                  );
                  if (gh) {
                    const payload = {
                      utcDateTime: item,
                      display: `${gh.fulldate} | ${formattedDateTimeStart}-${formattedDateTimeEnd}`,
                    };
                    dispatch(updateAppointmenTime(payload));
                  }
                }}
              >
                <span className="w-100">{`${formattedDateTimeStart} - ${formattedDateTimeEnd}`}</span>
              </ListItem>
            );
          })}
        </List>
      );
    } else {
      return (
<div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
        textAlign: 'center',
      }}
    >
      <Typography variant="body1" data-testid="nodata-testid">
        {t("noSlotAvailable")}
      </Typography>
    </div>
        
      );
    }
  }, [availableSlots, dateList, dateTracker, direction, dispatch, loading, t]);

  return (
    <>
      {/* title */}
      <Container maxWidth="xl">
        <h3 className="slide-header mb-4">
          {t("createEventForm.select_date_time")}
        </h3>

        {/* calendar input */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(selectedDate) || null}
            name="dob"
            onChange={(date) => setSelectedDate(new Date(date))}
            sx={{
              backgroundColor: "white",
              ".MuiInputBase-input": {
                padding: ".375rem .75rem",
                backgroundColor: "white",
              },
            }}
            slotProps={{
              textField: { fullWidth: true },
            }}
          />
        </LocalizationProvider>
      </Container>

      {/* date list */}
      <Container maxWidth="xl" className="datelist-layout">
        <div className="px-5 mt-4 timeline-con">
          {/* controls */}
          {direction === "rtl" ? (
            <>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`chev-next ${isFirst ? "no-option" : ""}`}
                onClick={handleScrollRight}
              />
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={`chev-prev ${isLast ? "no-option" : ""}`}
                onClick={handleScrollLeft}
              />
              {/* @ts-ignore */}
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={`chev-prev ${isFirst ? "no-option" : ""}`}
                onClick={handleScrollLeft}
              />
              {/* @ts-ignore */}
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`chev-next ${isLast ? "no-option" : ""}`}
                onClick={handleScrollRight}
              />
            </>
          )}

          {/* date timeleine */}
          <List className="date-list-container" ref={listRef}>
            {dateList.map((date, index) => (
              <ListItem
                key={index}
                id={`_${dateTracker}`}
                /* @ts-ignore */
                onClick={() => setSelectedDate(new Date(`${date.date}`))}
                className={` ${
                  dateTracker === date.date
                    ? "date-list-item selected"
                    : "date-list-item"
                }`}
              >
                <ListItemText
                  primary={
                    <span
                      className={
                        direction === "ltr"
                          ? "primary-text-ltr"
                          : "primary-text-rtl"
                      }
                    >
                      {date.primary}
                    </span>
                  }
                  secondary={
                    <span
                      className={
                        direction === "ltr"
                          ? "secondary-text-ltr"
                          : "secondary-text-rtl"
                      }
                    >
                      {date.secondary}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
        <Divider className="p-0 mb-4" />
      </Container>

      {/* timings list */}
      <Container maxWidth="xl">{renderSlots}</Container>
    </>
  );
};

export default Appointment;
