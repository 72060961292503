import Services from "./Services";
import { forwardRef, useRef, useMemo } from "react";
import Step from "./Step";
import { Slide, Box, Fade } from "@mui/material";
import Practitioner from "./Practitoner";
import Appointment from "./Appointment";
import WitingListConfirm from "./WaitingListConfirm";
import RequestReason from "./RequestReason";
import { useAppSelector } from "redux/reducers/hooks";
import Base from "Views/base";
import HistoryBackButton from "./BackButton";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const ServiceSlide = forwardRef((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <Services />
    </Box>
  );
});

const PractitionSlide = forwardRef((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <Practitioner />
    </Box>
  );
});

const AppointmentSlide = forwardRef((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <Appointment />
    </Box>
  );
});

const WaitingListSlide = forwardRef((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <WitingListConfirm />
    </Box>
  );
});

const RequestSlide = forwardRef((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <RequestReason />
    </Box>
  );
});

interface StepObj {
  title: string;
  data?: any;
}

interface StepProgressProps {
  slideprop: number;
  stepsprop: StepObj;
}

const StepProgress = forwardRef<HTMLDivElement, StepProgressProps>(
  (props, ref) => {
    const { slideprop, stepsprop } = props;

    return (
      <Box ref={ref} {...props}>
        <Step slide={slideprop} steps={stepsprop} />
      </Box>
    );
  }
);

export const getDirection = (lang: string) => {
  return lang === "ar" ? "rtl" : "ltr";
};

const PatientAppointmentForm = () => {
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);
  const theme = useMemo(
    () =>
      createTheme({
        direction: direction, // Use dynamic direction
        palette: {
          mode: "light", // Assuming light mode, but you can make this dynamic as well
        },
      }),
    [direction]
  );
  const cacheRtl = useMemo(
    () =>
      createCache({
        key: direction === "rtl" ? "muirtl" : "mui",
        stylisPlugins: direction === "rtl" ? [rtlPlugin] : [],
      }),
    [direction]
  );
  const containerRef = useRef(null);
  const { slideCounter, completedSteps, stepsData } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );
  const stepProgressRef = useRef<HTMLDivElement>(null);

  return (
    <Base
      isDashboard={true}
      showHeader={false}
      isPatientPortal={true}
      containerClass="mt-0"
      // rightContainerClass={loadingPage ? "page_loader_base" : ""}
      pageHeaderSx={{ height: "auto" }}
    >
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="bg-main mt-4" dir={direction}>
            <HistoryBackButton />
            {/* title */}
            <h3 className="ff-priamry text-purple-dark py-4">
              {t("patientAppointmentCreate.appointmentDetails")}
            </h3>

            {/* stepper */}
            <div className="stepper-layout">
              {completedSteps.length === 0 ? (
                <div className="w-100 stepper-container mb-4"></div>
              ) : (
                <div className="w-100 stepper-container mb-4 inline">
                  {/* completed steps */}
                  {completedSteps.map((stepIndex, index) => (
                    <Fade in={true} key={index} className="inline-item">
                      <StepProgress
                        ref={stepProgressRef}
                        slideprop={stepIndex - 1}
                        stepsprop={stepsData[stepIndex - 1]}
                      />
                    </Fade>
                  ))}
                </div>
              )}
            </div>

            {/* slides */}
            <Box ref={containerRef}>
              {slideCounter === 0 && (
                <Slide in={slideCounter === 0} direction="left">
                  <ServiceSlide />
                </Slide>
              )}
              {slideCounter === 1 && (
                <Slide in={slideCounter === 1} direction="left">
                  <PractitionSlide />
                </Slide>
              )}
              {slideCounter === 2 && (
                <Slide in={slideCounter === 2} direction="left">
                  <AppointmentSlide />
                </Slide>
              )}
              {slideCounter === 3 && (
                <Slide in={slideCounter === 3} direction="left">
                  <WaitingListSlide />
                </Slide>
              )}
              {slideCounter === 4 && (
                <Slide in={slideCounter === 4} direction="left">
                  <RequestSlide />
                </Slide>
              )}
            </Box>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </Base>
  );
};

export default PatientAppointmentForm;
