import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import {
  gql,
  useQuery,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getErrorMessage,
  isUserStaff,
  concatAllErrors,
  // getPermissionForAction,
} from "../Common/helpers";
import Preloader from "../Common/Preloder/Preloader";
import { useTranslation } from "react-i18next";
import { patientBadgesVar } from "../cache/cache";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers";

function LabRequests(props) {
  const identifier = props.match.params.identifier;
  const isStaff = isUserStaff();
  const { t } = useTranslation();
  const patientBadgesObject = useReactiveVar(patientBadgesVar);
  const [rawLabRequests, setLabRequests] = useState([]);

  const [openSnackbar] = useSnackbar(error_options);
  const REQUEST_LAB_REQUESTS = gql`
  query {
    labRequests(patient_Identifier:"${identifier}"){
      edges {
        node {
            id
            searchText
            usedTemplate{
              name
            }
          patient{
            id
            identifier
            firstName
            middleName
            lastName
          }
          groupTests{
            edges{
              node{
                name  
                labTests{
                  edges{
                    node{
                      name
                    }
                  }
                }
                children{
                  edges{
                    node{
                      name
                    }
                  }
                }
              }
            }
          }
          
          specificTests{
            edges{
              node{
                name
                
              }
            }
          }
          testResults{
            edges{
              node{
                specificTest{
                  name
                }
                result
              }
            }
          }
        }
      }
    }
  }
`;

  const DELETE_LAB_REQUEST = gql`
    mutation deleteLabRequest($id: ID!) {
      deleteLabRequest(id: $id) {
        deleted
      }
    }
  `;

  const { loading } = useQuery(REQUEST_LAB_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLabRequests(deepCopy(data));
    },

    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_LAB_REQUESTS error " + e);
      }
    },
  });

  // DELETE_ LAB REQ
  const [deleteLabRequest] = useMutation(DELETE_LAB_REQUEST, {
    onCompleted: ({ deleteLabRequest }) => {
      if (deleteLabRequest?.errors && deleteLabRequest?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteLabRequest?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    refetchQueries: [
      { query: REQUEST_LAB_REQUESTS, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_LAB_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  var labRequests =
    rawLabRequests && rawLabRequests.labRequests
      ? rawLabRequests.labRequests.edges
      : [];

  const deleteRefFunc = (id) => {
    if (window.confirm("Delete Lab Request?")) {
      let val = {};
      val["id"] = id;
      deleteLabRequest({ variables: val });
    }
  };

  const REQUEST_PATIENT_BADGES = gql`
    query {
      patientBadges
    }
  `;

  // eslint-disable-next-line no-empty-pattern
  const [getPatientBadges, {}] = useLazyQuery(REQUEST_PATIENT_BADGES, {
    fetchPolicy: "network-only",
    onCompleted: ({ patientBadges }) => {
      patientBadgesVar(patientBadges);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const POST_PATIENTS_LABREQ_READ = gql`
    mutation labRequestReadView($labs: [ID]!) {
      labRequestReadView(input: { labs: $labs }) {
        updated
      }
    }
  `;

  // EVENT PRINT CODE
  const [postLabReqReadView] = useMutation(POST_PATIENTS_LABREQ_READ, {
    onCompleted: ({ labRequestReadView }) => {
      getPatientBadges();
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("POST_PATIENTS_LABREQ_READ error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    if (labRequests.length > 0 && patientBadgesObject?.labs) {
      let labs = [];
      labs = labRequests.map((lab) => {
        return lab.node.id;
      });
      postLabReqReadView({ variables: { labs: labs } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labRequests]);

  return (
    <Base
      page_loading={loading}
      title={t("reports.labRequests")}
      isPatientPortal={!isStaff}
      showHeader={true}
    >
      {loading ? (
        <Preloader />
      ) : (
        <Row>
          {labRequests && labRequests.length > 0 ? (
            labRequests.map((lab, index) => {
              let sortedLabReq = lab?.node?.testResults?.edges?.sort((a, b) =>
                a?.node?.specificTest?.name?.localeCompare(
                  b?.node?.specificTest?.name
                )
              );
              return lab.node.testResults.edges.length > 0 ? (
                <Col
                  key={index}
                  xs={12}
                  md={4}
                  className="d-flex-column-str  mb-4"
                >
                  <Card>
                    <Card.Body className="p-4">
                      <Row className="mb-2">
                        <Col
                          md={8}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <h6 className="textPrimaryBold mb-0 ">
                            {t("reports.labRequest")}
                          </h6>
                        </Col>
                        <Col
                          md={2}
                          className="d-flex justify-content-end ml-auto"
                        >
                          <Link
                            to={
                              isStaff
                                ? "/patient/labrequests/detail/" + lab.node.id
                                : "/patientPortal/labrequests/detail/" +
                                  lab.node.id
                            }
                          >
                            <Button variant="link" className=" p-0">
                              {t("reports.view")}
                            </Button>
                          </Link>
                        </Col>
                        {isStaff ? (
                          <Col md={2} className="d-flex justify-content-end">
                            <Button
                              variant="link"
                              className="danger-color  p-0"
                              onClick={() => deleteRefFunc(lab.node.id)}
                            >
                              {t("reports.delete")}
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col md={12}>
                          <h6 className="capitalize">
                            <b>{lab.node.usedTemplate.name}</b>
                          </h6>
                        </Col>
                      </Row>
                      {sortedLabReq?.map((result, index) => {
                        return (
                          <Row key={index}>
                            <Col md={12}>
                              <h6> {result?.node?.specificTest?.name} </h6>
                            </Col>
                          </Row>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </Col>
              ) : null;
            })
          ) : (
            <Col xs={12} md={6}>
              {t("reports.noLabRequest")}
            </Col>
          )}
        </Row>
      )}
    </Base>
  );
}
export default withRouter(LabRequests);
