import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  Modal,
} from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import CustomModals from "../Modal/modal";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import DatePicker from "react-datepicker";
import {
  error_options,
  SNACK_DURATION,
  getObjKey,
  getForamttedTime,
  getDate,
  removeEmpty,
  getPermissionForAction,
  getErrorMessage,
  isUserStaff,
  concatAllErrors,
  ERROR_MESSAGE,
} from "../../Common/helpers";
import { valuesForPrescInstanceVar } from "../../cache/cache";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import { useReactiveVar } from "@apollo/client";

function FormBlock(props) {
  const {
    blockPrintData,
    blockData,
    REQUEST_UPDATE,
    is_patient_record,
    DYNAMIC_FORM_DATA,
    intake_form,
    survey_form,
    is_encounter,
    form_card,
    formBlockRef,
    encounterIdList,
    medical_record_permission,
    submittedFormUpdate,
    other_form_blocks,
    REQUEST_PATIENT_UPDATE,
    setBlockData,
    is_prescription,
    medicationBlockForm,
    other_json_data,
    collapseByDefault,
    DYNAMIC_FORM_DATA_UPDATE_MAIN,
    is_prev_medication_block,
    is_current_medication_block,
    previousEncounterPrescriptionBlockData,
  } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [showModal, setShowModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [showDeleteOrMoveModal, setShowDeleteOrMoveModal] = useState(false);
  const [deleteMoveData, setDeleteMoveData] = useState(null);
  const [choiceGroupData, setChoiceGroupData] = useState({});
  const [editBlockInstance, setEditBlockInstance] = useState(null);
  const [overrideForm, setOverrideForm] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [prescCopyToMed, setPrescCopyToMed] = useState(false);
  const [instanceToDeleteVariables, setInstanceToDeleteVariables] =
    useState(null);
  const isStaff = isUserStaff();
  const valuesForPrescCopy = useReactiveVar(valuesForPrescInstanceVar);
  const { t } = useTranslation();
  var formikRef = useRef();

  if (formBlockRef) {
    formikRef = formBlockRef;
  }

  const CREATE_UPDATE_FORM_BLOCK = gql`
    mutation createUpdateFormBlockField(
      $formBlock: ID!
      $relatedModel: ID!
      $jsonData: GenericScalar
      $instanceCode: String
    ) {
      createUpdateFormBlockField(
        input: {
          formBlock: $formBlock
          relatedModel: $relatedModel
          jsonData: $jsonData
          instanceCode: $instanceCode
        }
      ) {
        obj {
          date
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_INTAKE_BLOCK = gql`
    mutation createUpdateIntakeFormBlockField(
      $formBlock: ID!
      $relatedModel: ID!
      $jsonData: GenericScalar
      $instanceCode: String
    ) {
      createUpdateIntakeFormBlockField(
        input: {
          formBlock: $formBlock
          relatedModel: $relatedModel
          jsonData: $jsonData
          instanceCode: $instanceCode
        }
      ) {
        obj {
          data
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_SURVERY_BLOCK = gql`
    mutation createUpdateSurveyFormBlockField(
      $formBlock: ID!
      $relatedModel: ID!
      $jsonData: GenericScalar
      $instanceCode: String
    ) {
      createUpdateSurveyFormBlockField(
        input: {
          formBlock: $formBlock
          relatedModel: $relatedModel
          jsonData: $jsonData
          instanceCode: $instanceCode
        }
      ) {
        obj {
          data
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_PATIENT_FORM_BLOCK = gql`
    mutation createUpdatePatientFormBlockField(
      $formBlock: ID!
      $relatedModel: ID!
      $jsonData: GenericScalar
      $instanceCode: String
    ) {
      createUpdatePatientFormBlockField(
        input: {
          formBlock: $formBlock
          relatedModel: $relatedModel
          jsonData: $jsonData
          instanceCode: $instanceCode
        }
      ) {
        obj {
          data
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DELETE_MEDICATION_BLOCK_INSTANCE = gql`
    mutation deleteMedicationBlockInstance(
      $id: ID!
      $instanceCode: String!
      $formBlockKey: String!
    ) {
      deleteMedicationBlockInstance(
        id: $id
        instanceCode: $instanceCode
        formBlockKey: $formBlockKey
      ) {
        obj {
          data
        }
      }
    }
  `;

  const DELETE_PATIENT_PRESCRIPTION_BLOCK_INSTANCE = gql`
    mutation deletePatientPrescriptionFormBlockInstance(
      $id: ID!
      $instanceCode: String!
      $formBlockKey: String!
    ) {
      deletePatientPrescriptionFormBlockInstance(
        id: $id
        instanceCode: $instanceCode
        formBlockKey: $formBlockKey
      ) {
        obj {
          data
        }
      }
    }
  `;

  const DELETE_FORM_INSTANCE = gql`
    mutation deleteFormBlockInstance(
      $id: ID!
      $instanceCode: String!
      $formBlockKey: String!
    ) {
      deleteFormBlockInstance(
        id: $id
        instanceCode: $instanceCode
        formBlockKey: $formBlockKey
      ) {
        obj {
          data
        }
      }
    }
  `;

  const DELETE_PATIENT_FORM_INSTANCE = gql`
    mutation deletePatientFormBlockInstance(
      $id: ID!
      $instanceCode: String!
      $formBlockKey: String!
    ) {
      deletePatientFormBlockInstance(
        id: $id
        instanceCode: $instanceCode
        formBlockKey: $formBlockKey
      ) {
        obj {
          data
        }
      }
    }
  `;

  // CREATE INTAKE FORM BLOCK
  const [createUpdateIntakeFormBlockField] = useMutation(
    CREATE_UPDATE_INTAKE_BLOCK,
    {
      onCompleted: ({ createUpdateIntakeFormBlockField }) => {
        if (
          createUpdateIntakeFormBlockField?.errors &&
          createUpdateIntakeFormBlockField?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            createUpdateIntakeFormBlockField?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else if (createUpdateIntakeFormBlockField.obj) {
          if (formikRef && formikRef.current) {
            let formikValues = formikRef.current.values;
            if (formikValues.closeModal === false) {
              formikRef.current.setValues(getEmptyValues(initialValuesBlock));
              setEditBlockInstance(null);
              setEnableEdit(false);
            } else {
              setShowModal(false);
              setTimeout(() => {
                setShowDeleteOrMoveModal(false);
              }, 500);
            }
          } else {
            setShowModal(false);
            setTimeout(() => {
              setShowDeleteOrMoveModal(false);
            }, 500);
          }

          if (form_card) {
            if (submittedFormUpdate) {
              submittedFormUpdate(blockData.blockId);
            }
          }
        }
      },

      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "createUpdateIntakeFormBlockField error " + e
          );
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // CREATE SURVEY FORM BLOCK
  const [createUpdateSurveyFormBlockField] = useMutation(
    CREATE_UPDATE_SURVERY_BLOCK,
    {
      onCompleted: ({ createUpdateSurveyFormBlockField }) => {
        if (
          createUpdateSurveyFormBlockField?.errors &&
          createUpdateSurveyFormBlockField?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            createUpdateSurveyFormBlockField?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else if (createUpdateSurveyFormBlockField.obj) {
          if (formikRef && formikRef.current) {
            let formikValues = formikRef.current.values;
            if (formikValues.closeModal === false) {
              formikRef.current.setValues(getEmptyValues(initialValuesBlock));
              setEditBlockInstance(null);
              setEnableEdit(false);
            } else {
              setShowModal(false);
              setTimeout(() => {
                setShowDeleteOrMoveModal(false);
              }, 500);
            }
          } else {
            setShowModal(false);
            setTimeout(() => {
              setShowDeleteOrMoveModal(false);
            }, 500);
          }

          if (form_card) {
            if (submittedFormUpdate) {
              submittedFormUpdate(blockData.blockId);
            }
          }
        }
      },
      refetchQueries: [
        {
          query: REQUEST_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "createUpdateSurveyFormBlockField error " + e
          );
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // CREATE ENCOUNTER FORM BLOCK
  const [createUpdateFormBlockField] = useMutation(CREATE_UPDATE_FORM_BLOCK, {
    onCompleted: ({ createUpdateFormBlockField }) => {
      if (
        createUpdateFormBlockField?.errors &&
        createUpdateFormBlockField?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdateFormBlockField?.errors,
          true
        );
        if (!formikRef.current.values.hideSnackbar) {
          openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
        }
      } else if (createUpdateFormBlockField.obj) {
        if ((formikRef && formikRef.current) || valuesForPrescCopy?.data) {
          let formikValues = valuesForPrescCopy?.data
            ? valuesForPrescCopy?.data
            : formikRef.current.values;
          if (valuesForPrescCopy) {
            let isLastPrescInstance =
              valuesForPrescCopy?.index ===
              valuesForPrescCopy?.prescriptionListToSave.length - 1;
            if (isLastPrescInstance) {
              valuesForPrescInstanceVar(null);
            } else {
              if (parseInt(valuesForPrescCopy?.index) !== NaN) {
                // if value is 0 then then the result will be false
                let nextIndex = valuesForPrescCopy?.index + 1;
                let nextPresc =
                  valuesForPrescCopy?.prescriptionListToSave[nextIndex];
                updatePrescSavedValAndCreate(nextPresc, nextIndex);
              }
            }
          }
          if (formikValues.closeModal === false) {
            formikRef.current.setValues(getEmptyValues(initialValuesBlock));
            clearDiagnosisFields();
            clearMedicalFields();
            setEditBlockInstance(null);
            setEnableEdit(false);
          } else {
            setShowModal(false);
            setTimeout(() => {
              setShowDeleteOrMoveModal(false);
            }, 500);
          }
          if (formikValues.copyToMedication === true && medicationBlockForm) {
            let medicationPostValues = {};
            let prescriptionFields = formikValues;
            prescriptionFields = removeEmpty(prescriptionFields);
            let prescriptionFieldsKeys = Object.keys(prescriptionFields);
            let medicationFields =
              medicationBlockForm.node.formBlock.blockFormFields.edges.map(
                (fld) => {
                  return {
                    fieldName: fld.node.formField.fieldName,
                    isRequired: getIsRequired(fld.node),
                  };
                }
              );
            let medicationfieldName = medicationFields.find(
              (i) => i.fieldName.indexOf("medication") > -1
            );
            let otherRequiredMedKeys = medicationFields.map((i) => {
              if (i.isRequired === true) {
                return i.fieldName;
              }
              return null;
            });

            let medJsonData = {};
            otherRequiredMedKeys = otherRequiredMedKeys.filter(
              (i) => i !== medicationfieldName.fieldName && i !== null
            );
            // check if every required field in medicaiton block exists in prescription block
            let allFound = otherRequiredMedKeys.every((ai) =>
              prescriptionFieldsKeys.includes(ai)
            );
            if (allFound) {
              if (medicationfieldName) {
                medicationfieldName = medicationfieldName.fieldName;
              }
              let prescriptionFieldName = blockData.forms.find(
                (i) => i.node.formField.fieldName.indexOf("prescription") > -1
              );
              if (prescriptionFieldName) {
                prescriptionFieldName =
                  prescriptionFieldName.node.formField.fieldName;
              }
              medJsonData[medicationfieldName] =
                prescriptionFields[prescriptionFieldName];
              medicationFields = medicationFields.filter(
                (i) => i.fieldName !== medicationfieldName
              );
              medicationFields.map((i) => {
                medJsonData[i.fieldName] =
                  prescriptionFields[i.fieldName] !== undefined
                    ? prescriptionFields[i.fieldName]
                    : "";
                return null;
              });

              medicationPostValues["jsonData"] = JSON.stringify(
                medJsonData
              ).replace(/'/g, "`");
              medicationPostValues["formBlock"] =
                medicationBlockForm.node.formBlock.id;
              medicationPostValues["relatedModel"] = blockData.patientFormData;
              let closeModal = formikValues.closeModal;
              callMedicalCopyMutation(medicationPostValues, closeModal);
            } else {
              alert(
                "There are incompatible fields on the medication block. Please review the field names and try again."
              );
            }
          }
        } else {
          setShowModal(false);
          setTimeout(() => {
            setShowDeleteOrMoveModal(false);
          }, 500);
        }
      }
    },
    refetchQueries: [
      {
        query: REQUEST_UPDATE,
        fetchPolicy: "network-only",
        variables: { state: Math.random() },
      },
      {
        query: REQUEST_PATIENT_UPDATE,
        fetchPolicy: "network-only",
        variables: { state: Math.random() },
      },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("createUpdateFormBlockField error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const updatePrescSavedValAndCreate = (nextPresc, nextIndex) => {
    valuesForPrescInstanceVar({
      data: nextPresc.jsonDataToUse,
      index: nextIndex,
      prescriptionListToSave: valuesForPrescCopy.prescriptionListToSave,
    });
    createUpdateFormBlockField({ variables: nextPresc });
  };

  const callMedicalCopyMutation = (medicationPostValues, closeModal) => {
    formikRef?.current?.setFieldValue("copyToMedication", false);
    formikRef?.current?.setFieldValue("closeModal", closeModal);
    createUpdatePatientFormBlockField({ variables: medicationPostValues });
  };

  // CREATE PATIENT FORM BLOCK
  const [createUpdatePatientFormBlockField] = useMutation(
    CREATE_UPDATE_PATIENT_FORM_BLOCK,
    {
      onCompleted: ({ createUpdatePatientFormBlockField }) => {
        if (
          createUpdatePatientFormBlockField?.errors &&
          createUpdatePatientFormBlockField?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            createUpdatePatientFormBlockField?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else if (createUpdatePatientFormBlockField.obj) {
          if (formikRef && formikRef.current) {
            let formikValues = formikRef.current.values;
            if (formikValues.closeModal === false) {
              formikRef.current.setValues(getEmptyValues(initialValuesBlock));
              clearDiagnosisFields();
              clearMedicalFields();
              setEditBlockInstance(null);
              setEnableEdit(false);
            } else {
              setShowModal(false);
              setTimeout(() => {
                setShowDeleteOrMoveModal(false);
              }, 500);
            }
          } else {
            setShowModal(false);
            setTimeout(() => {
              setShowDeleteOrMoveModal(false);
            }, 500);
          }
          // props.history.push("/encounter/detail/"+createEncounter.obj.id);
        }
      },
      refetchQueries: [
        {
          query: REQUEST_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
        {
          query: REQUEST_PATIENT_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "createUpdatePatientFormBlockField error " + e
          );
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // delete form instance
  const [deleteMedicationBlockInstance] = useMutation(
    DELETE_MEDICATION_BLOCK_INSTANCE,
    {
      onCompleted: ({ deleteMedicationBlockInstance }) => {
        if (
          deleteMedicationBlockInstance?.errors &&
          deleteMedicationBlockInstance?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            deleteMedicationBlockInstance?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else {
          setShowModal(false);
          setTimeout(() => {
            setShowDeleteOrMoveModal(false);
          }, 500);
        }
      },
      refetchQueries: [
        {
          query: REQUEST_PATIENT_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("deleteMedicationBlockInstance error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // delete form instance
  const [deleteMainPatientBlockInstance] = useMutation(
    DELETE_MEDICATION_BLOCK_INSTANCE,
    {
      onCompleted: ({ deleteMainPatientBlockInstance }) => {
        if (
          deleteMainPatientBlockInstance?.errors &&
          deleteMainPatientBlockInstance?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            deleteMainPatientBlockInstance?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else {
          setShowModal(false);
          setTimeout(() => {
            setShowDeleteOrMoveModal(false);
          }, 500);
        }
      },
      refetchQueries: [
        {
          query: REQUEST_PATIENT_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("deleteMainPatientBlockInstance error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // delete form instance
  const [deletePatientPrescriptionFormBlockInstance] = useMutation(
    DELETE_PATIENT_PRESCRIPTION_BLOCK_INSTANCE,
    {
      onCompleted: ({ deletePatientPrescriptionFormBlockInstance }) => {
        if (
          deletePatientPrescriptionFormBlockInstance?.errors &&
          deletePatientPrescriptionFormBlockInstance?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            deletePatientPrescriptionFormBlockInstance?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else {
          setShowModal(false);
          setTimeout(() => {
            setShowDeleteOrMoveModal(false);
          }, 500);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "deletePatientPrescriptionFormBlockInstance error " + e
          );
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // delete form instance
  const [deleteFormBlockInstance] = useMutation(DELETE_FORM_INSTANCE, {
    onCompleted: ({ deleteFormBlockInstance }) => {
      if (
        deleteFormBlockInstance?.errors &&
        deleteFormBlockInstance?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deleteFormBlockInstance?.errors,
          true
        );
        if (!formikRef.current.values.hideSnackbar) {
          openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
        }
      } else {
        setShowModal(false);
        setTimeout(() => {
          setShowDeleteOrMoveModal(false);
        }, 500);
        deleteRelatedMainPatientBlock(deleteFormBlockInstance);
      }
    },
    refetchQueries: [
      {
        query: REQUEST_UPDATE,
        fetchPolicy: "network-only",
        variables: { state: Math.random() },
      },
      {
        query: REQUEST_PATIENT_UPDATE,
        fetchPolicy: "network-only",
        variables: { state: Math.random() },
      },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("deleteFormBlockInstance error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const deleteRelatedMainPatientBlock = (deletedInstData) => {
    if (DYNAMIC_FORM_DATA_UPDATE_MAIN) {
      let keys = Object.keys(DYNAMIC_FORM_DATA_UPDATE_MAIN);
      let dynamic_blk_id = keys.find(
        (i) => i.indexOf(instanceToDeleteVariables.formBlockKey) > -1
      );
      if (dynamic_blk_id) {
        let val = {};
        if (instanceToDeleteVariables?.instanceCode) {
          val["id"] = blockData.patientFormData;
          val["instanceCode"] = instanceToDeleteVariables.instanceCode;
          val["formBlockKey"] = dynamic_blk_id;
          deleteMainPatientBlockInstance({ variables: val }); // deleting instance from patient main record.
        }
      }
    }

    if (is_prescription) {
      // DELETE PRESCTIPTION FOR PATIENT PRESCRIPTION MODAL
      let val = {};
      val["id"] = blockData.prescriptionDataId; // patient_record?.node?.prescriptionData?.id
      val["instanceCode"] = instanceToDeleteVariables.instanceCode; // deleted prescription instance code
      val["formBlockKey"] =
        "encounter_" +
        blockData.encounterFormStaticId +
        "_" +
        instanceToDeleteVariables.formBlockKey; // deleted prescription form block key

      deletePatientPrescriptionFormBlockInstance({ variables: val });
    }
  };

  // delete patient form instance
  const [deletePatientFormBlockInstance] = useMutation(
    DELETE_PATIENT_FORM_INSTANCE,
    {
      onCompleted: ({ deletePatientFormBlockInstance }) => {
        if (
          deletePatientFormBlockInstance?.errors &&
          deletePatientFormBlockInstance?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            deletePatientFormBlockInstance?.errors,
            true
          );
          if (!formikRef.current.values.hideSnackbar) {
            openSnackbar(t("forms.fixErrors"), [SNACK_DURATION]);
          }
        } else if (deletePatientFormBlockInstance.deleted) {
          setShowModal(false);
          setTimeout(() => {
            setShowDeleteOrMoveModal(false);
          }, 500);
        }
      },
      refetchQueries: [
        {
          query: REQUEST_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
        {
          query: REQUEST_PATIENT_UPDATE,
          fetchPolicy: "network-only",
          variables: { state: Math.random() },
        },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("deletePatientFormBlockInstance error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const getEmptyValues = (initialValuesBlock) => {
    const emptyObj = {};
    let formikValues = formikRef?.current?.values;
    for (let key in initialValuesBlock) {
      emptyObj[key] = "";
      if (key === "copyToMedication") {
        emptyObj["copyToMedication"] = initialValuesBlock["copyToMedication"];
      }
    }
    if (formikValues?.closeModal) {
      emptyObj["closeModal"] = formikValues.closeModal;
    }
    return emptyObj;
  };

  //BASED ON THE VARIANTS, IT RETURNS RESPECTIVE FIELDS
  const getComponent = (form, handleChange, values) => {
    switch (form.formField.variant) {
      case "DROPDOWN":
        return dynamicComp(form, handleChange, values, "select");
      case "CHAR":
        return dynamicComp(form, handleChange, values, "input");
      case "TEXT":
        return dynamicComp(form, handleChange, values, "textarea");
      case "CHOICES":
        return choicesGroupComp(form, handleChange, values, "radiogroup");
      case "RADIO":
        return choicesGroupComp(form, handleChange, values, "radiogroup");
      case "BOOLEAN":
        return booleanComponent(form, handleChange, values, "checkbox");
      case "CHECKBOX":
        return choicesGroupComp(form, handleChange, values, "radiogroup", true);
      case "DATE":
        return dateComponent(form, handleChange, values, "dateselect");
      case "DIAGNOSIS":
        return diagnosisComponent(form, handleChange, values, "diagnosis");
      case "MEDICATION":
        return medicationComponent(form, handleChange, values, "diagnosis");
      case "PRESCRIPTION":
        return medicationComponent(form, handleChange, values, "diagnosis");
      default:
        return null;
    }
  };

  const clearDiagnosisFields = () => {
    try {
      var diagnosisFrame = document.getElementById("diagnosisFrame");
      if (diagnosisFrame) {
        var diagnosisInnerDoc =
          diagnosisFrame.contentDocument ||
          diagnosisFrame.contentWindow.document;
        let selectedEntity = diagnosisInnerDoc.getElementById(
          "paste-selectedEntity"
        );
        selectedEntity.value = "";
      }
    } catch {}
  };

  const clearMedicalFields = () => {
    try {
      var medicalFrame = document.getElementById("medicalFrame");
      if (medicalFrame) {
        var medicalInnerDoc =
          medicalFrame.contentDocument || medicalFrame.contentWindow.document;
        let rxterms = medicalInnerDoc.getElementById("rxterms");
        rxterms.value = "";
        let drug_strengths = medicalInnerDoc.getElementById("drug_strengths");
        drug_strengths.value = "";
      }
    } catch {}
  };

  // GET IS REQUIRED FROM BACKEND THROUGH MODEL TO USE FOR FRONTEND VALIDATION
  const getIsRequired = (current_form) => {
    if (current_form) {
      return current_form.required;
    } else {
      return false;
    }
  };

  const getWidth = (current_form_width) => {
    if (current_form_width) {
      let percent = parseInt(current_form_width) / 100;
      return percent * 12;
    } else {
      return 12;
    }
  };

  // DYNAMIC FIELD USED FOR INPUT,TEXTAREA, SELECT
  const dynamicComp = (current_form, handleChange, values, variant) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    return (
      <Form.Control
        autoComplete="off"
        as={variant}
        name={form_slug}
        rows={variant === "textarea" ? 4 : 1}
        // placeholder={t("forms.enter") + " " + current_form.formField.name}
        placeholder={""}
        value={values[form_slug]}
        onChange={(e) => {
          handleChange(e);
          clearErrorForFormField(form_slug);
        }}
        required={isRequired}
      >
        {variant &&
        variant === "select" &&
        current_form &&
        current_form.formField.options.edges ? (
          <>
            <option value={""}> {t("forms.select")}</option>
            {current_form.formField.options.edges.map((i_node, index) => {
              let i = i_node.node;
              return (
                <option value={i.staticId} key={index}>
                  {i.value}{" "}
                </option>
              );
            })}
          </>
        ) : null}
      </Form.Control>
    );
  };

  const choiceGroupSelect = (form_slug, options, i, multiselect) => {
    let staticId = i.staticId;
    if (multiselect) {
      let dataArr = choiceGroupData[form_slug]
        ? choiceGroupData[form_slug]
        : [];

      if (dataArr.includes(staticId)) {
        dataArr = dataArr.filter((i) => i !== staticId);
      } else {
        dataArr.push(staticId);
      }
      let obj = choiceGroupData;
      obj[form_slug] = dataArr;
      setChoiceGroupData(obj);
      formikRef.current.setFieldValue(form_slug, dataArr);
    } else {
      formikRef.current.setFieldValue(form_slug, staticId);
    }
  };
  //CHOICES FEILD
  const choicesGroupComp = (
    current_form,
    handleChange,
    values,
    variant,
    multiselect
  ) => {
    let form_slug = current_form.formField.fieldName;
    // let isRequired = getIsRequired(current_form);
    if (multiselect) {
      return (
        <Row>
          <Col md={12}>
            {variant &&
            variant === "radiogroup" &&
            current_form &&
            current_form.formField.options.edges
              ? current_form.formField.options.edges.map((i_node, index) => {
                  let i = i_node.node;
                  let scoreClass = "score-" + i.scoreValue;
                  return (
                    <div
                      key={index}
                      className={
                        "input-radio btn btn-sm button_group " +
                        (values[form_slug] &&
                        i.staticId &&
                        values[form_slug]?.includes(i.staticId)
                          ? " btn-primary " + scoreClass
                          : " btn-outline-primary ")
                      }
                    >
                      {" "}
                      <input
                        type="radio"
                        name={form_slug}
                        id={"radio_" + i.staticId}
                        value={
                          values[form_slug]?.includes(i.staticId) ? true : false
                        }
                        checked={
                          values[form_slug]?.includes(i.staticId) ? true : false
                        }
                        onClick={() => {
                          choiceGroupSelect(
                            form_slug,
                            current_form.formField.options,
                            i,
                            multiselect
                          );
                          clearErrorForFormField(form_slug);
                        }}
                      />{" "}
                      <label
                        className="mb-0 px-2 d-flex justify-content-between"
                        htmlFor={"radio_" + i.staticId}
                      >
                        <span>{i.value}</span>{" "}
                        <span>
                          {i.scoreValue ? "(+" + i.scoreValue + ")" : ""}
                        </span>
                      </label>
                    </div>
                  );
                })
              : null}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={12}>
            {variant &&
            variant === "radiogroup" &&
            current_form &&
            current_form.formField.options.edges
              ? current_form.formField.options.edges.map((i_node, index) => {
                  let i = i_node.node;
                  let scoreClass = "score-" + i.scoreValue;
                  return (
                    <div
                      key={index}
                      className={
                        "input-radio btn btn-sm button_group " +
                        (values[form_slug] &&
                        i.staticId &&
                        parseInt(values[form_slug]) === parseInt(i.staticId)
                          ? " btn-primary " + scoreClass
                          : " btn-outline-primary ")
                      }
                    >
                      {" "}
                      <input
                        type="radio"
                        name={form_slug}
                        id={"radio_" + i.staticId}
                        value={
                          values[form_slug] === parseInt(i.staticId)
                            ? true
                            : false
                        }
                        checked={
                          values[form_slug] === parseInt(i.staticId)
                            ? true
                            : false
                        }
                        onClick={() =>
                          choiceGroupSelect(
                            form_slug,
                            current_form.formField.options,
                            i,
                            multiselect
                          )
                        }
                      />{" "}
                      <label
                        className="mb-0 px-2 d-flex justify-content-between"
                        htmlFor={"radio_" + i.staticId}
                      >
                        <span>{i.value}</span>{" "}
                        {isStaff ? (
                          <span>
                            {i.scoreValue ? "(+" + i.scoreValue + ")" : ""}
                          </span>
                        ) : null}
                      </label>
                    </div>
                  );
                })
              : null}
          </Col>
        </Row>
      );
    }
  };

  const onChangeMultiSelect = (form_slug, values, newSelect) => {
    let arr = values[form_slug] ? values[form_slug] : [];
    if (arr.includes(newSelect.node.staticId)) {
      arr = arr.filter((i) => i !== newSelect.node.staticId);
    } else {
      arr.push(newSelect.node.staticId);
    }
    formikRef.current.setFieldValue(form_slug, arr);
  };

  const booleanComponent = (
    current_form,
    handleChange,
    values,
    variant,
    multiselect
  ) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    if (current_form.formField.variant === "BOOLEAN") {
      return (
        <div className="create-new-patient-checkboxes">
          <Form.Check
            type={variant}
            name={form_slug}
            required={isRequired}
            value={values[form_slug] ? values[form_slug] : false}
            checked={values[form_slug]}
            onChange={() => {
              formikRef.current.setFieldValue(form_slug, !values[form_slug]);
              clearErrorForFormField(form_slug);
            }}
          />
          <Form.Label className={isRequired ? "required" : ""}>
            {current_form.formField.name}
          </Form.Label>
        </div>
      );
    } else if (
      current_form.formField.variant === "CHECKBOX" &&
      current_form &&
      current_form.formField.options.edges.length > 0
    ) {
      return current_form.formField.options.edges.map((option, index) => {
        return (
          <>
            <div className="create-new-patient-checkboxes" key={index}>
              <Form.Check
                type={variant}
                name={option.staticId}
                required={isRequired}
                value={
                  Object.keys(values).length > 0 &&
                  values[form_slug].includes(option.node.staticId)
                    ? true
                    : false
                }
                checked={
                  Object.keys(values).length > 0 &&
                  values[form_slug].includes(option.node.staticId)
                    ? true
                    : false
                }
                onChange={() => onChangeMultiSelect(form_slug, values, option)}
              />
              <Form.Label className={isRequired ? "required" : ""}>
                {option.node.value}
              </Form.Label>
            </div>
          </>
        );
      });
    }
  };

  const dateComponent = (current_form, handleChange, values, variant) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    return (
      <DatePicker
        showMonthDropdown
        placeholderText={t("forms.selectDate")}
        showWeekNumbers
        showYearDropdown
        required={isRequired}
        autoComplete="off"
        selected={
          values[form_slug] && values[form_slug] !== "None"
            ? values[form_slug]
            : ""
        }
        onChange={(date) => {
          formikRef.current.setFieldValue(form_slug, date);
          clearErrorForFormField(form_slug);
        }}
        className="form-control"
        dateFormat="dd/MMM/yyyy"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
    );
  };

  const diagnosisComponent = (current_form, handleChange, values, variant) => {
    let editBlockInstanceJSON = JSON.stringify(editBlockInstance);
    return (
      <iframe
        className="diagnosis-iframe medicationBlock"
        title="medication"
        src={
          process.env.PUBLIC_URL +
          "/diagnosis.html?fieldName=" +
          current_form.formField.fieldName +
          "&editBlockInstanceJSON=" +
          editBlockInstanceJSON
        }
        id="diagnosisFrame"
        frameBorder="0"
        width="100%"
      />
    );
  };

  const medicationComponent = (current_form, handleChange, values, variant) => {
    let editBlockInstanceJSON = JSON.stringify(editBlockInstance);
    let editFieldName = current_form.formField.fieldName;
    let editUrl =
      process.env.PUBLIC_URL +
      "/medication.html?fieldName=" +
      editFieldName +
      "&editBlockInstanceJSON=" +
      editBlockInstanceJSON;
    editUrl = editUrl.replaceAll(" ", "");
    return (
      <iframe
        title="medication"
        src={
          process.env.PUBLIC_URL +
          "/medication.html?fieldName=" +
          editFieldName +
          "&editBlockInstanceJSON=" +
          editBlockInstanceJSON
        }
        id="medicalFrame"
        className="medicationBlock"
        frameBorder="0"
        width="100%"
      />
    );
  };

  useEffect(() => {
    if (showModal && !enableEdit) {
      if (formikRef?.current) {
        formikRef.current.setValues(getEmptyValues(initialValuesBlock));
      }
    } else if (!showModal) {
      setEnableEdit(false);
      setEditBlockInstance(null);
      setOverrideForm(null);
      if (formikRef?.current) {
        formikRef.current.setValues({});
      }
      setChoiceGroupData({});
      setTimeout(() => {
        setShowDeleteOrMoveModal(false);
      }, 500);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const getErrorForFormField = (fieldName) => {
    let errorMessages = localStorage.getItem("errorMessages");
    if (errorMessages) {
      errorMessages = JSON.parse(errorMessages);
      let fieldError = errorMessages.find(
        (i) => i.toLowerCase().indexOf(fieldName) > -1
      );
      if (fieldError) {
        return fieldError.split(":")[1];
      }
    }
  };

  const clearErrorForFormField = (fieldName) => {
    let errorMessages = localStorage.getItem("errorMessages");
    if (errorMessages) {
      errorMessages = JSON.parse(errorMessages);
      errorMessages = errorMessages.filter(
        (i) => !(i.toLowerCase().indexOf(fieldName) > -1)
      );
      localStorage.setItem("errorMessages", JSON.stringify(errorMessages));
    }
  };

  // THIS IS WHERE EACH FORM FIELD IS RENDERED
  const renderForms = (handleChange, values) => {
    let formBlockData = blockData;
    if (overrideForm) {
      formBlockData = overrideForm;
    }
    if (formBlockData && formBlockData.forms.length > 0) {
      return formBlockData.forms.map((current_form_node, index) => {
        let current_form = current_form_node.node;
        let isRequired = getIsRequired(current_form);
        let width = getWidth(current_form.width);
        if (current_form.formField.fieldName === "is-previous-medication") {
          return;
        }
        let formError = getErrorForFormField(current_form.formField.fieldName);
        return (
          <Col md={width} key={index} className="mb-3">
            <Form.Group className="mb-0">
              <Form.Label
                className={
                  "text-left textBold textGrey " +
                  (isRequired ? " required " : "") +
                  (current_form.formField.subText ? " mb-0 " : "")
                }
              >
                {current_form.formField.name}
              </Form.Label>
              {current_form.formField.subText ? (
                <Form.Control.Feedback
                  type="valid"
                  className="textGrey sub_text"
                >
                  {current_form.formField.subText}
                </Form.Control.Feedback>
              ) : null}
              {formError ? (
                <Form.Control.Feedback type="invalid" className=" sub_text">
                  {formError}
                </Form.Control.Feedback>
              ) : null}
              {getComponent(current_form, handleChange, values)}
            </Form.Group>
          </Col>
        );
      });
    }
  };

  //LISTENER FOR ON SELECT ON DIAGNOSIS AND MEDICATION BLOCK
  window.addEventListener("message", function (event) {
    if (event.origin === window.location.origin) {
      if (formikRef && formikRef.current) {
        if (event.data.event_id === "diagnosis") {
          formikRef.current.setFieldValue(
            event.data.fieldName,
            event.data.final_val + ", " + event.data.final_val_text
          );
        } else if (event.data.event_id === "medicataion") {
          let drugValue = event.data.drugFieldFinal;
          // if (!drugValue) {
          //   alert('Enter a medication.')
          // }
          if (drugValue && event.data.drug_strengthsFinal) {
            drugValue += ", Strength: " + event.data.drug_strengthsFinal;
            formikRef.current.setFieldValue(event.data.fieldName, drugValue);
          } else {
            formikRef.current.setFieldValue(event.data.fieldName, "");
          }
        }
      }
    }
  });

  // BASED ON DIFFERENT CONDITIONS, ONSUBMIT POSTS TO DIFFERENT ENDPOINTS
  const onSubmit = async (values, { resetForm }) => {
    if (
      values.hasOwnProperty("months") &&
      blockData.forms.includes((i) => i.node.formField.fieldName === "months")
    ) {
      if (!values["months"]) {
        alert("Add months, please.");
        return;
      }
      if (isNaN(Number(values["months"]))) {
        alert("Only numbers are allowed months.");
        return;
      }
    }

    if (
      values.hasOwnProperty("weeks") &&
      blockData.forms.includes((i) => i.node.formField.fieldName === "weeks")
    ) {
      if (!values["weeks"]) {
        alert("Add weeks, please.");
        return;
      }
      if (isNaN(Number(values["weeks"]))) {
        alert("Only numbers are allowed weeks.");
        return;
      }
    }

    if (
      (values["months"] && values["weeks"]) ||
      (!values["months"] &&
        !values["weeks"] &&
        (values["prescription"] ||
          values["medication"] ||
          values["unlisted-prescriptions"]))
    ) {
      alert("Enter either weeks or months, please.");
      return;
    }

    if (
      (values["prescription"] &&
        (values["unlisted-prescriptions"] || values["strength"])) ||
      (!values["prescription"] &&
        !values["unlisted-prescriptions"] &&
        is_prescription)
    ) {
      alert("Please enter either prescription or unlisted prescriptions.");
      return;
    }

    if (
      (values["unlisted-prescriptions"] && !values["strength"]) ||
      (!values["unlisted-prescriptions"] && values["strength"])
    ) {
      alert("Please enter unlisted prescriptions with strength");
      return;
    }

    if (
      (values["medication"] &&
        (values["unlisted-prescriptions"] || values["strength"])) ||
      (!values["medication"] &&
        !values["unlisted-prescriptions"] &&
        (is_prev_medication_block || is_current_medication_block))
    ) {
      alert("Please enter either medication or unlisted prescriptions.");
      return;
    }

    let medKey = "";
    let prescriptionKey = "";
    for (let key in values) {
      if (key.startsWith("medication")) {
        medKey = key;
        break;
      }
    }
    for (let key in values) {
      if (key.startsWith("prescription")) {
        prescriptionKey = key;
        break;
      }
    }

    if (values.hasOwnProperty("unlisted-medication")) {
      if (values["unlisted-medication"] && values[medKey]) {
        alert("Enter either a medication or unlisted medication, not both.");
        return;
      } else if (
        !values["unlisted-medication"] &&
        !values[medKey] &&
        !values[prescriptionKey]
      ) {
        alert("Enter either a medication or unlisted medication.");
        return;
      }
    }

    if (values.hasOwnProperty("strength") && values["strength"]) {
      if (values[medKey]) {
        alert(
          "Enter either a medication with strength or unlisted medication with strength, not both."
        );
        return;
      } else if (values[prescriptionKey]) {
        alert(
          "Enter either a prescription with strength or unlisted prescription with strength, not both."
        );
        return;
      }
    }

    let copyToMedication = values["copyToMedication"];
    let valuesToSend = Object.assign({}, values);
    if (overrideForm) {
      let overrideKeys = overrideForm.forms.map((form) => {
        return form.node.formField.fieldName;
      });
      Object.keys(valuesToSend).map((key) => {
        if (!overrideKeys.includes(key) && key !== "copyToMedication") {
          delete valuesToSend[key];
        }
      });
    }
    delete valuesToSend["copyToMedication"];

    if (
      copyToMedication &&
      blockData.initialValues.hasOwnProperty("prescriptions")
    ) {
      valuesToSend["is-previous-medication"] = false;
    }

    if (is_prev_medication_block) {
      valuesToSend["is-previous-medication"] = true;
    }
    let emptyValues = getObjKey(valuesToSend, "none");
    emptyValues.map((key) => {
      let isFieldInfo = blockData?.forms?.find(
        (i) => i?.node?.formField?.fieldName === key
      );
      let isFieldRequired = getIsRequired(isFieldInfo?.node);
      if (!isFieldRequired) {
        valuesToSend[key] = "";
      }
    });
    let jsonData = JSON.stringify(valuesToSend)
      .replace(/'/g, "`")
      .replace(/"/g, "'");
    let val = {};
    val["jsonData"] = jsonData;
    val["formBlock"] = blockData.id;
    if (editBlockInstance) {
      val["instanceCode"] = editBlockInstance?.form_block_intance_id;
    }

    if (is_patient_record) {
      if (overrideForm) {
        val["formBlock"] = overrideForm.formBlock;
        val["relatedModel"] = blockData.relatedModelId;
        if (copyToMedication) {
          val["copyToMedication"] = copyToMedication;
          val["is-previous-medication"] = false;
        }
        createUpdateFormBlockField({ variables: val });
      } else {
        val["relatedModel"] = blockData.patientFormData;
        createUpdatePatientFormBlockField({ variables: val });
      }
    } else {
      val["relatedModel"] = blockData.relatedModelId;
      if (is_encounter) {
        if (copyToMedication && is_prescription) {
          val["copyToMedication"] = copyToMedication;
          val["is-previous-medication"] = false;
        }
        createUpdateFormBlockField({ variables: val });
      } else if (intake_form) {
        await createUpdateIntakeFormBlockField({
          variables: val,
        });
      } else if (survey_form) {
        createUpdateSurveyFormBlockField({ variables: val });
      }
    }
  };

  // track onSubmit, check the structure of val and reproduce it here
  const approveFunc = (block, blockData) => {
    let val = {};
    let jsonData = {};
    for (const item of block?.form_block_instances[0]?.form_block_fields) {
      jsonData[item.form_field_name] = item.form_field_value_id
        ? item.form_field_value_id
        : item.form_field_value;
    }
    let jsonDataToUse = Object.fromEntries(
      Object.entries(jsonData).filter(
        ([_, v]) => v != "null" && v !== "none" && v !== "None" && v !== "Null"
      )
    );

    val["jsonData"] = JSON.stringify(jsonDataToUse).replace(/'/g, "`");
    val["formBlock"] = blockData.id;
    val["instanceCode"] = block?.form_block_instances[0]?.form_block_intance_id;
    if (is_patient_record) {
      val["relatedModel"] = blockData.patientFormData;
      createUpdatePatientFormBlockField({ variables: val });
    } else {
      val["relatedModel"] = blockData.relatedModelId;
      createUpdateFormBlockField({ variables: val });
    }
  };

  const getEncounterRelay = (encounterStaticId) => {
    let enc = encounterIdList?.find((i) => i.staticId === encounterStaticId);
    if (enc) {
      return enc.relayId;
    }
  };

  // FUNCTION USED TO POPULATE THE FORM BLOCK INSTANCE
  const get_dynamic_values = (block_static_id) => {
    let keys = Object.keys(DYNAMIC_FORM_DATA);
    let dynamic_blk_id = keys.filter((i) => i.indexOf(block_static_id) > -1);
    let res = [];
    if (dynamic_blk_id && dynamic_blk_id.length > 0) {
      res = dynamic_blk_id.map((blk) => {
        let temp_blk = blk.replace("main_medication_", "");
        var enc_static_id = temp_blk.split("_")[1];
        let encRelayId = getEncounterRelay(enc_static_id);
        let dynamicBlkObj = {
          form_block_id: DYNAMIC_FORM_DATA[blk].form_block_id,
          form_block_instances: DYNAMIC_FORM_DATA[blk].form_block_instances,
          form_block_name: DYNAMIC_FORM_DATA[blk].form_block_name,
          form_block_name_ar: DYNAMIC_FORM_DATA[blk].form_block_name_ar,
          encounterRelay: encRelayId,
          key: blk,
        };
        return dynamicBlkObj;
      });
    }
    return res;
  };

  var prev_val = [];
  var initialValuesBlock = blockData.initialValues;

  initialValuesBlock["copyToMedication"] = false;

  if (is_prescription) {
    initialValuesBlock["copyToMedication"] = true;
  }

  var block_static_id = "block_" + blockData.staticId;

  var block_json =
    blockData && blockData.json_data
      ? blockData.json_data[block_static_id]
      : null;

  const instanceIsPrevMedication = (item) => {
    if (
      item.form_block_fields.some(
        (i) =>
          i.form_field_name === "is-previous-medication" &&
          i.form_field_value === "True"
      )
    ) {
      return true;
    }
    return false;
  };

  const shouldDisplayLabelFunc = (fld) => {
    let currFormFieldObj = blockData?.forms?.find(
      (i) => i.node?.formField?.staticId === fld?.form_field_id + ""
    );

    if (currFormFieldObj) {
      return !currFormFieldObj?.node?.formField?.hideLabel;
    } else {
      return true;
    }
  };

  // FUNC THAT SETS THE FORM FIELD VALUES

  const getInstanceValues = (
    form_block_instancesArr,
    is_dynamic_patient,
    encounterRelay
  ) => {
    return form_block_instancesArr.map((item, index) => {
      let encounterIdToRedirect = encounterRelay;
      if (item.is_from_encounter && item.enc_static_id) {
        encounterIdToRedirect = getEncounterRelay(item.enc_static_id);
      }
      let block_data = [];
      for (let i = 0; i < item.form_block_fields.length; i++) {
        let fld = item.form_block_fields[i];
        // IF ENABLE EDIT THIS PART OF CODE DOES THE INITIALIZATION OF FORM BLOCKS
        if (
          enableEdit &&
          editBlockInstance &&
          editBlockInstance?.form_block_intance_id ===
            item?.form_block_intance_id
        ) {
          initialValuesBlock[fld.form_field_name] = fld.form_field_value;
          if (
            fld.form_field_value.indexOf("+00:") > -1 &&
            fld.form_field_value.indexOf("-") > -1
          ) {
            let date_fld = new Date(fld.form_field_value);
            initialValuesBlock[fld.form_field_name] = date_fld;
          } else if (
            typeof fld.form_field_value_id === "string" &&
            fld.form_field_value_id.indexOf(",") > -1
          ) {
            let fld_val_arr = fld.form_field_value_id.split(",");
            // fld_val_arr=fld_val_arr.map(item=>parseInt(item));
            initialValuesBlock[fld.form_field_name] = fld_val_arr;
          } else if (fld.form_field_value_id) {
            initialValuesBlock[fld.form_field_name] = fld.form_field_value_id;
          }

          let editBlockInstanceKeys = editBlockInstance.form_block_fields.map(
            (form) => {
              return form.form_field_name;
            }
          );
          Object.keys(initialValuesBlock).map((key) => {
            if (
              !editBlockInstanceKeys.includes(key) &&
              key !== "copyToMedication"
            ) {
              delete initialValuesBlock[key];
            }
          });
        }

        if (overrideForm) {
          let overrideKeys = overrideForm.forms.map((form) => {
            return form.node.formField.fieldName;
          });
          Object.keys(initialValuesBlock).map((key) => {
            if (!overrideKeys.includes(key) && key !== "copyToMedication") {
              delete initialValuesBlock[key];
            }
          });
        }

        let fld_val = fld.form_field_value;
        if (fld_val.indexOf("+00:") > -1 && fld_val.indexOf("-") > -1) {
          let date_fld = new Date(fld_val);
          date_fld = getDate(date_fld) + " " + getForamttedTime(date_fld);
          fld_val = date_fld;
        }
        // TODO push values for display
        if (fld_val && fld.form_field_name !== "is-previous-medication") {
          let shouldDisplayLabel = shouldDisplayLabelFunc(fld);
          if (shouldDisplayLabel) {
            block_data.push(fld.form_field_label + ": " + fld_val);
          } else {
            if (
              fld.form_field_name.indexOf("week") > -1 ||
              fld.form_field_name.indexOf("month") > -1
            ) {
              block_data.push(fld_val + " " + fld.form_field_label);
            } else {
              if (fld_val.indexOf("Strength:") > -1) {
                block_data.push(fld_val.replace("Strength:", ""));
              } else if (fld_val.toLowerCase().indexOf("none") > -1) {
                block_data.push(fld_val.replace("None", ""));
                block_data.push(fld_val.replace("none", ""));
              } else {
                block_data.push(fld_val);
              }
            }
          }
        }
        // block_data.push(fld_val);
      }
      let isPrevMed = instanceIsPrevMedication(item);
      return {
        block_data: block_data,
        form_block_intance_id: item?.form_block_intance_id,
        is_dynamic_patient: is_dynamic_patient,
        created_date: item.form_block_created_date,
        encounterRelay: encounterIdToRedirect ? encounterIdToRedirect : null,
        is_from_encounter: item.is_from_encounter,
        is_prev_medication_instance: isPrevMed,
      };
    });
  };

  //PREV VAL IS PREVIOUS VALUES THAT NEEDS TO BE DISPLAY ON FORM BLOCK GREY DIV
  if (block_json && block_json.form_block_instances) {
    prev_val = getInstanceValues(block_json.form_block_instances, false);
  }
  if (block_static_id) {
    var dynamic_values = DYNAMIC_FORM_DATA
      ? get_dynamic_values(block_static_id)
      : null;

    if (dynamic_values && dynamic_values.length > 0) {
      let dynamic_val_Arr = dynamic_values.map((dyn) => {
        return getInstanceValues(
          dyn.form_block_instances,
          true,
          dyn.encounterRelay
        );
      });

      var merged_dyn_arr = [].concat.apply([], dynamic_val_Arr);
      prev_val.push(...merged_dyn_arr);
      prev_val.sort((a, b) => a.created_date - b.created_date); // sort by date
    }
  }

  useEffect(() => {
    if (form_card && prev_val && prev_val.length > 0) {
      editEntries(prev_val[0]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev_val, form_card]);

  useEffect(() => {
    if (showModal) {
      localStorage.setItem("errorMessages", "");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const renderPreviousValues = (shouldDisplayLabel) => {
    let blockInstances =
      block_json && block_json.form_block_instances
        ? block_json.form_block_instances
        : [];
    var instancesToRender = blockInstances;
    if (is_prev_medication_block) {
      instancesToRender = instancesToRender.filter((instance) =>
        instance.form_block_fields.some(
          (i) =>
            i.form_field_name === "is-previous-medication" &&
            i.form_field_value === "True"
        )
      );
    } else if (is_current_medication_block) {
      instancesToRender = instancesToRender.filter((instance) =>
        instance.form_block_fields.some(
          (i) =>
            i.form_field_name === "is-previous-medication" &&
            i.form_field_value === "False"
        )
      );
    }
    if (shouldDisplayLabel) {
      if (instancesToRender.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return instancesToRender.map((block, index) => {
        var filteredFields = block.form_block_fields.filter(
          (item) => item.form_field_value
        );
        return (
          <div>
            {filteredFields.map((fld, index) => {
              if (fld.form_field_name !== "is-previous-medication") {
                return (
                  <span key={index}>
                    {fld.form_field_label}: {fld.form_field_value}
                    {index === filteredFields.length - 1 ? null : ", "}
                  </span>
                );
              }
            })}
          </div>
        );
      });
    }
  };

  const editEntries = (
    block_item_data,
    deleteEntry,
    is_current_medication_block,
    is_from_encounter
  ) => {
    let block_instance =
      block_json &&
      block_json.form_block_instances.find(
        (i) =>
          i?.form_block_intance_id === block_item_data?.form_block_intance_id
      );

    if (
      is_from_encounter &&
      other_json_data &&
      Object.keys(other_json_data).length > 0
    ) {
      let blk_st_id = block_instance.form_block_key.split("block_")[1];
      let other_block_id = "block_" + blk_st_id;
      let other_inst = other_json_data[
        other_block_id
      ].form_block_instances.find(
        (i) => i.form_block_intance_id === block_instance.form_block_intance_id
      );
      let currBlock = other_form_blocks.find(
        (i) => i.node.formBlock.staticId === blk_st_id
      );
      if (currBlock) {
        let forms = currBlock.node.formBlock.blockFormFields.edges;
        formikRef?.current?.setValues(getEmptyValues(initialValuesBlock));
        setOverrideForm({
          forms: forms,
          formBlock: currBlock.node.formBlock.id,
        });
      }
      if (other_inst) {
        setEditBlockInstance(other_inst);
      } else {
        setEditBlockInstance(block_instance);
      }
    } else {
      setEditBlockInstance(block_instance);
    }
    if (is_current_medication_block && deleteEntry) {
      setShowModal(true);
      setEnableEdit(true);
      setShowDeleteOrMoveModal(true);
      setDeleteMoveData(block_item_data);
    } else if (deleteEntry) {
      if (window.confirm("Delete Entry?")) {
        let val = {};
        val["instanceCode"] = block_instance?.form_block_intance_id;
        val["formBlockKey"] = "block_" + blockData.staticId;

        setShowModal(false);
        setTimeout(() => {
          setShowDeleteOrMoveModal(false);
        }, 500);

        if (is_patient_record && block_instance.is_from_encounter) {
          val["id"] = blockData.patientFormData;
          val["formBlockKey"] = block_instance.form_block_key;
          deleteMedicationBlockInstance({ variables: val });
        } else if (is_patient_record && !block_instance.is_from_encounter) {
          val["id"] = blockData.patientFormData;
          deletePatientFormBlockInstance({ variables: val });
        } else {
          val["id"] = blockData.relatedModelId;
          setInstanceToDeleteVariables(val);
          deleteFormBlockInstance({ variables: val });
        }
      }
    } else {
      setShowModal(true);
      setEnableEdit(true);
    }
  };
  const goToEncounterDetail = (item) => {
    setTimeout(() => {
      window.open(
        window.location.origin + "/encounter/detail/" + item.encounterRelay,
        "_blank"
      );
    }, 300);
  };

  const addAnotherInstance = () => {
    // creating/setting closeModal formik feild value to false, that will be used in onSubmit
    formikRef.current.setFieldValue("closeModal", false);
    setTimeout(() => {
      formikRef.current.handleSubmit();
    }, 300);
  };

  const printPrescriptions = (currentBlock) => {
    if (blockPrintData) {
      setBlockData(null);
    }
    setTimeout(() => {
      setBlockData(parseInt(currentBlock.staticId));
    }, 100);
    // setPrintPrescription(true);
    // window.print();
  };

  const copyLastPrescription = (blockData) => {
    setShowAlert(false);

    let lastDatePrecription =
      previousEncounterPrescriptionBlockData.form_block_instances;
    let result = lastDatePrecription.reduce((r, o) => {
      if (
        !r ||
        new Date(r[0].form_block_created_date).setHours(0, 0, 0, 0) <
          new Date(o.form_block_created_date).setHours(0, 0, 0, 0)
      )
        return [o];
      if (
        new Date(r[0].form_block_created_date).setHours(0, 0, 0, 0) ===
        new Date(o.form_block_created_date).setHours(0, 0, 0, 0)
      )
        r.push(o);
      return r;
    }, undefined);

    let prescriptionListToSave = result?.map((presInstance) => {
      let jsonData = {};
      let objToPost = {};
      for (const item of presInstance.form_block_fields) {
        jsonData[item.form_field_name] = item.form_field_value_id
          ? item.form_field_value_id
          : item.form_field_value;
      }
      let jsonDataToUse = Object.fromEntries(
        Object.entries(jsonData).filter(
          ([_, v]) =>
            v != "null" && v !== "none" && v !== "None" && v !== "Null"
        )
      );
      jsonDataToUse["copyToMedication"] = prescCopyToMed;
      objToPost["jsonData"] = JSON.stringify(jsonDataToUse).replace(/'/g, "`");
      objToPost["jsonDataToUse"] = jsonDataToUse;
      objToPost["formBlock"] = blockData.blockId;
      objToPost["relatedModel"] = blockData.relatedModelId;
      objToPost["copyToMedication"] = prescCopyToMed;
      objToPost["is-previous-medication"] = false;
      return objToPost;
    });
    if (prescriptionListToSave?.length > 0) {
      let firstPresc = prescriptionListToSave[0];
      createUpdateFormBlockField({ variables: firstPresc });
      valuesForPrescInstanceVar({
        data: firstPresc.jsonDataToUse,
        index: 0,
        prescriptionListToSave: prescriptionListToSave,
      });
    }
  };

  const showPrint = (iconType) => {
    if (
      iconType &&
      previousEncounterPrescriptionBlockData?.form_block_instances?.length > 0
    ) {
      return (
        <Button
          className="p-1"
          variant="link"
          onClick={() => setShowAlert(true)}
        >
          <i className="fa fa-copy"></i>
        </Button>
      );
    } else if (
      blockData.title.toLowerCase().indexOf("prescription") >= 0 &&
      !iconType
    ) {
      return (
        <Button
          className="p-1"
          variant="link"
          onClick={() => printPrescriptions(blockData)}
          disabled={
            block_json && block_json.form_block_instances.length > 0
              ? false
              : true
          }
        >
          <i className="fa fa-print"></i>
        </Button>
      );
    }
  };

  const openModal = () => {
    setShowModal(true);
    setEditBlockInstance(null);
    setEnableEdit(false);
  };

  const shouldDisplayInstance = (item) => {
    if (
      (!is_prev_medication_block &&
        is_current_medication_block &&
        item.is_prev_medication_instance) ||
      (is_prev_medication_block &&
        !is_current_medication_block &&
        !item.is_prev_medication_instance)
    ) {
      return "d-none";
    }
    return "";
  };

  const moveToPrevMed = () => {
    formikRef.current.setFieldValue("is-previous-medication", true);
    formikRef.current.setFieldValue("copyToMedication", true);
    formikRef.current.handleSubmit();
  };

  // if(!showModal){
  //   initialValuesBlock={};
  // }

  return (
    <>
      {form_card ? null : (
        <Accordion defaultActiveKey={!collapseByDefault ? "0" : "999"}>
          <Form.Group as={Row}>
            {/* as={Button} variant="link"  */}
            <Col
              xs={10}
              sm={10}
              md={10}
              className="text-left text-uppercase textBold textGrey"
            >
              <Accordion.Toggle
                as={Button}
                variant="link"
                className="pointer textGrey textBold text-uppercase p-0 w-100 d-flex justify-content-start"
                eventKey={"0"}
              >
                {blockData.title}
              </Accordion.Toggle>
            </Col>
            <Col
              xs={2}
              sm={2}
              md={2}
              className="d-flex justify-content-end align-items-center form_edit_buttons"
            >
              {medical_record_permission.includes("add") ? (
                <div className="d-flex align-items-center">
                  {block_json?.requires_approval &&
                  getPermissionForAction("medical_record", "approve") ? (
                    <Button
                      className="approveBlockBtn "
                      variant="primary"
                      onClick={() => approveFunc(block_json, blockData)}
                    >
                      <span> Approve </span>
                    </Button>
                  ) : null}
                  {!block_json?.requires_approval && is_encounter
                    ? showPrint("copy")
                    : null}
                  {!block_json?.requires_approval && is_encounter
                    ? showPrint()
                    : null}
                  <Button
                    variant="link"
                    onClick={openModal}
                    id={blockData.blockCode + "_add"}
                    className="p-1 ml-1"
                  >
                    <i className="fa fa-plus-circle"></i>
                  </Button>
                </div>
              ) : null}
            </Col>

            <Col sm={12} md={12}>
              <Accordion.Collapse eventKey={"0"}>
                <div className="dynamic_block_display_container mt-3">
                  <div className="dynamic_block_display form_edit_buttons thinScrollBar">
                    {prev_val &&
                      prev_val.map((item, index) => {
                        let filterData = item.block_data.filter(
                          (item) =>
                            item.indexOf("previous medication") < 0 &&
                            item.toLowerCase().indexOf("none") < 0
                        );
                        filterData = filterData.map((item) =>
                          item.replace("Diagnosis: ", "")
                        );
                        return (
                          <div
                            className={
                              "block_item_container " +
                              shouldDisplayInstance(item)
                            }
                            key={index}
                          >
                            {item.is_dynamic_patient ? (
                              <div className="block_item_edit d-flex">
                                {medical_record_permission.includes("edit") ? (
                                  <Button
                                    variant="link"
                                    className="px-0 "
                                    onClick={() => goToEncounterDetail(item)}
                                  >
                                    <i className="ml-2 fa fa-external-link"></i>
                                  </Button>
                                ) : null}
                              </div>
                            ) : medical_record_permission.includes("edit") ||
                              medical_record_permission.includes("delete") ? (
                              <div className="block_item_edit d-flex">
                                {!intake_form &&
                                !survey_form &&
                                medical_record_permission.includes("delete") ? (
                                  <Button
                                    variant="link"
                                    className="danger-color"
                                    onClick={() =>
                                      editEntries(
                                        item,
                                        true,
                                        is_current_medication_block,
                                        item.is_from_encounter
                                      )
                                    }
                                  >
                                    <i className="fa fa-times"></i>
                                  </Button>
                                ) : null}
                                {medical_record_permission.includes("edit") &&
                                !item.is_from_encounter ? (
                                  <Button
                                    variant="link"
                                    className="px-0 "
                                    onClick={() =>
                                      editEntries(
                                        item,
                                        false,
                                        is_current_medication_block,
                                        item.is_from_encounter
                                      )
                                    }
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                ) : null}
                              </div>
                            ) : null}
                            {/* if instance is from encounter,its click should take user to encounter detail page */}
                            <div className={"block_item print-prescription"}>
                              <span className="text-primary font-size-timestamp">
                                [{getDate(item.created_date)}]
                              </span>
                              -{" "}
                              {filterData.map((it, index) => {
                                if (it) {
                                  return (
                                    it +
                                    (index !== filterData.length - 1
                                      ? ", "
                                      : "")
                                  );
                                }
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Accordion.Collapse>
            </Col>

            <CustomModals
              showModal={showModal}
              modalHeader={
                showDeleteOrMoveModal
                  ? "Delete Current Medication Item?"
                  : blockData.title
              }
              setShowModal={setShowModal}
              dialogClassName="modal60h"
              contentClassName="modals-from-formBlock"
            >
              <div
                className={
                  "w100 d-none " +
                  (showDeleteOrMoveModal ? "d-block" : "d-none")
                }
              >
                <h5 className="text-center">
                  {" "}
                  Do you wish to delete the item or move to previous medication{" "}
                </h5>
                <div className="d-flex pt-4  justify-content-center">
                  <Button
                    variant="primary"
                    className="appointment-detail-btn "
                    onClick={moveToPrevMed}
                  >
                    Move to Previous Medication
                  </Button>
                  <Button
                    variant="danger"
                    className="ml-2 appointment-detail-btn"
                    onClick={() => editEntries(deleteMoveData, true)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <div
                className={
                  "w100 d-none " +
                  (!showDeleteOrMoveModal ? "d-block" : "d-none")
                }
              >
                <Formik
                  onSubmit={onSubmit}
                  initialValues={initialValuesBlock}
                  enableReinitialize
                  innerRef={formikRef}
                >
                  {({ handleChange, values, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="w100">
                      {renderPreviousValues(true) ? (
                        <div className="mb-2">
                          <Form.Label className="text-left textBold textGrey">
                            Previous Values:
                          </Form.Label>
                          {renderPreviousValues()}
                        </div>
                      ) : null}
                      <Row>{renderForms(handleChange, values)}</Row>
                      <Row className=" mt-4 justify-content-center justify-content-sm-end">
                        <Col className="d-flex justify-content-center justify-content-sm-end">
                          {is_prescription ? (
                            <Form.Group className="d-flex p-0 m-0 align-items-baseline">
                              <Form.Check
                                type="checkbox"
                                name="copyToMedication"
                                onChange={handleChange}
                                // disabled={!has_medication_block}
                                value={values.copyToMedication}
                                checked={values.copyToMedication}
                                className="d-inline-block require-payment-checkbox"
                              />
                              <Form.Label className="d-inline-block p-0 ml-1">
                                Copy to Medications
                              </Form.Label>
                            </Form.Group>
                          ) : null}
                          <Button
                            variant="primary"
                            size="md"
                            className="mx-2"
                            type="button"
                            onClick={() => {
                              setShowModal(false);
                              // setEditBlockInstance(null);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            size="md"
                            className="mx-2"
                            type="submit"
                            id="presc-submit"
                          >
                            Save
                          </Button>
                          <Button
                            variant="primary"
                            size="md"
                            className="mx-2"
                            type="button"
                            onClick={addAnotherInstance}
                          >
                            Add Another
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </CustomModals>
          </Form.Group>
        </Accordion>
      )}
      {form_card ? (
        <Col md={12}>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>{blockData.title}</Card.Title>
              <Formik
                key={blockData.blockId}
                onSubmit={onSubmit}
                initialValues={initialValuesBlock}
                enableReinitialize
                innerRef={formikRef}
              >
                {({ handleChange, values, handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit} className="w100">
                      <Row>{renderForms(handleChange, values)}</Row>
                      <Row
                        className={
                          " mt-4 justify-content-center justify-content-sm-end " +
                          form_card
                            ? " d-none "
                            : ""
                        }
                      >
                        <Col className="d-flex justify-content-center justify-content-sm-end">
                          <Button
                            variant="primary"
                            size="md"
                            className="mx-2"
                            type="button"
                            onClick={() => setShowModal(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            size="md"
                            className="mx-2"
                            type="submit"
                            id={"submit_" + blockData.blockId}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      ) : null}
      <Modal show={showAlert} onHide={() => setShowAlert(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("patientAppointmentDetails.createCopyOfLastPrescription")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <></>
          <div className="d-flex">
            <Form.Check
              className=""
              type="checkbox"
              value={prescCopyToMed}
              checked={prescCopyToMed}
              onChange={() => setPrescCopyToMed(!prescCopyToMed)}
            />
            {t("patientAppointmentDetails.Copytomedication")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            {isStaff ? "Cancel" : t("patientAppointmentDetails.no")}
          </Button>
          <Button
            variant="primary"
            onClick={() => copyLastPrescription(blockData)}
          >
            {isStaff ? "Submit" : t("patientAppointmentDetails.yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withRouter(FormBlock);
