//@ts-nocheck
import React, { useCallback, useEffect } from "react";
import { Formik, Form } from "formik";
import { MenuItem, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormikSelect from "./FormikSelect";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import {
  createUpdatePatientTherapy,
  getPatient,
} from "redux/slices/patientSlice";
import { useSnackbar } from "react-simple-snackbar";
import { error_options, SNACK_DURATION, success_options } from "Common/helpers";
import { useHistory } from "react-router-dom";

function TherapyForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, patientData } = useAppSelector(
    ({ rootPatientSlice }) => rootPatientSlice
  );
  const [openErrorSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const history = useHistory();

  useEffect(() => {
    dispatch(getPatient());
  }, [dispatch]);

  const therapistOptions = ["male", "female", "dontMind"];

  const preferTobeOptions = ["long", "short"];

  const inTherapyOptions = ["current", "past", "future"];

  const iLikeTherapyOptions = ["structured", "flexible"];

  const iLikeTherapyToOptions = ["everySession", "timeToTime", "noHome"];
  const iwantTherapyToOptions = ["faceDifficult", "slow"];
  const iwantTherapyTo2Options = ["lead", "collab", "allowLead"];

  const initialValues = {
    therapistGender: "",
    haveTherapist: "",
    goodExpTher: "",
    preferToBe: "",
    inTherapy: "",
    iLikeTherapy: "",
    iLikeTherapyTo: "",
    iwantTherapyTo: "",
    iwantTherapyTo2: "",
  };

  const onSubmit = useCallback(
    async (values) => {
      const res = await dispatch(
        createUpdatePatientTherapy({
          data: JSON.stringify(values),
          id: patientData.id,
        })
      );
      //@ts-ignore
      if (res?.error) {
        openErrorSnackbar(res?.payload, [SNACK_DURATION]);
        return;
      } else if (res?.payload?.errors?.length > 0) {
        openErrorSnackbar(res?.payload?.errors?.[0]?.messages, [
          SNACK_DURATION,
        ]);
        return;
      } else if (res) {
        openSnackbarSuccess(t("patientrequestReport.submitted"), [
          SNACK_DURATION,
        ]);

        // redirect to dashboard.
        history.push("/patientPortal/dashboard");
      }
    },
    [
      dispatch,
      history,
      openErrorSnackbar,
      openSnackbarSuccess,
      patientData?.id,
      t,
    ]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, touched, handleChange, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>{t("therapyForm.header")}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="therapistGender"
                label={t("therapyForm.therapistGender")}
                labelId="therapistGender-label"
                required={true}
              >
                {therapistOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.therapistGenderOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="haveTherapist"
                label={t("therapyForm.haveTherapist")}
                labelId="haveTherapist-label"
                required={true}
              >
                <MenuItem value={true}>
                  {t("patientAppointmentDetails.yes")}
                </MenuItem>
                <MenuItem value={false}>
                  {t("patientAppointmentDetails.no")}
                </MenuItem>
              </FormikSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormikSelect
                name="goodExpTher"
                label={t("therapyForm.goodExpTher")}
                labelId="goodExpTher-label"
                required={true}
              >
                <MenuItem value={"yes"}>
                  {t("patientAppointmentDetails.yes")}
                </MenuItem>
                <MenuItem value={"no"}>
                  {t("patientAppointmentDetails.no")}
                </MenuItem>
                <MenuItem value={"partly"}>{t("therapyForm.partly")}</MenuItem>
              </FormikSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormikSelect
                name="preferToBe"
                label={t("therapyForm.preferToBe")}
                labelId="preferToBe-label"
                required={true}
              >
                {preferTobeOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.preferTobeOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="inTherapy"
                label={t("therapyForm.inTherapy")}
                labelId="inTherapy-label"
                required={true}
              >
                {inTherapyOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.inTherapyOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="iLikeTherapy"
                label={t("therapyForm.iLikeTherapy")}
                labelId="iLikeTherapy-label"
                required={true}
              >
                {iLikeTherapyOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.iLikeTherapyOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="iLikeTherapyTo"
                label={t("therapyForm.iLikeTherapyTo")}
                labelId="iLikeTherapyTo-label"
                required={true}
              >
                {iLikeTherapyToOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.iLikeTherapyToOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="iwantTherapyTo"
                label={t("therapyForm.iwantTherapyTo")}
                labelId="iwantTherapyTo-label"
                required={true}
              >
                {iwantTherapyToOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item} style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}>
                      {t(`therapyForm.iwantTherapyToOptions.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormikSelect
                name="iwantTherapyTo2"
                label={t("therapyForm.iwantTherapyTo2")}
                labelId="iwantTherapyTo2-label"
                required={true}
              >
                {iwantTherapyTo2Options.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {t(`therapyForm.iwantTherapyTo2Options.${item}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>

            <Grid item xs={12} style={{ padding: 20 }}>
              <LoadingButton
                name="submit"
                type="submit"
                variant="contained"
                loading={loading}
                disabled={loading}
              >
                {t("submit")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default TherapyForm;
