import { Row, Col, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  getDate,
  isSuperUserFunc,
  getForamttedTime,
  displayMiddleName,
  concatAllErrors,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import Logo from "../assets/images/Mindwell-Logo.svg";
import Preloader from "../Common/Preloder/Preloader";
import Stamp from "component/Stamp/Stamp";
import { useTranslation } from "react-i18next";
import { urlBackend } from "apolloSettings";
import * as Sentry from "@sentry/browser";

const getDirection = (lang) => {
  return lang === "ar" ? "rtl" : "ltr";
};

var today = new Date();
today = getDate(today) + " " + getForamttedTime(today);

function RequestReportDetail(props) {
  const patient_record_id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);

  const isStaff = isUserStaff();
  const isSuperUser = isSuperUserFunc();
  let returnAllSectionPermissions = true;
  const patient_report_permission = getPermissionForAction(
    "patient_report",
    "",
    returnAllSectionPermissions
  );
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);
  const user = JSON.parse(localStorage.getItem("user", {}));

  const [overrideName, setOverrideName] = useState("");

  const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReport(id:"${patient_record_id}"){

            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            staticId
            patient{
              firstName
              middleName
              lastName
              phone
              civilId
              id
              dob
              displayGender
              identifier
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              titlePrefix
              description
              firstName
              lastName
              arabicName
              arabicDescription
            }
        }
  }
`;

  const GENERTE_PATIENT_RECORD_PRINT_CODE = gql`
    mutation generatePatientRecordPrintCode($id: ID!) {
      generatePatientRecordPrintCode(id: $id) {
        code
      }
    }
  `;

  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null, loading } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ patientReport }) => {
        if (!patientReport) {
          props.history.push("/404-not-found");
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_REPORTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  let languageEn = patient_reports_obj?.patientReport?.languageEn;

  // DELETE_APPOINTMENT
  const [generatePatientRecordPrintCode] = useMutation(
    GENERTE_PATIENT_RECORD_PRINT_CODE,
    {
      onCompleted: ({ generatePatientRecordPrintCode }) => {
        let oneTimeCode = generatePatientRecordPrintCode.code;
        setTimeout(() => {
          window.open(
            urlBackend +
              "pdf/reports/patient/report/" +
              patient_reports_obj?.patientReport?.staticId +
              "/" +
              oneTimeCode +
              "/"
          );
        }, 300);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "GENERTE_PATIENT_RECORD_PRINT_CODE error " + e
          );
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const getPrintLink = () => {
    let variables = { id: patient_reports_obj?.patientReport?.id };
    generatePatientRecordPrintCode({ variables });
  };

  const getButtons = () => {
    return (
      <div className="d-flex encounter-button-container">
        <Button
          variant="primary"
          className="ml-1 back w-100 mt-0"
          onClick={getPrintLink}
        >
          {t("patientPaymentDetails.downloadReport")}
        </Button>
      </div>
    );
  };

  let title = languageEn ? "Clinical Report" : "تقرير اكلينيكي";
  let reportBy = languageEn
    ? "Report prepared by"
    : "تم تحضير هذا التقرير من قبل";

  let nameTitle = languageEn ? "Patient Name" : "الاسم";
  let civilIdTitle = languageEn ? "ID Number" : "رقم الهوية";
  let phoneTitle = languageEn ? "Phone" : "الهاتف";

  let clinicalSummaryTitle = languageEn
    ? "Clinical Summary"
    : "الملخص ‏الإكلينيكي";
  let diagnosisTitle = languageEn ? "Diagnosis" : "التشخيص";
  let treatmentRecommendationTitle = languageEn
    ? "Treatment Recommendations"
    : "الخطة العلاجية";
  let notesTitle = languageEn ? "Notes" : "ملاحظات";

  let doctorLine1 = languageEn
    ? "Dr.Mohammad Alsuwaidan MD, MPH, FRCPC, DipABPN"
    : "الدكتور محمد السویدان";
  let doctorLine2 = languageEn
    ? "Consultant Psychiatrist"
    : "استشاري طب نفسي  ";
  let doctorLine3 = languageEn
    ? "Founder & Clinical Director - Alsuwaidan Clinic / MindWell"
    : "المؤسس والمدير الطبي   ";

  let languageClass = !languageEn ? "arabic" : "english";

  const getOverrideName = () => {
    let result;
    if (
      overrideName == "" ||
      overrideName == " " ||
      overrideName.trim() == ""
    ) {
      return;
    } else {
      result = overrideName;
    }
    return result;
  };

  const renderReportDetail = () => {
    return (
      <>
        {patient_reports_obj && patient_reports_obj.patientReport ? (
          <>
            <Row className={`category_box `}>
              {/* <Row className="category_box"> */}
              <Col xs={12} sm={6} md={6}>
                {/* <div className='d-flex align-items-baseline'> */}
                <div>
                  <h6 className="capitalize mr-1">
                    {t("patientRequestReportDetails.nameTitle")}:
                    <Link
                      className="d-inline-block px-4 patient-name-print-mode-link"
                      to={`/patient/record/${
                        patient_reports_obj.patientReport.patient.id +
                        "/?identifier=" +
                        patient_reports_obj.patientReport.patient.identifier
                      }`}
                    >
                      <Button
                        variant="link"
                        className="p-0 patient-name-print-mode-btn"
                      >
                        {getOverrideName()
                          ? getOverrideName()
                          : patient_reports_obj.patientReport.patient
                              .firstName +
                            " " +
                            displayMiddleName(
                              patient_reports_obj.patientReport.patient
                                .middleName
                            ) +
                            " " +
                            patient_reports_obj.patientReport.patient.lastName}
                      </Button>
                    </Link>
                  </h6>
                  <div className="d-flex align-items-baseline justify-items-space-between">
                    {isStaff ? (
                      <Form.Group className="text-right d-print-none mr-1 flex-grow-1">
                        <Form.Control
                          className="mr-1"
                          autoComplete="nope"
                          type="text"
                          onChange={(e) => setOverrideName(e.target.value)}
                          value={overrideName}
                          placeholder="Override Name"
                        />
                      </Form.Group>
                    ) : null}
                  </div>
                </div>
                <h6>
                  <span>{t("patientRequestReportDetails.civilIdTitle")}: </span>
                  <span className="px-4">
                    {patient_reports_obj.patientReport.patient.civilId}
                  </span>
                </h6>
                <h6>
                  {t("patientRequestReportDetails.phoneTitle")}:
                  <div className="phone-direction px-4">
                    {patient_reports_obj.patientReport.patient.phone}
                  </div>
                </h6>
              </Col>
            </Row>
            <Row className={`mb-3 `}>
              {/* <Row className='mb-3'> */}
              <Col xs={12} sm={6} md={6}>
                <div className="mt-4">
                  <h6 className="mb-1 font-weight-bold">
                    {t("patientRequestReportDetails.clinicalSummaryTitle")}:
                  </h6>
                  {patient_reports_obj.patientReport.clinicalSummary}
                </div>
                <div className="mt-3">
                  <h6 className="mb-1 font-weight-bold">
                    {t("patientRequestReportDetails.diagnosisTitle")}:
                  </h6>
                  {patient_reports_obj.patientReport.daignosis}
                </div>
                <div className="mt-3">
                  <h6 className="mb-1 font-weight-bold">
                    {t(
                      "patientRequestReportDetails.treatmentRecommendationTitle"
                    )}
                    :
                  </h6>
                  {patient_reports_obj.patientReport.treatmentRecommendation}
                </div>
                <div className="mt-3">
                  <h6 className="mb-1 font-weight-bold">
                    {t("patientRequestReportDetails.notesTitle")}:
                  </h6>
                  {patient_reports_obj.patientReport.notes}
                </div>
              </Col>
            </Row>
            <Row className={``}>
              <Col>
                <div className="mt-3 ">
                  <h6 className="mb-1 font-weight-bold">
                    {t("patientRequestReportDetails.reportBy")}:
                  </h6>
                  {direction === "ltr" ? (
                    <>
                      <div>
                        {patient_reports_obj.patientReport.doctor.titlePrefix}{" "}
                        {patient_reports_obj.patientReport.doctor.firstName}{" "}
                        {patient_reports_obj.patientReport.doctor.lastName}
                      </div>
                      <div>
                        {patient_reports_obj.patientReport.doctor.description}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {patient_reports_obj.patientReport.doctor.arabicName}
                      </div>
                      <div>
                        {
                          patient_reports_obj.patientReport.doctor
                            .arabicDescription
                        }
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : null}
      </>
    );
  };

  const PrintReportDetailComponent = () => {
    return (
      <div className={`print prescriptions-print-page `}>
        <div className="lab-request-header" style={{ height: 160 + "px" }}>
          <Row className={`h100 `}>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={"Logo"} className="img-fluid printLogo" src={Logo} />
              <h4 className="ml-4">{t("patientRequestReportDetails.title")}</h4>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>
        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <div
                  className="page-header-space"
                  style={{ height: 160 + "px" }}
                ></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderReportDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className={``}>
          <Stamp />
        </div>
        <div className={``}>{t("patientRequestReportDetails.doctorLine1")}</div>
        <div className={``}>{t("patientRequestReportDetails.doctorLine2")}</div>
        <div className={``}>{t("patientRequestReportDetails.doctorLine3")}</div>
      </div>
    );
  };

  return (
    <>
      <Base
        title={t("Clinical Report Request")}
        isPatientPortal={!isStaff}
        showHeader={true}
        rightChild={getButtons()}
      >
        {loading ? (
          <Preloader />
        ) : (
          <Row className="d-print-none">
            <Col md={12}>{renderReportDetail()}</Col>
          </Row>
        )}
      </Base>

      <PrintReportDetailComponent />
    </>
  );
}

export default withRouter(RequestReportDetail);
