import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  isUserStaff,
  getErrorMessage,
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../Common/helpers";
import drag from "../assets/images/drag.jpg";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";

const FileUpload = (props) => {
  const isStaff = isUserStaff();
  const {
    currentImage,
    setCurrentImage,
    setUploaded,
    imageForPatientEditCreate,
  } = props;

  const [openSnackbar] = useSnackbar(error_options);
  const [fileName, setFileName] = useState("");

  const UploadMutation = gql`
    mutation updatePatientSelfFile($file: Upload!) {
      updatePatientSelfFile(file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  const UPDATE_PATIENT_FILE = gql`
    mutation updatePatientFile($id: ID!, $file: Upload!) {
      updatePatientFile(id: $id, file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  // pass in the UploadMutation mutation we created earlier.
  const [uploadFile] = useMutation(UploadMutation, {
    onCompleted({ updatePatientSelfFile }) {
      if (
        updatePatientSelfFile?.errors &&
        updatePatientSelfFile?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          updatePatientSelfFile?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        setCurrentImage(updatePatientSelfFile.obj.idFile);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("uploadFile error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [updatePatientFile, { loading }] = useMutation(UPDATE_PATIENT_FILE, {
    onCompleted({ updatePatientFile }) {
      if (updatePatientFile?.errors && updatePatientFile?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(updatePatientFile?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        setCurrentImage(updatePatientFile.obj.idFile);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("updatePatientFile error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const file = acceptedFiles[0];
      // use the uploadFile variable created earlier
      if (file) {
        const filePreviewUrl = URL.createObjectURL(file);
        setCurrentImage(filePreviewUrl); // Set the preview URL to be used in the img src
        setFileName(file.name); // Store the file name

        let val = {
          file: file,
        };
        if (isStaff) {
          updatePatientFile({
            variables: { ...val, id: props.patientId },
            onCompleted: () => {},
          });
        } else {
          uploadFile({
            // use the variables option so that you can pass in the file we got above
            variables: { ...val },
            onCompleted: () => {
              setUploaded(true);
            },
          });
        }
      }
    },
    // pass in uploadFile as a dependency
    [
      isStaff,
      props.patientId,
      setCurrentImage,
      setUploaded,
      updatePatientFile,
      uploadFile,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png,application/pdf" // Specify accepted file types
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive && "isActive"}`}
      >
        <input {...getInputProps()} type="file" id="reg-file-test" />
        {currentImage ? (
          <div
            className={
              "text-left " + imageForPatientEditCreate
                ? "imageForPatientEditCreate"
                : ""
            }
          >
            <div className={loading ? "opacityhalf" : "idFileWrapper"}>
              {loading ? (
                <div className="spinnerParent">
                  <Spinner animation="border" size="sm" />
                </div>
              ) : null}
              <img src={currentImage} alt={fileName} className={""} />
            </div>
            <div>
              <Button
                variant="link"
                className="px-0 mt-2 "
                onClick={() => window.open(currentImage)}
              >
                <i className="fa fa-external-link"></i>
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-left">
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="dragPic">
                <img src={drag} alt="" />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default FileUpload;
