import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button, Form, Badge } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CustomModals from "../Modal/modal";
import { gql, useQuery } from "@apollo/client";
import {
  getPatientsAge,
  displayMiddleName,
  concatAllErrors,
} from "../../Common/helpers";

import {
  ContentState,
  convertToRaw,
  convertFromHTML,
  DefaultDraftBlockRenderMap,
  getSafeBodyFromHTML,
} from "draft-js";
import { EditorState, SelectionState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as Sentry from "@sentry/browser";
import _ from "lodash";

const NotesBlock = (props) => {
  const [activeTemplate, setActiveTemplate] = useState(0);
  const [saveStatus, setSaveStatus] = useState(true);

  const editorStateRef = useRef();
  const {
    setShowModalNotes,
    showModalNotes,
    is_modal,
    patient,
    previousNotes,
    setNotesValue,
    encounternote_permission,
    editorState,
    setEditorState,
    convertedContent,
    setConvertedContent,
    editorReadOnly,
    setEditorReadOnly,
    notesSaveCall,
    setSavedStateBe,
    savedStateBe,
    time,
    setTime,
    previousEncounterNotesData,
  } = props;
  // const [time, setTime] = React.useState(null);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML(state);
    setSaveStatus(false);
  };
  const autoSave = (state, currentContentAsHTML) => {
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        notesSaveCall(null, true, currentContentAsHTML);
        setSavedStateBe(state);
        setSaveStatus(true);
      }, 5000)
    );
  };

  const convertContentToHTML = (state) => {
    let raw = convertToRaw(state.getCurrentContent());

    let blocks = raw.blocks;

    for (let item in blocks) {
      let obj = blocks[item];

      if (obj.type == "br") {
        obj.type = "unstyled";
      }
    }

    raw.blocks = blocks;

    let currentContentAsHTML = draftToHtml(raw);
    currentContentAsHTML = currentContentAsHTML.replaceAll("<p></p>", "<br>");
    setConvertedContent(currentContentAsHTML);
    setNotesValue(currentContentAsHTML);
    autoSave(state, currentContentAsHTML);
  };

  useEffect(() => {
    if (previousNotes) {
      if (editorState.getCurrentContent().hasText()) {
        const currentSelection = editorState.getSelection();
        const stateWithContentAndSelection = EditorState.forceSelection(
          editorState,
          currentSelection
        );
        setEditorState(stateWithContentAndSelection);
      } else {
        const blockRenderMap = DefaultDraftBlockRenderMap.set("br", {
          element: "br",
        });
        const blocksFromHTML = convertFromHTML(
          previousNotes,
          getSafeBodyFromHTML,
          blockRenderMap
        );

        const intVal = EditorState.createWithContent(
          ContentState.createFromBlockArray(blocksFromHTML)
        );
        setEditorState(intVal);
      }
      setConvertedContent(previousNotes);
    }
  }, [previousNotes]);

  useEffect(() => {
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(
      editorState,
      currentSelection
    );
    setEditorState(stateWithContentAndSelection);
  }, [editorReadOnly]);

  let firstName = patient ? patient.firstName : "";
  let middleName = patient ? displayMiddleName(patient.middleName) : "";
  let lastName = patient ? patient.lastName : "";
  let age = patient ? getPatientsAge(patient.dob) : "";
  let number = patient ? patient.phone : "";
  let gender = patient ? patient.gender : "";

  let templates = [];

  const REQUEST_TEMPLATES = gql`
    query {
      noteTemplates {
        edges {
          node {
            id
            title
            text
          }
        }
      }
    }
  `;

  const { data, loading } = useQuery(REQUEST_TEMPLATES, {
    fetchPolicy: "network-only",

    onCompleted() {},
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_TEMPLATES error " + e);
      }
      // openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const variables = [
    { label: "__age__", value: age },
    { label: "__firstname__", value: firstName },
    { label: "__middleName__", value: middleName },
    { label: "__lastname__", value: lastName },
    { label: "__number__", value: number },
    { label: "__gender__", value: gender },
  ];

  if (!loading) {
    templates =
      data && data.noteTemplates
        ? data.noteTemplates.edges.map((template) => {
            let processedText = template.node.text;

            for (var item in variables) {
              processedText = processedText.replace(
                variables[item].label,
                variables[item].value
              );
            }

            return {
              title: template.node.title,
              text: processedText,
            };
          })
        : [];
  }

  const addTemplate = () => {
    setShowModalNotes(false);

    let dataToSave = convertedContent + templates[activeTemplate].text;

    const blockRenderMap = DefaultDraftBlockRenderMap.set("br", {
      element: "br",
    });
    const blocksFromHTML = convertFromHTML(
      dataToSave,
      getSafeBodyFromHTML,
      blockRenderMap
    );

    const intVal = EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML)
    );
    // setEditorState(intVal);
    handleEditorChange(intVal);
    setConvertedContent(dataToSave);
    setNotesValue(dataToSave);
  };

  const onTabClick = (event) => {
    event.preventDefault();

    // create an object that holds the blockkey, text and possible indexes of ___
    let blockArray = [];

    // get all the blocks Keys according to position
    let blockKeysArray = [];

    // get all blocks on the editor
    const blockMap = editorState.getCurrentContent().getBlockMap();

    // loop over on blocks to get keys, indexes and contents
    blockMap.forEach((contentBlock) => {
      const blockKey = contentBlock.getKey();
      const text = contentBlock.getText();
      const templateIndexes = [];

      let lastIndex = -1;
      while ((lastIndex = text.indexOf("___", lastIndex + 1)) !== -1) {
        templateIndexes.push(lastIndex);
      }

      let blockData = {
        blockKey: blockKey,
        blockText: text,
        templateIndexes: templateIndexes,
      };
      blockArray.push(blockData);
      blockKeysArray.push(blockKey);
    });

    // get the current block where the cursor is active.
    let selectionState = editorState.getSelection();
    let anchorKey = selectionState.getAnchorKey();
    let cursorCurrentLocation = selectionState.getEndOffset();

    // now lets further process the only blocks that we need to loop over
    // find position of active anchor key from blockkeys list
    let currentPosition = blockKeysArray.indexOf(anchorKey);
    let lastItemFromArray = blockKeysArray.length - 1;

    // now we must check if current block cursorend position is lessthan templateIndexes
    // if < we can jump on the next ___ on the same block. Else we check the next block
    // and do the same
    for (const blockItem in blockArray) {
      const blockData = blockArray[blockItem];
      let breakOut = false;
      let blockKey = blockData.blockKey;

      if (blockItem >= currentPosition) {
        let indexes = blockData.templateIndexes;

        // if cursorcurrentposition is from currentPosition keep it else
        // reset it to 0
        if (blockItem != currentPosition) {
          cursorCurrentLocation = 0;
        }

        if (indexes.length > 0) {
          for (const indexItem in indexes) {
            let index = indexes[indexItem];

            if (cursorCurrentLocation <= index) {
              let start = index;
              let end = index + 3;
              // we jump to this location.
              breakOut = true;

              // set the new editor state
              let selection = new SelectionState({
                anchorKey: blockKey, // key of block
                anchorOffset: start,
                focusKey: blockKey,
                focusOffset: end, // key of block
                hasFocus: true,
                isBackward: false, // isBackward = (focusOffset < anchorOffset)
              });

              let newEditorState = EditorState.forceSelection(
                editorState,
                selection
              );

              setEditorState(newEditorState);
              break;
            }
          }
        }
      }

      if (breakOut) {
        break;
      }

      // if we cant find anymore ___ to jump we try to reset the jump and were on the last block.

      if (blockItem == lastItemFromArray) {
        let firstBlockKey = blockKeysArray[0];
        // focus the cursor on the first letter of the first block
        let selection = new SelectionState({
          anchorKey: firstBlockKey, // key of block
          anchorOffset: 0,
          focusKey: firstBlockKey,
          focusOffset: 0, // key of block
          hasFocus: true,
          isBackward: false, // isBackward = (focusOffset < anchorOffset)
        });

        let newEditorState = EditorState.forceSelection(editorState, selection);

        setEditorState(newEditorState);
        break;
      }
    }
  };

  const copyNotesAfterExisting = (editorReadOnly) => {
    if (!editorReadOnly) {
      let dataToSave = convertedContent + previousEncounterNotesData;

      const blockRenderMap = DefaultDraftBlockRenderMap.set("br", {
        element: "br",
      });
      const blocksFromHTML = convertFromHTML(
        dataToSave,
        getSafeBodyFromHTML,
        blockRenderMap
      );

      const intVal = EditorState.createWithContent(
        ContentState.createFromBlockArray(blocksFromHTML)
      );
      // setEditorState(intVal);
      handleEditorChange(intVal);
      setConvertedContent(dataToSave);
      setNotesValue(dataToSave);
    }
  };
  return (
    <>
      <Form.Group as={Row}>
        {!is_modal ? (
          <Form.Label
            column
            xs={7}
            sm={7}
            md={7}
            className="text-left textBold textGrey"
          >
            NOTES
          </Form.Label>
        ) : null}
        {!is_modal ? (
          <Col
            xs={5}
            sm={5}
            md={5}
            className="text-xs-right d-flex justify-content-end align-items-start"
          >
            {encounternote_permission &&
            encounternote_permission.includes("add") ? (
              <>
                <Button
                  variant="link"
                  href="#"
                  className="d-flex justify-content-end px-0 icon-black "
                  onClick={() => {
                    if (!editorReadOnly) {
                      notesSaveCall();
                      setSaveStatus(true);
                    }
                    setEditorReadOnly(!editorReadOnly);
                  }}
                >
                  {editorReadOnly ? "Edit" : "Save"}
                </Button>
                {previousEncounterNotesData ? (
                  <Button
                    variant="link"
                    href="#"
                    className="mt-2 icon-black "
                    onClick={() => copyNotesAfterExisting(editorReadOnly)}
                  >
                    <i className="fa fa-copy"></i>
                  </Button>
                ) : null}
                <Button
                  variant="link"
                  href="#"
                  className={
                    "d-flex justify-content-end icon-black add_button " +
                    (previousEncounterNotesData ? " px-0 " : " pr-0 ")
                  }
                  onClick={() => setShowModalNotes(true)}
                >
                  <i className="fa fa-plus-circle mt-2"></i>
                </Button>
              </>
            ) : null}
          </Col>
        ) : null}
      </Form.Group>
      <Col sm={12} md={12} className="px-0">
        <div className="notes-textarea-container">
          {/* third party to change  */}
          <Editor
            editorRef={(ref) => (editorStateRef.current = ref)}
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            spellCheck={true}
            onTab={onTabClick}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "list",
                "textAlign",
                "history",
              ],
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              inline: {
                options: ["bold", "italic", "underline"],
              },

              blockType: {
                options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
              },
              fontSize: {
                options: [12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            readOnly={editorReadOnly}
          />
          {!saveStatus && <Badge variant="danger">Unsaved Changes</Badge>}
        </div>
      </Col>
      <CustomModals
        dialogClassName="modal60h"
        showModal={showModalNotes}
        modalHeader={"CHOOSE TEMPLATE"}
        setShowModal={setShowModalNotes}
        contentClassName={" notes-modal"}
      >
        <Row className=" mt-5 tab-template-container">
          <Col sm={4} md={4} xs={12} className="tab-title">
            {templates.map((template, index) => {
              return (
                <div key={index}>
                  <h3
                    className={`tablinks ${
                      index === activeTemplate ? "active" : ""
                    }`}
                    onClick={(e) => setActiveTemplate(+e.target.dataset.index)}
                    data-index={index}
                  >
                    {template.title}
                  </h3>
                </div>
              );
            })}
          </Col>
          <Col sm={8} md={8} xs={12} className="tab-content">
            <div className="tabcontent">
              {templates[activeTemplate] ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: templates[activeTemplate].text,
                  }}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-5">
          <Col className="d-flex justify-content-center justify-content-sm-end">
            <Button
              variant="primary"
              size="md"
              onClick={() => setShowModalNotes(false)}
            >
              CANCEL
            </Button>
            <Button
              variant="primary"
              size="md"
              className="ml-3"
              onClick={addTemplate}
            >
              ADD
            </Button>
          </Col>
        </Row>
      </CustomModals>
    </>
  );
};

export default withRouter(NotesBlock);
