//@ts-nocheck
import React, { useCallback, useEffect,useState } from "react";
import { Formik, Form } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FormikTextField from "./FormikTextField";
import FormikSelect from "./FormikSelect";
import FormikDatePicker from "./FormikDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import { getPatient } from "redux/slices/patientSlice";
import { createUpdatePatientConsultation } from "redux/slices/patientSlice";
import { useSnackbar } from "react-simple-snackbar";
import { error_options, SNACK_DURATION, success_options } from "Common/helpers";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import dayjs from "dayjs";
export const SectionHeader = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "1.5rem",
      }}
    >
      {title}
    </Typography>
  );
};

function OnboardingForm() {
  const { t } = useTranslation();
  const defaultStrings = {
    selectSomeItems: t("multiSelectDoc.select"),
    search: t("multiSelectDoc.search"),
  };
  const dispatch = useAppDispatch();
  const { loading, patientData } = useAppSelector(
    ({ rootPatientSlice }) => rootPatientSlice
  );
  const [openErrorSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const history = useHistory();

  useEffect(() => {
    dispatch(getPatient());
  }, [dispatch]);

  const medicalHistoryOptions = [
    "anemia",
    "asthma",
    "bariatic",
    "cancer",
    "concussion",
    "diabetes",
    "epilepsy",
    "g6pd",
    "heartDisease",
    "highBlood",
    "highCho",
    "irritableBowel",
    "kidney",
    "liver",
    "migrane",
    "ms",
    "stroke",
    "thyroid",
    "noMedical",
    "other",
  ];

  const relationshipStatusOptions = [
    "single",
    "married",
    "divorced",
    "widowed",
    "inRelationship",
  ];

  const levelEduOptions = [
    "none",
    "gradeSchool",
    "highSchool",
    "collegeUni",
    "graduateSchool",
  ];

  const relationOptions = [
    "spouseParent",
    "parent",
    "sibling",
    "sonDaug",
    "otherRelative",
    "friend",
  ];

  const initialValues = {
    consultationRegulation: false,
    relationshipStatus: "",
    levelEducation: "",
    emergencyContactPhone: "",
    relationship: "",
    emergencyContact: "",
    haveYouSeenPsychiatric: "",
    psychiatricAdmitted: "",
    psychiatricFamily: "",
    psychiatriclistRelative: "",
    psychiatricSmoke: "",
    psychiatricListMedication: "",
    psychiatricmedAllergies: "",
    psychiatriclistAller: "",
    followingMedicalHistory: [],
    pregnant: "",
    breastfeeding: "",
    lastDayMenstrual: "",
  };

  const [selectedMedicalHistory, setSelectedMedicalHistory] = useState([]);
  const onSubmit = useCallback(
    async (values) => {
      const res = await dispatch(
        createUpdatePatientConsultation({
          data: JSON.stringify(values),
          id: patientData.id,
        })
      );
      //@ts-ignore
      if (res?.error) {
        openErrorSnackbar(res?.payload, [SNACK_DURATION]);
        return;
      } else if (res?.payload?.errors?.length > 0) {
        openErrorSnackbar(res?.payload?.errors?.[0]?.messages, [
          SNACK_DURATION,
        ]);
        return;
      } else if (res) {
        openSnackbarSuccess(t("patientrequestReport.submitted"), [
          SNACK_DURATION,
        ]);
        if (
          res?.completedTherapyPreference &&
          res.completedTherapyPreference === false
        ) {
          history.push("/patientPortal/therapy/form");
        } else {
          // redirect to dashboard.
          history.push("/patientPortal/dashboard");
        }
      }
    },
    [
      dispatch,
      history,
      openErrorSnackbar,
      openSnackbarSuccess,
      patientData?.id,
      t,
    ]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, touched, handleChange, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <SectionHeader title={t("onboardingForm.termsAndCondition")} /> */}
              <FormControl required variant="standard">
                <FormLabel component="legend">
                  {t("onboardingForm.consultationRegulation")}
                </FormLabel>
                <Typography>
                  {t("onboardingForm.termsAndConditionContent")}
                </Typography>
                <FormControlLabel
                  label={t("onboardingForm.agree")}
                  required
                  control={
                    <Checkbox
                      name="consultationRegulation"
                      checked={values.checked}
                      onChange={handleChange}
                    />
                  }
                />
              </FormControl>
            </Grid>
            {/* Personal info */}
            <Grid item xs={12}>
              <SectionHeader title={t("onboardingForm.personalInfo")} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="relationshipStatus"
                    label={t("onboardingForm.relationStatus")}
                    labelId="relationshipStatus-label"
                    required={true}
                  >
                    {relationshipStatusOptions.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {t(`onboardingForm.relationshipOptions.${item}`)}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="levelEducation"
                    label={t("onboardingForm.levelEdu")}
                    labelId="levelEducation-label"
                    required={true}
                  >
                    {levelEduOptions.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {t(`onboardingForm.educationOptions.${item}`)}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="emergencyContact"
                    labelKey="onboardingForm.emergencyCon"
                    error={errors.emergencyContact && touched.emergencyContact}
                    required={true}
                    helperTextKey="onboardingForm.emergencyWarning"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="emergencyContactPhone"
                    labelKey="onboardingForm.emerPhone"
                    error={
                      errors.emergencyContactPhone &&
                      touched.emergencyContactPhone
                    }
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="relationship"
                    label={t("onboardingForm.relationship")}
                    labelId="relationship-label"
                    required={true}
                  >
                    {relationOptions.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {t(`onboardingForm.relationOption.${item}`)}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                </Grid>
              </Grid>
            </Grid>
            {/* Psychiatric info */}

            <Grid item xs={12}>
              {/* <SectionHeader title={t("onboardingForm.psychiatricHistory")} /> */}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="haveYouSeenPsychiatric"
                    label={t("onboardingForm.haveYouseen")}
                    labelId="have-you-label"
                    required={true}
                  >
                    <MenuItem value={true}>
                      {t("patientAppointmentDetails.yes")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("patientAppointmentDetails.no")}
                    </MenuItem>
                  </FormikSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="psychiatricAdmitted"
                    label={t("onboardingForm.admitted")}
                    labelId="psychiatricAdmitted-label"
                    required={true}
                  >
                    <MenuItem value={true}>
                      {t("patientAppointmentDetails.yes")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("patientAppointmentDetails.no")}
                    </MenuItem>
                  </FormikSelect>
                </Grid>
                <Grid item xs={12}>
                  <FormikSelect
                    name="psychiatricFamily"
                    label={t("onboardingForm.family")}
                    labelId="psychiatricFamily-label"
                    required={true}
                  >
                    <MenuItem value={true}>
                      {t("onboardingForm.hasFamIll")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("onboardingForm.noFamily")}
                    </MenuItem>
                  </FormikSelect>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="psychiatriclistRelative"
                    labelKey="onboardingForm.listRelative"
                    error={
                      errors.psychiatriclistRelative &&
                      touched.psychiatriclistRelative
                    }
                    required={values?.psychiatricFamily ? true : false}
                    rows={4}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikSelect
                    name="psychiatricSmoke"
                    label={t("onboardingForm.smoke")}
                    labelId="psychiatricSmoke-label"
                    required={true}
                  >
                    <MenuItem value={true}>
                      {t("onboardingForm.smokes")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("onboardingForm.notSmokes")}
                    </MenuItem>
                  </FormikSelect>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="psychiatricListMedication"
                    labelKey="onboardingForm.listMedication"
                    error={
                      errors.psychiatricListMedication &&
                      touched.psychiatricListMedication
                    }
                    required={false}
                    rows={4}
                    multiline={true}
                    helperTextKey="onboardingForm.listMedText"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikSelect
                    name="psychiatricmedAllergies"
                    label={t("onboardingForm.medAllergies")}
                    labelId="psychiatricmedAllergies-label"
                    required={true}
                    helperTextKey="onboardingForm.medAllergiesText"
                  >
                    <MenuItem value={true}>
                      {t("onboardingForm.hasAller")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("onboardingForm.notHaveAller")}
                    </MenuItem>
                  </FormikSelect>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="psychiatriclistAller"
                    labelKey="onboardingForm.listAller"
                    error={
                      errors.psychiatriclistAller &&
                      touched.psychiatriclistAller
                    }
                    required={values?.psychiatricmedAllergies ? true : false}
                    rows={4}
                    multiline={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {/* <SectionHeader title={t("onboardingForm.medicalHistory")} /> */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <MultiSelect
                    options={medicalHistoryOptions.map(item => ({
                      value: item,
                      label: t(`onboardingForm.medicalOptions.${item}`),
                    }))}
                    value={selectedMedicalHistory}
                    required
                    ClearIcon=""
                    hasSelectAll={false}
                    placeholder={t('onboardingForm.selectMedicalHistory')}
                    onChange={setSelectedMedicalHistory}
                    labelledBy="Select"
                    overrideStrings={{ selectAll: "Select All", ...defaultStrings }}
                  />

                  {/* <FormikSelect
                    name="followingMedicalHistory"
                    label={t("onboardingForm.followingMedicalHistory")}
                    labelId="followingMedicalHistory-label"
                    required={true}
                    multiple={true}
                  >
                    {medicalHistoryOptions.map((item) => {
                      return (
                        <MenuItem value={item} id={item} key={item}>
                          {t(`onboardingForm.medicalOptions.${item}`)}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect> */}
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="listOtherMedical"
                    labelKey="onboardingForm.listOtherMedical"
                    error={errors.listOtherMedical && touched.listOtherMedical}
                    required={false}
                    rows={4}
                    multiline={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* this is only for female */}
            {patientData?.gender?.toLowerCase() === "female" && (
              <Grid item xs={12}>
                <SectionHeader title={t("onboardingForm.menstrual")} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="pregnant"
                      label={t("onboardingForm.pregnant")}
                      labelId="pregnant-label"
                      required={true}
                    >
                      <MenuItem value={true}>
                        {t("patientAppointmentDetails.yes")}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t("patientAppointmentDetails.no")}
                      </MenuItem>
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="breastfeeding"
                      label={t("onboardingForm.breastfeeding")}
                      labelId="breastfeeding-label"
                      required={true}
                    >
                      <MenuItem value={true}>
                        {t("patientAppointmentDetails.yes")}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t("patientAppointmentDetails.no")}
                      </MenuItem>
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikDatePicker
                      name="lastDayMenstrual"
                      label={t("onboardingForm.lastDayMenstrual")}
                      required={true}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: { fullWidth: true },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} style={{ padding: 20 }}>
              <LoadingButton
                name="submit"
                type="submit"
                variant="contained"
                loading={loading}
                disabled={loading}
              >
                {t("submit")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingForm;
