import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Base from './base.js';
import Modals from '../component/Modal/modal';

function ManageEncounter(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Base title={'ENCOUNTER CONFIGURATION'} showHeader={true}>
      <Row>
        <Col xs={12} sm={8} md={8}>
          <Form.Group as={Row}>
            <Form.Label column sm={12} md={6} lg={5} xl={4} className="text-right">
              ENCOUNTER TYPE:
            </Form.Label>
            <Col sm={12} md={6} lg={6} xl={5}>
              <Form.Control
                autoComplete="off"
                type="text"
                name="encounterType"
                placeholder="Text"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="mb-1 textGreyBold">DIAGNOSES</span>
          <span className="ml-4">Fields:</span>
          <ul className="ml-5">
            <li>ICD: ICD-10 Codes</li>
            <li>DSM: DSM Codes</li>
            <li>Note: Text</li>
            <li>Date: Date</li>
          </ul>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textGreyBold mb-1">FAMILY HEALTH HISTORY</span>
          <span className="ml-4">Fields:</span>
          <ul className="ml-5">
            <li>Note: Text</li>
            <li>Date: Date</li>
          </ul>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str mb-3">
          <span className="textGreyBold mb-1">PATIENT RISK SCORE</span>
          <span className="ml-4">Fields:</span>
          <ul className="ml-5">
            <li>Type: Choices</li>
            <li>Score: Number</li>
            <li>Date: Date</li>
          </ul>
        </Col>
      </Row>

      <Modals
        showModal={showModal}
        modalHeader={'ADD SECTION/QUESTION'}
        setShowModal={setShowModal}>
        <Row className="mb-5 mt-5">
          <Col xs={12} sm={12} md={12}>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={4} className="text-right">
                Section Name
              </Form.Label>
              <Col sm={12} md={7}>
                <Form.Control autoComplete="off" type="text" name="sectioName" placeholder="Text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={4} className="text-right">
                Field Name
              </Form.Label>
              <Col sm={12} md={7}>
                <Form.Control autoComplete="off" type="text" name="fieldName" placeholder="Text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={4} className="text-right">
                Field Type
              </Form.Label>
              <Col sm={12} md={7}>
                <Form.Control autoComplete="off" as="select" name="type">
                  <option value="">Type 1</option>
                  <option value="">Type 2</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-2 mt-5">
          <Col
            xs={12}
            sm={12}
            md={12}
            className="d-flex flex-wrap justify-content-center justify-content-md-end">
            <Button variant="primary" size="md" className="mr-2 mb-1">
              CANCEL
            </Button>
            <Button variant="primary" size="md" className="mr-2 mb-1">
              SAVE
            </Button>
            <Button variant="primary" size="md" className="mb-1">
              ADD ANOTHER
            </Button>
          </Col>
        </Row>
      </Modals>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-start button-below">
          <Button
            variant="link"
            className="back textPrimaryBold d-inline-flex ml-4"
            onClick={() => setShowModal(true)}>
            <i className="fa fa-plus-circle mr-2 font-size" aria-hidden="true"></i>
            <span>ADD NEW SECTION</span>
          </Button>
        </Col>
      </Row>
    </Base>
  );
}
export default ManageEncounter;
