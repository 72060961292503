import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import Base from "./base.js";
import { withRouter } from "react-router-dom";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  success_options,
  displayMiddleName,
  getErrorMessage,
  isUserStaff,
  concatAllErrors,
  // getPermissionForAction,
} from "../Common/helpers";
import Preloader from "../Common/Preloder/Preloader";
import CustomModals from "../component/Modal/modal";
import LabsComponent from "./Labs/LabsComponent";
import { useDropzone } from "react-dropzone";
import * as Sentry from "@sentry/browser";
import { urlBackend } from "apolloSettings";
import { deepCopy } from "../Common/helpers";
import { useTranslation } from "react-i18next";

function LabRequestDetail(props) {
  const [showModal, setShowModal] = useState(false);
  const [labInitialValues, setLabInitialValues] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [data, setData] = useState(null);

  const lab_req_id = props.match.params.id;
  const isStaff = isUserStaff();

  const [openSnackbar] = useSnackbar(error_options);
  const { t } = useTranslation();

  const REQUEST_LAB_REQUEST_DETAIL = gql`
  query {
    labRequest(id:"${lab_req_id}"){
      id
      staticId
      file
      patient{
        phone
        civilId
        id
        firstName
        middleName
        lastName
        identifier
        dob
        displayGender
        impressionDiagnosis
      }
      searchText
      description
      usedTemplate {
        id
        staticId
        testCategories {
          edges {
            node {
              id
              name
              staticId
              structure
              testType
              parent {
                name
              }
              children {
                edges {
                  node {
                    id
                    name
                    staticId
                    labTests {
                      edges {
                        node {
                          staticId
                          name
                          id
                        }
                      }
                    }
                  }
                }
              }
              labTests {
                edges {
                  node {
                    staticId
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      groupTests {
        edges {
          node {
            name
            id
            staticId
            testType
            labTests {
              edges {
                node {
                  staticId
                  name
                  id
                }
              }
            }
          }
        }
      }
      specificTests {
        edges {
          node {
            name
            id
            staticId
          }
        }
      }
      testResults {
        edges {
          node {
            specificTest {
              name
              id
              staticId
            }
            category{
              name
              id
              testType
            }
            result
          }
        }
      }
    }
  }
`;

  const DELETE_LAB_REQUEST = gql`
    mutation deleteLabRequest($id: ID!) {
      deleteLabRequest(id: $id) {
        deleted
      }
    }
  `;

  const UPLOAD_MUTATION = gql`
    mutation updateLabFile($file: Upload!, $id: ID!) {
      updateLabFile(file: $file, id: $id) {
        obj {
          file
        }
      }
    }
  `;

  const GENERTE_LAB_REQUEST_PRINT_CODE = gql`
    mutation generateLabRequestPrintCode($id: ID!) {
      generateLabRequestPrintCode(id: $id) {
        code
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [uploadFile] = useMutation(UPLOAD_MUTATION, {
    onCompleted({ updateLabFile }) {
      if (updateLabFile?.errors && updateLabFile?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(updateLabFile?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Result file uploaded");
        updateDetailPage();
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("UPLOAD_MUTATION error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // QUESTION what is the difference - useLazyQuery and then useEffect and useQuery
  // and no useEffect?
  const [getLabRequestDetail, { loading }] = useLazyQuery(
    REQUEST_LAB_REQUEST_DETAIL,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ labRequest }) => {
        if (!labRequest) {
          props.history.push("/404-not-found");
        } else {
          setData(deepCopy(labRequest));
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_LAB_REQUEST_DETAIL error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    getLabRequestDetail();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDetailPage = () => {
    // used to pass in lab component that updates lab request detail after edit
    getLabRequestDetail();
  };

  // DELETE_ LAB REQ
  const [deleteLabRequest] = useMutation(DELETE_LAB_REQUEST, {
    onCompleted: ({ deleteLabRequest }) => {
      if (deleteLabRequest?.errors && deleteLabRequest?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteLabRequest?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        props.history.goBack();
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_LAB_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  var labRequest = data;

  let patient_name =
    labRequest && labRequest.patient
      ? labRequest.patient.firstName +
        " " +
        displayMiddleName(labRequest.patient.middleName) +
        labRequest.patient.lastName
      : "";

  // DELETE_APPOINTMENT
  const [generateLabRequestPrintCode] = useMutation(
    GENERTE_LAB_REQUEST_PRINT_CODE,
    {
      onCompleted: ({ generateLabRequestPrintCode }) => {
        let oneTimeCode = generateLabRequestPrintCode.code;
        setTimeout(() => {
          window.open(
            urlBackend +
              "pdf/reports/lab/request/" +
              labRequest?.staticId +
              "/" +
              oneTimeCode +
              "/"
          );
        }, 300);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("GENERTE_LAB_REQUEST_PRINT_CODE error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const deleteRefFunc = () => {
    if (window.confirm("Delete Lab Request?")) {
      let val = {};
      val["id"] = labRequest.id;
      deleteLabRequest({ variables: val });
    }
  };

  useEffect(() => {
    let initVal = {};
    if (labRequest) {
      initVal["id"] = labRequest.id;
      initVal["description"] = labRequest.description;
      initVal["selectedTemplate"] = {
        node: labRequest.usedTemplate,
      };
      let profileTestReqArr = labRequest.groupTests.edges.map((test) => {
        return test.node.staticId;
      });
      let specificUnderProfile = labRequest.groupTests.edges.map((test) => {
        return test.node.labTests.edges.map((i) => i.node.staticId);
      });
      let specificTestReqArr = labRequest.specificTests.edges.map((test) => {
        return test.node.staticId;
      });
      let merged = [].concat.apply([], specificUnderProfile); // get all the specific tests that are under profile so its not duplicate
      let difference = specificTestReqArr.filter((x) => !merged.includes(x)); // remove all tests from specific tests that are a part of existing profile test
      initVal["specificTestReqArr"] = difference;
      initVal["specificUnderProfile"] = merged;
      initVal["profileTestReqArr"] = profileTestReqArr;
      initVal["patientIdentifier"] = labRequest.patient.identifier;
      setLabInitialValues(initVal);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labRequest]);

  const editLabRequest = () => {
    setShowModal(true);
  };

  const renderTemplateTwo = () => {
    let testNormalCategoryArr =
      labRequest &&
      labRequest.usedTemplate.testCategories &&
      labRequest.usedTemplate.testCategories.edges
        ? labRequest.usedTemplate.testCategories.edges.filter(
            (i) => i.node.testType === "NORMAL"
          )
        : [];
    let testProfileCategoryArr =
      labRequest &&
      labRequest.usedTemplate.testCategories &&
      labRequest.usedTemplate.testCategories.edges
        ? labRequest.usedTemplate.testCategories.edges.filter(
            (i) => i.node.testType === "PROFILE"
          )
        : [];

    let groupTestsUsed = [];
    labRequest &&
      labRequest.groupTests &&
      labRequest.groupTests.edges.map((item, index) => {
        let labTests = item.node.labTests.edges.map((item, index) => {
          return {
            name: item.node.name,
            staticId: item.node.staticId,
            id: item.node.id,
            __typename: "SpecificTestNode",
          };
        });
        groupTestsUsed = [...groupTestsUsed, ...labTests];
        return null;
      });

    if (testProfileCategoryArr.length > 0) {
      testProfileCategoryArr = testProfileCategoryArr.filter(
        (i) => i.node.structure !== "child"
      );
    }
    return (
      <div className="template_two">
        <div className="category_box mb-4">
          <h6 className="notes-title print-title-text">
            <b> {t("patientLabRequest.notesComments")} </b>
          </h6>
          <p className="profiles-comments">
            {" "}
            {labRequest && labRequest.description
              ? labRequest.description
              : " - "}
          </p>
        </div>
        <div className="mb-4">
          <h6 className="notes-title  print-title-text">
            <b>{t("patientLabRequest.tests")}</b>
          </h6>
        </div>
        <div className="category_section">
          <Row className="tests-container">
            {testNormalCategoryArr && testNormalCategoryArr.length > 0
              ? testNormalCategoryArr.map((normalCat, index) => {
                  let lab_tests = normalCat.node.labTests.edges;
                  lab_tests = lab_tests?.sort((a, b) =>
                    a.node.name.localeCompare(b.node.name)
                  );
                  let children = normalCat.node.children.edges;
                  let has_active_test = false;

                  if (
                    labInitialValues &&
                    labInitialValues.specificTestReqArr &&
                    lab_tests.find((i) => {
                      let exist = groupTestsUsed.filter(
                        (item) => item.staticId === i.node.staticId
                      );
                      return (
                        labInitialValues.specificTestReqArr.includes(
                          i.node.staticId
                        ) && exist.length === 0
                      );
                    })
                  ) {
                    has_active_test = true;
                  }
                  if (
                    labInitialValues &&
                    labInitialValues.specificTestReqArr &&
                    children
                  ) {
                    children.map((child) => {
                      let child_lab_test = child.node.labTests.edges;
                      child_lab_test = child_lab_test?.sort((a, b) =>
                        a.node.name.localeCompare(b.node.name)
                      );
                      if (
                        child_lab_test.find((i) => {
                          let exist = groupTestsUsed.filter(
                            (item) => item.staticId === i.node.staticId
                          );
                          return (
                            labInitialValues.specificTestReqArr.includes(
                              i.node.staticId
                            ) && exist.length === 0
                          );
                        })
                      ) {
                        has_active_test = true;
                      }
                      return null;
                    });
                  }

                  return has_active_test ? (
                    <Col
                      xs={4}
                      sm={6}
                      md={6}
                      lg={4}
                      className="active-tests mb-3"
                      key={index}
                    >
                      <div className="category_box">
                        <h6 className="group_title print-title-text">
                          <b>{normalCat.node.name}</b>
                        </h6>
                        {lab_tests.length > 0
                          ? lab_tests.map((lab, index) => {
                              if (
                                labInitialValues &&
                                labInitialValues.specificTestReqArr.includes(
                                  lab.node.staticId
                                )
                              ) {
                                return (
                                  <div
                                    key={index}
                                    className="create-new-patient-checkboxes"
                                  >
                                    <Form.Label className="">
                                      {lab.node.name}
                                    </Form.Label>
                                  </div>
                                );
                              }
                              return null;
                            })
                          : null}
                        {children.length > 0
                          ? children.map((child, index) => {
                              let child_lab_test = child.node.labTests.edges;
                              child_lab_test = child_lab_test?.sort((a, b) =>
                                a.node.name.localeCompare(b.node.name)
                              );
                              return (
                                <div key={index}>
                                  {child_lab_test.length > 0
                                    ? child_lab_test.map((child_lab, index) => {
                                        if (
                                          labInitialValues &&
                                          labInitialValues.specificTestReqArr.includes(
                                            child_lab.node.staticId
                                          )
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="create-new-patient-checkboxes my-1"
                                            >
                                              <Form.Label>
                                                {child_lab.node.name}
                                              </Form.Label>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </Col>
                  ) : null;
                })
              : null}
          </Row>
        </div>
        {labRequest &&
        labRequest.groupTests &&
        labRequest.groupTests.edges.length > 0 ? (
          <Row className="mx-0 mb-5">
            <Col xs={12} md={12} className="category_box_profiles  px-0 mt-2">
              <div className="category_section">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="profiles-th  print-title-text">
                        {t("patientLabRequest.profiles")}
                      </th>
                      <th className="profiles-th  print-title-text">
                        {t("patientLabRequest.profileDes")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {labRequest &&
                      labRequest.groupTests &&
                      labRequest.groupTests.edges.map((item, index) => {
                        let lab_tests = item.node.labTests.edges;
                        lab_tests = lab_tests.sort((a, b) =>
                          a.node.name.localeCompare(b.node.name)
                        );
                        return (
                          <tr key={index}>
                            <td
                              xs={4}
                              md={4}
                              className="create-new-patient-checkboxes"
                            >
                              <span>{item.node.name}</span>
                            </td>
                            <td xs={8} md={8}>
                              {lab_tests?.map((lab, index) => {
                                return (
                                  <span key={index}>
                                    {lab.node.name}
                                    {index === lab_tests.length - 1 ? "" : ", "}
                                  </span>
                                );
                              })}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        ) : null}

        <CustomModals
          showModal={showModal}
          modalHeader={"LAB REQUEST"}
          setShowModal={setShowModal}
          dialogClassName="modal60h"
        >
          <LabsComponent
            patient_id={
              labRequest && labRequest.patient ? labRequest.patient.id : ""
            }
            setShowModal={setShowModal}
            showModal={showModal}
            updateDetailPage={updateDetailPage}
            labInitialValues={labInitialValues}
          />
        </CustomModals>
      </div>
    );
  };

  const printLabRequest = () => {
    let variables = { id: labRequest?.id };
    generateLabRequestPrintCode({ variables });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const file = acceptedFiles[0];
      // use the uploadFile variable created earlier
      if (file && labRequest) {
        let val = {
          file: file,
          id: labRequest.id,
        };
        uploadFile({
          // use the variables option so that you can pass in the file we got above
          variables: { ...val },
          onCompleted: () => {},
        });
      }
    },
    // pass in uploadFile as a dependency
    [uploadFile, labRequest]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const viewLabReqResult = () => {
    setTimeout(() => {
      window.open(labRequest.file, "_blank");
    }, 300);
  };

  return (
    <>
      <Base
        page_loading={loading}
        title={patient_name ? patient_name + " LAB REQUEST" : "LAB REQUEST"}
        isPatientPortal={!isStaff}
        showHeader={true}
        rightChild={
          <div className="d-flex flex-column d-print-none">
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="mx-2"
                onClick={printLabRequest}
              >
                <b> {t("patientPaymentDetails.print")} </b>
              </Button>
              {isStaff ? (
                <Button
                  variant="primary"
                  className="mx-2 edit-lab-request"
                  onClick={editLabRequest}
                >
                  {" "}
                  <b> {t("profileView.edit")} </b>{" "}
                </Button>
              ) : null}
              {isStaff ? (
                <Button
                  variant="danger"
                  className="encounter-detail-btn mx-2"
                  onClick={deleteRefFunc}
                >
                  {" "}
                  <b> {t("reports.delete")} </b>{" "}
                </Button>
              ) : null}
            </div>
            {labRequest && isStaff ? (
              <div className="d-flex mt-3  justify-content-end">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button variant="primary" className="mx-2">
                    {" "}
                    <b>
                      {labRequest.file
                        ? t("patientLabRequest.uploadNewResult")
                        : t("patientLabRequest.uploadResult")}
                    </b>{" "}
                  </Button>
                </div>
                {labRequest.file ? (
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={viewLabReqResult}
                  >
                    {" "}
                    <b> {t("patientLabRequest.viewResult")} </b>{" "}
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        }
      >
        {loading ? (
          <Preloader />
        ) : (
          <Row className="d-print-none">
            <Col md={12}>{renderTemplateTwo()}</Col>
          </Row>
        )}
      </Base>
    </>
  );
}
export default withRouter(LabRequestDetail);
