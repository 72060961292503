import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Table, Button, Pagination } from "react-bootstrap";

import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  success_options,
  PER_PAGE_COUNT,
  urlActions,
  getErrorMessage,
  concatAllErrors,
  getDateKuwait,
} from "../Common/helpers";
import { Formik } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../cache/cache";
import CustomModals from "../component/Modal/modal";
import RecurringEventModal from "./recurringEventModal";
import _ from "lodash";
import Preloader from "../Common/Preloder/Preloader";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers";

function ManageLeaveRequests(props) {
  const { history } = props;
  const formikRef = useRef();

  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [activePage, setActivePage] = useState(1);
  const [showEventModal, setShowEventModal] = useState(false);
  const [allDoctors, setAllDoctors] = useState(null);

  const [recurringEventItem, setRecurringEventItem] = useState();
  const [orderedRecurringEvents, setOrderedRecurringEvents] = useState();
  const [startLoading, setStartLoading] = useState(false);
  const [canViewMultiplePractitioners, setCanViewMultiplePractitioners] =
    useState(false);

  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }
  }, []);

  useEffect(() => {
    if (!showEventModal) {
      setRecurringEventItem();
    }
  }, [showEventModal]);

  var userDetails = useReactiveVar(userDetailsVar);
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const REQUEST_DOCTOR = gql`
    query ($doctor: String) {
      doctors(active: true, identifier: $doctor) {
        edges {
          node {
            firstName
            lastName
            id
            email
            arabicName
            titlePrefix
            identifier

            recurringEvents {
              edges {
                node {
                  id
                  startDate
                  startTime
                  endTime
                  title
                  description
                  everyday
                  days
                  dateUntil
                }
              }
            }
          }
        }
      }
    }
  `;
  const REQUEST_ALL_DOCTORS = gql`
    query {
      doctors(active: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            arabicName
            titlePrefix
            identifier

            recurringEvents {
              edges {
                node {
                  id
                  startDate
                  startTime
                  endTime
                  title
                  description
                  everyday
                  days
                  dateUntil
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data: rawAllDoctors, loading } = useQuery(REQUEST_ALL_DOCTORS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_ALL_DOCTORS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setAllDoctors(deepCopy(rawAllDoctors));
  }, [rawAllDoctors]);

  const [getDoctorsFilter, { data: doctorObjFilter }] = useLazyQuery(
    REQUEST_DOCTOR,
    { fetchPolicy: "network-only" }
  );

  let canViewAppointments =
    userDetails &&
    userDetails.canViewAppointments &&
    userDetails.canViewAppointments.edges
      ? userDetails.canViewAppointments.edges.map((doc) => {
          return doc.node.identifier;
        })
      : null;

  let doctorObj = doctorObjFilter?.doctors?.edges.filter((i) => {
    if (userDetails?.isSuperuser) {
      return i;
    } else {
      try {
        return canViewAppointments.some((j) => j === i?.node?.identifier);
      } catch {
        return false;
      }
    }
  });

  let all_doctors_obj = allDoctors?.doctors?.edges?.sort((a, b) =>
    a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  let daysArr = [
    {
      key: "0",
      value: "Sunday",
    },
    {
      key: "1",
      value: "Monday",
    },
    {
      key: "2",
      value: "Tuesday",
    },
    {
      key: "3",
      value: "Wednesday",
    },
    {
      key: "4",
      value: "Thursday",
    },
    {
      key: "5",
      value: "Friday",
    },
    {
      key: "6",
      value: "Saturday",
    },
  ];

  const eventDays = (item) => {
    let recurring_days = item.node.days;
    let arr = daysArr.filter((i) => recurring_days.includes(i.key));
    // setArrayDays(arr)
    if (arr.length > 0) {
      arr = arr.map((item) => item.value);
    }
    return arr;
  };

  const DELETE_RECURRING_EVENT = gql`
    mutation deleteRecurringEvent($id: ID!) {
      deleteRecurringEvent(id: $id) {
        deleted
      }
    }
  `;

  const [deleteRecurringEvent] = useMutation(DELETE_RECURRING_EVENT, {
    onCompleted: ({ deleteRecurringEvent }) => {
      if (
        deleteRecurringEvent?.errors &&
        deleteRecurringEvent?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deleteRecurringEvent?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Event Deleted", [SNACK_DURATION]);
        setTimeout(() => {
          setStartLoading(false);
        }, 2000);
      }
    },
    refetchQueries: [{ query: REQUEST_DOCTOR, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_RECURRING_EVENT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const deleteEvent = (id) => {
    let val = {
      id: id,
    };
    if (window.confirm("Delete Event?")) {
      setStartLoading(true);
      deleteRecurringEvent({ variables: val });
    }
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    history.push("/manage/recurring/events?page=" + number);
  };

  const renderPages = () => {
    let total_pages = orderedRecurringEvents?.length
      ? Math.ceil(orderedRecurringEvents?.length / PER_PAGE_COUNT)
      : 1;
    // let items = [];
    // for (let number = 1; number <= total_pages; number++) {
    //   items.push(
    //     <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
    //       {number}
    //     </Pagination.Item>,
    //   );
    // }
    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
      // }
      // return items;
    }
  };

  const editRecurringEvent = (item) => {
    setRecurringEventItem(item);
    setShowEventModal(true);
  };

  let recurringEventsOrdered = [];

  useEffect(() => {
    if (doctorObj) {
      let temp = [];

      doctorObj.map((item, index) => {
        let recurr_array = item?.node?.recurringEvents?.edges;
        recurr_array = recurr_array?.map((j, index) => {
          const tempNode = { ...j.node };

          tempNode.startDate = getDateKuwait(tempNode.startDate);
          tempNode.dateUntil = getDateKuwait(tempNode.dateUntil);

          let new_obj = JSON.parse(
            JSON.stringify({
              ...j,
              node: tempNode,
            })
          );
          new_obj["doctor"] = item;
          return new_obj;
        });
        temp.push(...recurr_array);
      });
      temp = _.orderBy(temp, ["node.startDate"], ["desc"]);
      recurringEventsOrdered = temp;

      setOrderedRecurringEvents(recurringEventsOrdered);
    }
  }, [doctorObjFilter]);

  useEffect(() => {
    getDoctorsFilter();
    if (canViewAppointments?.length > 1) {
      setCanViewMultiplePractitioners(true);
    }
  }, []);

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
    }
    getDoctorsFilter();
  };

  const initialValues = {
    doctor: "",
  };

  const onSubmit = (values) => {
    let variables = {};
    if (values.doctor) {
      variables["doctor"] = values.doctor;
    }
    getDoctorsFilter({
      variables: variables,
    });
  };

  return (
    <Base title={"BLOCK CALENDAR"} showHeader={true}>
      <Row>
        <Col md={8} lg={8} className="mr-auto">
          {canViewMultiplePractitioners ? (
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              innerRef={formikRef}
            >
              {({ handleSubmit, handleChange, handleBlur, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="">
                    <Col md={12} lg={8} xl={8} sm={12}>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={3}
                          md={3}
                          lg={3}
                          className="text-right pr-0"
                        >
                          Practitioner
                        </Form.Label>
                        <Col sm={12} md={9} lg={9}>
                          <Form.Control
                            autoComplete="off"
                            onBlur={handleBlur}
                            as="select"
                            name="doctor"
                            value={values.doctor}
                            onChange={handleChange}
                          >
                            <option value="">Select Practitioner</option>
                            {all_doctors_obj?.map((doctor) => {
                              return (
                                <option
                                  value={doctor?.node?.identifier}
                                  key={doctor?.node?.id}
                                >
                                  {doctor?.node?.firstName}{" "}
                                  {doctor?.node?.lastName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={12} lg={4} xl={4} sm={12}>
                      <div className={`d-flex submitPayment`}>
                        <Button
                          type="button"
                          variant="primary"
                          className="mx-3"
                          onClick={resetFilter}
                        >
                          {" "}
                          Reset{" "}
                        </Button>
                        <Button type="submit" variant="primary">
                          {" "}
                          Submit{" "}
                        </Button>
                        {/* <Button className='ml-3' variant="primary" onClick={printPayment}> Print </Button> */}
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : null}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="d-flex justify-content-end mb-4"
        >
          <Button
            onClick={() => setShowEventModal(true)}
            size="md"
            variant="primary"
            className="mr-2"
          >
            Add Event
          </Button>
        </Col>
      </Row>
      {loading || startLoading ? (
        <Preloader />
      ) : (
        <>
          <Row>
            <Table responsive className=" table-borderless">
              <thead>
                <tr className="patient_table_row tableHeader">
                  <th scope="col " className="text-uppercase">
                    Practitioner
                  </th>
                  <th scope="col " className="text-uppercase">
                    Title
                  </th>
                  <th scope="col " className="text-uppercase">
                    Start Date
                  </th>
                  <th scope="col " className="text-uppercase">
                    Start Time
                  </th>
                  <th scope="col " className="text-uppercase">
                    End Time
                  </th>
                  <th scope="col " className="text-uppercase">
                    End Date
                  </th>
                  <th scope="col " className="text-uppercase">
                    Days
                  </th>
                  <th scope="col " className="text-uppercase">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderedRecurringEvents?.length > 0 ? (
                  orderedRecurringEvents.map((item, index) => {
                    return (
                      <tr key={index} className="patient_table_row">
                        <td>
                          {item.doctor.node.titlePrefix}{" "}
                          {item.doctor.node.firstName}{" "}
                          {item.doctor.node.lastName}
                        </td>
                        <td>{item.node.title}</td>
                        <td>{getDate(new Date(item.node.startDate))}</td>
                        <td>
                          {item.node.startTime !== "00:00:00"
                            ? item.node.startTime
                            : "All Day"}
                        </td>
                        <td>
                          {item.node.endTime !== "23:59:00"
                            ? item.node.endTime
                            : "All Day"}
                        </td>
                        <td>
                          {item.node.dateUntil === null
                            ? "-"
                            : getDate(new Date(item.node.dateUntil))}
                        </td>
                        <td>
                          {!item.node.everyday
                            ? item?.node?.days?.length < 7
                              ? eventDays(item).join(", ")
                              : "Everyday"
                            : "Everyday"}
                        </td>
                        <td>
                          <div className="d-flex flex-row">
                            <Button
                              onClick={() => editRecurringEvent(item)}
                              size="sm"
                              variant="primary"
                              className="mr-2"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => deleteEvent(item.node.id)}
                              size="sm"
                              variant="danger"
                              className=""
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>No Recurring Events yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>

          <CustomModals
            dialog
            contentClassName="modal60h recurring-modal"
            showModal={showEventModal}
            modalHeader="Create Event"
            setShowModal={setShowEventModal}
          >
            <RecurringEventModal
              doctorObj={all_doctors_obj}
              doctor={doctorObjFilter}
              recurringEventItem={recurringEventItem}
              setShowEventModal={setShowEventModal}
              showEventModal={showEventModal}
              REQUEST_DOCTOR={REQUEST_DOCTOR}
              setStartLoading={setStartLoading}
            />
          </CustomModals>
        </>
      )}
    </Base>
  );
}
export default ManageLeaveRequests;
