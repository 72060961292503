import { Row, Col, Card, Button } from "react-bootstrap";
import Base from "./base.js";
import { withRouter } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  success_options,
  capitalizeFirstLetter,
  // getPermissionForAction,
  getDate,
  concatAllErrors,
} from "../Common/helpers";
import Preloader from "../Common/Preloder/Preloader";
import { getPermissionForAction, getErrorMessage } from "../Common/helpers";
import * as Sentry from "@sentry/browser";

function RefundRequestDetail(props) {
  const refund_id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const refundRequest_permission = getPermissionForAction("user", "", true);
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const { data } = useQuery(USER_DETAILS);

  const userDetails =
    data && data.userDetails ? JSON.parse(data.userDetails) : null;
  const REFUND_REQUEST_DETAIL = gql`
    query{
      refundRequestDetail(id:"${refund_id}") {
        id
        amount
        status
        displayStatus
        notes
        dateRefunded
        refundId
        requiresApproval
        approvalStatus
        created
        staffNotes
        paymentModel {
          displayReason
          patient {
            firstName
            lastName
            email
            phone
          }
        }
        appointment {
          start
        }
      }
    }
  `;

  // GET refund request detail
  const { data: refund_request_detail_obj = null, loading } = useQuery(
    REFUND_REQUEST_DETAIL,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ refundRequestDetail }) => {
        if (!refundRequestDetail) {
          props.history.push("/404-not-found");
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REFUND_REQUEST_DETAIL error " + e);
        }
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const REQUEST_SETTINGS = gql`
    query {
      settings
    }
  `;

  const { data: settings_obj = null } = useQuery(REQUEST_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_SETTINGS error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const REFUND_REQUEST_APPROVAL = gql`
    mutation refundRequestApproval($refundRequest: ID!, $status: String!) {
      refundRequestApproval(
        input: { refundRequest: $refundRequest, status: $status }
      ) {
        obj {
          created
          id
          status
          displayStatus
          approvalStatus
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const [refundRequestApproval] = useMutation(REFUND_REQUEST_APPROVAL, {
    onCompleted: ({ refundRequestApproval }) => {
      if (
        refundRequestApproval?.errors &&
        refundRequestApproval?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          refundRequestApproval?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = refundRequestApproval.obj.approvalStatus;
        setTimeout(() => {
          openSnackbarSuccess("Refund Request " + status, [SNACK_DURATION]);
        }, 1000);
      }
    },
    refetchQueries: [
      { query: REFUND_REQUEST_DETAIL, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REFUND_REQUEST_APPROVAL error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });
  const submitRequest = (refund_detail_obj, approve) => {
    if (approve === true) {
      let val = {
        refundRequest: refund_detail_obj?.refundRequestDetail?.id,
        status: "accepted",
      };
      refundRequestApproval({ variables: val });
    } else {
      if (window.confirm("Cancel Refund Request?")) {
        let val = {
          refundRequest: refund_detail_obj?.refundRequestDetail?.id,
          status: "declined",
        };
        refundRequestApproval({ variables: val });
      }
    }
  };

  const getStatus = (status) => {
    if (status === "DECLINED") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Base title={"Refund Request"} showHeader={true}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Row>
            <Col xs={12} md={6} className="d-flex-column-str mb-4">
              <span className="textPrimaryBold">
                {
                  refund_request_detail_obj?.refundRequestDetail?.paymentModel
                    ?.patient?.firstName
                }{" "}
                {
                  refund_request_detail_obj?.refundRequestDetail?.paymentModel
                    ?.patient?.lastName
                }
              </span>
              <span>
                Email :{" "}
                {
                  refund_request_detail_obj?.refundRequestDetail?.paymentModel
                    ?.patient?.email
                }
              </span>
              <span>
                Phone :{" "}
                {
                  refund_request_detail_obj?.refundRequestDetail?.paymentModel
                    ?.patient?.phone
                }
              </span>
            </Col>
            <Col className="py-3" md={12}>
              <h6 className="textPrimaryBold">
                {" "}
                <b> Refund Request Details </b>
              </h6>
              <div className="mb-2">
                {refund_request_detail_obj?.refundRequestDetail?.appointment !=
                null ? (
                  <h6>
                    {" "}
                    Appointment Date:{" "}
                    {getDate(
                      refund_request_detail_obj?.refundRequestDetail
                        ?.appointment?.start
                    )}
                  </h6>
                ) : (
                  refund_request_detail_obj?.refundRequestDetail?.paymentModel
                    ?.displayReason
                )}
              </div>

              <h6>
                {" "}
                Refund Request Created:
                {getDate(
                  refund_request_detail_obj?.refundRequestDetail?.created
                )}
              </h6>
              <h6>
                {" "}
                Status:{" "}
                <b>
                  {" "}
                  {
                    refund_request_detail_obj?.refundRequestDetail
                      ?.displayStatus
                  }{" "}
                </b>{" "}
              </h6>
              <h6>
                {" "}
                Amount:{" "}
                <b>
                  {" "}
                  {
                    refund_request_detail_obj?.refundRequestDetail?.amount
                  } KWD{" "}
                </b>{" "}
              </h6>
              {refund_request_detail_obj?.refundRequestDetail?.staffNotes ? (
                <h6>
                  {" "}
                  Staff Notes:{" "}
                  {
                    refund_request_detail_obj?.refundRequestDetail?.staffNotes
                  }{" "}
                </h6>
              ) : null}
            </Col>
          </Row>

          {refund_request_detail_obj?.refundRequestDetail?.approvalStatus !=
            "ACCEPTED" &&
          refund_request_detail_obj?.refundRequestDetail?.approvalStatus !=
            "DECLINED" ? (
            <Row className={"button_block mb-2"}>
              <Col
                className="justify-content-center d-flex mt-2"
                xs={{ order: "last" }}
              >
                <Button
                  variant="primary"
                  size="md"
                  active
                  onClick={() =>
                    submitRequest(refund_request_detail_obj, false)
                  }
                >
                  Deny
                </Button>
              </Col>
              <Col
                className="justify-content-center d-flex mt-2"
                xs={{ order: "first" }}
              >
                <Button
                  variant="primary"
                  size="md"
                  active
                  onClick={() => submitRequest(refund_request_detail_obj, true)}
                >
                  Approve
                </Button>
              </Col>
            </Row>
          ) : (
            <td className="text-center py-4">
              {" "}
              <span>
                {" "}
                {getStatus(
                  refund_request_detail_obj.refundRequestDetail?.status
                )}{" "}
              </span>
            </td>
          )}
        </>
      )}
    </Base>
  );
}

export default withRouter(RefundRequestDetail);
