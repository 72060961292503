import { Row, Col, Figure } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Base from './base.js';
import Base from 'Views/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faSmile,
  faCalendar,
  faCopy,
  faHourglassHalf,
  faUser,
  faLock,
  faMicroscope,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons';

export default function Settings() {
  const renderGridContent = () => {
    let data = [
      {
        icon: <FontAwesomeIcon icon={faCopy} />,
        title: 'Manage Encounter',
        subTitle: 'Section types & fields',
        slug: '/manage/encounter',
      },
      {
        icon: <FontAwesomeIcon icon={faClipboard} />,
        title: 'Manage Clinical Forms',
        subTitle: 'Section types & fields',
        slug: '/manage/intake/forms',
      },
      {
        icon: <FontAwesomeIcon icon={faSmile} />,
        title: 'Manage Customer Surveys',
        subTitle: 'Section types & fields',
        slug: '/manage/customer/surveys',
      },
      {
        icon: <FontAwesomeIcon icon={faHourglassHalf} />,
        title: 'Manage Waiting List',
        subTitle: 'Section types & fields',
        slug: '/manage/waiting/list',
      },
      {
        icon: <FontAwesomeIcon icon={faCalendar} />,
        title: 'Appointment Settings',
        subTitle: 'Section types & fields',
        slug: '/appointment/settings',
      },
      {
        icon: <FontAwesomeIcon icon={faGoogle} />,
        title: 'Link Google account',
        subTitle: 'Section types & fields',
        slug: '/link/google/account',
      },
      {
        icon: <FontAwesomeIcon icon={faUser} />,
        title: 'User Management',
        subTitle: 'Section types & fields',
        slug: '/user/management',
      },
      {
        icon: <FontAwesomeIcon icon={faLock} />,
        title: 'Password Settings',
        subTitle: 'Section types & fields',
        slug: '/password/settings',
      },
      {
        icon: <FontAwesomeIcon icon={faMicroscope} />,
        title: 'Lab and request',
        subTitle: 'Section types & fields',
        slug: '/labs/requests',
      },
    ];

    return data.map((item, index) => (
      <Col xs={12} md={6} lg={4} key={index}>
        <Link to={item.slug}>
          <Figure className="centered-figure">
            <div className="icon-image-container mb-4">{item.icon}</div>
            <Figure.Caption>
              <h4 className="grid-title">{item.title}</h4>
              <p className="grid-sub-title">{item.subTitle}</p>
            </Figure.Caption>
          </Figure>
        </Link>
      </Col>
    ));
  };

  return (
    <Base title={'Settings'} showHeader={true}>
      {/* Header */}
      {/* <Row className="mb-5">
        <Col className="pt-3 p-lg-10">
          <h4 className="section-header">Settings</h4>
        </Col>
      </Row> */}

      {/* Content */}
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={10}>
          <Row>{renderGridContent()}</Row>
        </Col>
      </Row>
    </Base>
  );
}
