import React, { useRef } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import {
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";

function RefundRequestModal(props) {
  const {
    setShowRefundModal,
    appointment_detail_id,
    paymentDetailData_id,
    REQUEST_PAYMENT_DETAIL,
    REQUEST_EVENT,
    amountAvailableRefund,
  } = props;
  const formikRef = useRef();
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const { t } = useTranslation();
  const [openSnackbarError] = useSnackbar(error_options);

  const APPOINTMENT_PARTIAL_REFUND_REQUEST = gql`
    mutation appointmentPartialRefundRequest(
      $id: ID!
      $amount: Decimal!
      $staff_notes: String
    ) {
      appointmentPartialRefundRequest(
        id: $id
        amount: $amount
        staffNotes: $staff_notes
      ) {
        error
        obj {
          id
          start
        }
      }
    }
  `;

  const PARTIAL_MANUAL_PAYMENT_REQUEST_REFUND = gql`
    mutation partialManualPaymentRequestRefund(
      $id: ID!
      $amount: Decimal!
      $staff_notes: String
    ) {
      partialManualPaymentRequestRefund(
        id: $id
        amount: $amount
        staffNotes: $staff_notes
      ) {
        error
        obj {
          id
        }
      }
    }
  `;

  const [
    appointmentPartialRefundRequest,
    { loading: appPartialRefundRequestLoading },
  ] = useMutation(APPOINTMENT_PARTIAL_REFUND_REQUEST, {
    onCompleted({ appointmentPartialRefundRequest }) {
      if (
        appointmentPartialRefundRequest?.errors &&
        appointmentPartialRefundRequest?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          appointmentPartialRefundRequest?.errors
        );
        openSnackbarError(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Refund Request Created");
        setShowRefundModal(false);
      }
    },
    refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException(
          "APPOINTMENT_PARTIAL_REFUND_REQUEST error " + e
        );
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [
    partialManualPaymentRequestRefund,
    { loading: partialRefundRequestLoading },
  ] = useMutation(PARTIAL_MANUAL_PAYMENT_REQUEST_REFUND, {
    onCompleted({ partialManualPaymentRequestRefund }) {
      if (
        partialManualPaymentRequestRefund?.errors &&
        partialManualPaymentRequestRefund?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          partialManualPaymentRequestRefund?.errors
        );
        openSnackbarError(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Refund Request Created");
        setShowRefundModal(false);
      }
    },
    refetchQueries: [
      { query: REQUEST_PAYMENT_DETAIL, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException(
          "PARTIAL_MANUAL_PAYMENT_REQUEST_REFUND error " + e
        );
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const onSubmit = (values, { resetForm }) => {
    if (values.amount > amountAvailableRefund) {
      openSnackbarError("Refund Amount should not exceed available amount");
    } else {
      let val = {
        amount: values.amount,
        staff_notes: values.staff_notes,
      };
      if (appointment_detail_id) {
        val["id"] = appointment_detail_id;
        appointmentPartialRefundRequest({
          // use the variables option so that you can pass in the file we got above
          variables: val,
          // onCompleted: () => {},
        });
      } else {
        val["id"] = paymentDetailData_id;
        partialManualPaymentRequestRefund({
          // use the variables option so that you can pass in the file we got above
          variables: val,
          // onCompleted: () => {},
        });
      }
    }
  };

  const initialValues = {
    amount: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required"),
    staff_notes: Yup.string().required("Notes is required"),
  });

  return (
    <>
      <Row className="">
        <Col md={12} lg={12} className="">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
            validationSchema={validationSchema} // Add this line
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              values,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} className="mb-2">
                    <Form.Group as={Row}>
                      <Form.Label column sm={5} md={5} className=" pr-0">
                        <span className="">Enter Refund Amount </span>
                      </Form.Label>
                      <Col sm={7} md={7}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="number"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                        />
                        {errors.amount && touched.amount && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.amount}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={2} md={2} className=" pr-0">
                        <span className="">Notes </span>
                      </Form.Label>
                      <Col sm={10} md={10}>
                        <Form.Control
                          autoComplete="off"
                          as="textarea"
                          type="textarea"
                          name="staff_notes"
                          value={values.staff_notes}
                          onChange={handleChange}
                        />
                        {errors.staff_notes && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.staff_notes}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={12}>
                    <div className="d-flex justify-content-end submitPayment">
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={() => setShowRefundModal(false)}
                      >
                        {t("patientFileUploadModal.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        disabled={
                          appPartialRefundRequestLoading ||
                          partialRefundRequestLoading
                        }
                      >
                        {appPartialRefundRequestLoading ||
                        partialRefundRequestLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          t("patientFileUploadModal.submit")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
export default RefundRequestModal;
