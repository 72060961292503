import { gql } from "@apollo/client";

export const GET_PATIENT = gql`
  query GetPatient {
    getPatient {
      patientId
      firstName
      middleName
      lastName
      gender
      id
      completedOnboarding
      onboardingData
      completedTherapyPreference
      therapyPreference
      profileComplete
      identifier
    }
  }
`;

export const CREATE_UPDATE_CONSULATION_FORM = gql`
  mutation createUpdatePatientConsultation($id: ID!, $data: String!) {
    createUpdatePatientConsultation(input: { id: $id, onboardingData: $data }) {
      errors {
        field
        messages
      }
      obj {
        patientId
        firstName
        middleName
        lastName
        gender
        id
        completedOnboarding
        onboardingData
        completedTherapyPreference
        therapyPreference
        profileComplete
        identifier
      }
    }
  }
`;

export const CREATE_UPDATE_THERAPY_FORM = gql`
  mutation createUpdatePatientTherapy($id: ID!, $data: String!) {
    createUpdatePatientTherapy(input: { id: $id, therapyPreference: $data }) {
      errors {
        field
        messages
      }
      obj {
        patientId
        firstName
        middleName
        lastName
        gender
        id
        completedOnboarding
        onboardingData
        completedTherapyPreference
        therapyPreference
        profileComplete
        identifier
      }
    }
  }
`;

export const GET_PATIENT_PROFILE = gql`
  query GetPatientProfile {
    getPatient {
      firstName
      middleName
      lastName
      gender
      id
      identifier
      idFile
      civilId
      dob
      country {
        name
        code
      }
      address
      governorate {
        name
        id
      }
      area {
        name
        id
      }
      phone
      email
    }
  }
`;

export const UPDATE_PATIENT_PROFILE = gql`
  mutation patientProfileUpdate(
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $dob: Date!
    $gender: String!
    $civilId: String
    $address: String
    $governorate: ID
    $country: String!
    $area: ID
  ) {
    patientProfileUpdate(
      input: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        dob: $dob
        gender: $gender
        civilId: $civilId
        address: $address
        governorate: $governorate
        country: $country
        area: $area
      }
    ) {
      obj {
        identifier
        firstName
        middleName
        lastName
        gender
        id
        idFile
        civilId
        dob
        country {
          name
          code
        }
        address
        governorate {
          name
          id
        }
        area {
          name
          id
        }
        phone
        email
      }
    }
  }
`;
