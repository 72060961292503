//@ts-nocheck
import React, { useState } from "react";
import { TextareaAutosize, Container } from "@mui/material";

import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import { createPatientEvent, resetState } from "redux/slices/appointmentSlice";
import LoadingButton from "@mui/lab/LoadingButton";
//@ts-ignore
import { useSnackbar } from "react-simple-snackbar";
import { error_options, SNACK_DURATION } from "Common/helpers";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateUrlLink } from "Common/helpers";

const getDirection = (lang: string) => {
  return lang === "ar" ? "rtl" : "ltr";
};

const RequestReason = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const direction = getDirection(i18n.language);
  const [text, setText] = useState("");
  const history = useHistory();

  const [openSnackbar] = useSnackbar(error_options);

  const { stepsData, buttonLoading, patientReferral } = useAppSelector(
    ({ rootAppointmentSlice }) => rootAppointmentSlice
  );

  const handleSubmission = async () => {
    // process the data here
    const practitionerData = stepsData.find(
      (item) => item.title === "practitioner"
    );

    const dateData = stepsData.find((item) => item.title === "date");

    const waitingData = stepsData.find((item) => item.title === "waiting");

    //@ts-ignore
    const { utcDateTime } = dateData.data || {};

    //@ts-ignore
    const { doctor, service } = practitionerData.data || {};

    if (doctor && service && utcDateTime) {
      const payload = {
        doctor: doctor?.id,
        doctorEventType: service?.id,
        start: utcDateTime[0],
        require_payment: true,
        mainSlot: false,
        overrideValidation: false,
        //@ts-ignore
        waitinglist: waitingData?.data?.value,
        description: text,
      };

      if (patientReferral?.id) {
        payload.referral = patientReferral.id;
      }

      const res = await dispatch(createPatientEvent(payload));

      //@ts-ignore
      if (res?.error) {
        openSnackbar(res?.payload, [SNACK_DURATION]);
        return;
      } else if (res?.payload?.errors?.length > 0) {
        openSnackbar(res?.payload?.errors?.[0]?.messages, [SNACK_DURATION]);
        return;
      } else if (res) {
        //@ts-ignore
        const paymentLink =
          res?.payload?.obj?.paymentSources?.edges?.[0]?.node?.paymentLink;
        const price = res?.payload?.obj?.price;

        const requirePayment = res?.payload?.obj?.patientRequirePayment;
        const eventId = res?.payload?.obj?.id;

        if (requirePayment && price > 0) {
          window.location.href = generateUrlLink(paymentLink, i18n);
        } else {
          // navigate to detail page
          dispatch(resetState());
          history.push(`/patientPortal/appointment/detail/${eventId}`);
        }
      }
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <h3 className="slide-header mb-4 ">{t("createEventForm.reason")}</h3>
        <div className="textarea-container">
          <LoadingButton
            variant="contained"
            className={`app-btn sub-btn-lg bg-blue-light ${
              direction === "rtl" ? "rtl" : "ltr"
            } `}
            onClick={handleSubmission}
            loading={buttonLoading}
            disabled={buttonLoading}
          >
            {t("createEventForm.submit")}
          </LoadingButton>
          <TextareaAutosize
            placeholder={t("createEventForm.answerReason")}
            minRows={10}
            className="w-100 custom-textarea"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <LoadingButton
          variant="contained"
          className="app-btn sub-btn-sm bg-blue-light"
          onClick={handleSubmission}
          loading={buttonLoading}
          disabled={buttonLoading}
        >
          {t("createEventForm.submit")}
        </LoadingButton>
      </Container>
    </>
  );
};

export default RequestReason;
