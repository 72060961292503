import Base from './base.js';
import React, { useState, useEffect, useRef } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';
import { Pagination, Row, Col, Container, Button, Form, DropdownButton, Dropdown, Table, Badge } from 'react-bootstrap';
import Preloader from '../Common/Preloder/Preloader';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, PER_PAGE_COUNT, urlActions, getPermissionForAction, getValidDateStringForGraphQL, removeEmpty,displayMiddleName,isUserStaff } from '../Common/helpers';
import { dataPatientInVar } from '../cache/cache.js';
import { Formik } from 'formik';
import CustomModals from '../component/Modal/modal';
import * as Sentry from "@sentry/browser";
 


function NotFoundError(props) {
  const isStaff = isUserStaff();

  return (
    // <Base title={'Error - 404'} showHeader={false} isPatientPortal={!isStaff}>
      <Container fluid className="mb-4 error-container">
        <Row className='d-flex justify-content-center align-items-center'>
        <Col className='col-lg-12 error-title'>

          404 Page Not Found
        </Col>
        <Col className='col-lg-12 error-text'>
          We cannot find the page you are looking for.
        </Col>
        <Button className='mt-5'>
          <Link to="/">
            Back to Home
          </Link>
        </Button>
        </Row>
        
      </Container>
    // </Base>
  );
}
export default withRouter(NotFoundError);
