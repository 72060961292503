import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  getPatientsAge,
  getForamttedTime,
  getDay,
  getDate,
  replaceUnderscoreAndDashBySpace,
  getTotalRemaining,
  displayMiddleName,
  isSuperUserFunc,
  getErrorMessage,
  urlActions,
  getValidDateStringForGraphQL,
  timeToDate,
  concatAllErrors,
} from "../Common/helpers";
import { useTranslation } from "react-i18next";
import PaymentDetail from "component/AppointmentDetails/paymentDetail";
import RefundRequests from "component/AppointmentDetails/refundRequests";
import Preloader from "../Common/Preloder/Preloader";
import { Formik } from "formik";
import CustomModals from "../component/Modal/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faFile } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/images/logo.png";
import { editableAppointmentVar } from "../cache/cache.js";
import RefundRequestModal from "./refundRequestModal.js";
import _ from "lodash";
import { useInterval } from "../component/UseInterval";
import * as Sentry from "@sentry/browser";

function AppointmentDetails(props) {
  var today = new Date();
  today = getDate(today) + " " + getForamttedTime(today);

  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const identifier = props.match.params.appointmentId;
  const [shouldBeSeen, setShouldBeSeen] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enablePatientEdit, setEnablePatientEdit] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isOverlapAppointment, setIsOverlapAppointment] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [noRefund, setNoRefund] = useState(false);
  const [hold, setHold] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const formikRef = useRef();
  const isStaff = isUserStaff();
  const isSuperUser = isSuperUserFunc();
  // eslint-disable-next-line no-unused-vars
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [uniqueChildrenAppointments, setUniqueChildrenAppointments] = useState(
    []
  );
  const [amountAvailableRefund, setAmountAvailableRefund] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedSession, setSelectedSession] = useState(null);
  const { t, i18n } = useTranslation();
  const original_permissions = localStorage.getItem("original_permissions");

  const [expiryHour, setExpiryHour] = useState(0);

  let passDateInURL = urlActions(window.location.href, "get", "passDateInURL");
  if (passDateInURL) {
    passDateInURL = passDateInURL.replace(" ", "+");
  }

  const REQUEST_SETTINGS = gql`
    query {
      settings
    }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(REQUEST_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_SETTINGS error " + e);
      }
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const REQUEST_EVENT = gql`
  query {
    event(id:"${identifier}"){
      created
      eventId
      title
      description
      patientNotes
      start
      end
      id
      identifier
      duration
      discount
      status
      displayCancelStatus
      cancelStatus
      price
      displayStatus
      shouldBeSeen
      requirePayment
      cancelledReason
      onlineLink
      hasRefund
      groupStartDate
      groupEndDate
      blockedDates
      groupDays
      waitinglist {
        waitingList {
          id
        }
      }
      payments {
        edges {
          node {
            paid
            amount
          }
        }
      }
      parent{
        id
      }
      multipleScheduleSlots{
        edges{
          node{
            slotsAvailable
            start
            id
          }
        }
      }
      children{
        edges{
          node{
            id
            patient{
              firstName
              lastName
            }
            start
            status
          }
        }
      }
      refundRequests {
        edges{
          node {
            id
            amount
            status
            displayStatus
            notes
            dateRefunded
            staffNotes
            refundId
            created
            requiresApproval
            approvalStatus
            displayApprovalStatus
          }
        }
      } 
      creator{
        firstName
        lastName
      }
      created
      intakeForms{
        edges{
          node{
            id
            dynamicForm{
              name
            }
          }
        }
      }
      encounters{
        edges{
          node{
            id
          }
        }
      }
      mainSlot
      slotsAvailable
      doctorEventType{
        id
        title{
          name
        }
        buffer
        duration
        price
        groupSession
        eventLocation {
          id
          title
        }
        eventType {
          id
        }
        color{
          hexCode
          name
          colorId
        }
      }
      eventType{
        id
        name
      }
      location{
        id
        title
      }
      patient{
        firstName
        middleName
        lastName
        phone
        dob
        id
        gender
        identifier
        civilId
        idFile
      }
      paymentSources{
        edges{
          node{
            amountDebited
            status
            reference
            id
            currency
            paymentType
            paymentObject{
              identifier
            }
            amountAllocated
            amountAvailableForRefund
            datePaid
            linkMessage
            paymentLink
            smsSent
            sourceType{
              name
              code
            }
          }
        }
      }
      doctor{
        id
        doctorId
        titlePrefix
        arabicName
        description
        firstName
        lastName  
        email
        phone   
        identifier 
        identifier
        availability{
          edges{
            node{
              day
              id
              shifts{
                edges{
                  node{
                    endTime
                    id
                    startTime
                  }
                }
              }
            }
          }
        }
        recurringEvents{
          edges {
            node {
              startDate
              startTime
              endTime
              title
              description
              everyday
              days
              dateUntil
            }
          }
        }
        eventType {
          edges {
            node {
              id
              title{
                name
              }
              buffer
              duration
              price
              groupSession
              eventLocation {
                id
                title
              }
              eventType {
                id
              }
            }
          }
        }    
      }
    }
  }
`;

  const CANCEL_APPOINTMENT = gql`
    mutation cancelEvent($id: ID!, $hold: Boolean!, $reason: String!) {
      cancelEvent(id: $id, hold: $hold, reason: $reason) {
        obj {
          status
          id
          holdWaitingReplacement
          cancelledReason
        }
      }
    }
  `;

  const CHANGE_APPOINTMENT_STATUS = gql`
    mutation changeEventStatus($id: ID!, $status: String!) {
      changeEventStatus(input: { id: $id, status: $status }) {
        errors {
          field
          messages
        }
        obj {
          status
        }
      }
    }
  `;

  const UPDATE_APPOINTMENT = gql`
    mutation updatePartialEvent(
      $id: ID!
      $requirePayment: Boolean!
      $shouldBeSeen: DateTime
      $description: String!
      $title: String!
      # $onlineLink: String
      $doctor: ID!
    ) {
      updatePartialEvent(
        input: {
          id: $id
          requirePayment: $requirePayment
          shouldBeSeen: $shouldBeSeen
          description: $description
          title: $title
          # onlineLink: $onlineLink
          doctor: $doctor
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          patientNotes
          id
          eventType {
            name
            id
          }
          location {
            title
            id
          }
          doctor {
            id
            titlePrefix
            description
            firstName
            lastName
            arabicName
          }
          duration
          price
        }
      }
    }
  `;

  const UPDATE_PATIENT_NOTES_APPOINTMENT = gql`
    mutation updatePatientNotesEvent(
      $id: ID!
      $patientNotes: String!
      $doctor: ID!
    ) {
      updatePatientNotesEvent(
        input: { id: $id, patientNotes: $patientNotes, doctor: $doctor }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          patientNotes
          id
          eventType {
            name
            id
          }
          location {
            title
            id
          }
          doctor {
            id
            titlePrefix
            description
            firstName
            lastName
            arabicName
          }
          duration
          price
        }
      }
    }
  `;

  const DELETE_APPOINTMENT = gql`
    mutation deleteEvent($id: ID!) {
      deleteEvent(id: $id) {
        deleted
        error
      }
    }
  `;

  const REQUEST_DOCTOR_LEAVES = gql`
    query leaves($doctor_identifier: String!) {
      leaves(doctor_Identifier_Icontains: $doctor_identifier) {
        edges {
          node {
            id
            date
          }
        }
      }
    }
  `;

  const REFUND_APPOINTMENT = gql`
    mutation refundRequest($id: ID!) {
      refundRequest(id: $id) {
        error
        obj {
          status
          id
          hasRefund
          paymentSources {
            edges {
              node {
                status
                amountDebited
                amountRefunded
                sourceType {
                  name
                }
              }
            }
          }
        }
      }
    }
  `;

  // DELETE_APPOINTMENT
  const [deleteAppointment] = useMutation(DELETE_APPOINTMENT, {
    onCompleted: ({ deleteEvent }) => {
      if (deleteEvent?.errors && deleteEvent?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (deleteEvent.deleted) {
          openSnackbarSuccess("Appointment Deleted", [SNACK_DURATION]);
          props.history.push(
            "/appointments/?doctor=" + appointment_detail.doctor.doctorId
          );
        } else if (deleteEvent.error) {
          openSnackbar(deleteEvent.error, [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("deleteAppointment error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const { data, loading } = useQuery(REQUEST_EVENT, {
    fetchPolicy: "network-only",

    onCompleted: ({ event }) => {
      if (event === null) {
        props.history.push("/404-not-found");
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENT error " + e);
      }
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const CREATE_ENCOUNTER = gql`
    mutation createEncounter(
      $patient: ID!
      $doctor: ID!
      $facility: ID!
      $encounterType: ID!
      $appointment: ID
      $date: DateTime
    ) {
      createEncounter(
        input: {
          patient: $patient
          doctor: $doctor
          facility: $facility
          encounterType: $encounterType
          date: $date
          appointment: $appointment
        }
      ) {
        obj {
          id
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const REQUEST_EVENTS = gql`
    query ($doctor_identifier: String!, $start: Date!, $end: Date!) {
      events(
        doctor_Identifier: $doctor_identifier
        start: $start
        end: $end
        active: true
      ) {
        edges {
          node {
            title
            id
            eventId
            status
            start
            end
            holdWaitingReplacement
            onlineLink
            slotsAvailable
            groupStartDate
            groupEndDate
            groupDays

            multipleScheduleSlots {
              edges {
                node {
                  slotsAvailable
                  start
                }
              }
            }
            parent {
              id
            }
            doctor {
              id
              firstName
              identifier
              lastName
              eventType {
                edges {
                  node {
                    id
                    duration
                    buffer
                    groupSession
                  }
                }
              }
              availability {
                edges {
                  node {
                    id
                    day
                    shifts {
                      edges {
                        node {
                          startTime
                          endTime
                          id
                        }
                      }
                    }
                  }
                }
              }
              recurringEvents {
                edges {
                  node {
                    startDate
                    startTime
                    endTime
                    title
                    description
                    everyday
                    days
                    dateUntil
                  }
                }
              }
            }
            doctorEventType {
              duration
              buffer
              groupSession
              id
            }
            patient {
              id
              identifier
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const { data: userDetialsData } = useQuery(USER_DETAILS);
  let canEditAppointments = [];
  var userDetails = userDetialsData ? userDetialsData.userDetails : null;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
    canEditAppointments = userDetails.canEditAppointments.edges.map((doc) => {
      return doc.node.identifier;
    });
  }

  const [doctorLeaves, { data: doctorLeaveList = null }] = useLazyQuery(
    REQUEST_DOCTOR_LEAVES,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_DOCTOR_LEAVES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [GetEvents, { data: eventsList = null }] = useLazyQuery(
    REQUEST_EVENTS,
    {
      fetchPolicy: "network-only",
      onError: ({ networkError }, e) => {
        Sentry.setContext("ERROR CODE statusCode ", {
          code: networkError?.statusCode,
        });
        Sentry.setContext("error", networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_EVENTS error " + networkError);
        }
      },
    }
  );
  // const { history } = props;
  const appointment_detail = data ? data.event : null;

  let blockedDatesStr = appointment_detail?.blockedDates;
  let validJSON = blockedDatesStr?.replace(/'/g, '"');
  let validJSONBlockedDates = validJSON ? JSON.parse(validJSON) : [];

  const getRecurGroupSlotsFunc = (groupEvent) => {
    let tempRecurrGroupSlots = [];
    var start = new Date(groupEvent.groupStartDate);
    var end = new Date(groupEvent.groupEndDate);
    for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
      let recurrSessionStart =
        getValidDateStringForGraphQL(d) + "T" + groupEvent.start.split("T")[1];
      let hasAppOnSession = groupEvent.children.edges.filter(
        (i) =>
          new Date(i.node.start).getTime() ===
            new Date(recurrSessionStart).getTime() &&
          i.node.status !== "CANCELLED"
      );
      let hasActiveApp = hasAppOnSession.find(
        (i) => i.node.patient && i.node.status !== "CANCELLED"
      );
      let patientsOnAppSession = hasAppOnSession ? hasAppOnSession.length : 0;
      let currentDay = d.getDay(); // javascript returns sunday as 0 but backend considers monday as 0 and sunday as 6 so this calculation is to match the currect day
      if (groupEvent.groupDays.includes(currentDay + "")) {
        let newCreatedEventRecurr = JSON.parse(
          JSON.stringify(appointment_detail)
        );
        newCreatedEventRecurr["start"] = recurrSessionStart;
        newCreatedEventRecurr["end"] =
          getValidDateStringForGraphQL(d) + "T" + groupEvent.end.split("T")[1];
        newCreatedEventRecurr["backgroundColor"] =
          selectedSession?.node.eventType.color?.hexCode;
        newCreatedEventRecurr["borderColor"] =
          selectedSession?.node.eventType.color?.hexCode;
        newCreatedEventRecurr["passDateInURL"] = recurrSessionStart;
        newCreatedEventRecurr["numOfPatients"] = patientsOnAppSession;
        newCreatedEventRecurr["hasActiveApp"] = hasActiveApp;
        tempRecurrGroupSlots.push(newCreatedEventRecurr);
      }
    }
    return tempRecurrGroupSlots;
  };

  useEffect(() => {
    if (
      appointment_detail?.doctor?.identifier &&
      appointment_detail?.patient === null
    ) {
      doctorLeaves({
        variables: {
          doctor_identifier: appointment_detail?.doctor?.identifier,
        },
      });
    }
    if (appointment_detail && appointment_detail.shouldBeSeen) {
      setShouldBeSeen(new Date(appointment_detail.shouldBeSeen));
    }
    let amountForRefund = appointment_detail?.paymentSources?.edges;
    amountForRefund = _.sumBy(amountForRefund, function (amount) {
      return parseFloat(amount.node.amountAvailableForRefund);
    });
    setAmountAvailableRefund(amountForRefund);

    if (
      appointment_detail?.children &&
      !appointment_detail?.parent &&
      appointment_detail?.doctorEventType?.groupSession
    ) {
      let recurrGroupSlots = getRecurGroupSlotsFunc(appointment_detail);
      setUniqueChildrenAppointments(recurrGroupSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_detail]);

  useEffect(() => {
    if (eventsList?.events?.edges?.length > 0) {
      let nonGroupAppointments = eventsList.events.edges.filter(
        (i) => i.node.doctorEventType?.groupSession === false
      );
      if (nonGroupAppointments.length > 0) {
        // eslint-disable-next-line array-callback-return
        let conflictApp = nonGroupAppointments.find((item, index) => {
          let newStartTime = new Date(item.node.start);

          let start = new Date(passDateInURL);

          let end = new Date(passDateInURL);
          end.setMinutes(end.getMinutes() + appointment_detail?.duration);

          if (newStartTime >= start && newStartTime < end) {
            return item;
          }
        });
        if (conflictApp) {
          setIsOverlapAppointment(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsList]);

  useEffect(() => {
    if (appointment_detail?.doctor?.identifier && passDateInURL) {
      let end = new Date(passDateInURL);
      end.setHours(23);
      end.setMinutes(59);
      let variables = {
        doctor_identifier: appointment_detail.doctor.identifier,
        start: getValidDateStringForGraphQL(new Date(passDateInURL)),
        end: getValidDateStringForGraphQL(end),
      };
      GetEvents({ variables: variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passDateInURL, appointment_detail]);

  const [cancelEvent] = useMutation(CANCEL_APPOINTMENT, {
    awaitRefetchQueries: true,
    onCompleted: ({ cancelEvent }) => {
      if (cancelEvent?.errors && cancelEvent?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(cancelEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (cancelEvent.obj && cancelEvent.obj.status === "CANCELLED") {
          openSnackbarSuccess("Appointment Cancelled", [SNACK_DURATION]);
          setShowAlert(false);
        }
      }
    },
    refetchQueries: [
      {
        query: REQUEST_EVENT,
        fetchPolicy: "network-only",
      },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("cancelEvent error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [changeEventStatus, { loading: evenStatusLoading }] = useMutation(
    CHANGE_APPOINTMENT_STATUS,
    {
      awaitRefetchQueries: true,
      onCompleted: ({ changeEventStatus }) => {
        if (
          changeEventStatus?.errors &&
          changeEventStatus?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            changeEventStatus?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          openSnackbarSuccess(
            "Appointment Status Changed to " + changeEventStatus.obj.status
          );
        }
      },
      refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("changeEventStatus error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const [updatePartialEvent] = useMutation(UPDATE_APPOINTMENT, {
    awaitRefetchQueries: true,
    onCompleted: ({ updatePartialEvent }) => {
      if (
        updatePartialEvent?.errors &&
        updatePartialEvent?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(updatePartialEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Appointment Detail Updated", [SNACK_DURATION]);
        setEnableEdit(false);
      }
    },
    refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("updatePartialEvent error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [updatePatientNotesEvent] = useMutation(
    UPDATE_PATIENT_NOTES_APPOINTMENT,
    {
      awaitRefetchQueries: true,
      onCompleted: ({ updatePatientNotesEvent }) => {
        if (
          updatePatientNotesEvent?.errors &&
          updatePatientNotesEvent?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            updatePatientNotesEvent?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          openSnackbarSuccess("Appointment Detail Updated", [SNACK_DURATION]);
          setEnablePatientEdit(false);
        }
      },
      refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("updatePartialEvent error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const [createUpdateEncounter] = useMutation(CREATE_ENCOUNTER, {
    onCompleted: ({ createEncounter }) => {
      if (createEncounter?.errors && createEncounter?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(createEncounter?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (createEncounter.obj) {
        props.history.push("/encounter/detail/" + createEncounter.obj.id);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("createUpdateEncounter error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [refundRequest] = useMutation(REFUND_APPOINTMENT, {
    awaitRefetchQueries: true,
    onCompleted: ({ refundRequest }) => {
      if (refundRequest?.errors && refundRequest?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(refundRequest?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Refund request in process", [SNACK_DURATION]);
      }
    },
    refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("refundRequest error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const cancelAppointment = () => {
    setShowAlert(true);
  };

  const onSubmit = (values, { resetForm }) => {
    let variables = values;
    variables["id"] = appointment_detail.id;
    variables["title"] = appointment_detail.title;
    if (shouldBeSeen) {
      variables["shouldBeSeen"] = shouldBeSeen.toISOString();
    }
    updatePartialEvent({ variables: variables });
  };

  const onPatientNotesSubmit = (values, { resetForm }) => {
    let variables = values;
    variables["id"] = appointment_detail.id;
    updatePatientNotesEvent({ variables: variables });
  };

  const initialValues = {
    requirePayment: appointment_detail
      ? appointment_detail.requirePayment
      : true,
    description: appointment_detail ? appointment_detail.description : "",
    doctor:
      appointment_detail &&
      appointment_detail.doctor &&
      appointment_detail.doctor.id
        ? appointment_detail.doctor.id
        : "",
  };

  const initiaPatientNoteslValues = {
    patientNotes: appointment_detail ? appointment_detail.patientNotes : "",
    doctor:
      appointment_detail &&
      appointment_detail.doctor &&
      appointment_detail.doctor.id
        ? appointment_detail.doctor.id
        : "",
  };

  const cancelAppointmentClicked = () => {
    if (isStaff && cancelReason === "") {
      alert("You must provide a reason to cancel the appointment.");
    } else {
      cancelEvent({
        variables: {
          id: appointment_detail.id,
          hold: hold,
          reason: cancelReason,
        },
      });
    }
  };

  const changeEventStatusNoShow = () => {
    if (window.confirm("Confirm No Show?")) {
      changeEventStatus({
        variables: { id: appointment_detail.id, status: "no_show" },
      });
    }
  };
  const changeEventStatusCheckedIn = () => {
    if (window.confirm("Confirm Checked In?")) {
      changeEventStatus({
        variables: { id: appointment_detail.id, status: "completed" },
      });
    }
  };

  const submitPayButton = (link) => {
    const currentLanguage = i18n.language;

    const url = new URL(link);
    const pathnameParts = url.pathname.split("/").filter((part) => part); // Remove empty parts

    // Insert the language part into the URL
    if (currentLanguage !== "en") {
      // Assuming 'en' is the default and does not need to be prefixed
      pathnameParts.splice(0, 0, currentLanguage);
    }

    // Reconstruct the URL with the language prefix
    url.pathname = "/" + pathnameParts.join("/");
    window.location.href = url.toString();
  };

  const renderRightChild = (appointment_detail) => {
    if (appointment_detail && appointment_detail.status) {
      let status = appointment_detail.status;
      let jsx = null;
      if (status === "CANCELLED") {
        jsx = (
          <>
            <Button variant="danger" disabled className="m-0-5">
              {t("patientAppointmentDetails.appointmentCancelled")}
            </Button>
          </>
        );
      } else if (
        (canEditAppointments.includes(appointment_detail.doctor.identifier) &&
          getPermissionForAction("appointment", "delete")) ||
        !isStaff ||
        isSuperUser
      ) {
        jsx = (
          <>
            {(status === "CONFIRMED" ||
              status === "COMPLETED" ||
              status === "NO_SHOW") &&
            isStaff ? (
              <>
                {status !== "COMPLETED" ? (
                  <Button
                    variant="primary"
                    className="m-0-5"
                    onClick={changeEventStatusCheckedIn}
                    disabled={evenStatusLoading}
                  >
                    {evenStatusLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Checked In"
                    )}
                  </Button>
                ) : null}
                {status !== "NO_SHOW" ? (
                  <Button
                    variant="danger"
                    onClick={changeEventStatusNoShow}
                    disabled={evenStatusLoading}
                    className="m-0-5"
                  >
                    {evenStatusLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "No Show"
                    )}
                  </Button>
                ) : null}
              </>
            ) : null}

            {!isStaff &&
            status === "PENDING" &&
            appointment_detail.requirePayment ? (
              <Button
                variant="primary"
                className="m-0-5"
                onClick={() => {
                  submitPayButton(
                    appointment_detail.paymentSources.edges[0].node.paymentLink
                  );
                }}
              >
                {t("patientAppointmentDetails.pay") +
                  " " +
                  getTotalRemaining(paymentSources, price)[1] +
                  " KWD"}
              </Button>
            ) : null}

            {status === "CONFIRMED" ||
            status === "PENDING" ||
            status === "PARTIALLY_PAID" ? (
              <Button
                variant="outline-danger m-0-5"
                onClick={cancelAppointment}
              >
                {t("patientAppointmentDetails.cancelAppointment")}
              </Button>
            ) : null}
          </>
        );
      }
      return (
        <>
          {jsx}
          {renderRefundButton(appointment_detail)}
        </>
      );
    }
  };

  const renderRefundButton = (appointment_detail) => {
    const canAlwaysRefund = original_permissions.includes("can_always_refund");

    let status = appointment_detail.status;
    if (status === "CANCELLED" || canAlwaysRefund) {
      return (
        <>
          {isStaff &&
          appointment_detail?.paymentSources?.edges.some(
            // eslint-disable-next-line eqeqeq
            (i) => i?.node?.amountAvailableForRefund != 0
          ) ? (
            <Button
              variant="primary"
              className="m-0-5"
              onClick={() => setShowRefundModal(true)}
            >
              {t("patientAppointmentDetails.refund")}
            </Button>
          ) : null}
        </>
      );
    }
    return null;
  };

  const hasConflictRecurr = () => {
    let appDay = new Date(passDateInURL).getDay();

    let docRecurringEvents = appointment_detail?.doctor?.recurringEvents?.edges;

    let filterDocRecurr = docRecurringEvents.filter(
      (i) => i.node.days.some((j) => j === appDay + "") || i.node.everyday
    );

    // eslint-disable-next-line array-callback-return
    let filterDoc = filterDocRecurr.filter((i) => {
      if (
        new Date(i.node.startDate).getTime() <=
          new Date(passDateInURL).getTime() &&
        new Date(passDateInURL).getTime() <=
          new Date(i.node.dateUntil).getTime()
      ) {
        let recurrStTime = timeToDate(passDateInURL, i.node.startTime, "h:m:s");
        let recurrEndTime = timeToDate(passDateInURL, i.node.endTime, "h:m:s");

        if (
          new Date(recurrStTime).getTime() <=
            new Date(passDateInURL).getTime() &&
          new Date(passDateInURL).getTime() <= new Date(recurrEndTime).getTime()
        ) {
          return i;
        }
      }
    });
    if (filterDoc.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const disableAddPatientButton = () => {
    let shifts = isDoctorShiftAvailable();
    let leaves = isDoctorOnLeave();
    let conflictRecurr = hasConflictRecurr();

    if (
      isOverlapAppointment ||
      !shifts ||
      leaves ||
      conflictRecurr ||
      new Date(passDateInURL).getTime() < new Date().getTime() ||
      validJSONBlockedDates.some(
        (i) => i === getValidDateStringForGraphQL(new Date(passDateInURL))
      )
    ) {
      setDisableAddButton(true);
    } else {
      setDisableAddButton(false);
    }
  };

  const isDoctorOnLeave = () => {
    if (doctorLeaveList) {
      let leaveMatch = doctorLeaveList?.leaves?.edges.filter(
        (item) =>
          getValidDateStringForGraphQL(new Date(item.node.date)) ===
          passDateInURL.split("T")[0]
      );
      if (leaveMatch.length !== 0) {
        return true;
      }
    } else {
      return false;
    }
  };

  const isDoctorShiftAvailable = () => {
    let doctorAvailability = appointment_detail?.doctor?.availability?.edges;
    let appointmentDay = new Date(passDateInURL).getDay();

    let docAvailableDays = doctorAvailability?.find(
      (item, index) => item.node.day === appointmentDay + ""
    );

    let docAvailableShift = docAvailableDays?.node?.shifts?.edges.find(
      // eslint-disable-next-line array-callback-return
      (item, index) => {
        let newStart = new Date(passDateInURL);
        let newStartTime = item.node.startTime;
        newStartTime = newStartTime.split(":");
        newStart.setHours(newStartTime[0]);
        newStart.setMinutes(newStartTime[1]);

        let newEnd = new Date(passDateInURL);
        let newEndTime = item.node.endTime;
        newEndTime = newEndTime.split(":");
        newEnd.setHours(newEndTime[0]);
        newEnd.setMinutes(newEndTime[1]);

        let start = new Date(passDateInURL);

        let end = new Date(passDateInURL);
        end.setMinutes(end.getMinutes() + appointment_detail?.duration);

        if (start >= newStart && end <= newEnd) {
          return true;
        }
      }
    );
    if (docAvailableShift) {
      return true;
    }
  };

  const editAppointmentFunc = (addPatientFromGroup) => {
    if (isStaff) {
      if (addPatientFromGroup) {
        let appDet = JSON.parse(JSON.stringify(appointment_detail));
        appDet["start"] = passDateInURL;
        let endD = new Date(passDateInURL);
        endD.setMinutes(endD.getMinutes() + appointment_detail.duration);
        appDet["end"] = endD.toISOString();
        props.history.push({
          pathname: "/create/appointment",
          search: "groupAddPatient=true",
        });
        editableAppointmentVar(appDet);
      } else {
        editableAppointmentVar(appointment_detail);
        if (passDateInURL) {
          props.history.push({
            pathname: "/create/appointment",
            search: "groupEditPatient=true",
          });
        } else {
          props.history.push({
            pathname: "/create/appointment",
            search: "isEditAppointment=true",
          });
        }
      }
    } else {
      editableAppointmentVar(appointment_detail);
      props.history.push("/patientPortal/create/appointment");
    }
  };

  const deleteEventFunc = (appId) => {
    if (window.confirm("Are you sure to delete this appointment?")) {
      let variables = {
        id: appId,
      };
      deleteAppointment({ variables });
    }
  };

  const editEventFunc = (appointment_detail) => {
    let appDet = JSON.parse(JSON.stringify(appointment_detail));
    let earliestEndDate = uniqueChildrenAppointments.find(
      (i) => i.numOfPatients > 0
    );

    if (earliestEndDate) {
      earliestEndDate = earliestEndDate.start;
    } else {
      earliestEndDate = appointment_detail.groupStartDate;
    }
    if (appointment_detail.groupStartDate) {
      earliestEndDate = new Date(earliestEndDate);
      earliestEndDate.setHours(earliestEndDate.getHours() + 24);
      appDet["earliestEndDate"] = earliestEndDate;
    }
    appDet["editGroupTherapy"] = true;
    appDet["recurringGroupSlots"] = uniqueChildrenAppointments;
    editableAppointmentVar(appDet);
    props.history.push({
      pathname: "/create/appointment",
      search: "editGroupTherapy=true&&group=true",
    });
  };

  const rightChildren = () => {
    if (appointment_detail?.doctor && appointment_detail?.patient) {
      let val = {
        appointment: appointment_detail.id,
        doctor: appointment_detail.doctor.id,
        patient: appointment_detail?.patient?.id,
        facility: appointment_detail.location.id,
        encounterType: appointment_detail.eventType.id,
        date: appointment_detail.start,
      };

      return (
        <>
          {/* WORKING what condition have to be here to show button regarding permissions?
        If appointment is paid (confirmed) - no button needed */}
          {isStaff &&
          appointment_detail &&
          (appointment_detail.status !== "CONFIRMED" ||
            (paymentSources &&
              parseInt(getTotalRemaining(paymentSources, price)[1]) > 0)) ? (
            <Link
              className="d-inline-block"
              // to={`/manual/payment`}
              to={{
                pathname: `/manual/payment`,
                state: {
                  appointment_detail: appointment_detail,
                  isAppointment: true,
                },
              }}
            >
              <Button className="m-0-5" variant="primary">
                Manual Payment
              </Button>
            </Link>
          ) : null}
          {isStaff &&
          appointment_detail &&
          getPermissionForAction("encounter", "add") &&
          appointment_detail.encounters.edges.length === 0 ? (
            <Button
              variant="primary"
              className="m-0-5"
              onClick={() => createUpdateEncounter({ variables: val })}
            >
              Create Encounter
            </Button>
          ) : null}

          {getPermissionForAction("appointment", "edit") && (
            <Button
              variant="primary"
              className="m-0-5"
              onClick={() => editAppointmentFunc(false)}
            >
              Edit Appointment
            </Button>
          )}

          {isStaff &&
          appointment_detail &&
          getPermissionForAction("encounter", "view") &&
          appointment_detail.encounters.edges.length > 0 ? (
            <Link
              className="d-inline-block m-0-5"
              to={`/encounter/detail/${appointment_detail.encounters.edges[0].node.id}`}
            >
              <Button variant="primary">Go to Encounter</Button>
            </Link>
          ) : null}
          {renderRightChild(appointment_detail)}
        </>
      );
    }

    if (
      appointment_detail?.doctorEventType?.groupSession &&
      !appointment_detail.parent
    ) {
      let isDeleteDisabled = false;
      if (appointment_detail?.groupStartDate) {
        let hasPatient = uniqueChildrenAppointments.find((i) => i.hasActiveApp);
        if (hasPatient) {
          isDeleteDisabled = true;
        }
      } else if (passDateInURL) {
        let hasPatients = appointment_detail.children.edges.length > 0;
        if (hasPatients) {
          isDeleteDisabled = true;
        }
      }
      return (
        <div className="d-flex">
          {passDateInURL || !appointment_detail?.groupStartDate ? (
            <Button
              variant="primary"
              className="appointment-detail-btn"
              onClick={() => editAppointmentFunc(true)}
              disabled={disableAddButton}
            >
              {disableAddButton ? (
                <OverlayTrigger
                  placement="bottom"
                  // show={true}
                  shouldUpdatePosition={true}
                  overlay={
                    new Date(passDateInURL).getTime() < new Date().getTime() ? (
                      <Tooltip id="tooltip-top" style={{ marginTop: 10 }}>
                        Patients cannot be added to a past session.
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip-top" style={{ marginTop: 10 }}>
                        Patients cannot be added because the practitioner is on
                        leave or has a conflicting appointment.
                      </Tooltip>
                    )
                  }
                >
                  <div> Add Patients </div>
                </OverlayTrigger>
              ) : (
                "Add Patients"
              )}
            </Button>
          ) : null}
          {appointment_detail?.groupStartDate &&
          !appointment_detail.parent &&
          !appointment_detail.patient &&
          passDateInURL ? (
            <Link
              to={{
                pathname: "/appointment/detail/" + appointment_detail.id,
                search: null,
              }}
            >
              <Button variant="primary" className="appointment-detail-btn mr-4">
                View Recurring Group Details
              </Button>
            </Link>
          ) : getPermissionForAction("appointment", "delete") ? (
            <Button
              variant="danger"
              disabled={isDeleteDisabled}
              onClick={() => deleteEventFunc(appointment_detail.id)}
              className="appointment-detail-btn"
            >
              {t("reports.delete")}
            </Button>
          ) : null}
          {appointment_detail?.groupStartDate &&
          !appointment_detail.parent &&
          !appointment_detail.patient &&
          !passDateInURL ? (
            <Button
              variant="primary"
              onClick={() => editEventFunc(appointment_detail)}
              className="appointment-detail-btn"
            >
              Edit
            </Button>
          ) : null}
        </div>
      );
    }
  };

  useEffect(() => {
    if (appointment_detail && settings_obj) {
      let today = new Date();
      let appointmentDate = new Date(appointment_detail?.start);
      let hoursToSet =
        parseInt(today.getHours()) +
        parseInt(settings_obj.settings.allow_refund_hour);

      setExpiryHour(settings_obj.settings.allow_refund_hour);

      today.setHours(hoursToSet); // to check if the new date is is greater than the appointment date
      if (appointmentDate <= today) {
        setNoRefund(true);
      } else {
        setNoRefund(false);
      }
    }

    if (appointment_detail && appointment_detail.start) {
      let today = new Date();
      let appointmentDate = new Date(appointment_detail.start);
      if (
        today < appointmentDate ||
        appointment_detail?.status === "PENDING" ||
        appointment_detail?.status === "CANCELLED"
      ) {
        setDisableButton(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_detail]);

  useInterval(() => {
    let today = new Date();
    let appointmentDate = new Date(appointment_detail?.start);
    if (disableButton) {
      if (today > appointmentDate) {
        setDisableButton(false);
      }
    }
  }, 10000);

  useEffect(() => {
    if (appointment_detail) {
      disableAddPatientButton();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_detail, isOverlapAppointment, doctorLeaveList]);

  const price = appointment_detail
    ? parseFloat(appointment_detail.price)
    : null;

  const paymentSources =
    appointment_detail &&
    appointment_detail.paymentSources &&
    appointment_detail.paymentSources.edges
      ? appointment_detail.paymentSources.edges
      : null;

  const handleCancelReason = (event) => {
    setCancelReason(event.target.value);
  };

  const getReasonText = () => {
    // ? it works, but maybe we can optimize this solution
    // ! create appointment and cancel it as staff and then as patient
    if (
      appointment_detail &&
      appointment_detail.cancelStatus &&
      appointment_detail.displayCancelStatus &&
      appointment_detail.cancelStatus === "CANCEL_BY_PATIENT" &&
      appointment_detail.status === "CANCELLED"
    ) {
      return (
        <div>
          <h6>Cancelled Status: {appointment_detail.displayCancelStatus}</h6>
          <h6>Cancelled Reason: {appointment_detail.cancelledReason}</h6>
        </div>
      );
    } else if (
      appointment_detail &&
      appointment_detail.cancelStatus &&
      appointment_detail.displayCancelStatus &&
      appointment_detail.cancelStatus === "CANCEL_BY_STAFF" &&
      appointment_detail.status === "CANCELLED"
    ) {
      return (
        <div>
          <h6>Cancelled Status: {appointment_detail.displayCancelStatus}</h6>
          <h6>Cancelled Reason: {appointment_detail.cancelledReason}</h6>
        </div>
      );
    } else if (
      appointment_detail &&
      appointment_detail.cancelStatus &&
      appointment_detail.displayCancelStatus &&
      appointment_detail.cancelStatus === "CANCEL_BY_SYSTEM" &&
      appointment_detail.status === "CANCELLED"
    ) {
      return (
        <div>
          <h6>Cancelled Status: {appointment_detail.displayCancelStatus}</h6>
          <h6>Cancelled Reason: {appointment_detail.cancelledReason}</h6>
        </div>
      );
    }
  };

  let date =
    appointment_detail && appointment_detail.start
      ? new Date(appointment_detail.start)
      : "";

  const validate = (values) => {
    let errors = {};
    if (
      values.onlineLink &&
      values.onlineLink.substr(0, 7) !== "http://" &&
      values.onlineLink.substr(0, 8) !== "https://"
    ) {
      errors.onlineLink = `Wrong format`;
    }
    return errors;
  };

  const renderPaymentDetail = () => {
    return (
      <tr className="page-table-cell">
        <td className="page-table-cell">
          <div className="page">
            <Row>
              {appointment_detail && appointment_detail.patient ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    {t("Patient Name")}:{" "}
                    {appointment_detail?.patient?.firstName}{" "}
                    {displayMiddleName(appointment_detail?.patient?.middleName)}
                    {appointment_detail?.patient?.lastName}
                  </h6>
                  <h6 className="capitalize">
                    {t("patientDashboard.phone")}:{" "}
                    <span dir="ltr">{appointment_detail?.patient?.phone}</span>
                  </h6>
                  <h6 className="capitalize">
                    {t("patientDashboard.civilid")}:{" "}
                    {appointment_detail?.patient?.civilId}
                  </h6>
                </Col>
              ) : null}
              {appointment_detail ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    {t("Appointment Date")}: {getDate(appointment_detail.start)}{" "}
                    {getForamttedTime(appointment_detail.start)}
                  </h6>
                  <h6 className="capitalize">
                    {t("patientAppointments.practitioner")}:{" "}
                    {i18n.language == "en"
                      ? appointment_detail?.doctor.titlePrefix +
                        " " +
                        appointment_detail?.doctor.firstName +
                        " " +
                        appointment_detail?.doctor.lastName
                      : appointment_detail?.doctor.arabicName}
                  </h6>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs={12}>
                <h6 className="mb-0 mt-3">
                  <b>{t("patientPaymentDetails.payments")}:</b>
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {appointment_detail && appointment_detail.paymentSources ? (
                  <div className="payment-container">
                    {appointment_detail.paymentSources.edges.map(
                      (item, index) => {
                        return item.node.status === "Paid" ? (
                          <div key={index} className="mb-2">
                            <h6 className="capitalize">
                              {t("patientPaymentDetails.amount")}:{" "}
                              {item.node.amountDebited} {item.node.currency}
                            </h6>
                            <h6 className="capitalize">
                              {t("Transaction Date")}:{" "}
                              {getDate(appointment_detail.created)}{" "}
                              {getForamttedTime(appointment_detail.start)}
                            </h6>
                            <h6 className="capitalize">
                              {t("Reference Number")}: {item.node.reference}
                            </h6>
                            {item.node.sourceType ? (
                              <>
                                {item.node.sourceType.code === "cash" ? (
                                  <h6 className="capitalize">
                                    {t("Payment Type")}:{" "}
                                    {item.node.sourceType.name}
                                  </h6>
                                ) : (
                                  <span className="d-flex flex-row">
                                    <h6 className="capitalize">
                                      {t("Payment Type")}:{" "}
                                      <span> {item.node.sourceType.name} </span>
                                    </h6>
                                    <h6>
                                      <span> (TAP) </span>
                                    </h6>
                                  </span>
                                )}
                              </>
                            ) : null}
                          </div>
                        ) : null;
                      }
                    )}
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </td>
      </tr>
    );
  };

  const RenderPrintPaymentPageComponent = () => {
    return (
      <div className="print prescriptions-print-page">
        <div className="lab-request-header">
          <Row className="h100">
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={"Logo"} className="img-fluid printLogo" src={Logo} />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>

        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderPaymentDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  let daysArr = [
    {
      key: "0",
      value: "Sunday",
    },
    {
      key: "1",
      value: "Monday",
    },
    {
      key: "2",
      value: "Tuesday",
    },
    {
      key: "3",
      value: "Wednesday",
    },
    {
      key: "4",
      value: "Thursday",
    },
    {
      key: "5",
      value: "Friday",
    },
    {
      key: "6",
      value: "Saturday",
    },
  ];

  const getSlotsAvailable = () => {
    let currSlotAvailable =
      appointment_detail.multipleScheduleSlots?.edges.find(
        (i) => i.node.start === passDateInURL
      );
    if (currSlotAvailable) {
      return currSlotAvailable.node.slotsAvailable;
    } else {
      return appointment_detail.slotsAvailable;
    }
  };
  const openInNewTab = (url) => {
    setTimeout(() => {
      window.open(url, "_blank", "noopener,noreferrer");
    }, 300);
  };
  const Label = ({ label, value }) => (
    <div className="">
      <div
        className="mr-2"
        style={{
          color: "gray",
          fontSize: 12,
        }}
      >
        {label}
      </div>
      <div>
        <b>{value}</b>
      </div>
    </div>
  );

  return (
    <>
      <Base
        title={
          isStaff
            ? t("patientAppointmentDetails.appointmentDetails")
            : appointment_detail
            ? `${getDate(appointment_detail?.start)} ${
                appointment_detail?.doctorEventType?.title?.name
              } 
          ${t("with")} ${
                i18n.language == "en"
                  ? "Dr. " +
                    appointment_detail.doctor.firstName +
                    " " +
                    appointment_detail.doctor.lastName
                  : appointment_detail.doctor.arabicName
              }`
            : ""
        }
        showHeader={true}
        back="/patients"
        isPatientPortal={!isStaff}
        rightChild={rightChildren()}
        containerClass="appointment-detail-page"
        isAppointmentDetailPage={true}
      >
        {loading ? (
          <Preloader />
        ) : (
          <>
            {!isStaff ? (
              <div
                className="card"
                style={{
                  backgroundColor: "white",
                  border: 0,
                }}
              >
                <div className="card-body">
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div>{appointment_detail?.identifier}</div>
                  </div>
                  <Label
                    label={t("patientAppointmentDetails.date")}
                    value={getDate(new Date(appointment_detail?.start))}
                  />
                  <Label
                    label={t("patientAppointmentDetails.time")}
                    value={getForamttedTime(
                      new Date(appointment_detail?.start)
                    )}
                  />
                  <Label
                    label={t("patientAppointmentDetails.type")}
                    value={appointment_detail?.doctorEventType?.title?.name}
                  />
                  <Label
                    label={t("patientAppointmentDetails.status")}
                    value={appointment_detail?.status}
                  />
                  <Formik
                    onSubmit={onPatientNotesSubmit}
                    initialValues={initiaPatientNoteslValues}
                    innerRef={formikRef}
                    validate={validate}
                  >
                    {({ handleSubmit, handleChange, values, errors }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <Row className={"mt-3 pb-4"}>
                          <Col sm={12} md={12} lg={6} xs={{ order: "last" }}>
                            <div className="d-flex align-items-end">
                              <h6 className="d-inline-block">
                                <b>{t("patientAppointmentDetails.notes")}</b>
                              </h6>
                              <div className="d-inline-block ml-2">
                                <Button
                                  variant="link"
                                  type="submit"
                                  className={
                                    enablePatientEdit ? "" : " d-none py-0"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faSave}
                                    className="mx-2 "
                                  />
                                  <b>
                                    {" "}
                                    {t("patientAppointmentDetails.save")}{" "}
                                    {enablePatientEdit}
                                  </b>
                                </Button>
                                <Button
                                  variant="link"
                                  type="button"
                                  onClick={() => {
                                    setEnablePatientEdit(true);
                                  }}
                                  className={
                                    enablePatientEdit ? " d-none py-0" : ""
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="mx-2 "
                                  />
                                  <b>
                                    {" "}
                                    {t("patientAppointmentDetails.edit")}{" "}
                                    {enablePatientEdit}
                                  </b>
                                </Button>
                              </div>
                            </div>
                            <div>
                              {enablePatientEdit ? (
                                <Form.Control
                                  autoComplete="off"
                                  as="textarea"
                                  rows={5}
                                  name="patientNotes"
                                  value={values.patientNotes}
                                  onChange={handleChange}
                                />
                              ) : (
                                <div>{values.patientNotes}</div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            ) : (
              <>
                <Row>
                  <Col md={6} className="mb-3">
                    <h6>
                      <b>
                        {t("patientAppointmentDetails.appointmentInformation")}
                      </b>
                    </h6>

                    {isStaff &&
                    appointment_detail?.creator &&
                    appointment_detail?.created ? (
                      <div className="mb-3">
                        <h6>
                          {t("patientAppointmentDetails.CreatedBy")}:
                          {appointment_detail.creator.firstName}
                          {appointment_detail.creator.lastName}
                        </h6>
                        <h6>
                          {t("patientAppointmentDetails.CreatedDate")}:
                          {getDate(new Date(appointment_detail.created))}
                          {getForamttedTime(
                            new Date(appointment_detail.created)
                          )}
                        </h6>
                      </div>
                    ) : null}

                    {appointment_detail?.onlineLink &&
                    appointment_detail?.location?.title.includes("Online") ? (
                      <div>
                        <a
                          className="d-inline-block"
                          href={appointment_detail?.onlineLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant="primary"
                            className="py-1 px-2 mb-2"
                            onClick={() =>
                              openInNewTab(appointment_detail?.onlineLink)
                            }
                          >
                            {t("patientAppointments.joinSession")}
                          </Button>
                        </a>
                      </div>
                    ) : null}
                    {appointment_detail &&
                    appointment_detail.intakeForms.edges.length > 0 ? (
                      <div className="mt-2">
                        {!isStaff ? (
                          <h6 className="d-inline-block">
                            <b>
                              {t("patientAppointmentDetails.fillTheFollowing")}
                            </b>
                          </h6>
                        ) : (
                          <h6>The patient has the following forms to fill:</h6>
                        )}
                        <div>
                          {appointment_detail.intakeForms.edges.map((i) => {
                            if (isStaff) {
                              return (
                                <div className="m-2 mb-2">
                                  <FontAwesomeIcon
                                    icon={faFile}
                                    className="mr-1  "
                                  />
                                  {i.node.dynamicForm.name}
                                </div>
                              );
                            } else {
                              return (
                                <>
                                  <Link
                                    target="_blank"
                                    to={
                                      "/patientPortal/consultation/form"
                                    }
                                  >
                                    <Button variant="link" className="p-0">
                                      <FontAwesomeIcon
                                        icon={faFile}
                                        className="mr-1 "
                                      />
                                      {i.node.dynamicForm.name}
                                    </Button>
                                  </Link>
                                  <br />
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                    {appointment_detail ? (
                      <div>
                        <h6
                          id="id-app-identifer"
                          data-appointment-identifier={
                            appointment_detail?.identifier
                          }
                        >
                          <b>{appointment_detail?.identifier}</b>
                        </h6>
                        {appointment_detail.start &&
                        !passDateInURL &&
                        appointment_detail.patient ? (
                          <h6>
                            {t("patientAppointmentDetails.date")}:
                            <span> {getDay(date.getDay())} </span>
                            <span>{getDate(date)}</span>
                          </h6>
                        ) : null}
                        {appointment_detail.start &&
                        !passDateInURL &&
                        appointment_detail.patient ? (
                          <h6>
                            {t("patientAppointmentDetails.time")}:
                            <span> {getForamttedTime(date)}</span>
                          </h6>
                        ) : null}

                        {passDateInURL ? (
                          <h6>
                            {t("patientAppointmentDetails.date")}:
                            <span>
                              {getDay(new Date(passDateInURL).getDay())}
                            </span>
                            <span>{getDate(new Date(passDateInURL))}</span>
                          </h6>
                        ) : null}
                        {passDateInURL ? (
                          <h6>
                            {t("patientAppointmentDetails.time")}:
                            <span>
                              {getForamttedTime(new Date(passDateInURL))}
                            </span>
                          </h6>
                        ) : null}

                        {appointment_detail.doctorEventType?.title.name ? (
                          <h6>
                            {t("patientAppointmentDetails.type")}:
                            <span className="capitalize">
                              {appointment_detail.doctorEventType?.title.name}
                            </span>
                          </h6>
                        ) : (
                          ""
                        )}
                        {appointment_detail.location.title ? (
                          <h6>
                            {t("patientAppointmentDetails.location")}:
                            {appointment_detail.location.title}
                          </h6>
                        ) : null}
                        {appointment_detail?.patient?.discount ? (
                          <h6>
                            {t("patientAppointmentDetails.duration")}:
                            {appointment_detail.discount} %
                          </h6>
                        ) : null}
                        {appointment_detail.price ? (
                          <h6>
                            {t("patientAppointmentDetails.price")}:
                            {appointment_detail.price} KWD
                          </h6>
                        ) : null}
                        {appointment_detail.status === "CANCELLED" &&
                        (appointment_detail.payments.edges[0]?.node.paid ===
                          true ||
                          amountAvailableRefund) ? (
                          <>
                            {isStaff &&
                            appointment_detail.paymentSources.edges[0] ? (
                              <h6>
                                {t("patientAppointmentDetails.refundRemaining")}
                                : {amountAvailableRefund.toFixed(3)} KWD
                              </h6>
                            ) : null}
                            {appointment_detail.paymentSources.edges[0] ? (
                              <h6>
                                {t(
                                  "patientAppointmentDetails.refundedToPatient"
                                )}
                                :
                                {(
                                  appointment_detail.price -
                                  amountAvailableRefund
                                ).toFixed(3)}
                                KWD
                              </h6>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {appointment_detail.paymentSources.edges[0] ? (
                              <h6>
                                {t("patientAppointmentDetails.paid")}:
                                {getTotalRemaining(paymentSources, price)[0]}
                                KWD
                              </h6>
                            ) : null}
                            {appointment_detail.paymentSources.edges[0] ? (
                              <h6>
                                {t("patientAppointmentDetails.totalRemaining")}:
                                {getTotalRemaining(paymentSources, price)[1]}
                                KWD
                              </h6>
                            ) : null}
                          </>
                        )}
                        {appointment_detail.status &&
                        appointment_detail.patient ? (
                          <h6 className="capitalize">
                            {t("patientAppointmentDetails.status")}:
                            {replaceUnderscoreAndDashBySpace(
                              appointment_detail.status.toLowerCase()
                            )}
                          </h6>
                        ) : (
                          <div>
                            {isStaff && passDateInURL ? (
                              <h6>
                                {t(
                                  "patientAppointmentDetails.totalRemainingSeats"
                                )}
                                : {appointment_detail.slotsAvailable}
                              </h6>
                            ) : null}
                            {isStaff && passDateInURL ? (
                              <h6>
                                {t("patientAppointmentDetails.availableSeats")}:{" "}
                                {getSlotsAvailable()}
                              </h6>
                            ) : null}
                          </div>
                        )}
                        {/* {appointment_detail.onlineLink && isStaff ? <h6>Link: <a className="payment-link-icon" rel="noreferrer" target="_blank" href={appointment_detail.onlineLink}>{appointment_detail.onlineLink}</a></h6> : null} */}
                        {isStaff ? getReasonText() : null}
                      </div>
                    ) : null}
                  </Col>
                  {appointment_detail?.doctorEventType?.groupSession &&
                  !appointment_detail.parent ? null : (
                    <Col md={6} className="patient-info-appointment">
                      <h6>
                        <b>
                          {t("patientAppointmentDetails.patientInformation")}
                        </b>
                      </h6>
                      {appointment_detail ? (
                        <div>
                          <h6 className="d-inline-block">
                            {t("patientAppointmentDetails.name")}:
                            <Link
                              className="d-inline-block"
                              to={`/patient/record/${
                                appointment_detail.patient
                                  ? appointment_detail?.patient?.id +
                                    "/?identifier=" +
                                    appointment_detail?.patient?.identifier
                                  : null
                              }`}
                            >
                              <Button variant="link" className="p-0">
                                {appointment_detail?.patient?.firstName +
                                  " " +
                                  displayMiddleName(
                                    appointment_detail?.patient?.middleName
                                  ) +
                                  appointment_detail?.patient?.lastName}
                              </Button>
                            </Link>
                          </h6>
                          {appointment_detail?.patient?.identifier ? (
                            <h6>
                              {t("patientAppointmentDetails.fileNumber")}:
                              {appointment_detail?.patient?.identifier}
                            </h6>
                          ) : null}
                          {appointment_detail?.patient?.dob ? (
                            <h6 className="capitalize">
                              {t("patientAppointmentDetails.age")}:
                              {getPatientsAge(appointment_detail?.patient?.dob)}
                            </h6>
                          ) : null}
                          {appointment_detail?.patient?.gender ? (
                            <h6>
                              {t("patientAppointmentDetails.gender")}:
                              <span className="capitalize">
                                {appointment_detail?.patient?.gender.toLowerCase()}
                              </span>
                            </h6>
                          ) : null}
                          {appointment_detail?.patient?.phone ? (
                            <h6>
                              {t("patientAppointmentDetails.phone")}:
                              <span dir="ltr">
                                {appointment_detail?.patient?.phone}
                              </span>
                            </h6>
                          ) : null}
                          {appointment_detail?.patient?.discount ? (
                            <h6>
                              Discount provided: {appointment_detail.discount} %
                            </h6>
                          ) : null}
                        </div>
                      ) : null}
                    </Col>
                  )}
                  <Col md={6} className="d-flex justify-content-start">
                    <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                      <h6>
                        <b>
                          {t(
                            "patientAppointmentDetails.practitionerInformation"
                          )}
                        </b>
                      </h6>
                      {appointment_detail ? (
                        <div>
                          <h6>
                            {t("patientAppointmentDetails.practitionerName")}:
                            {i18n.language == "en"
                              ? (appointment_detail?.doctor?.titlePrefix
                                  ? appointment_detail?.doctor?.titlePrefix
                                  : "") +
                                " " +
                                appointment_detail?.doctor.firstName +
                                " " +
                                appointment_detail?.doctor.lastName
                              : appointment_detail?.doctor.arabicName}
                          </h6>
                          {isStaff ? (
                            <h6>
                              {t(
                                "patientAppointmentDetails.practitionerDescription"
                              )}
                              :
                              {i18n.language == "en"
                                ? appointment_detail?.doctor.description
                                : appointment_detail?.doctor.description}
                            </h6>
                          ) : null}
                          {isStaff && appointment_detail.doctor.email ? (
                            <h6>
                              {t("patientAppointmentDetails.practitionerEmail")}
                              : {appointment_detail.doctor.email}
                            </h6>
                          ) : null}
                          {isStaff && appointment_detail.doctor.phone ? (
                            <h6>
                              {t("patientAppointmentDetails.practitionerPhone")}
                              :
                              <span dir="ltr">
                                {appointment_detail.doctor.phone}
                              </span>
                            </h6>
                          ) : null}
                        </div>
                      ) : null}

                      {appointment_detail?.doctorEventType?.groupSession &&
                      appointment_detail.groupStartDate &&
                      appointment_detail.groupEndDate &&
                      appointment_detail.groupDays.length > 0 ? (
                        <div className="my-4">
                          <h6>
                            <b>Group Information</b>
                          </h6>
                          <h6>
                            Group Start Date :
                            {getDate(
                              new Date(appointment_detail.groupStartDate)
                            )}
                          </h6>
                          <h6>
                            Group End Date :
                            {getDate(new Date(appointment_detail.groupEndDate))}
                          </h6>
                          <h6>
                            {t("patientAppointmentDetails.time")}:
                            <span>
                              {getForamttedTime(
                                new Date(appointment_detail.start)
                              )}
                            </span>
                          </h6>
                          <h6>
                            Group Days :
                            {appointment_detail.groupDays
                              .map((i) => {
                                return daysArr[i].value;
                              })
                              .join(" ,")}
                          </h6>

                          {validJSONBlockedDates.length > 0 ? (
                            <h6>
                              Blocked Dates:
                              {validJSONBlockedDates
                                .map((i) => {
                                  return getDate(new Date(i));
                                })
                                .join(", ")}
                            </h6>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {appointment_detail?.doctorEventType?.groupSession &&
                !appointment_detail.parent ? null : (
                  <>
                    <Formik
                      onSubmit={onSubmit}
                      initialValues={initialValues}
                      innerRef={formikRef}
                      validate={validate}
                    >
                      {({ handleSubmit, handleChange, values, errors }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <Row className={"mt-3 pb-4"}>
                            <Col
                              sm={12}
                              md={12}
                              lg={6}
                              className="mt-2"
                              xs={{ order: "last" }}
                            >
                              <div className="d-flex align-items-end">
                                <h6 className="d-inline-block">
                                  <b>{t("patientAppointmentDetails.notes")}</b>
                                </h6>
                                {getPermissionForAction(
                                  "appointment",
                                  "edit"
                                ) ? (
                                  <div className="d-inline-block ml-2">
                                    <Button
                                      variant="link"
                                      type="submit"
                                      className={
                                        enableEdit ? "" : " d-none py-0"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faSave}
                                        className="mr-1 "
                                      />
                                      <b> Save {enableEdit}</b>
                                    </Button>
                                    <Button
                                      variant="link"
                                      type="button"
                                      onClick={() => {
                                        setEnableEdit(true);
                                      }}
                                      className={
                                        enableEdit ? " d-none py-0" : ""
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="mr-1 "
                                      />
                                      <b> Edit {enableEdit}</b>
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                {enableEdit ? (
                                  <Form.Control
                                    autoComplete="off"
                                    as="textarea"
                                    rows={5}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                  />
                                ) : (
                                  <div>{values.description}</div>
                                )}
                                {/* {enableEdit ?
                        <div>
                          <Form.Group className="p-0 mt-2 m-0">
                            <Form.Label className="p-0 mr-1">Appointment Link:</Form.Label>
                            <Form.Control
                              autoComplete="off"
                              as="input"
                              name="onlineLink"
                              value={values.onlineLink}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {errors.onlineLink && (
                            <div className="online-link-error valid-feedback">{errors.onlineLink}</div>
                          )}
                        </div> : null
                      } */}
                                {isStaff ? (
                                  <Form.Group className="d-flex p-0 m-0">
                                    <Form.Label className="d-inline-block p-0">
                                      {t(
                                        "patientAppointmentDetails.requirePayment"
                                      )}
                                    </Form.Label>
                                    <Form.Check
                                      type="checkbox"
                                      disabled={!enableEdit}
                                      name="requirePayment"
                                      onChange={handleChange}
                                      value={values.requirePayment}
                                      checked={values.requirePayment}
                                      className="d-inline-block pl-3 require-payment-checkbox"
                                    />
                                  </Form.Group>
                                ) : null}
                                {/* <Form.Group as={Row}>
                      <Form.Label column md={6} className="text-left pr-0">
                        Should be seen
                      </Form.Label>
                      <Col md={6}>
                        <DatePicker
                          disabled={!enableEdit}
                          selected={shouldBeSeen ? shouldBeSeen : ''}
                          onChange={(info) => setShouldBeSeen(info)}
                          name="should_be_seen"
                          className="form-control"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="dd/MMM/yyyy - h:mm aa"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </Col>
                    </Form.Group> */}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                    <Formik
                      onSubmit={onPatientNotesSubmit}
                      initialValues={initiaPatientNoteslValues}
                      innerRef={formikRef}
                      validate={validate}
                    >
                      {({ handleSubmit, handleChange, values, errors }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <Row className={"mt-3 pb-4"}>
                            <Col sm={12} md={12} lg={6} xs={{ order: "last" }}>
                              <div className="d-flex align-items-end">
                                <h6 className="d-inline-block">
                                  <b>Patient notes</b>
                                </h6>
                                {getPermissionForAction(
                                  "appointment",
                                  "edit"
                                ) ? (
                                  <div className="d-inline-block ml-2">
                                    <Button
                                      variant="link"
                                      type="submit"
                                      className={
                                        enablePatientEdit ? "" : " d-none py-0"
                                      }
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faSave}
                                        className="mr-1 "
                                      />
                                      <b> Save {enablePatientEdit}</b>{" "}
                                    </Button>
                                    <Button
                                      variant="link"
                                      type="button"
                                      onClick={() => {
                                        setEnablePatientEdit(true);
                                      }}
                                      className={
                                        enablePatientEdit ? " d-none py-0" : ""
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="mr-1 "
                                      />{" "}
                                      <b> Edit {enablePatientEdit}</b>{" "}
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                {enablePatientEdit ? (
                                  <Form.Control
                                    autoComplete="off"
                                    as="textarea"
                                    rows={5}
                                    name="patientNotes"
                                    value={values.patientNotes}
                                    onChange={handleChange}
                                  />
                                ) : (
                                  <div>{values.patientNotes}</div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
                <Row>
                  {appointment_detail &&
                  ((appointment_detail?.doctorEventType?.groupSession &&
                    appointment_detail.parent) ||
                    !appointment_detail?.doctorEventType?.groupSession) ? (
                    <Col sm={12} md={12}>
                      <PaymentDetail event={appointment_detail} />
                      {appointment_detail?.refundRequests?.edges?.length > 0 ? (
                        <RefundRequests
                          event={appointment_detail}
                          REQUEST_EVENT={REQUEST_EVENT}
                          appointment={true}
                        />
                      ) : null}
                    </Col>
                  ) : null}
                </Row>
                {appointment_detail?.doctorEventType?.groupSession &&
                !appointment_detail.parent &&
                (passDateInURL ||
                  (appointment_detail.children.edges.length > 0 &&
                    !appointment_detail.groupStartDate)) ? (
                  <Card>
                    <Card.Body>
                      <Card.Title>Patients</Card.Title>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Patient</th>
                            <th>Appointment Link</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {((passDateInURL &&
                            appointment_detail.children.edges.some(
                              (child) => child.node.start === passDateInURL
                            )) ||
                            !appointment_detail.groupStartDate) &&
                          appointment_detail.children.edges.length > 0 ? (
                            appointment_detail.children.edges.map(
                              (child, index) => {
                                if (passDateInURL) {
                                  if (child.node.start === passDateInURL) {
                                    return (
                                      <tr key={index}>
                                        <td className="align-middle">
                                          {child.node.patient.firstName}{" "}
                                          {child.node.patient.lastName}
                                        </td>
                                        <td className="align-middle">
                                          <Link
                                            to={{
                                              pathname:
                                                "/appointment/detail/" +
                                                child.node.id,
                                              search:
                                                "passDateInURL=" +
                                                passDateInURL,
                                            }}
                                          >
                                            Link
                                          </Link>
                                        </td>
                                        <td className="align-middle">
                                          {child.node.status}
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return (
                                    <tr key={index}>
                                      <td className="align-middle">
                                        {child.node.patient.firstName}{" "}
                                        {child.node.patient.lastName}
                                      </td>
                                      <td className="align-middle">
                                        <Link
                                          to={{
                                            pathname:
                                              "/appointment/detail/" +
                                              child.node.id,
                                            search:
                                              "passDateInURL=" + passDateInURL,
                                          }}
                                        >
                                          Link
                                        </Link>
                                      </td>
                                      <td className="align-middle">
                                        {child.node.status}
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                            )
                          ) : (
                            <td className="align-middle">No Patients Added</td>
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                ) : null}
                {appointment_detail?.doctorEventType?.groupSession &&
                !appointment_detail.parent &&
                !passDateInURL &&
                appointment_detail.groupStartDate ? (
                  <Card>
                    <Card.Body>
                      <Card.Title>Recurring appointments</Card.Title>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Appointment Date</th>
                            <th className="text-center">Slots Booked</th>
                            <th className="text-center">Session Link</th>
                            <th className="text-center">
                              Session Availability
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {uniqueChildrenAppointments.length > 0 ? (
                            uniqueChildrenAppointments.map(
                              (childApp, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="align-middle">
                                      <h6>
                                        <span>
                                          {" "}
                                          {getDay(
                                            new Date(childApp.start).getDay()
                                          )}{" "}
                                        </span>
                                        <span>
                                          {getDate(new Date(childApp.start))}
                                        </span>
                                      </h6>
                                    </td>
                                    <td className="align-middle text-center">
                                      <h6>{childApp.numOfPatients}</h6>
                                    </td>
                                    <td className="align-middle text-center">
                                      <Link
                                        to={{
                                          pathname:
                                            "/appointment/detail/" +
                                            childApp.id,
                                          search:
                                            "passDateInURL=" + childApp.start,
                                        }}
                                      >
                                        Link
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {" "}
                                      {validJSONBlockedDates.some(
                                        (i) =>
                                          getValidDateStringForGraphQL(
                                            new Date(childApp.start)
                                          ) === i
                                      )
                                        ? "Disabled"
                                        : "Available"}{" "}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td className="align-middle">
                                No Recurring Appointments
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                ) : null}
              </>
            )}

            <Modal show={showAlert} onHide={() => setShowAlert(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {t("patientAppointmentDetails.cancelAppointment")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  {isStaff
                    ? "Cancel Appointment for " +
                      (appointment_detail
                        ? appointment_detail?.patient?.firstName +
                          " " +
                          displayMiddleName(
                            appointment_detail?.patient?.middleName
                          ) +
                          appointment_detail?.patient?.lastName
                        : " Patient " + "?")
                    : t("patientAppointmentDetails.areYouSure")}

                  {noRefund ? (
                    <h6 className="textPrimary">
                      {t("patientAppointmentDetails.youWill", {
                        allow_refund_hour: expiryHour,
                      })}
                    </h6>
                  ) : null}
                </>
                {isStaff ? (
                  <div className="d-flex">
                    <Form.Check
                      className=""
                      type="checkbox"
                      value={hold}
                      checked={hold}
                      onChange={() => setHold(!hold)}
                    />
                    Hold the Slot
                  </div>
                ) : null}
                <div className="mt-4">
                  {isStaff ? (
                    <Form.Group className="p-0 m-0">
                      <Form.Label className="p-0">Reason</Form.Label>
                      <Form.Control
                        required
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        name="cancelled_reason"
                        value={cancelReason}
                        onChange={handleCancelReason}
                      />
                    </Form.Group>
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowAlert(false)}>
                  {isStaff ? "Cancel" : t("patientAppointmentDetails.no")}
                </Button>
                <Button variant="primary " onClick={cancelAppointmentClicked}>
                  {isStaff ? "Submit" : t("patientAppointmentDetails.yes")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
        <CustomModals
          dialog
          className="modal60h"
          showModal={showRefundModal}
          modalHeader="Refund Request"
          setShowModal={setShowRefundModal}
        >
          {appointment_detail && appointment_detail.id ? (
            <>
              <Row className="my-4">
                <Col xs={12} sm={12} md={12}>
                  {t("patientAppointmentDetails.availableRefund")} :{" "}
                  {amountAvailableRefund} KWD
                </Col>
              </Row>
              <RefundRequestModal
                appointment_detail_id={appointment_detail.id}
                setShowRefundModal={setShowRefundModal}
                showRefundModal={showRefundModal}
                REQUEST_EVENT={REQUEST_EVENT}
                amountAvailableRefund={amountAvailableRefund}
              />
            </>
          ) : null}
        </CustomModals>
      </Base>

      <RenderPrintPaymentPageComponent />
    </>
  );
}
export default withRouter(AppointmentDetails);
