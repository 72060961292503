//@ts-nocheck
import React from "react";
import { TextField, FormControl, FormHelperText } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const StandAloneDatePicker = ({
  label,
  value,
  onChange,
  required = false,
  helperText = "",
  error = false,
  ...props
}) => {
  const labelId = `datepicker-${label.replace(/\s+/g, "-").toLowerCase()}-label`;

  console.log("value", value);


  return (
    <FormControl fullWidth required={required} error={error}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          label={required ? `${label}*` : label}
          value={value}
          onChange={onChange}
          format="DD-MM-YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              value={value ? dayjs(value).format("DD-MM-YYYY") : ""}
              labelId={labelId}
              required={required}
              error={error}
              helperText={helperText}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default StandAloneDatePicker;
