import React from "react";
import { gql, useQuery } from "@apollo/client";
import Calendar from "./Calendar";
import * as Sentry from "@sentry/browser";

const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

const CALENDAR_SLOTS = gql`
  query receiveDate {
    calendarSlots @client
  }
`;

const REFERRAL_OBJECT = gql`
  query receiveReferralData {
    referralObj @client
  }
`;
export default function CalendarHoc(props) {
  const {
    selectedDoctor,
    doctorLeavesList,
    fullCalendarRef,
    myAppointments,
    selectedSession,
    GetEvents,
    doctorResources,
    settings_obj,
    error,
    selectedDocList,
    doctorsList,
    appFetchVariables,
    appointmentCreate,
    setVariablesToBeFetched,
    activeDocShiftArr,
    variablesToBeFetched,
    setActiveDocShiftArr,
    stopPolling,
    userDetails,
    reqPrevNextData,
    startPolling,
  } = props;

  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);

  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT, {
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("SELECTED_DATE_EVENT error " + e);
      }
    },
  });

  const { data: referralObjectData = [] } = useQuery(REFERRAL_OBJECT);

  if (error) return `Error! ${error.message}`;

  return (
    <Calendar
      error={error}
      referralObjectData={referralObjectData}
      myAppointments={myAppointments}
      selectedDoctor={selectedDoctor}
      selectedDateEvent={selectedDateEvent}
      doctorLeavesList={doctorLeavesList}
      fullCalendarRef={fullCalendarRef}
      selectedSession={selectedSession}
      calendarData={calendarData}
      GetEvents={GetEvents}
      doctorResources={doctorResources}
      settings_obj={settings_obj}
      doctorsList={doctorsList}
      selectedDocList={selectedDocList}
      appFetchVariables={appFetchVariables}
      appointmentCreate={appointmentCreate}
      setVariablesToBeFetched={setVariablesToBeFetched}
      setActiveDocShiftArr={setActiveDocShiftArr}
      activeDocShiftArr={activeDocShiftArr}
      stopPolling={stopPolling}
      userDetails={userDetails}
      variablesToBeFetched={variablesToBeFetched}
      reqPrevNextData={reqPrevNextData}
      startPolling={startPolling}
    />
  );
}
