import React from "react";
import { useField } from "formik";
import { FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

const FormikSelect = ({
  label,
  children,
  labelId,
  required,
  helperTextKey = "",
  multiple = false, // Add a prop to support multiple selections
  ...props
}: any) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const helperText = helperTextKey ? t(helperTextKey) : "";

  // Adjust the field value for multiple select support
  const value = multiple
    ? Array.isArray(field.value)
      ? field.value
      : []
    : field.value;

  return (
    <FormControl fullWidth error={!!errorText} required={required}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        multiple={multiple}
        {...field}
        value={value} // Ensure value is correctly handled for multiple selections
        {...props}
      >
        {children}
      </Select>
      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  );
};

export default FormikSelect;
