import React, { useMemo, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  getErrorMessage,
  SNACK_DURATION,
  error_options,
  success_options,
  concatAllErrors,
  ERROR_MESSAGE,
} from "Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";

const SpecificPaymentTypes = {
  link: "TAP (Go Collect)",
  iban: "IBAN",
  knet: "Knet",
};

const MANUAL_PAYMENT = gql`
  mutation createUpdatePayment(
    $patient: ID!
    $reason: String!
    $doctor: ID
    $amount: Float!
    $paymentType: String!
    $specificPaymentType: String
    $notes: String
    $patientReport: ID!
  ) {
    createUpdatePayment(
      input: {
        patient: $patient
        reason: $reason
        doctor: $doctor
        amount: $amount
        paymentType: $paymentType
        specificPaymentType: $specificPaymentType
        notes: $notes
        patientReport: $patientReport
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        id
        paid
        paymentType
      }
      activeSource {
        sourceType {
          name
        }
        paymentType
        specificPaymentType
      }
    }
  }
`;

const ReportManualPayment = (props) => {
  const [paymentType, setPaymentType] = useState("");
  const [notes, setNotes] = useState("");
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const history = useHistory();

  const { modalOpen, setModalOpen, activeReport, getPatientReports } = props;
  const { patient, doctor, id, payments } = activeReport?.node || {};

  const [manualPayment] = useMutation(MANUAL_PAYMENT, {
    onCompleted: ({ createUpdatePayment }) => {
      if (
        createUpdatePayment?.errors &&
        createUpdatePayment?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdatePayment?.errors
        );
        openSnackbarError(error_messages_string, [SNACK_DURATION]);
      } else if (createUpdatePayment.obj) {
        openSnackbarSuccess("Payment Successsful.");

        getPatientReports();

        setModalOpen(false);
        history.push("/accounts/payment/detail/" + createUpdatePayment.obj.id);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("MANUAL_PAYMENT error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const renderData = useMemo(() => {
    return (
      <div className="row">
        <div className="col-4">Patient name</div>
        <div className="col-8">
          {patient?.firstName} {patient?.middleName} {patient?.lastName}
        </div>
        <div className="col-4">Reason</div>
        <div className="col-8">Report</div>
        <div className="col-4">Amount</div>
        <div className="col-8">{payments?.edges?.[0]?.node?.amount}</div>
        <div className="col-4">Payment method</div>
        <div className="col-8">Offline</div>
        <div className="col-4">
          <Form.Label classNameName="text-right pr-0 required">
            <span classNameName="">Specific Payment Type *</span>
          </Form.Label>
        </div>
        <div className="col-8 mb-1">
          <Form.Control
            autoComplete="off"
            as="select"
            name="specific_payment_type"
            onChange={(event) => setPaymentType(event.target.value)}
          >
            <option value="">Select Specific Payment Type</option>
            {Object.entries(SpecificPaymentTypes).map(([key, value], index) => {
              return (
                <option value={key} key={index}>
                  {value}
                </option>
              );
            })}
          </Form.Control>
        </div>
        <div className="col-4">
          <Form.Label classNameName="text-right pr-0 required">
            <span classNameName="">Notes *</span>
          </Form.Label>
        </div>
        <div className="col-8">
          <Form.Control
            autoComplete="off"
            as="textarea"
            rows={5}
            type="textarea"
            name="note"
            onChange={(event) => setNotes(event.target.value)}
          />
        </div>
      </div>
    );
  }, [
    patient?.firstName,
    patient?.lastName,
    patient?.middleName,
    payments?.edges,
  ]);

  const handleSubmit = () => {
    if (!paymentType || !notes) {
      alert("Please fill up the required fields.");
      return;
    }

    const payload = {
      patient: patient?.id,
      reason: "report",
      amount: payments?.edges?.[0]?.node?.amount,
      specificPaymentType: paymentType,
      paymentType: "manual",
      patientReport: id,
      doctor: doctor?.id,
    };

    manualPayment({ variables: payload });
  };

  return (
    <Modal
      show={modalOpen}
      onHide={() => setModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogclassNameName="modal-60w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Manual payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderData}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="secondary" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportManualPayment;
