import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { TextField } from "@mui/material";

// Props could include additional Formik and MUI TextField props as needed
function FormikTextField({
  name,
  labelKey,
  helperTextKey = "",
  error,
  required = false,
  ...props
}: any) {
  const { t } = useTranslation();

  return (
    <Field
      as={TextField}
      label={t(labelKey)}
      name={name}
      fullWidth
      variant="outlined"
      margin="dense"
      helperText={helperTextKey ? t(helperTextKey) : ""}
      error={Boolean(error)}
      required={required}
      {...props}
    />
  );
}

export default FormikTextField;
