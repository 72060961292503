import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Base from './base.js';
import Modals from '../component/Modal/modal';

function LabAndRequests(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <Base title={'LABS & REQUESTS'} showHeader={true}>
      <Row>
        <Col xs={12} md={10} className="d-flex-column-str  mb-4">
          <span className="textPrimaryBold">LAB 1 TEMPLATE</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str  mb-4">
          <span className="textPrimaryBold">LAB 2 TEMPLATE</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str  mb-4">
          <span className="textPrimaryBold">LAB 3 TEMPLATE</span>
        </Col>
        <Col xs={12} md={10} className="d-flex-column-str  mb-4">
          <span className="textPrimaryBold">LAB 4 TEMPLATE</span>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={12} md={12} className="d-flex justify-content-end button-below">
          <Button variant="primary" size="md" className="mr-5 btn-pg-low" onClick={() => setShowModal(true)}>
            ADD NEW
          </Button>
        </Col>
      </Row>
      <Modals
        showModal={showModal}
        modalHeader={'ADD NEW LAB AND REQUESTS'}
        setShowModal={setShowModal}>
        <Row className="mb-5 mt-5">
          <Col xs={12} sm={12} md={12}>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={3} className="text-right">
                Name
              </Form.Label>
              <Col sm={12} md={8}>
                <Form.Control autoComplete="off" type="text" name="name" placeholder="Text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={12} md={3} className="text-right">
                Template
              </Form.Label>
              <Col sm={12} md={8}>
                <Form.Control autoComplete="off" as="select" name="template" >
                  <option value="">Template 1</option>
                  <option value="">Template 2</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-2 mt-5">
          <Col
            xs={12}
            sm={12}
            md={12}
            className="d-flex flex-wrap justify-content-center justify-content-md-between">
            <Button variant="primary" size="md" className="mr-2 mb-1">
              CANCEL
            </Button>
            <Button variant="primary" size="md" className="mr-2 mb-1">
              SAVE
            </Button>
            <Button variant="primary" size="md" className="mr-2 mb-1">
              DELETE
            </Button>
            <Button variant="primary" size="md" className="mb-1">
              ADD ANOTHER
            </Button>
          </Col>
        </Row>
      </Modals>
    </Base>
  );
}
export default LabAndRequests;
